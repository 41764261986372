<mat-sidenav-container
   	class="mango-root-container">
  <mat-sidenav
	  #drawer
	  mode="side"
	  role="navigation"
	  class="mango-root-sidenav mat-elevation-z3"
	  [opened]="!handsetLayout"
      [fixedInViewport]="true"
	  [mode]="handsetLayout ? 'over' : 'side'"
    [disableClose]="!handsetLayout"
    *ngIf="session.isLoggedIn() && !session.hideNav"
	  >
    <!--*ngIf="(isLoggedIn$ | async)"-->

    <div class="logo-box">
      <a [routerLink]="['/home']">
        <h1></h1>
      </a>
    </div>
<!--
-->

    <mat-nav-list>
      <a mat-list-item [routerLink]="['/home']" routerLinkActive="active" >
        <mat-icon>home</mat-icon>
        <ng-container i18n="@@menu.home">Úvodní stránka</ng-container>
      </a>

      <!-- Submenu Služby -->
      <ng-container *ngIf="this.session.getOption('SELFCARE.SUBMENU.services')">
        <mat-list-item (click)="_showSubmenuServices = !_showSubmenuServices" [ngClass]="{ 'subpath-active': isSubmenuActive('services') }">
          <mat-icon>shopping_cart</mat-icon>
          <span i18n="@@menu.services">Mé služby</span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : _showSubmenuServices}">keyboard_arrow_right</mat-icon>
        </mat-list-item>
        <div class="submenu" [ngClass]="{'expanded' : _showSubmenuServices}">
          <sc-menu-item ident="user-services" i18n="@@menu.services.overview">Přehled</sc-menu-item>
          <sc-menu-item ident="documents-agreements" i18n="@@menu.services.agreements">Smlouvy</sc-menu-item>
          <sc-menu-item ident="telephony" i18n="@@menu.services.telephony">Hlasové služby</sc-menu-item>
          <sc-menu-item ident="data" i18n="@@menu.services.data">Datové služby</sc-menu-item>
        </div>
      </ng-container>

      <!-- Submenu Vyúčtování -->
      <ng-container *ngIf="this.session.getOption('SELFCARE.SUBMENU.billing')">
        <mat-list-item (click)="_showSubmenuBilling = !_showSubmenuBilling" [ngClass]="{ 'subpath-active': isSubmenuActive('billing') }">
          <mat-icon>account_balance</mat-icon>
          <span i18n="@@menu.billing">Vyúčtování</span>
          <mat-icon class="menu-button" [ngClass]="{'rotated' : _showSubmenuBilling}">keyboard_arrow_right</mat-icon>
        </mat-list-item>
        <div class="submenu" [ngClass]="{'expanded' : _showSubmenuBilling}">
          <sc-menu-item ident="billing" i18n="@@menu.billing.overview">Daňové doklady</sc-menu-item>
          <sc-menu-item ident="user-payments" i18n="@@menu.user.billing.payments">Platby</sc-menu-item>
          <sc-menu-item ident="documents-other" i18n="@@menu.billing.other">Ostatní doklady</sc-menu-item>
          <sc-menu-item ident="unicredit" i18n="@@menu.billing.unicredit">Předplatné</sc-menu-item>
        </div>
      </ng-container>

      <sc-menu-item ident="nodes">
        <mat-icon>devices</mat-icon>
        <ng-container i18n="@@menu.nodes">Zařízení</ng-container>
      </sc-menu-item>
      <sc-menu-item ident="protected">
        <mat-icon>policy</mat-icon>
        <ng-container i18n="@@menu.protected">Chráněné údaje</ng-container>
      </sc-menu-item>
      <sc-menu-item ident="process">
        <mat-icon [matBadgeHidden]="unresolvedProcesses === 0" matBadgeColor="warn" [matBadge]="unresolvedProcesses.toString()" matBadgeOverlap="true">question_answer</mat-icon>
        <ng-container i18n="@@menu.process">Požadavky</ng-container>
      </sc-menu-item>

      <a *ngIf="session.isConfigUser()"
         mat-list-item
         [routerLink]="['/registration-config']"
         routerLinkActive="active"
         (click)="closeDrawer()">
        <mat-icon>help_outline</mat-icon>
        <ng-container i18n="@@menu.registration.config">Konfigurace registrace</ng-container>
      </a>

      <a *ngFor="let eb of pageEditorBlocks"
         mat-list-item
         [routerLink]="['/page-editor-block', eb.ttId]"
         routerLinkActive="active">
       {{eb.ttName}}
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>

    <div [ngClass]="{'mango-root-content' : session.isLoggedIn() }">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
