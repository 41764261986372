import { Injectable } from '@angular/core';
import {DataBase} from '@og_soft/data-base';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSaFreeUnitsService extends DataBase<{}> {
    // getallUrl = 'telephony-free-units';

  protected getAllUrl(): string {
    return 'telephony-free-units' as const;
  }
}
