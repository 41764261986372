import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DataParams} from '../_services/data-params.service';
import {ParamGroup} from '../_models/param-group';
import {ParamControlService} from '../_services/param-control.service';
import {ParamBase} from '../_models/param-base';
import {MangoParamFormControl} from '../mango-param-form-control';
import {DependencyService} from '../_services/dependency.service';
import {DataParamsProcess} from '../../mango-processes/data-params-process';

export interface ObjectId {
  id: number;     // id samotného objektu
  // id objektu pro definici, ve chvíli kdy samotný objekt je 0 - např. task_type pro procesy,
  // service_name pro služby atd...
  defId?: number;
}

@Component({
  selector: 'param-form',
  templateUrl: './param-form.component.html',
  styleUrls: ['./param-form.component.scss'],
//  providers: [ ParamControlService ]
})
export class ParamFormComponent implements OnInit {
  @Input() paramsService: DataParams;
  @Input() dependencyService: DependencyService; // Servisa, která řeší vzájemné závislosti parametrů
  @Input() form: FormGroup;
  @Input() paramFormName: string;
//  @Input() dataParams: DataParams;
  @Input() attributesService: any = null; // TODO: Pokud bude potřeba i pro něco jiného než pro procesy, tak tu třídu zobecnit.
  @Input() set objectId(value: ObjectId) {
    this._objectId = value;
    this.fetchParams();
  }

  @Output() formFinished = new EventEmitter<boolean>();

  get objectId(): ObjectId {
    return this._objectId;
  }

  private _objectId: ObjectId;

  paramGroups: ParamGroup[] = [];

  constructor(
    private paramControlService: ParamControlService,
    private dataParamsProcess: DataParamsProcess
  ) {

  }

  ngOnInit(): void {
    this.fetchParams();
  }

  fetchParams(): void {
    const start = Date.now();
    let options = {};
    if (this.objectId) {
      options = {id: this.objectId.id, defId: this.objectId.defId};
    }
    // Data naplňuju jen v případě že znám ID objektu ke kterému chci parametry získat.
    // Pokud je objektem zákazník, tak znám ID vždy, tak to naplňuju vždycky.
    if (this.objectId.id || this.objectId.defId) {
      this.paramsService.getParams(options, 10).subscribe(result => {
        //console.log('params', result);
        if (this.attributesService !== null) {
          this.attributesService.getAttributesAsParams(this.objectId.id || null, this.objectId.defId).subscribe(attrResult => {
            result = result.concat(attrResult);
            this.makeGroup(result);
            console.log('Konfigurace formuláře: ', (Date.now() - start), 'ms');
          });
        } else {
          this.makeGroup(result);
        }
      }, err => {
        console.log('Chyba při získání parametrů ' + err);
      });
    }
  }

  private makeGroup(params: ParamBase[]): void {
    const group = this.paramControlService.toFormGroup(params);
    this.form.removeControl(this.paramFormName);
    this.form.addControl(this.paramFormName, group);

    this.paramGroups = [];

    // Vytvořím si defaultní skupinu, kterou přidám nakonec. Do ní dám všechyn parametry které nejsou ve vlastní skupině
    this.paramGroups.push(
      {
        id: 0,
        position: 999,
        minParamPos: 0,
        type: 'OTHER',
        caption: '',
        params: [[], ]
      }
    );

    // Vytvořím skupiny pro každou skupinu parametrů, pro každý atribut a jednu defaultní,
    // kam spadnou parametry bez skupiny.
    // V každé skupině je potom dvourozměrné pole params. Pokud nejde o tabulkový parametr,
    // budou všechny parametry na prvním indexu (params[0]), pokud jde o tabulkové parametry, tak
    // v params[i] je vždy řádek tabulky a i je index řádku

    params.forEach(param => {
      if (param.group && param.group.id) {
        let groupFound = false;
        for (let i = 0; i < this.paramGroups.length; i++) {
          if (this.paramGroups[i].id === param.group.id) {
            groupFound = true;
            // Naplním si atribut pro nejmenší pořadí parametrů
            if (this.paramGroups[i].minParamPos > param.order) {
              this.paramGroups[i].minParamPos = param.order;
            }

            // Tabulkové indexované parametry si rozděluji do polí podle jednotlivých indexů
            if (param.group.type === 'TABLE') {

              if (!this.paramGroups[i].params[param.index]) {
                this.paramGroups[i].params[param.index] = [];
              }
              this.paramGroups[i].params[param.index].push(param);

              // Pokud nejde o tabulkové parametry, budou všechny parametry v dané skupině pohromadě
            } else {
              this.paramGroups[i].params[0].push(param);
            }
          }
        }
        if (groupFound === false) {
          const minParamPosition = param.order;

          if (param.group.type === 'TABLE') {
            const paramsArr: ParamBase[][] = [[], ];
            paramsArr[param.index] = [param];

            this.paramGroups.push(
              {
                id: param.group.id,
                position: param.group.position,
                type: param.group.type,
                caption: param.group.caption,
                minParamPos: minParamPosition,
                params: paramsArr,
              }
            );
          } else {
            this.paramGroups.push(
              {
                id: param.group.id,
                position: param.group.position,
                type: param.group.type,
                caption: param.group.caption,
                minParamPos: minParamPosition,
                params: [[param]]
              }
            );
          }
        }
      } else {
        if (param.attribute) { // Každému atributu vytvoříme zvláštní skupinu
          this.paramGroups.push(
            {
              id: 0,
              position: param.group.position || 999,
              type: 'ATTRIBUTE',
              caption: '',
              minParamPos: 0,
              params: [[param]]
            }
          );
        } else { // Všechny ostatní parametry dám do skupiny ostatní
          this.paramGroups[0].params[0].push(param);
        }
      }
    });

    // Seřadím si skupiny
    this.paramGroups.sort(
      (a, b) => {
        if (a.position === b.position) {
          return a.minParamPos - b.minParamPos;
        }
        return a.position - b.position;
      });

    // Pak parametry uvnitř každé skupiny
    this.paramGroups.forEach(group => {
      group.params.forEach(pars => {
        pars.sort(
          (a, b) => {
            return a.order - b.order;
          });
      });
    });

    this.formFinished.emit(true);
  }

  indexedParamAdd($event: ParamBase): void {

    let paramKey = null;
    let maxIndex = 0;
    let newParam = null;

    // Projdu všechny skupiny parametrů
    for (let i = 0; i < this.paramGroups.length; i++) {
      // Vynechám atributy a tabulkové parametry
      if (this.paramGroups[i].type !== 'TABLE' && this.paramGroups[i].type !== 'ATRIBUTE') {
        // A hledám daný parametr v prvním řádku - projdu přitom vše a vezmu parametr s největším indexem
        for (let j = 0; j < this.paramGroups[i].params[0].length; j++) {
          if (this.paramGroups[i].params[0][j].indexName === $event.indexName) {
            if (this.paramGroups[i].params[0][j].index >= maxIndex) {
              paramKey = j;
              maxIndex = this.paramGroups[i].params[0][j].index;
            }
          }
        }
        // Pokud jsme našel parametr v této skupině, přidám ho do ní a končím
        if (paramKey !== null) {
          newParam = this.makeParamCopy(this.paramGroups[i].params[0][paramKey], maxIndex + 1);
          this.paramGroups[i].params[0].splice((paramKey + 1), 0, newParam);
          break;
        }

      }

    }

  }

  indexedParamDelete($event: ParamBase): void {
    // NETESTOVÁNO

    let paramKey = null;

    // Projdu všechny skupiny parametrů
    for (let i = 0; i < this.paramGroups.length; i++) {
      // Vynechám atributy a tabulkové parametry
      if (this.paramGroups[i].type !== 'TABLE' && this.paramGroups[i].type !== 'ATRIBUTE') {
        // A hledám daný parametr v prvním řádku
        for (let j = 0; j < this.paramGroups[i].params[0].length; j++) {
          if (this.paramGroups[i].params[0][j].name === $event.name) {
            paramKey = j;
            break;
          }
        }
        // Pokud jsme našel parametr v této skupině, smažu ho odtud a končím
        if (paramKey) {
          this.paramGroups[i].params[0].splice(paramKey, 1);
          break;
        }
      }
    }

    (this.form.get(this.paramFormName) as FormGroup).removeControl($event.name);
  }

  /**
   * Metoda služí k zjištění, jestli je v rámci skupiny nějaký parametr viditelný - řídí potom zobrazení nadpisu
   * @ParamGroup group
   */
  getGroupVisibility(group: ParamGroup): boolean {
    if (this.form.controls.processCoreForm) {
      for (let i = 0; i < group.params.length; i++) {
        for (let j = 0; j < group.params[i].length; j++) {
          if (this.form.controls.processCoreForm.get(group.params[i][j].name) &&
            (this.form.controls.processCoreForm.get(group.params[i][j].name) as MangoParamFormControl).visible
          ) {
            return true;
          }
        }
      }
    } else {
      // FIXME: Tady je potřeba rozumě pořešit ty závislosti pro neprocesní parametry
      return true;
    }
    return false;
  }


  removeTableRow(group: ParamGroup, index: number): void {
    group.params[index].forEach(param => {
      (this.form.get(this.paramFormName) as FormGroup).removeControl(param.name);
    });

    group.params.splice(index, 1);
  }

  addTableRow(group: ParamGroup): void {
    const newRow = [];

    group.params[group.params.length - 1].forEach(param => {
      newRow.push(this.makeParamCopy(param, param.index + 1));
    });

    group.params.push(newRow);
  }

  makeParamCopy(param: ParamBase, newIndex: number): ParamBase {
    const newOptions = { ...param };
    newOptions.index = newIndex;
    newOptions.name = param.indexName;
    newOptions.value = '';

    // Musím si transformovat atribut group,a by odpovídal formátu v jakým chodí z databáze (abych mohl volat transformParams)
    // let group = newOptions.group;
    // newOptions.group = [];
    // newOptions.group[0] = group;

    const newParam = this.paramsService.transformParams(newOptions, this.dataParamsProcess.getServices());
    const control = this.paramControlService.makeControlFromParam(newParam);
    (this.form.get(this.paramFormName) as FormGroup).addControl(newParam.name, control);
    return newParam;
  }


}

