<sc-main-toolbar>
</sc-main-toolbar>

<div class="mango-table-page-contents">
  <h1 i18n="@@menu.process">Požadavky</h1>
  <sc-page-caption-block></sc-page-caption-block>


  <ng-container *ngIf="activeProcesses && activeProcesses.length > 0">
    <h2><span i18n="@@ProcessList.activeProcesses.caption">Požadavky ke zpracování</span></h2>
    <div *ngFor="let activeProcess of activeProcesses">
      <mat-card>
        <mat-card-header>
        <mat-card-title>#{{activeProcess.id}}&nbsp;{{activeProcess.name}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          {{getLastNote(activeProcess)}}
        </mat-card-content>
        <mat-card-actions>
          <div class="buttons-row">
            <button mat-button (click)="addComment(activeProcess)"
                    i18n="@@ProcessList.activeProcesses.button.note">Poznámka</button>
            <button mat-flat-button color="primary" (click)="openProcess(activeProcess.id, null)"
                    i18n="@@ProcessList.activeProcesses.button.open">Zpracovat</button>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>

  <ng-container *ngIf="processTypesNew && processTypesNew.length > 0">
    <h2><span i18n="@@ProcessList.newProcess.caption">Založení nového požadavku</span></h2>
    <div class="container new-process-buttons-container">
      <div *ngFor="let type of processTypesNew" class="container-item">
        <!-- <button class="new-button" mat-storked-button color="primary" [routerLink]="['/process', 0, {type: type.id}]"> -->
        <button mat-storked-button mat-stroked-button color="primary" class="new-button"
                (click)="openProcess(0, type.id)">
         <!-- <mat-icon>add_circle_outline</mat-icon> -->
         <mat-icon>add_circle_outline</mat-icon>
         {{type.name}}
        </button>
      </div>
    </div>
  </ng-container>

<!--    V případě, že žádné nevyřízené požadavky nemám, nezobrazuju ani nadpis (na stránce jsou i jiné prvky)
        Zároveň pokud mám nastavené filtry, tak nadpis zobrazuji.
-->
    <h2 *ngIf="tbl.nrecords > 0 || (tbl.filterInfo && tbl.filterInfo.length > 0)">
      <span i18n="@@ProcessList.requirements.caption">Moje neuzavřené požadavky</span>
    </h2>
    <mgt-header [filterValues]="filterValues" [tab]="tab">
      <mgt-filters #tableFilters [formGroup]="filters" (onApplyFilters)="filterValues = $event">

        <mat-form-field class="mango-form-field">
          <input matInput formControlName="fulltext"
                 id="fulltext" name="fulltext"
                 i18n-placeholder="@@ProcessList.filter.fulltext.placeholder"
                 placeholder="Fulltext">
        </mat-form-field>

        <mat-form-field class="mango-form-field">
          <mat-select formControlName="typeId"
                      placeholder='Typ požadavku'
                      i18n-placeholder="@@ProcessList.filter.type.placeholder">
            <mat-option *ngFor="let option of procesTypesFilterOptions" [value]="option.id">{{option.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="filter-row">

          <mat-form-field class="mango-form-field">
            <datetime-control [pickerType]="'calendar'"
                              formControlName="dateFrom" name="dateFrom" #df1
                              i18n-placeholder="@@ProcessList.filters.dateFrom.placeholder" placeholder="Nahlášeno od"
                              [max]="filters.get('dateTo').value"
            ></datetime-control>
            <datetime-control-trigger matSuffix [for]="df1"></datetime-control-trigger>
            <mat-error *ngIf="filters.controls.dateFrom.errors">{{formHelper.getErrorMessage(filters.get('dateFrom'))}}</mat-error>
          </mat-form-field>

          <mat-form-field class="mango-form-field">
            <datetime-control [pickerType]="'calendar'"
                              formControlName="dateTo" name="dateTo" #df2
                              i18n-placeholder="@@ProcessList.filters.dateTo.placeholder" placeholder="Nahlášeno do"
                              [min]="filters.get('dateFrom').value"
                              [errorStateMatcher]="errorMatcher"
            ></datetime-control>
            <datetime-control-trigger matSuffix [for]="df2"></datetime-control-trigger>
            <mat-error *ngIf="filters.controls.dateTo.errors">{{formHelper.getErrorMessage(filters.get('dateTo'))}}</mat-error>
            <mat-error *ngIf="filters.errors">{{formHelper.getErrorMessage(filters.get('dateTo'), filters)}}</mat-error>
          </mat-form-field>
        </div>

        <mat-form-field *ngIf="procesUnitsFilterOptions.length > 1" class="mango-form-field">
          <mat-select formControlName="unit"
                      placeholder='Místo poskytování služeb'
                      i18n-placeholder="@@ProcessList.filter.unit.placeholder">
            <mat-option *ngFor="let option of procesUnitsFilterOptions" [value]="option.id">{{option.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mgt-filters>

    </mgt-header>
    <mgt-table #tbl [service]="dataService" [filters]="filterValues">

      <mgt-row
          [replacing-detail]="true"
          *ngFor="let r of tbl.scrollItems;"
          [record]="r"
          (expandedChange)="getNotes(r, $event)"
      >

        <mgt-cell-group master>
          <mgt-cell i18n-title="@@ProcessList.record.name.title" title="Požadavek" role="title">
            #{{r.id}}&nbsp;{{r.name}}
          </mgt-cell>
          <mgt-cell i18n-title="@@ProcessList.record.stateName.title" title="Stav">
            {{r.stateName}}
          </mgt-cell>

        </mgt-cell-group>

        <mgt-cell-group>
          <!--<mgt-cell title="Typ" role="title" style="max-width: 48px;">-->
            <!--{{r.typeName}}-->
          <!--</mgt-cell>-->
          <mgt-cell  i18n-title="@@ProcessList.record.startTime.title"  title="Založen">
            {{r.startTime | date: 'd. M. yyyy'}}
          </mgt-cell>
          <mgt-cell *ngIf="priorityNameTitleVisible()" i18n-title="@@ProcessList.record.priorityName.title"  title="Priorita">
            {{r.priorityName}}
          </mgt-cell>
        </mgt-cell-group>

        <div detail>
          <!--<mgt-auto-detail [value]="r"></mgt-auto-detail>-->
          <div class="services-heading">
            <div>
              <h3 class="mat-title" style="vertical-align: middle;"> #{{r.id}}&nbsp;{{r.name}}</h3>
            </div>
          </div>


          <div class="detail-row detail-row-grid">
            <div class="fields">
              <div class="detail-field">
                <div class="mat-caption"><span i18n="@@ProcessList.startTime.caption">Založeno</span></div>
                <div class="value">{{r.startTime | date: 'd. M. yyyy'}}</div>
              </div>
              <div class="detail-field">
                <div class="mat-caption"><span i18n="@@ProcessList.typeName.caption">Typ</span></div>
                <div class="value">{{r.typeName}}</div>
              </div>
              <div class="detail-field">
                <div class="mat-caption"><span i18n="@@ProcessList.stateName.caption">Stav</span></div>
                <div class="value">{{r.stateName}}</div>
              </div>
              <div class="detail-field" *ngIf=" priorityNameVisible()"  >
                <div class="mat-caption"><span i18n="@@ProcessList.slaName.caption">Priorita</span></div>
                <div class="value">{{r.priorityName}}</div>
              </div>
            </div>
            <div class="params">
              <ng-container *ngFor="let displayedParam of displayedParams">
                <ng-container *ngIf="r.hasOwnProperty(displayedParam.name) && r[displayedParam.name] !== ''">
                  <div class="detail-field">
                    <div class="mat-caption">{{displayedParam.caption}}</div>
                    <div class="value">{{transformParamValue(r[displayedParam.name], displayedParam)}}</div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <div class="notes">
              <div class="detail-field" *ngIf="r.notes && r.notes.length > 0">
                <div class="mat-caption"><span i18n="@@ProcessList.notes.caption">Poznámky</span> <span>({{r.notes.length}})</span> </div>
                <div class="value note-table">
                  <div *ngFor="let note of r.notes | slice:0:4" class="note-list">
                    <!--                  show note in row-->
                    <div class="note">
                      <div class="note-header">
                        <div class="note-date">{{note.time | date: 'd.M.yyyy HH:mm'}}</div>
                        <div class="note-author">{{note.userName}}</div>:&nbsp;
                      </div>
                      <div class="note-text">{{note.note}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <mgt-actions>
          <button mat-button *ngIf="r.editable && settingVisible()" (click)="addComment(r)">
            <mat-icon class="button-icon">add_comment</mat-icon>
            <span i18n="@@ProcessList.createNote.button">Poznámka</span>
          </button>
          <button mat-button *ngIf="r.editable && settingEditableVisible()" (click)="openProcess(r.id, null)">
            <mat-icon class="button-icon">settings</mat-icon>
            <span i18n="@@ProcessList.settings.editable.button">Upravit</span>
          </button>
          <button mat-button *ngIf="!r.editable" (click)="openProcess(r.id, null)">
            <mat-icon class="button-icon">settings</mat-icon>
             <span i18n="@@ProcessList.settings.nonEditable.button">Detail</span>
          </button>
        </mgt-actions>

      </mgt-row>

<!--      Info o prázdné stránce zobrazím pouze v případě, že na stránce nemám žádné další prvky-->
<!--      (tlačítko na založení nového procesu, nebo seznam procesů k vyřízení)-->
      <div no-data-alternate-content *ngIf="processTypesNew.length < 1 && activeProcesses.length < 1">
        <page-empty i18n-message="@@ProcessList.PageEmpty.message" message="Není co zobrazit."></page-empty>
      </div>

    </mgt-table>

</div>

