import {Component, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {SessionService} from '../_services/session.service';
import {PayOrderService} from '../_services/pay-order.service';
import {FormHelperService} from '../_services/form-helper.service';
import {DialogConfig, DialogRef} from '@og_soft/dialog';
import {MangoFormControl} from '../_models/mango-form-control';
import {numberDecimalPlacesValidator} from '../_directives/common-validators';
import {environment} from '../../environments/environment';
import {Payment3dsecureRequestService} from '../_services/payment-3dsecure-request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataUserEdit} from '../_services/user-edit.service';

@Component({
  selector: 'app-card-payment',
  templateUrl: './card-payment.component.html',
  styleUrls: ['./card-payment.component.scss']
})
export class CardPaymentComponent implements OnInit {
  form: FormGroup;
  varSym: any;
  amount: any;
  typeAttach: any;
  saIds: any;
  isTest = false;
  private userData?;

  constructor(
    dconfig: DialogConfig,
    public session: SessionService,
    public formHelper: FormHelperService,
    private payOrder: PayOrderService,
    private dlgRef: DialogRef,
    private payment3dsecureRequest: Payment3dsecureRequestService,
    private dsrv: DataUserEdit,
  ) {
     this.amount = dconfig.data.amount;
     this.varSym = dconfig.data.varsym;
     this.typeAttach = dconfig.data.typeAttach;
     this.saIds = dconfig.data.saIds;
     this.isTest = !(environment.dbVersion === 'stable');
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      amount: new MangoFormControl('', [
        Validators.required,
        Validators.min(0.001),
        Validators.max(999999),
        numberDecimalPlacesValidator(Number(this.session.options.PRICE_DECIMAL_PRECISION))]
      ),
      varSym: new MangoFormControl('', [Validators.required, Validators.pattern('^\\d{6,10}$')]),
      typeAttach: new MangoFormControl(''),
      saIds: new MangoFormControl(''),
    });

    if (this.amount > 0 ) {
      this.form.controls.amount.setValue( this.amount );
      this.form.controls.amount.disable();
    }
    this.form.controls.varSym.setValue( this.varSym );
    this.form.controls.varSym.disable();
    this.form.controls.typeAttach.setValue( this.typeAttach );
    this.form.controls.saIds.setValue( this.saIds );
   // this.form.controls.typeAttach.disable();

    this.dsrv.getSingleton({}, 1800).subscribe(userData => {
      this.userData = userData;
    }, err => {
      console.log('Chyba při získání dat uživatelského profilu ' + err);
    });
  }

  public valuesSet(values: any): void {
    this.form.setValue(values);
  }

  public itemDisabled(name: 'amount' | 'varSym'): void {
    this.form.get(name).disable();
  }

  public save(): void {
    if (this.form.valid)  {
      if (this.userData.phones || this.userData.emails) {
        this.session.processingSet(true, $localize`:@@CardPayment.pay3DSec.message.redirecting:Probíhá přesměrování na platební bránu.`);
        const formData = this.form.getRawValue();
        // Nejprve se musí na backendu vytvořit payOrder záznam.
        this.payOrder.post({
          amount: formData.amount,
          varSym: formData.varSym,
          typeAttach: formData.typeAttach,
          saIds: formData.saIds,
        }).subscribe(po => {
          if (parseInt(po.orderNumber, 10) > 0){
            //  Na základě payOrder se pak může vytvořit request na platební bránu a provést přesměrování
            this.payment3dsecureRequest.request(formData.amount, po, this.returnUrl).subscribe(url => {
              this.dlgRef.close();
              window.location.href = url.request;
              }, error => {
              this.session.message($localize`:@@CardPayment.pay3DSec.error.message:Nepodařilo se iniciovat platbu kartou.`);
              this.session.processingSet(false);
            });
          } else {
            this.session.message( $localize`:@@CardPayment.pay3DSec.error.message:Nepodařilo se iniciovat platbu kartou.` );
            this.session.processingSet(false);
          }
          }, err => {
          this.session.message( $localize`:@@CardPayment.pay3DSec.next.error.message:Nepodařilo se iniciovat platbu kartou.` );
          this.session.processingSet(false);
        });
      } else {
        this.session.message( $localize`:@@CardPayment.pay3DSec.contacts.message:Pokud platíte kartou, musíme mít ve svém profilu uvedený e-mail nebo telefon.` );
      }
    } else {
      this.session.message( $localize`:@@CardPayment.pay3DSec.validate.message:Některé položky nejsou vyplněné nebo nemají správnou hodnotu.` );
      this.formHelper.markDirty(this.form);
    }
  }

  /**
   * Vytváří návratové URL pro zpětné přesměrovávání z platební brány.
   */
  private get returnUrl(): string {
    // Toto je dost tricky. V angularové ActivatedRoute ani v Routeru jsme nenašel nic co by šlo dobře využít
    // Potřebuju, aby se vytvořila routa /cardresp/_aktuální routa_
    // Problémy nastávají hlavně na develu, kde máme v pathname ještě nějaké další adresáře.
    // Nakonec se spoléhám na to, že aktuální routa bude vždy jen jedna a bude poslední.
    // Rozdělím si pathname, a na předposlední místo vložím /cardresp.
    const path = window.location.pathname.split('/').filter(Boolean);
    const route = path.pop();
    return window.location.origin + (path.length > 0 ? ('/' + path.join('/')) : '') + '/cardresp/' + route;
  }
}
