import {Pipe, PipeTransform} from '@angular/core';

export interface BytesCalculated {
  value: number;
  scale: number;
  units: string;
}

@Pipe({
  name: 'bytesPipe'
})
export class BytesPipe implements PipeTransform {

  private units(): string[] {
    return ['B', 'kB', 'MB', 'GB', 'TB', 'PB'];
  }

  calculate(value: string | number): BytesCalculated {
    let bytes: number = (typeof value === "string") ? parseInt(value, 10) : +value;
    let unitIndex: number = 0;
    while (bytes >= 1000 && (unitIndex < this.units().length - 1)) { // Umožňuje čísla nad tisíc formátovat již ve vyšším řádu
      bytes /= 1024;
      unitIndex++;
    }

    return {
      value: bytes,
      scale: unitIndex,
      units: this.units()[unitIndex]
    };
  }

  transform(value: string | number, maxPrecision: number = 2): string {
    if (value === undefined || value === null || (typeof value == 'number' && Number.isNaN(value))) {
      return '';
    }

    let calculated = this.calculate(value);
    // Jako výsledek odstraníme koncové nuly za des. čárkou a připojíme jednotky.
    return calculated.value.toFixed(maxPrecision).replace(/(\.[0-9]+)0+$/, '\$1').replace(/\.0*$/, '') + ' ' + calculated.units;
  }

}
