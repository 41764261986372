import {Component, OnInit} from "@angular/core";
import {DialogConfig, DialogRef} from "@og_soft/dialog";
import {SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-process-guide-dialog',
  templateUrl: './process-guide-dialog.component.html',
  styleUrls: ['./process-guide-dialog.component.scss']
})
export class ProcessGuideDialogComponent implements OnInit {
  guide: SafeHtml;

  constructor(
    public config: DialogConfig,
  ) {
    this.guide = config.data.guide;
  }

  ngOnInit() {
  }
}
