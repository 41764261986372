import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionService} from "../_services/session.service";
import {UtilPrintService} from "../_services/util-print.service";
import {DataDocumentsOther} from "../_services/data-documents-other";
import {FormGroup} from "@angular/forms";
import {DataDocumentsOtherTypes} from "../_services/data-documents-other-types";
import {ActivatedRoute} from "@angular/router";
import {ForgetTableComponent} from "../_libraries/forget-table/forget-table/forget-table.component";
import {MangoFormControl} from '../_models/mango-form-control';
import {DialogConfig, DialogService} from "@og_soft/dialog";
import {CardPaymentComponent} from "../card-payment/card-payment.component";

@Component({
  selector: 'app-documents-other',
  templateUrl: './documents-other.component.html',
  styleUrls: ['./documents-other.component.scss']
})


export class DocumentsOtherComponent implements OnInit {

  filters: FormGroup;
  filterValues: any = {};

  @ViewChild(ForgetTableComponent, {static: false}) public tab: ForgetTableComponent;

  private subParams: any;

  constructor(public dataService: DataDocumentsOther,
              public session: SessionService,
              private route: ActivatedRoute,
              public dataDocumentsOtherTypes: DataDocumentsOtherTypes,
              public dialog: DialogService,
              public printUtil: UtilPrintService) { }


  ngOnInit() {
    this.filters = new FormGroup({
      docType: new MangoFormControl(''),
    });


    this.subParams = this.route.params.subscribe(params => {
      this.filters.setValue({docType: params['id'] ? +params['id'] : null});
    });


  }

  getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }


  debt(amountPay: number): number {
    if ( amountPay > 0 ) {
      return 1;
    }
    return 0;
  }


  payment(data: any): void {
    const dconfp = new DialogConfig();
    let attachType = null;
    if ( data.docData.payVarSymbol == data.docData.docOtherVarSymbol)
    {
      attachType = 17;
    }
    dconfp.data = {
      amount: data.docData.amountPay,
      varsym: data.docData.payVarSymbol,
      typeAttach: attachType,
      saId: 0,
    };
    //    this.formCardPay.itemDisabled("amount");
    //    this.formCardPay.itemDisabled("varsym");

    const dref = this.dialog.open( CardPaymentComponent, dconfp);
    dref.afterClosed.subscribe(result => {
      // if (result)
      // console.error("Tady mam data ", result)
    });
  }



}
