import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
/**
 * Třída slouží pro obsluhu metod spojených se změnou hesla.
 */
export class PasswordService{

  constructor(
    private http: HttpClient,

  ) {

  }

  public resetPassword(params): Observable<any>{
    let data = {...params, ...{ct: environment.ct}};
    return this.http.post(`${environment.baseUrl}/reset-password`,data);
  }

  public checkEmail(params): Observable<any>{
    let queryParams = {...params, ...{ct: environment.ct}};

    return this.http.get(`${environment.baseUrl}/check-unique-email`,{params: queryParams});
  }

  public changePassword(params): Observable<any>{
    let data = {...params, ...{ct: environment.ct}};
    return this.http.post(`${environment.baseUrl}/change-password`,data);
  }

  public checkCodeValidity(params): Observable<any>{
    let queryParams = {...params, ...{ct: environment.ct}};
    return this.http.get(`${environment.baseUrl}/check-code-validity`,{params: queryParams});
  }

}
