<div [formGroup]="form">
  <div #inpElement *ngIf="!deviceService.isMobile()">
    <input
            matInput
            formControlName="inputDate"
            [id]="id"
            [textMask]="maskDate"
            class="date-date-input"
    >
    <input *ngIf="displayTime"
           #timeInput
           matInput
           formControlName="inputTime"
           [id]="id"
           type="time"
           class="date-time-input"
    >
    <input
            formControlName="calendarDate"
            [matDatepicker]="picker"
            hidden
    >
    <mat-datepicker-toggle tabIndex="-1" [for]="picker" class="date-picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </div>


  <div *ngIf="deviceService.isMobile()">
    <input *ngIf="!displayTime"
           type="date"
           matInput
           [id]="id"
           formControlName='inputDate'
    >
    <input *ngIf="displayTime"
           type="datetime-local"
           matInput
           [id]="id"
           formControlName='inputDate'
    >
  </div>

</div>
