import {DataBase} from "@og_soft/data-base";
import {Injectable} from '@angular/core';

/**
 * Interface pro jeden záznam z historie UniCreditu, jak ho vrací API.
 *
 * TypeScript používá interfaces pro typovou kontrolu. Je otázka, zda
 * to kromě toho má pozitivní vliv ma schopnost IDE napovídat. Pokud
 * se to nepotvrdí, tak se s těmi interfaces psát nebudeme a budeme
 * prostě používat typ "any".
 */
export interface RecUnicredit {
	serviceName:  string;
	description:  string;
	date:         string;
	amount:       number;
	dateTo:       string;
	dateFrom:     string;
	userName:     string;
	descriptionEu:  string;
}

@Injectable({
	providedIn: 'root'
})
export class DataUnicredit extends DataBase<RecUnicredit> {
  // getallUrl = "eu-unicredit";

  protected getAllUrl(): string {
    return 'eu-unicredit' as const;
  }
}

