import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ProtectedDataItemsCategoriesRegistration extends DataBase<{}> {
    // getallUrl = 'protected-data-items-categories-registration';

  protected getAllUrl(): string {
    return 'protected-data-items-categories-registration' as const;
  }

    getSingleton(params?: any, cacheTimeout?: number, urlExpandParams?: any): Observable<{}> {
        if (!params) {params = {}; }
        params.ct = environment.ct;
        return super.getSingleton(params, cacheTimeout, urlExpandParams);
    }
}




