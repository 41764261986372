import {Injectable} from '@angular/core';
import {SessionService} from '../_services/session.service';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UtilPrintService {

  constructor(public session: SessionService) { }

  public docLinkUrl(docType, docId) {
    let baseUrl = environment.baseUrl;
    if (environment.production && this.session.user.locDefaults.selfcareUrl) {
      baseUrl = `https://${this.session.locDefaults.selfcareUrl}/api`;
    }

    return this.session.user ?
      `${baseUrl}/print-document/${docId}?dt=${docType}&atb=${this.session.user.atb}` :
      "";
  }
}
