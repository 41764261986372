import {Component, Input, OnInit} from '@angular/core';
import {ForgetTableRowComponent} from '../forget-table-row/forget-table-row.component';

interface FieldData {
	title:   string;
	role:    string;
	content: string;
}

@Component({
	selector: 'mgt-auto-detail',
	template: `
	  <h3 *ngIf="titleText" [innerHTML]="titleText"></h3>
	  <div *ngFor="let cd of nonTitleData" class="auto-detail-field">
	    <div class="mat-caption caption" *ngIf="cd.title">{{cd.title}}:</div>
	    <div class="value" [innerHTML]="cd.content"></div>
	  </div>
	`,
	styles: [`
	  :host h3 {
      /* margin: 0 0 .5em; */
	  }
	`],
})
export class ForgetTableAutoDetailComponent implements OnInit {
	constructor(protected row: ForgetTableRowComponent) { }

	@Input()
	protected value: any;

	ngOnInit() { }

	public get data(): FieldData[] {
    return this.row.columns.map(e => ({
      title: e.title,
      role:  e.role,
      content: e.nativeElement.innerHTML,
    }));
	}

	public get nonTitleData(): FieldData[] {
		return this.data.filter(e => e.role !== "title");
	}

	public get titleText(): string {
    let e = this.data.find(e => e.role === "title");
    return e ? e.content : null;
	}
}
