import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup, Validators} from '@angular/forms';
import {FormHelperService} from '../_services/form-helper.service';
import {UserEditFormService, UserEditFormType} from '../user-edit/user-edit-form.service';
import {CrossFieldErrorMatcher, passwordCheckValidator} from '../_directives/common-validators';


@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {
  @Input() parentForm?: FormGroup;

  form: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  public formName: string;

  constructor(
    public formHelper: FormHelperService,
    private userEditFormService: UserEditFormService,
  ) {
    this.formName = UserEditFormType.credentials;
    this.form = this.userEditFormService.getForm(UserEditFormType.credentials);
  }

  ngOnInit(): void {

    const passwordValidators = [];
    const passwordCheckValidators = [];

    passwordValidators.push(Validators.required);
    passwordValidators.push(Validators.pattern('^(?!.*(.)\\1{2,})(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,255}|.{12,255})$'));

    passwordCheckValidators.push(Validators.required);

    this.form.get('password').setValidators(passwordValidators);
    this.form.get('passwordCheck').setValidators(passwordCheckValidators);
    this.form.setValidators(passwordCheckValidator);

    if (this.parentForm) {
      this.parentForm.addControl('passwordForm', this.form);
    }
  }

  getErrorMessage(component: AbstractControl): string {
    if (component.valid) {
      return '';
    } else {
      if (component.hasError('required')) {
        return $localize`:@@PasswordChange.error.required:Tohle vyplnit musíte.`;
      }
      if (component.hasError('pattern')) {
        return $localize`:@@PasswordChange.error.passwordTooWeak:Příliš slabé heslo.`;
      }
    }
  }
}
