import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogConfig, DialogRef} from "@og_soft/dialog";
import {SessionService} from "../../_services/session.service";


@Component({
  selector: 'app-protected-data-recall-erase-dialog',
  templateUrl: './protected-data-recall-erase-dialog.component.html',
  styleUrls: ['./protected-data-recall-erase-dialog.component.scss']
})

export class ProtectedDataRecallEraseDialogComponent implements OnInit {

  public dataconf;

  constructor(public config: DialogConfig,
              private session: SessionService,
              private dlgRef: DialogRef,
  ) { this.dataconf = config.data; }

  ngOnInit() {
  }

  save() {
      let catId = this.dataconf.data.catId;
      if (catId) {
        this.dlgRef.close(this.dataconf);
      }
   }

}







