import {Injectable} from '@angular/core';
import {PopupOption} from "@og_soft/popup-list-control";
import {DataBase} from "@og_soft/data-base";

export interface CodeList {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataCodeList extends DataBase<CodeList> {
  // getallUrl = "code-list";

  protected getAllUrl(): string {
    return 'code-list' as const;
  }

  tranformOptions(row): PopupOption {
    return {id: row.key, name: row.value};
  }
}
