//import { Component, OnInit } from '@angular/core';
import {Component, OnInit} from '@angular/core';
import {DataUserContactsService} from '../_services/data-user-contacts.service';
import {SessionService} from '../_services/session.service';

import {FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../_services/form-helper.service";
import {MangoFormControl} from '../_models/mango-form-control';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  form: FormGroup;

  constructor(
    public dataService: DataUserContactsService,
    public session: SessionService,
    public formHelper: FormHelperService,
  ) { }

  ngOnInit() {
    this.form = new FormGroup
      ({ firstName: new MangoFormControl("", [Validators.required])
       , lastName:  new MangoFormControl("", [Validators.required])
       , mail:      new MangoFormControl("", [Validators.required])
       , phone:     new FormGroup
         ({ gsm:        new MangoFormControl("", [])
          , landline:   new MangoFormControl("", [])
          , landline2:  new MangoFormControl("", [])
          , fax:        new MangoFormControl("", [])
          })
       , cred:      new FormGroup
         ({ login:     new MangoFormControl("", [])
          , password:  new MangoFormControl("", [])
          , passwordc: new MangoFormControl("", [])
          })
       });
  }

  joinNonempty(a: any[]) {
    return a.filter(el => !!el).join(", ");
  }

  public newContact = false;
  public editContact = null;
  public deleteContact = null;

  protected goAddContact() {
    this.newContact = true;
    this.form.reset();
  }

  protected goEditContact(c) {
    this.editContact = c;
    this.form.patchValue(c);
  }

  protected goDeleteContact(c) {
    this.deleteContact = c;
  }

  protected goCancelAnything() {
    this.newContact = false;
    this.editContact = null;
    this.deleteContact = null;
    this.form.reset();
  }

  protected confirmCreateContact() {
    var data = this.form.getRawValue();
    console.log("XXX confirmCreateContact value:", data);
    this.dataService.post(data).subscribe(d => {
      console.log("XXX Přidáno, vrátilo se tohle:", d, "Ještě vymyslet, jak to refreshnout.");
      this.session.message( $localize`:@@Contacts.contactCreate.message:Kontakt byl vytvořen.` );
      this.goCancelAnything();
    }, err => {
      console.error("Se to nějak nepovedlo:", err);
      this.session.message( $localize`:@@Contacts.contactCreate.message:Operace se nezdařila.` ); // TODO: appbus error message
    });
  }

  protected confirmEditContact() {
    var data = this.form.getRawValue();
    console.log("XXX confirmEditContact value:", data);
    this.dataService.put(this.editContact.id, data).subscribe(d => {
      console.log("XXX Uloženo, vrátilo se tohle:", d, "Ještě vymyslet, jak to refreshnout.");
      this.session.message( $localize`:@@Contacts.contactEdit.message:Kontakt byl uložen.` );
      this.goCancelAnything();
    }, err => {
      console.error("Se to nějak nepovedlo:", err);
      this.session.message( $localize`:@@Contacts.contactEdit.message:Operace se nezdařila.` ); // TODO: appbus error message
    });
  }

  protected confirmDeleteContact() {
    this.dataService.delete(this.deleteContact.id)
    //.pipe(catchError());
  }
}
