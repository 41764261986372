import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormHelperService} from '../../_services/form-helper.service';
import {DependencyUserService} from '../../_libraries/mango-users/dependency-user.service';
import {ProtectedDataCategoryConsentAdd} from '../../_services/protected-data-category-consent-add';
import {UserEditFormService, UserEditFormType} from '../user-edit-form.service';
import {DataParamsUser} from '../../_libraries/mango-users/data-params-user.service';
import {SessionService} from '../../_services/session.service';

@Component({
  selector: 'app-user-edit-params',
  templateUrl: './user-edit-params.component.html',
  styleUrls: ['./user-edit-params.component.scss']
})
export class UserEditParamsComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public formHelper: FormHelperService,
    public userParamsDependencyService: DependencyUserService,
    public protectedDataCategoryConsentAdd: ProtectedDataCategoryConsentAdd,
    private userEditFormService: UserEditFormService,
    public userParamsService: DataParamsUser,
    public session: SessionService,
  ) {
    this.form = this.userEditFormService.getForm(UserEditFormType.params);
  }

  ngOnInit() {
  }
}
