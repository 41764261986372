import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {DataBase, DataBaseServiceConfig, FilterInfo} from '@og_soft/data-base';
import {ParamBase, ParamObjectType} from '../param-form/_models/param-base';
import {HttpClient} from '@angular/common/http';
import {CachingInterceptor} from '@og_soft/cache';
import {DataValueDomainPatterns} from '../param-form/_services/data-value-domain-pattern';
import {DataCodeList} from '../param-form/_services/data-code-list';
import {DataValueDomain} from '../param-form/_services/data-value-domain';
import {DataProcessPremises} from '../../_services/process-premises.service';
import {DataProcessServices} from '../../_services/process-services.service';
import {DataProcessUnits} from '../../_services/process-units.service';
import {ProcessNote} from './data-process-history';
import {SessionService} from '../../_services/session.service';
import {ParamServices} from '../param-form/_services/data-params.service';
import {DatePipe} from '@angular/common';
import {RecBillingMeta} from '../../_services/data-billing.service';

export interface IProcessTransition {
  id: number;
  name: string;
  priority: number;
  description: string;
  visible: string;
  stateName: string;
}

export interface IProcessButton {
  id: number;
  name: string;
  description: string;
  visible: string;
  editable: string;
  order: number;
  disabled?: boolean;
}

export interface ProcessMeta {
  dateFrom?: string;
  dateTo?: string;
  fulltext?: string;
  type?: string;
  typeName?: string;
  unit?: string;
  unitName?: string;
}

/**
 * Atributy které se jmenují 'databázovým' jménem, jsou atributy, které jsou součástí processCore. Toto pojmenování
 * zjednodušuje jejich kontrolu a použití při ukládání processCore.
 */
export interface Process {
  id: number;
  ML_NAME: string;
  ML_P_ID: number;
  ML_SA_IDS: number[];
  ML_UNIT_ID: number;
  ML_USER_ID: number;
  startTime: Date;
  typeId: number;
  typeName: string;
  stateId: number;
  stateName: string;
  stateDescription: string;
  lastActivity: string;
  lastActivityObj?: Date;
  ML_DESC: string;
  editable: boolean; // Určuje jestli je proces ve stavu kdy je editovatelný
  visibilityDependencyUnits: string;
  visibilityDependencyPremises: string;
  visibilityDependencyServices: string;
  visibilityDependencyMlName: string;
  visibilityDependencyMlDesc: string;
  editDependencyUnits: string;
  editDependencyPremises: string;
  editDependencyServices: string;
  editDependencyMlName: string;
  editDependencyMlDesc: string;
  requireDependencyUnits: string;
  requireDependencyPremises: string;
  requireDependencyServices: string;
  requireDependencyMlName: string;
  requireDependencyMlDesc: string;
  notes?: ProcessNote[];
  transitions: IProcessTransition[];
  buttons: IProcessButton[];
}

@Injectable({
  providedIn: 'root'
})
export abstract class DataProcessGeneral extends DataBase<Process> {
  private _processChanged: BehaviorSubject<boolean>;

  configureUrl(config: DataBaseServiceConfig): void {
    this.baseurl = config.mangoUrl;
  }

  constructor(public http: HttpClient,
              private serviceConfig: DataBaseServiceConfig,
              private cachingInterceptor: CachingInterceptor,
              protected dataValuePatterns: DataValueDomainPatterns,
              protected dataCodeList: DataCodeList,
              protected dataValueDomain: DataValueDomain,
              protected dataProcessPremises: DataProcessPremises,
              protected dataProcessUnits: DataProcessUnits,
              protected dataProcessServices: DataProcessServices,
              protected session: SessionService,
  ) {
    super(http, serviceConfig, cachingInterceptor);
    this._processChanged = new BehaviorSubject<boolean>(false);
  }

  transformDataToParams(data: Process, services: ParamServices): ParamBase[]{

    const params = [];
    let param = new ParamBase(
      {
        name: 'ML_P_ID',
        value: data.ML_P_ID ? data.ML_P_ID.toString() : null,
        objId: data.id ? data.id : null,
        objectType: ParamObjectType.Process,
        caption: $localize`:@@DataProcess.atribute.caption.premise:Provozovna`,
        order: Number.MIN_VALUE,
        group: {position: 4, id: null, type: null, caption: null},
        type: 'SELECT',
        domain: 'attribute::PREMISE',
        visibilityDependency: data.visibilityDependencyPremises,
        editDependency: data.editDependencyPremises,
        requireDependency: data.requireDependencyPremises,
        attribute: true,
        editable: data.editable,
      }, services
    );
    params.push(param);

    param = new ParamBase(
      {
        name: 'ML_UNIT_ID',
        value: data.ML_UNIT_ID ? data.ML_UNIT_ID.toString() : null,
        objId: data.id ? data.id : null,
        objectType: ParamObjectType.Process,
        caption: $localize`:@@DataProcess.atribute.caption.unit:Místo poskytování služeb`,
        order: Number.MIN_VALUE,
        group: {position: 8, id: null, type: null, caption: null},
        type: 'SELECT',
        domain: 'attribute::UNIT',
        visibilityDependency: data.visibilityDependencyUnits,
        editDependency: data.editDependencyUnits,
        requireDependency: data.requireDependencyUnits,
        attribute: true,
        editable: data.editable,
      }, services
    );
    params.push(param);

    param = new ParamBase(
      {
        name: 'ML_SA_IDS',
        value:  data.ML_SA_IDS ? [data.ML_SA_IDS.join()] : null,
        objId: data.id ? data.id : null,
        objectType: ParamObjectType.Process,
        caption: $localize`:@@DataProcess.atribute.caption.services:Služby`,
        order: Number.MIN_VALUE,
        group: {position: 10, id: null, type: null, caption: null},
        type: 'SELECT', // Tento atribut je zvláštního typu, a nemá odpovídající parametrový typ
        index: 0,
        indexed: true,
        unique: true,
        domain: 'attribute::SERVICE',
        visibilityDependency: data.visibilityDependencyServices,
        editDependency: data.editDependencyServices,
        requireDependency: data.requireDependencyServices,
        attribute: true,
        editable: data.editable,
      }, services
    );
    params.push(param);

    param = new ParamBase(
      {
        name: 'ML_NAME',
        value: data.id ? data.ML_NAME : data.typeName,
        defaultValue: data.id ? data.ML_NAME : data.typeName,
        objId: data.id ? data.id : null,
        objectType: ParamObjectType.Process,
        caption: $localize`:@@DataProcess.atribute.caption.name:Název`,
        order: Number.MIN_VALUE,
        group: {position: 16, id: null, type: null, caption: null},
        type: 'STRING',
        visibilityDependency: data.visibilityDependencyMlName,
        editDependency: data.editDependencyMlName,
        requireDependency: data.requireDependencyMlName,
        attribute: true,
        editable: data.editable,
      }, services
    );
    params.push(param);

    param = new ParamBase(
      {
        name: 'ML_DESC',
        value: data.ML_DESC,
        objId: data.id ? data.id : null,
        objectType: ParamObjectType.Process,
        caption: $localize`:@@DataProcess.atribute.caption.description:Popis`,
        order: Number.MIN_VALUE,
        group: {position: 20, id: null, type: null, caption: null},
        type: 'TEXT',
        visibilityDependency: data.visibilityDependencyMlDesc,
        editDependency: data.editDependencyMlDesc,
        requireDependency: data.requireDependencyMlDesc,
        attribute: true,
        editable: data.editable,
      }, services
    );
    params.push(param);

    return params;
  }

  getServices(): ParamServices {
    return {
      dataValuePatterns: this.dataValuePatterns,
      dataCodeList: this.dataCodeList,
      dataValueDomain: this.dataValueDomain,
      dataProcessPremises: this.dataProcessPremises,
      dataProcessServices: this.dataProcessServices,
      dataProcessUnits: this.dataProcessUnits
    };
  }

  getProcessChanged(): Observable<boolean>{
    return this._processChanged;
  }

  put(id: string, data: any, params?: any, urlExpandParams?: any): Observable<Process> {
    return super.put(id, data, params, urlExpandParams).pipe(tap(() => {
      this._processChanged.next(true);
    }));
  }


}

// Data pro existující a nové procesy získávám z jiných endpointů, ala mají stejný formát.

@Injectable({
  providedIn: 'root'
})
export class DataProcess extends DataProcessGeneral {

  // getallUrl = 'processes';

  protected getAllUrl(): string {
    return 'processes' as const;
  }

  configureUrl(config: DataBaseServiceConfig): void {
    this.baseurl = config.mangoUrl;
  }

  getAttributesAsParams(id, defId): Observable<ParamBase[]> {
    return this.getone(id, {defId}).pipe(map(param => {
      return  this.transformDataToParams(param, this.getServices());
    }));
  }

  protected recordPostprocess(record: Process): Process {
    if (record.lastActivity){
      record.lastActivityObj = new Date(record.lastActivity);
    }
    return record;
  }

  getServices(): ParamServices{
    return {
      dataValuePatterns: this.dataValuePatterns,
      dataCodeList: this.dataCodeList,
      dataValueDomain: this.dataValueDomain,
      dataProcessPremises: this.dataProcessPremises,
      dataProcessServices: this.dataProcessServices,
      dataProcessUnits: this.dataProcessUnits
    };
  }

  metaFetchFilterInfo(meta: ProcessMeta): FilterInfo[]{
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.fulltext){
        info.push({
          label: $localize`:@@DataProcess.filterInfo.fulltext.label:Fulltext`,
          filterNames: ['fulltext'],
          value: '"' + meta.fulltext + '"'
        });
      }
      if (meta.dateFrom || meta.dateTo) {
        const dp = new DatePipe('cs-CZ');
        const dates = [];
        dates.push(meta.dateFrom ? dp.transform(meta.dateFrom, 'd. M. yyyy') : '');
        dates.push(meta.dateTo   ? dp.transform(meta.dateTo,   'd. M. yyyy') : '');
        info.push({
          label: $localize`:@@DataProcess.filterInfo.date.label:Nahlášeno`,
          filterNames: ['dateFrom', 'dateTo'],
          value: dates.join(' - ')
        });
      }
      if (meta.typeName){
        info.push({
          label: $localize`:@@DataProcess.filterInfo.type.label:Typ požadavku`,
          filterNames: ['typeId'],
          value: meta.typeName
        });
      }
      if (meta.unitName){
        info.push({
          label: $localize`:@@DataProcess.filterInfo.unit.label:Místo poskytování služeb`,
          filterNames: ['unit'],
          value: meta.unitName
        });
      }

      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataNewProcess extends DataProcessGeneral {

  // getallUrl = 'process-new';

  protected getAllUrl(): string {
    return 'process-new' as const;
  }

  getAttributesAsParams(id, defId): Observable<ParamBase[]> {
    return this.getSingleton({defId}).pipe(map(param => {
      return  this.transformDataToParams(param, this.getServices());
    }));
  }

  configureUrl(config: DataBaseServiceConfig): void {
    this.baseurl = config.mangoUrl;
  }
}

