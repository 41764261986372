import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(array: any[], fn?: any): any[] {
    if (!Array.isArray(array)) {
      return array;
    }
    if (fn === undefined) {
      fn = v => v;
    }
    return array.filter(fn);
  }

}
