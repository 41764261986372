<sc-main-toolbar>
  <!--  <h1 i18n="@@menu.process">Požadavky</h1>-->

  <!--  <div actions>-->
  <!--    <button mat-button (click)="startNewProces()">-->
  <!--      <mat-icon>add</mat-icon>-->
  <!--      <span i18n="@@ProcessList.add.button">Nový</span>-->
  <!--    </button>-->
  <!--  </div>-->
</sc-main-toolbar>

<div class="mango-table-page-contents">
  <sc-page-caption-block></sc-page-caption-block>
  <h1 i18n="@@menu.process">Test populistu</h1>

<form [formGroup]="form">
  <mat-checkbox formControlName="dbFilter">Filtrovat přímo v databázi</mat-checkbox>
  <br>

  <ng-container *ngIf="test">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@ProcesList.test.label">Klasický select (pro porovnání)</mat-label>
      <mat-select formControlName="classicSelect" id="classicSelect">
        <mat-option *ngFor="let option of processOptions" [value]="option.id">{{option.name}}</mat-option>
      </mat-select>

      <mat-error *ngIf="form.get('classicSelect').invalid && (form.get('classicSelect').dirty || form.get('classicSelect').touched)">
        {{formHelper.getErrorMessage(form.get('classicSelect'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <div *ngIf="test">Hodnota: {{form.get('classicSelect').value}}</div>
  <br>

  <ng-container *ngIf="test">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@ProcesList.test.label">Klasický multiselect (pro porovnání)</mat-label>
      <mat-select formControlName="classicMultiSelect" id="classicMultiSelect" multiple>
        <mat-option *ngFor="let option of processOptions" [value]="option.id">{{option.name}}</mat-option>
      </mat-select>

      <mat-error *ngIf="form.get('classicMultiSelect').invalid && (form.get('classicMultiSelect').dirty || form.get('classicMultiSelect').touched)">
        {{formHelper.getErrorMessage(form.get('classicMultiSelect'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <div *ngIf="test">Hodnota: {{form.get('classicMultiSelect').value}}</div>
  <br>

  <ng-container *ngIf="test">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@ProcesList.test.label">Nová komponenta static options</mat-label>
      <popup-list formControlName="simpleStatic" id="simpleStatic" [staticOptions]="processOptions" [dataSource]="" [filterDb]='false'>
      </popup-list>
      <mat-error *ngIf="form.get('simpleStatic').invalid && (form.get('simpleStatic').dirty || form.get('simpleStatic').touched)">
        {{formHelper.getErrorMessage(form.get('simpleStatic'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <div *ngIf="test">Hodnota: {{form.get('simpleStatic').value}}</div>
  <br>

  <ng-container *ngIf="test">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@ProcesList.test.label">Nová komponenta db options</mat-label>
      <popup-list formControlName="simpleDB" id="simpleDB" [dataSource]="dataService" [filterDb]="form.get('dbFilter').value" [defaultFilters]="deafautlTestFilter">
      </popup-list>
      <mat-error *ngIf="form.get('simpleDB').invalid && (form.get('simpleDB').dirty || form.get('simpleDB').touched)">
        {{formHelper.getErrorMessage(form.get('simpleDB'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <div *ngIf="test">Hodnota: {{form.get('simpleDB').value}}</div>
  <br>

  <ng-container *ngIf="test">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@ProcesList.test.label">Nová komponenta db i static </mat-label>
      <popup-list formControlName="simpleCombination" id="simpleCombination" [staticOptions]="processOptions" [dataSource]="dataService" [defaultFilters]="deafautlTestFilter" [filterDb]="form.get('dbFilter').value">
      </popup-list>
      <mat-error *ngIf="form.get('simpleCombination').invalid && (form.get('simpleCombination').dirty || form.get('simpleCombination').touched)">
        {{formHelper.getErrorMessage(form.get('simpleCombination'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <div *ngIf="test">Hodnota: {{form.get('simpleCombination').value}}</div>
  <br>

  <ng-container *ngIf="test">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@ProcesList.test.label">Nová komponenta multi static</mat-label>
      <popup-list formControlName="multiStatic" id="multiStatic" [multiple]="true" [staticOptions]="processOptions">
      </popup-list>
      <mat-error *ngIf="form.get('multiStatic').invalid && (form.get('multiStatic').dirty || form.get('multiStatic').touched)">
        {{formHelper.getErrorMessage(form.get('multiStatic'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <div *ngIf="test">Hodnota: {{form.get('multiStatic').value}}</div>
  <br>

  <ng-container *ngIf="test">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@ProcesList.test.label">Nová komponenta multi db options</mat-label>
      <popup-list formControlName="multiDB" id="multiDB" [multiple]="true" [dataSource]="dataService" [filterDb]="form.get('dbFilter').value" [defaultFilters]="deafautlTestFilter">
      </popup-list>
      <mat-error *ngIf="form.get('multiDB').invalid && (form.get('multiDB').dirty || form.get('multiDB').touched)">
        {{formHelper.getErrorMessage(form.get('multiDB'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <div *ngIf="test">Hodnota: {{form.get('multiDB').value}}</div>
  <br>

  <ng-container *ngIf="test">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@ProcesList.test.label">Nová komponenta multi db i static</mat-label>
      <popup-list formControlName="multiCombination" id="multiCombination" [multiple]="true" [staticOptions]="processOptions" [dataSource]="dataService" [filterDb]="form.get('dbFilter').value" [defaultFilters]="deafautlTestFilter">
      </popup-list>
      <mat-error *ngIf="form.get('multiCombination').invalid && (form.get('multiCombination').dirty || form.get('multiCombination').touched)">
        {{formHelper.getErrorMessage(form.get('multiCombination'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>

  <div *ngIf="test">Hodnota: {{form.get('multiCombination').value}}</div>
  <br>


</form>
</div>