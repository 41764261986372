import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';

export enum UserType{
  S = 'S',
  F = 'F',
  P = 'P',
}

export interface UserData {
  U_BP_IDS: string;
  accountBank: string;
  accountIban: string;
  accountNumber: string;
  accountPreNumber: string;
  accountSpecSym: string;
  accountVarSym: string;
  active: boolean;
  agreementName: string;
  billingAddress: string;
  billingAddressCity: string;
  billingAddressHouseId: string;
  billingAddressRecipient: string;
  billingAddressStreet: string;
  billingAddressZip: string;
  city: string;
  codeName: string;
  codeReference: string;
  companyFid: string;
  companyId: string;
  companyName: string;
  companyVatId: string;
  dateTest: string;
  dateTimeTest: string;
  dayOfBirth: string;
  degree: string;
  degreeBehind: string;
  documentNumber: string;
  emails: string;
  fax: string;
  firstName: string;
  houseId: string;
  icoDph: string;
  infoEmail: boolean;
  infoMarketing: boolean;
  infoPost: boolean;
  infoSms: boolean;
  lastName: string;
  login: string;
  mailingAddress: string;
  mailingAddressCity: string;
  mailingAddressHouseId: string;
  mailingAddressRecipient: string;
  mailingAddressStreet: string;
  mailingAddressZip: string;
  personalNumber: string;
  phones: string;
  premises: string;
  providerType: string;
  sipo: string;
  statutary: string;
  street: string;
  type: UserType;
  valuation: string;
  vatRegistered: boolean;
  vatRegisteredFrom: string;
  zip: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataUserEdit extends DataBase<UserData> {
  // getallUrl = 'eu-user';

  protected getAllUrl(): string {
    return 'eu-user' as const;
  }
}
