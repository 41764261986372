import {Injectable} from '@angular/core';
import {DataBase} from "@og_soft/data-base";


export interface RecEditorBlock {
  ttId: number,
  ttName: string,
  ord: number,
  placement: string,
}

@Injectable({
  providedIn: 'root'
})
export class EditorBlockService extends DataBase<RecEditorBlock> {
  // getallUrl = "editor-block";

  protected getAllUrl(): string {
    return 'editor-block' as const;
  }
}
