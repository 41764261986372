<sc-main-toolbar>
</sc-main-toolbar>

<div class="mango-table-page-contents">
  <h1 i18n="@@menu.unicredit">Předplatné</h1>
  <sc-page-caption-block></sc-page-caption-block>

    <section class="overview-and-actions" *ngIf="varSymbolGet()">
      <div>
        <div class="balance-label mango-text-secondary"><span i18n="@@EuUnicredit.creditCard.caption">Zůstatek kreditu</span></div>
        <div class="balance-value" [ngClass]="{mobile: session.isMobile}">
          {{balanceGet()| currency:getCurrencySymbol()}}
        </div>
      </div>
      <div class="actions mango-button-row aligned-right">
        <a mat-flat-button
           color="primary"
           *ngIf ="proxyPhonetGet() > 0 && saPhonetGet() > 0 && this.session.getOption('SELFCARE.EuUnicreditComponent.EuUnicreditComponent.Action.phonetCredit')"
           (click)="phonetCredit()"
           target="_blank">
          <span i18n="@@EuUnicredit.phoneForwarded.button">Přenos kreditu do Phonetu</span>
        </a>

        <a mat-flat-button
           *ngIf =" ( this.session.getOption('SELFCARE.user-services.creditCard.button')) "
           color="primary"
           (click)="payment()"
           target="_blank">
          <span i18n="@@EuUnicredit.creditCard.button">Dobít kredit</span>
        </a>
      </div>
    </section>

  <section class="history" [style.display]="tbl.nrecords > 0 ? 'block' : 'none'">
    <h2>Historie</h2>

    <mgt-table #tbl [service]="dataService">
       <mgt-header [filterValues]="[]" [tab]="tbl">
       </mgt-header>
       <mgt-row
        [replacing-detail]="false"
        *ngFor="let r of tbl.scrollItems"
        [record]="r">

        <mgt-ico>
            <mat-icon [ngClass]="{'mango-text-warn': r.amount < 0 , 'mango-text-success': r.amount > 0, 'icon-withdraw': r.amount < 0 , 'icon-deposit': r.amount > 0 }">keyboard_backspace</mat-icon>
            <!--<mat-icon [ngClass]="{'mango-text-warn': r.amount < 0 , 'mango-text-success': r.amount > 0, 'icon-withdraw': r.amount < 0 , 'icon-deposit': r.amount > 0 }">{{r.amount > 0 ? 'add' :'remove'}}</mat-icon>-->
        </mgt-ico>

        <mgt-cell-group master>
          <mgt-cell title="Služba" i18n-title="@@EuUnicredit.record.serviceName.title" role="title">
            {{r.serviceName}}
          </mgt-cell>

          <mgt-cell title="Datum a čas" i18n-title="@@EuUnicredit.record.date.title" >
            {{r.date | date: "mediumDate"}} {{r.date | date: "shortTime"}}
          </mgt-cell>

          <!--<mgt-cell desktop-only title="" role="description">-->
            <!--{{r.descriptionEu}}-->
          <!--</mgt-cell>-->
        </mgt-cell-group>

        <mgt-cell-group>
          <mgt-cell title="Částka" i18n-title="@@EuUnicredit.record.amount.title" role="result"
                [ngClass]="{ 'cell-right': true
                         , 'mango-text-warn': r.amount < 0
                         , 'mango-text-success': r.amount > 0
                         }">
            {{r.amount | currency:getCurrencySymbol()}}
          </mgt-cell>
        </mgt-cell-group>


        <div detail>
                <div *ngIf="r.dateFrom" class="detail-field">
                      <div class="mat-caption"><span i18n="@@EuUnicredit.dateFromTo.caption">Uhrazené období</span></div>
                      <div class="value">{{r.dateFrom | date: "d. M. yyyy" }} {{r.dateTo | date: " - d. M. yyyy"}}</div>
                  </div>

                  <div class="detail-field">
                      <div class="value">{{r.descriptionEu}}</div>
                  </div>

        </div>


          </mgt-row>

      <div no-data-alternate-content>
        <page-empty i18n-message="@@EuUnicredit.PageEmpty.message" message="Není co zobrazit."></page-empty>
      </div>

    </mgt-table>
  </section>

</div>
