import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SessionService} from '../_services/session.service';
import {UtilPrintService} from '../_services/util-print.service';
import {DataDocumentsAgreements} from '../_services/data-documents-agreements';
import {MangoFormControl} from '../_models/mango-form-control';
import {FormGroup} from '@angular/forms';
import {ForgetTableComponent} from '../_libraries/forget-table/forget-table/forget-table.component';
import {DialogConfig, DialogService} from '@og_soft/dialog';
import {DocumentAgreementDialogComponent} from './document-agreement-dialog/document-agreement-dialog.component';
import {AgreementConfirmService} from '../_services/agreement-confirm.service';
import {of} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, switchMap} from 'rxjs/operators';


@Component({
  selector: 'app-documents-agreements',
  templateUrl: './documents-agreements.component.html',
  styleUrls: ['./documents-agreements.component.scss']
})


export class DocumentsAgreementsComponent implements OnInit {

  constructor(
    public dataService: DataDocumentsAgreements,
    public session: SessionService,
    public printUtil: UtilPrintService,
    private readonly dialogService: DialogService,
    private readonly confirmService: AgreementConfirmService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.filters = new FormGroup({
      valid: new MangoFormControl(''),
    });
    this.filters.patchValue(this.filterValues);
  }

  @ViewChild(ForgetTableComponent, {static: false}) public tab: ForgetTableComponent;

  filters: FormGroup;
  filterValues: any = { valid: true };

  confirmCdId?: number;

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        if (params.confirm) {
          // Jestliže už je potvrzeno, přesměrovat na 
          // printUtil.docLinkUrl('cd', params.confirm )
          this.confirmService.getone(params.confirm).subscribe(res => {
            if ((<any> res).confirmationNeeded) {
              this.confirmCdId = params.confirm;
              this.confirmAgreement(this.confirmCdId);
            } else {
              window.open(this.printUtil.docLinkUrl('cd', params.confirm ), '_blank');
            }
          });
        }
      });
  }

  confirmAgreement(cdId: number, documentNameTpl?: TemplateRef<void>): void {
    const dialogConfig = new DialogConfig();
    dialogConfig.data = {
      documentId: cdId,
      documentNameTpl,
    };
    const dialog = this.dialogService.open(DocumentAgreementDialogComponent, dialogConfig);
    dialog.afterClosed
      .pipe(
        filter(result => result?.agreementConfirmed === true),
        switchMap(() => {
          // TODO trigger confirm action and reload necessary data
          this.confirmService.post({documentId: cdId}).subscribe(() => {
            this.tab.fetchAgain();
            this.session.message( $localize`:@@DocumentAgreementDialog.confirmed.message:Smlouva byla potvrzena. Děkujeme! :)` );
          }, err => {
            console.error('Tak to tak úplně nevyšlo.', err);
          });
          console.log('TODO trigger confirm action and reload necessary data');
          return of(null);
        })
      )
      .subscribe();
  }

}
