import {Component, HostBinding, OnInit} from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'mgt-ico',
  //templateUrl: './mgt-ico.component.html',
  //styleUrls: ['./mgt-ico.component.scss']
	template: `<ng-content></ng-content>`,

	styles: [`
    :host {
      flex: 0 0 36px;
    }
    :host[desktop-only].mobile {
      display: none;
    }
    :host[mobile-only]:not(.mobile) {
      display: none;
    }
  `],
})
export class MgtIcoComponent implements OnInit {
  /* Informace od deviceDetectorService, že jsme na mobilu. */
  @HostBinding('class.mobile')
  public mobile: boolean = false;


  constructor(private deviceDetector: DeviceDetectorService,) {
    this.mobile = deviceDetector.isMobile();
  }

  ngOnInit() {
  }
}
