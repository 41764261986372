import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';

export interface IntegrationTestResult {
  result: 'OK'|'FAIL';
  message: string;
}

@Injectable({
  providedIn: 'root'
})
/**
 * Servisa pro testování platby kartou
 */
export class CsobEchoService extends DataBase<IntegrationTestResult> {
  // getallUrl = 'payment-3dsecure-echo';

  protected getAllUrl(): string {
    return 'payment-3dsecure-echo' as const;
  }
}
