import {AbstractControl, AsyncValidator, AsyncValidatorFn, FormGroup, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {NodeParamUniqueValidatorService} from '../_services/node-param-unique-validator.service';
import {Injectable} from '@angular/core';
import {MangoParamFormControl} from '../_libraries/param-form/mango-param-form-control';

@Injectable({providedIn: 'root'})
export class NodeParamUniqueValidator implements AsyncValidator {
  constructor(
    private nodeParamUniqueValidatorService: NodeParamUniqueValidatorService
  ) {}
  validate(ctrl: MangoParamFormControl):
    Promise<ValidationErrors | null> | Observable < ValidationErrors | null > {
      ctrl = ctrl as MangoParamFormControl;
      if (!ctrl.param)
      {
        return of(null);
      }

      const options = {
        id: ctrl.param.objId,
        name: ctrl.param.name,
        index: ctrl.param.index,
        value: ctrl.value,
      };

      return this.nodeParamUniqueValidatorService.getSingleton(options).pipe(
        map(valid => (valid ? null : {notUnique: true})),
        catchError(() => null)
      );
  }
}
