import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {DataUserServices} from './data-user-services';
import {DataBase, DataBaseServiceConfig, FilterInfo, GetallResult} from '@og_soft/data-base';
import {CachingInterceptor} from '@og_soft/cache';


/**
 * Interface pro jeden záznam ze služeb zákazníka, jak ho vrací API.
 *
 * TypeScript používá interfaces pro typovou kontrolu. Je otázka, zda
 * to kromě toho má pozitivní vliv ma schopnost IDE napovídat. Pokud
 * se to nepotvrdí, tak se s těmi interfaces psát nebudeme a budeme
 * prostě používat typ "any".
 */
export interface RecUserPayments {
  datePayment: string;
  amountPayment: number;
  vsPayment: string;
  notePayment: string;
  typePayment: string;
  advType: string;
  advVarSym: string;
  expDoc: number;
  pDoc: string;
  plId: number;
  name: string;
  paName: string;
  paType: string;
  service: any[]; // FIXME přesnější specifikaci typu místo any
  paramIdent: string;

}

export interface RecUserPaymentsMeta {
  dateFrom: string;
  dateTo: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataUsersPayments extends DataBase<RecUserPayments> {
  // getallUrl = 'user-payments';

  protected getAllUrl(): string {
    return 'user-payments' as const;
  }

  constructor(public http: HttpClient
    ,         public serviceConfig: DataBaseServiceConfig
    ,         public userServices: DataUserServices
    ,         private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected dataPostprocess(data: GetallResult<RecUserPayments>): GetallResult<RecUserPayments> {
    // FIXME optimalizovat, aby se to dělalo jedním getAll s parametry - viz. např. user-nodes.service.ts
    for (let i = 0; i < data.data.length; i++) {
      for (let iSa = 0; iSa < data.data[i].service.length; iSa++) {
        this.userServices.getone(data.data[i].service[iSa].id).subscribe(d => {
          data.data[i].service[iSa].name = d.saName;
          data.data[i].service[iSa].paramIdent = DataUserServices.saIdentGet(d);
          this.onDataCollected.emit();
        });
      }
    }
    return super.dataPostprocess(data);
  }

  metaFetchFilterInfo(meta: RecUserPaymentsMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.dateFrom || meta.dateTo) {
        const dp = new DatePipe('cs-CZ');
        const dates = [];
        dates.push(meta.dateFrom ? dp.transform(meta.dateFrom, 'd. M. yyyy') : '');
        dates.push(meta.dateTo ? dp.transform(meta.dateTo, 'd. M. yyyy') : '');
        info.push({
          label: $localize`:@@DataUserPayments.filterInfo.date.label:Období`,
          filterNames: ['dateFrom', 'dateTo'],
          value: dates.join(' - ')
        });
      }

      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }
}
