import {Injectable} from '@angular/core';
import {DataBase, DataBaseServiceConfig, GetallResult} from '@og_soft/data-base';
import {HttpClient} from '@angular/common/http';
import {CachingInterceptor} from '@og_soft/cache';
import {SessionService} from '../../_services/session.service';
import {Observable} from 'rxjs';

export interface ProcessType {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataProcessTypes extends DataBase<ProcessType> {
  // getallUrl = 'process-types';
  protected getAllUrl(): string {
    return 'process-types' as const;
  }

  configureUrl(config: DataBaseServiceConfig): void {
    this.baseurl = config.mangoUrl;
  }

  getTypesForInsert(): Observable<GetallResult<ProcessType>>{
    return this.getall({insertOnly: true}, 3600);
  }

  tranformOptions(row: ProcessType): { id: string; name: string; disabled?: boolean } {
    return {id: row.id.toString(), name: row.name};
  }
}
