import {Component, OnInit, ViewChild} from '@angular/core';
import {DataBilling} from '../_services/data-billing.service';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilPrintService} from '../_services/util-print.service';
import {CardPaymentComponent} from '../card-payment/card-payment.component';
import {SessionService} from '../_services/session.service';
import {ForgetTableComponent} from '../_libraries/forget-table/forget-table/forget-table.component';
import {DialogConfig, DialogService} from '@og_soft/dialog';
import {MangoFormControl} from '../_models/mango-form-control';
import {DataUserServices} from '../_services/data-user-services';
import {Payment3dsecureResponseService} from '../_services/payment-3dsecure-response.service';
import {FormHelperService} from '../_services/form-helper.service';
import {dateRangeValidate} from '@og_soft/datetime-control';
import {CrossFieldErrorMatcher} from '../_directives/common-validators';

@Component({
  selector: 'app-eu-billing',
  templateUrl: './eu-billing.component.html',
  styleUrls: ['./eu-billing.component.scss']
})
export class EuBillingComponent implements OnInit {
  // @ViewChild('vscroller', {static: false}) public vscroller: VirtualScrollerComponent;

  constructor(
    private route: ActivatedRoute,
    public dataService: DataBilling,
    public printUtil: UtilPrintService,
    private router: Router,
    private payment3dSecureResponse: Payment3dsecureResponseService,
    public session: SessionService,
    public dialog: DialogService,
    public dataUserServices: DataUserServices,
    public formHelper: FormHelperService,
  ) {
  }

  filters: FormGroup;
  filterValues: any = {};

  private subParams: any;

  @ViewChild('tbl', {static: false}) public tab: ForgetTableComponent;

  // @ViewChild("dlgCardPay", {static: false}) dlgCardPay: ModalDialogComponent;
  @ViewChild('formCardPay', {static: false}) formCardPay: CardPaymentComponent;

  // just specific error type, otherwise it will highlight on any error within the group
  errorMatcher = new CrossFieldErrorMatcher(['dateRangeInvalid']);

  ngOnInit(): void {
    this.filters = new FormGroup({
      dateFrom: new MangoFormControl(''),
      dateTo: new MangoFormControl(''),
      saIds: new MangoFormControl(''),
    }, { validators: dateRangeValidate('dateFrom', 'dateTo') });
    this.filters.patchValue(this.filterValues);

    this.subParams = this.route.params.subscribe(params => {
      this.filterValues = params.saIds ? {saIds: params.saIds} : {};
      this.filters.patchValue(this.filterValues);
    });
  }

  getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }


  debt(amountPay: number): number {
    if ( amountPay > 0 ) {
      return 1;
    }
    return 0;
  }

   payment(data: any): void {
      const dconfp = new DialogConfig();
      dconfp.data = {
        amount: data.amountPay,
        varsym: data.nameTax,
        typeAttach: null,
        saId: 0,

      };
   //    this.formCardPay.itemDisabled("amount");
   //    this.formCardPay.itemDisabled("varsym");

      const dref = this.dialog.open( CardPaymentComponent, dconfp);
      dref.afterClosed.subscribe(result => {
        // if (result)
       // console.error("Tady mam data ", result)
      });
    }

  isAfterMaturity(r: any): boolean {
    const maturity = new Date(r.dateMaturity);
    const now = new Date();
    return maturity.getTime() <= now.getTime();
  }


  typePayVisible(): boolean {
    const opt = this.session.getOption('SELFCARE.eu-billing.typePay');
    return ! (opt && opt.includes('hidden'));
  }


}
