import {Injectable} from "@angular/core";
import {CanDeactivate} from "@angular/router";
import {ProcessEditComponent} from "./process-edit.component";
import {Observable, of} from "rxjs";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProcessEditCanDeactivate implements CanDeactivate<ProcessEditComponent> {
  constructor() {}

  canDeactivate(component: ProcessEditComponent): Observable<boolean> {
    return of(component.leavingWithBackButton).pipe(
      tap((canLeave)=>{
      if (!canLeave){
        component.leaveProcess();
      }
    }));
  }
}
