<dialog-title>
<ng-container i18n="Návrh na uzavření ticketu|Nadpis dialogu uzavření ticketu@@UserServiceEdit"
>Přenos kreditu do Phonetu</ng-container><br>
</dialog-title>

<p>
   <span class="'mango-text-success'"><span i18n="@@PhonetCredit.message.text">Aktuální použitelný zůstatek uniCredit je</span> {{balanceGet()| currency:getCurrencySymbol() }}</span>
</p>


<dialog-content>

<form [formGroup]="form">

<ng-container *ngIf="formHelper.isVisible(form.get('userServices'))">
                <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                  <mat-label i18n="@@PhonetCredit.userServices.label">Služba</mat-label>
                  <popup-list formControlName="userServices" id="userServices" name="userServices" [staticOptions]="" [dataSource]="phonetCreditServices" [filterDb]='false' [noValue]="false"i18n-title="@@PhonetCredit.userServices.title" title="Služba">
                    </popup-list>
                  <mat-error *ngIf="form.get('userServices').invalid && (form.get('userServices').dirty || form.get('userServices').touched)">
                    {{formHelper.getErrorMessage(form.get('userServices'))}}
                  </mat-error>
                </mat-form-field>
               </ng-container>



<ng-container *ngIf="formHelper.isVisible(form.get('amount'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@PhonetCredit.amount.label">Částka</mat-label>
                        <input matInput formControlName="amount" id="amount" name="amount" type="number"
                          i18n-placeholder="@@PhonetCredit.amount.placeholder" placeholder="undefined" i18n-title="@@PhonetCredit.amount.title" title="Částka">
                          <span matSuffix>{{session.user.locDefaults.currencySymbol}}</span>
                        <mat-error *ngIf="form.get('amount').invalid && (form.get('amount').dirty || form.get('amount').touched)">
                          {{formHelper.getErrorMessage(form.get('amount'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>



</form>

</dialog-content>

<dialog-actions>
  <button mat-flat-button dialog-close i18n="Zavřít|Tlačítko na zavření dialogu v dialogu při uzavření ticketu@@ProcessFinish.button.Close">Zavřít</button>
  <button mat-flat-button color="primary" (click)="save()" i18n="Odeslat|Tlačítko na uložení dialogu v dialogu při uzavření ticketu@@ProcessFinish.button.Send">Uložit</button>

</dialog-actions>

