import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UserServiceEditComponent} from '../user-service-edit/user-service-edit.component';
import {SessionService} from '../_services/session.service';
import {RecUnit} from '../_services/data-units.service';
import {UserServicesService} from '../_services/user-services.service';
import {DataUserServices, RecServiceParam, RecUserServices} from '../_services/data-user-services';
import {UserNodesService} from '../_services/user-nodes.service';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ForgetTableComponent} from '../_libraries/forget-table/forget-table/forget-table.component';
import {DialogConfig, DialogService} from '@og_soft/dialog';
import {MangoFormControl} from '../_models/mango-form-control';
import {DataParamsServiceActive} from '../_services/data-params-service-active.service';
import {DataSaFreeUnitsService} from '../_services/data-sa-free-units.service';
import {CardPaymentComponent} from "../card-payment/card-payment.component";

@Component({
  selector: 'app-user-services',
  templateUrl: './user-services.component.html',
  styleUrls: ['./user-services.component.scss']
})
export class UserServicesComponent implements OnInit, AfterViewInit {

  constructor(public dataService: UserServicesService,
              public dataNodes: UserNodesService,
              public session: SessionService,
              private route: ActivatedRoute,
              public dialog: DialogService,
              private saFreeUnitsService: DataSaFreeUnitsService,
              private saParamsService: DataParamsServiceActive,
  ) {

  }

  /**
   * Vrací záznamy jednotek, na kterých je daná služba.
   */
  public get units(): RecUnit[][] {
    return null;
    // if (!this.tabGroup) {
    //
    //     console.error("Nic tam není.");
    //
    //   return null; // Asking too early.
    //
    // }
    // let index = this.tabGroup.selectedIndex;
    // if (!this.services[index].units) {
    //   this.unitsService.getall({saId: +this.services[index].saId}).subscribe(units => {
    //     this._services[index].units = units.data;
    //   });
    // }
    // return this.services[index].units;
  }

  filters: FormGroup;
  filterValues: any = { _finInfo: true };

  private subParams: any;

  saIdentGet = DataUserServices.saIdentGet;

  @ViewChild(ForgetTableComponent, {static: false}) public tab: ForgetTableComponent;


  private _units: RecUnit;

  private gsmFreeUnits = {};

  ngOnInit(): void {
    this.filters = new FormGroup({
      nodeId: new MangoFormControl(''),
      _finInfo: new MangoFormControl(''),
    });
    this.filters.patchValue(this.filterValues);

    this.subParams = this.route.params.subscribe(params => {
      this.filterValues.nodeId = params.id ? params.id : null;
      this.filters.patchValue(this.filterValues);
    });

  }

  ngAfterViewInit(): void {
    // Předběžné načtení parametrů pro editaci služby, aby se při otevření dialogu nenačítali dlouho.
    // Dělám ho jen v případě, že má uživatel málo služeb (počítám, že typicky jednu nebo dvě)
    // Pokud je tedy služeb víc jak pět, tak si parametry nepřednačítám, abych zbytečně nezahrnoval server dotazy,
    // projeví se to pouze tak, že při otevření dialogu na editaci služby se bude trošku déle čekat.
    this.tab.bufferSubj.subscribe(next => {
      if (next.length <= 5){
        next.forEach(service => {
          this.saParamsService.getall({id: service.saId, defId: undefined}, 600).subscribe();
        });
      }
      // Potřebujeme id služeb, k nimž dočítat další informace (nyní volné minuty u hlasových)
      let gsmSaIds = [];
      next.forEach(service => {
        if (service.scFlags && service.scFlags.includes("SCF_GSM")) {
          if (service.params.find(el => el.name == "selfcare.service.free_units" && el.value == 1)) {
            gsmSaIds.push(service.saId);
          }
        }
      });
      if (gsmSaIds.length) {
        console.log("Budu zobrazovat volné jednotky u služeb:", gsmSaIds);
        this.saFreeUnitsService.getSingleton({"serviceIds": gsmSaIds.join(",")}).subscribe(res => {
          Object.assign(this.gsmFreeUnits, res);
          console.log("Free units now:", this.gsmFreeUnits);
        });
      }
    });
  }

  public scFlagsToIcons(flags: string[]): string[] {
    if (flags && flags.length) {
      const types = flags.map((flag) => {
        switch (flag) {
          case 'SCF_DATA':
          case 'SCF_DATA_LINK':
            return 'public'; // globe
          case 'SCF_TV_IPTV':
          case 'SCF_TV_ANALOG':
          case 'SCF_TV_DVB':
            return 'tv';
          case 'SCF_VOIP':
          case 'SCF_GSM':
          case 'SCF_SMS':
          case 'SCF_3S':
            return 'phone'; // general phone icon
          case 'SCF_EMAIL':
            return 'email'; // envelope icon
          case 'SCF_WHOLESALE':
          case 'SCF_VPS':
            return 'computer';
          case 'SCF_LOC':
            return 'toll'; // coins icon
        }
        return '';
      })
      . filter((v, i, a) => a.indexOf(v) === i) // toto je implementace unique nad polem
      ;
      if (types.length > 3) {
        return ['category'];
      }
      return types;
    }
    return [];
  }

  public saStateToIcon(state: string, detail: boolean): string {
    switch (parseInt(state, 10)) {
      case 0: return 'block'; // "zákaz stání"
      case 1: return detail ? 'done' : ''; // normální běžící služba
      case 2: return 'clear';
      case 3: return 'schedule';
      default: return '';
    }
  }

  public saIsTelephony(scState: Array<string>): number | false {
    return Array.isArray(scState)
      ? ['SCF_VOIP', 'SCF_GSM', 'SCF_SMS', 'SCF_3S'].filter(value => scState.indexOf(value) !== -1).length
      : false;
  }

  public saHasNodes(r: RecUserServices): boolean {
    return r.nodes && Array.isArray(r.nodes) && r.nodes.length > 0;
  }

  public saHasParams(r: RecUserServices ): boolean {
     return r.paramsEditable > 0;
  }

  public period(r: any): boolean {
    return r.snType === 'M';

  }
  public saEdit(saId: number, saData: any): void {
    const dconf = new DialogConfig();
    dconf.data = {
      saId,
      saData,
    };
    const dref = this.dialog.open(UserServiceEditComponent, dconf);
    dref.afterClosed.subscribe(result => {
      if (result) {
        // Volá se getall i když by se nabízelo spíš getone. Důvod je ten, že getall načte přesně záznam tak, jak si
        // jej načítá tabulka. Záznam(y) pak vložíme do tabulky. Načítáme znovu ze serveru, protože data se mohla při
        // uložení modifikovat vlivem workflow, apod.
        // Pozor na statický "filtr" _finInfo - to je dost HACK!
        this.dataService.getall({saIds: [saId], _finInfo: true}).subscribe(serviceData => {
          serviceData.data.forEach(record => this.tab.recordUpdate(record, (a, b) => a.saId === b.saId));
        });
      }
    });
  }

  getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;

  }

  bpEuNameVisible(): boolean {
    const opt = this.session.getOption('SELFCARE.user-services.bpEuName');
    return ! (opt && opt.includes('hidden'));
  }

  prescriptionVisible(): boolean {
    const opt = this.session.getOption('SELFCARE.user-services.prescription');
    return ! (opt && opt.includes('hidden'));
  }

  getGsmFreeUnitsVisible(saId): boolean {
    return saId in this.gsmFreeUnits;
  }

  getGsmFreeMinutesAvailable(saId): boolean {
    return this.gsmFreeUnits[saId].freeCalls;
  }

  getGsmFreeMinutesTotal(saId): boolean {
    return this.gsmFreeUnits[saId].freeCallsTotal;
  }

  getGsmFreeSmsAvailable(saId): boolean {
    return this.gsmFreeUnits[saId].freeSms;
  }

  getGsmFreeSmsTotal(saId): boolean {
    return this.gsmFreeUnits[saId].freeSmsTotal;
  }

  payment(data: any): void {
    const dconfp = new DialogConfig();
    let attachType = 18;
    dconfp.data = {
      amount: data.ttdDebt,
      varsym: data.saVarSymbol,
      saIds: data.saIds,
      typeAttach: attachType,
    };
    //    this.formCardPay.itemDisabled("amount");
    //    this.formCardPay.itemDisabled("varsym");

    const dref = this.dialog.open( CardPaymentComponent, dconfp);
    dref.afterClosed.subscribe(result => {
      // if (result)
      // console.error("Tady mam data ", result)
    });
  }

}
