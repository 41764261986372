import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {UserServicesComponent} from './user-services/user-services.component';
import {EuBillingComponent} from './eu-billing/eu-billing.component';
import {ContactsComponent} from './contacts/contacts.component';
import {EuUnicreditComponent} from './eu-unicredit/eu-unicredit.component';
import {ProtectedDataComponent} from './protected-data/protected-data.component';
import {DocumentsComponent} from './documents/documents.component';
import {DocumentsAgreementsComponent} from './documents-agreements/documents-agreements.component';
import {DocumentsOtherComponent} from './documents-other/documents-other.component';
import {TelephonyListComponent} from './telephony-list/telephony-list.component';
import {UserPaymentsComponent} from './user-payments/user-payments.component';
import {DataViewComponent} from './data-view/data-view.component';
import {EditorBlockPageComponent} from './editor-block-page/editor-block-page.component';
import {NodesComponent} from './nodes/nodes.component';
import {HomeComponent} from './home/home.component';
import {CanDeactivateGuard} from './_guards/can-deactivate.guard';
import {ScLoginComponent} from './sc-login/sc-login.component';
import {PasswordComponent} from './password/password.component';
import {AuthGuard} from './_guards/auth.guard';
import {ProcessListComponent} from './process-list/process-list.component';
import {ProcessEditComponent} from './process-edit/process-edit.component';
import {ScGetDocumentComponent} from './sc-get-document/sc-get-document.component';
import {RmRedirectComponent} from './rm-redirect/rm-redirect.component';
import {TestComponentComponent} from './test-component/test-component.component';
import {VerifyRegistrationComponent} from './verify-registration/verify-registration.component';
import {RegistrationComponent} from './registration/registration.component';
import {CsobIntegrationTestComponent} from './integration-tests/csob-integration-test/csob-integration-test.component';
import {TestGuard} from './_guards/test.guard';
import {CardResponseComponent} from './core/card-response/card-response.component';
import {ProcessEditCanDeactivate} from "./process-edit/process-edit-can-deactivate";

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'}, // TODO: revidovat
  {path: 'home', component: HomeComponent, data: {title: 'Nástěnka'}},
  {path: 'user-profile',  component: UserProfileComponent, data: {title: 'Můj profil'}, canActivate: [AuthGuard]},
  {path: 'contacts',  component: ContactsComponent, data: {title: 'Mé kontakty'}, canActivate: [AuthGuard]},
  {path: 'billing',   component: EuBillingComponent, data: {title: 'Vyúčtování'}, canActivate: [AuthGuard]},
  {path: 'billing/:saIds',   component: EuBillingComponent, data: {title: 'Vyúčtování'}, canActivate: [AuthGuard]},
  {path: 'user-payments',   component: UserPaymentsComponent, data: {title: 'Platby'}, canActivate: [AuthGuard]},
  {path: 'user-services',  component: UserServicesComponent, data: {title: 'Mé služby'}, canActivate: [AuthGuard]},
  {path: 'user-services/:id',  component: UserServicesComponent, data: {title: 'Mé služby'}, canActivate: [AuthGuard]},
  {path: 'unicredit', component: EuUnicreditComponent, data: {title: 'UniCredit'}, canActivate: [AuthGuard]},
  {path: 'protected', component: ProtectedDataComponent, data: {title: 'Chráněná data'}, canActivate: [AuthGuard]},
  {path: 'documents', component: DocumentsComponent, data: {title: 'Dokumenty'}, canActivate: [AuthGuard]},
  {path: 'telephony', component: TelephonyListComponent, data: {title: 'Výpis hovorů'}, canActivate: [AuthGuard]},
  {path: 'telephony/:saId', component: TelephonyListComponent, data: {title: 'Výpis hovorů'}, canActivate: [AuthGuard]},
  {path: 'data', component: DataViewComponent, data: {title: 'Datové služby'}, canActivate: [AuthGuard]},
  {path: 'process', component: ProcessListComponent, data: {title: 'Požadavky'}, canActivate: [AuthGuard]},
  {path: 'process/:id', component: ProcessEditComponent,
    data: {title: 'Požadavek'}, canActivate: [AuthGuard], canDeactivate: [ProcessEditCanDeactivate]},
  {path: 'nodes', component: NodesComponent, data: {title: 'Zařízení'}, canActivate: [AuthGuard]},
  {path: 'nodes/:saId', component: NodesComponent, data: {title: 'Zařízení'}, canActivate: [AuthGuard]},
  {path: 'page-editor-block/:id', component: EditorBlockPageComponent, data: {}},
  {path: 'documents-agreements', component: DocumentsAgreementsComponent, data: {title: 'Smluvní dokumenty'}, canActivate: [AuthGuard]},
  {path: 'documents-other', component: DocumentsOtherComponent, data: {title: 'Ostatní dokumenty'}, canActivate: [AuthGuard]},
  {path: 'login', component: ScLoginComponent, data: {title: 'Login'} },
  {path: 'password', component: PasswordComponent, data: {title: 'Změna hesla'}},
  {path: 'registration', component: RegistrationComponent, data: {title: 'Registrace'}},
  {path: 'get-document/:id', component: ScGetDocumentComponent},
  {path: 'rm-redirect/:data', component: RmRedirectComponent},
  {path: 'test', component: TestComponentComponent},
  {path: 'csob-integration-test', component: CsobIntegrationTestComponent, canActivate: [TestGuard]},
  {path: 'csob-integration-test/:', component: CsobIntegrationTestComponent, canActivate: [TestGuard]},
  {path: 'verify-registration', component: VerifyRegistrationComponent},
  {path: 'registration-config', component: RegistrationComponent,
    data: {title: 'Konfigurace registračního formuláře'}, canActivate: [AuthGuard]},
  {path: 'cardresp/:red', component: CardResponseComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
