import {Injectable} from '@angular/core';
import {DataBase, DataBaseServiceConfig} from '@og_soft/data-base';
import {HttpClient} from '@angular/common/http';
import {CachingInterceptor} from '@og_soft/cache';
import {PhoneNumberPipe} from '@og_soft/phone-number';

export interface RecPhonetCreditGetData {
    saPhonetId: number;
    saPhonetName: string;
    saPhonetNum: string;
    saPhonetAmount?: number;
}

@Injectable({
    providedIn: 'root'
})

export class PhonetCreditGetService extends DataBase<RecPhonetCreditGetData> {


  constructor(http: HttpClient, configService: DataBaseServiceConfig, cache: CachingInterceptor,
              protected phoneNumber: PhoneNumberPipe) {
    super(http, configService, cache);
  }

  protected recordPostprocess(record: RecPhonetCreditGetData): RecPhonetCreditGetData {

    if (record.saPhonetNum) {
      record.saPhonetName += ' (' + this.phoneNumber.transform('+' + record.saPhonetNum) + ')';
    }

    return super.recordPostprocess(record);
  }
  // getallUrl = "phonet-credit-get";

  protected getAllUrl(): string {
    return 'phonet-credit-get' as const;
  }
}




