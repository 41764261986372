import {Component} from '@angular/core';
import {SessionService} from './_services/session.service';
import {environment} from '../environments/environment';
import {CheckDbService} from './core/check-db.service';
import {first} from 'rxjs/operators';
import {Router} from '@angular/router';
import {UnavailableService} from './core/unavailable.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'ISP Demo - samoobsluha';

  public theme = environment.theme || 'default-theme';

  constructor(
    public session: SessionService,
    private checkDbService: CheckDbService,
    private router: Router,
    private unavailable: UnavailableService,
  ) {
    this.session.processingSet(true, '');
    this.checkDbService.isDbReady().pipe(first()).subscribe(ready => {
      if (ready){
        this.session.checkLogin();
      } else {
        this.unavailable.startUnavailable();
      }
    }, error => {
      this.unavailable.startUnavailable();
    });
  }
}
