import {Injectable} from '@angular/core';
import {DataUnitsService, RecUnit} from './data-units.service';
import {RecNode} from './data-nodes.service';
import {DataBase, FilterInfo} from '@og_soft/data-base';
import {PhoneNumberPipe} from '@og_soft/phone-number';
import {PopupOption} from '@og_soft/popup-list-control';


/**
 * Interface pro jeden záznam ze služeb zákazníka, jak ho vrací API.
 */
export interface RecServiceParam {
  type: string;
  name: string;
  title: string;
  value: any;
  saIdent: boolean;
  view: string[];
  units: string;
  classId?: number;
  visibleInTable?: boolean;
}
export interface RecUserServiceFUP {
  enabled: boolean;
  limit: number;
  count_type: string;
  period: string;
  data: number;
  show: boolean;
}
export interface RecUserServices {
  saId: number;
  saBaseId: number;
  saIds: string;
  saName: string;
  saPrice: number;
  saPackagePrice: number;
  snVatBottomUp: number;
  reverseCharge: number;
  saDateStart: string;
  saDateEnd: string;
  saAccountFrom: string;
  saAccountTo: string;
  saTradingLimit: string;
  saVarSymbol: string;
  collectionType: string;
  ttdDebt: number;
  saStateId: string;
  snType: string;
  saEuStateName: string;
  saPeriod: string;
  saPeriodName: string;
  bpId: number;
  bpEuName: string;
  expire: string;
  scFlags: string;
  params: RecServiceParam[];
  fup: RecUserServiceFUP;
  units?: RecUnit[];
  nodes?: RecNode[];
  paramsEditable: number;
  paramsRowVisible?: boolean;
  unitAddress?: string;
}


export interface RecUserServiceMeta {
  id: string;
  name: string;
  parIdent?: string;
}

export interface RecUserServicesState {
  saState: string;
}


@Injectable({
  providedIn: 'root'
})
export class DataUserServices extends DataBase<RecUserServices, RecUserServiceMeta> {
  // getallUrl = 'user-service';

  protected getAllUrl(): string {
    return 'user-service' as const;
  }

  public static saIdentGet(saData: RecUserServices, format: boolean = true): string {
    for (let i = 0; saData.params && i < saData.params.length; i++) {
      const parInfo = saData.params[i];
      if (parInfo.saIdent && parInfo.value) {
        return format
          ? (parInfo.type === 'PHONE'
            ?  PhoneNumberPipe.prototype.transform('+' + parInfo.value)
            : parInfo.value)
          : parInfo.value;
      }
    }
    return DataUnitsService.shortenAddress(saData.unitAddress);
  }

  tranformOptions(row: RecUserServices): PopupOption {
    const saIdent = DataUserServices.saIdentGet(row, true);
    return {
      id: row.saBaseId.toString(),
      name: row.saName + (saIdent ? (' (' + saIdent + ')') : ''),
      icon: row.saStateId === '2' ? 'clear' : '',
      sorter: row.saStateId === '2' ? 0 : 1,
    };
  }

  metaFetchFilterInfo(meta: RecUserServiceMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.id) {
        let name = meta.name;
        const ident = meta.parIdent;
        if (ident) {
          name += ', ' + ident;
        }
        info.push({
          label: $localize`:@@DataUserServices.filterInfo.node.label:Zařízení`,
          filterNames: ['nodeId'],
          value: name
        });
      }
      return info;
    }

    return super.metaFetchFilterInfo(meta);
  }

}

@Injectable({
  providedIn: 'root'
})

export class UserServicesStateService extends DataBase<RecUserServicesState> {
  // getallUrl = 'user-services-state';

  protected getAllUrl(): string {
    return 'user-services-state' as const;
  }
}

