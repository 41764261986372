import { Component, OnInit } from '@angular/core';
import {DialogConfig} from "@og_soft/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  public heading: string = 'Confirm'
  public message: string = 'Do you really want to continue the action?';
  public messageDetail: string = '';

  constructor(
    public config: DialogConfig,
  ) {
    this.heading = config.data.heading ? config.data.heading : this.heading;
    this.message = config.data.message ? config.data.message : this.message;
    this.messageDetail = config.data.messageDetail ? config.data.messageDetail : this.messageDetail;
  }

  ngOnInit() {
  }

}
