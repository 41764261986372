import {ParamBase} from "./_models/param-base";
import {MangoFormControl} from '../../_models/mango-form-control';

export class MangoParamFormControl extends MangoFormControl {
  get param(): ParamBase {
    return this._param;
  }

  set param(value: ParamBase) {
    this._param = value;
  }

  private _param: ParamBase;
}
