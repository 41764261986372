import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {DependencyService} from "../_libraries/param-form/_services/dependency.service";



/**
 * Servisa, která se stará o vyhodnocení závislostí parametrů služby
 * V tuto chvíli není vyhodnocování závislostí nijak implementované, parametry jsou viditelné vždy
 *
 */
@Injectable({
    providedIn: 'root'
})
export class DependencyServiceService extends DependencyService{
    private data: {} = {}; //Moje představa je, že si služba bude držet veškerá data na vyhodnocení závislostí, a že tyto data budou



    public resolveDependency(dependencyStr: string, objectId: number, actualData: any): Observable<boolean> {
       // console.log('Vyhodnocuju závislost: ',dependencyStr);

        if (dependencyStr === '') {
            return of(false);
        }

        if (dependencyStr === 'ALL') {
            return of(true);
        }


        let paramDep = dependencyStr.split(';');
        for (let key in actualData.params) {
            if (actualData.params.hasOwnProperty(key)) {
                if (key == paramDep[0]) {
                    let value = actualData.params[key];
                    let paramDepValue = paramDep[1].split(',');
                    for (let key2 in paramDepValue) {
                        if (paramDepValue[key2] == value) {
                            return of(true);
                        }
                    }
                }
            }
        }


        return of(false);
    }

}


