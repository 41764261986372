import {FormControl} from '@angular/forms';

export class MangoFormControl extends FormControl {

  private visibility = {};

  public show(reason = 'nothing-specific'): void {
    this.visibility[reason] = true;
  }

  public hide(reason = 'nothing-specific'): void {
    this.visibility[reason] = false;
  }

  get visible(): boolean {
    for (const reason in this.visibility) {
      // V property visibility máme mapu důvodů být/nebýt viditelní
      // ve formátu důvod=>true/false. Potřebujeme ty důvody projít
      // a pokud kvůli žádnému nemáme být neviditelní, ukázat se.
      // Následující řádek patří ke standardnímu způsobu, jak v js iterovat
      // přes properties objektů.
      if (Object.prototype.hasOwnProperty.call(this.visibility, reason)) {
        if (! this.visibility[reason]) {
          return false;
        }
      }
    }
    return true;
  }
}
