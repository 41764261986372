import {Injectable} from '@angular/core';
import {DataBase} from "@og_soft/data-base";
import {PopupOption} from "@og_soft/popup-list-control";


export interface RecDocumentsOtherTypes {
    id: string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})

export class DataDocumentsOtherTypes extends DataBase<RecDocumentsOtherTypes> {
    // getallUrl = "documents-other-types";

  protected getAllUrl(): string {
    return 'documents-other-types' as const;
  }

    tranformOptions(row): PopupOption {
        return {id: row.cddtType, name: row.cddtTitle};
    }
}




