import {Injectable} from '@angular/core';
import {DataUserServices, RecUserServices} from './data-user-services';
import {HttpClient} from '@angular/common/http';
import {DataUnitsService} from './data-units.service';
import {DataNodesService} from './data-nodes.service';
import {DataBaseServiceConfig, GetallResult} from '@og_soft/data-base';
import {CachingInterceptor} from '@og_soft/cache';
import {SessionService} from './session.service';

@Injectable({
  providedIn: 'root'
})
export class UserServicesService extends DataUserServices {

  constructor(public http: HttpClient,
              private serviceConfig: DataBaseServiceConfig,
              public unitsService: DataUnitsService,
              public nodesService: DataNodesService,
              private cachingInterceptor: CachingInterceptor,
              public session: SessionService,
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected dataPostprocess(data: GetallResult<RecUserServices>): GetallResult<RecUserServices> {
    // Pozor toto sice dotáhne data z druhé service, ale je to asynchronní. Tedy v závislosti na rychlosti odezvy se
    // nejprve zobrazí data service první a až po čase se dotáhnou data service druhé.
    const ids: number[] = [];
    for (const service of data.data) {
      if (ids.indexOf(service.saId) < 0) {
        ids.push(service.saId);
      }
      service.paramsRowVisible = false;
      for (const param of service.params){
        // Vyhodnotím zobrazitelnost parametrů: řídím se sloupečkem SCP_VIEW a právem SELFCARE.user-service.param.view
        if (this.session.getOption('SELFCARE.user-service.param.view') &&
            this.session.getOption('SELFCARE.user-service.param.view').includes(param.classId + ':' + param.name)
        ) {
          param.visibleInTable = true; // Parametr zobrazím v detailu tabulky.
          service.paramsRowVisible = true; // Pokud zobrazím alespoň jeden parametr, zobrazím celou sekci
        }
      }
      // Parametry k zobrazení u služby jsou řazené dle abecedy
      service.params = service.params.sort((a, b) => a.title.localeCompare(b.title));
    }
    this.unitsService.getall({saIds: ids.join(',')})
      .subscribe(d => {
        data.data.map(rec => {
          for (const unit of d.data) {
            if (rec.saId === unit.saId) {
              if (!rec.units) {
                rec.units = [];
              }
              rec.units.push(unit);
            }
          }
        });
        this.onDataCollected.emit();
        return data;
      });

    this.nodesService.getall({saIds: ids.join(',')})
      .subscribe(d => {
        data.data.map(rec => {
          for (const node of d.data) {
            if (node.saIds && Array.isArray(node.saIds) && (node.saIds.indexOf(+rec.saId) >= 0)) {
              if (!rec.nodes) {
                rec.nodes = [];
              }
              rec.nodes.push(node);
            }
          }
        });
        this.onDataCollected.emit();
        return data;
      });

    return super.dataPostprocess(data);
  }

}
