import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {SessionService} from './session.service';



@Injectable({
  providedIn: 'root'
})
/**
 * Servisa slouží ke konfiguraci formuláře pro registrace. Je velmi úzce uzpůsobená na získání
 * jen určitých vyjmenovaných práv (omezeno v BE). Proto i implementace je celkem proprietární.
 * Nechci zobecňovat právě proto, aby to nevedlo k tomu,ž e si budeme nezabezpečeně používat
 * nějaké větší množství práv.
 */
export class InstallationConfigService extends DataBase<any> {
  // getallUrl = 'installation-config';

  protected getAllUrl(): string {
    return 'installation-config' as const;
  }

  /**
   * Vrací objekt s konfigurací registračního formuláře.
   */
  getRegistrationFormConfig(): Observable<any> {
    return this.getSingleton({ct: environment.ct}, 3600).pipe(map(data => {
   //   console.log('XXXXXXXXXXXXXXXXXXXXXX data: ',data);
        const sorted = data.options; // data mám seřazená z databáze

        // Z jednotlivých práv si udělám objekty
        const filtered = {};
        let last = null;
        for (const item of sorted) {
          // pro každý klíč si vezmu jen první hodnotu (jsou seřazené, nejvyšší je první)
          if (item.k.startsWith(SessionService.RIGHT_BASE + '.registration') && item.k !== last) {
            last = item.k;
            const parts = item.k.split('.');
            let obj;
            if (parts.length === 3) {
              filtered[parts[2]] = item.v;
            } else {
              if (!filtered.hasOwnProperty(parts[2])) {
                filtered[parts[2]] = {};
              }
              obj = filtered[parts[2]];
            }
            for (let j = 3; j < parts.length; j++) {
              if (j === parts.length - 1) {
                obj[parts[j]] = item.v;
              } else {
                if (!obj.hasOwnProperty(parts[j])) {
                  obj[parts[j]] = {};
                }
              }
              obj = obj[parts[j]];
            }
          }
        }
 //        console.log('XXXXXXXXXXXXXXXXXXXXXX filtered: ',filtered);
        return filtered;
      }
    ));
  }

  displayRegistration(): Observable<boolean> {
    return this.getSingleton({ct: environment.ct}, 3600).pipe(map(data => {
      for (const item of data.options) {
        if (item.k === SessionService.RIGHT_BASE + '.registration') {
          return item.v === '1';
        }
      }
      return false;
    }));
  }

  registrationOptions(): Observable<any> {
    return this.getSingleton({ct: environment.ct}, 3600).pipe(map(data => {
      const rights = {};
      for (const item of data.options) {
        rights[item.k] = item.v;
      }
      return rights;
    }));
  }

  getLocDef(): Observable<any> {
    return this.getSingleton({ct: environment.ct}, 3600).pipe(map(data => {
      return data.locDef;
    }));
  }
}
