import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';
import {IntegrationTestResult} from './csob-echo.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Servisa pro testování platby kartou
 */
export class CsobReverseService extends DataBase<IntegrationTestResult> {
  // getallUrl = 'payment-3dsecure-reverse';

  protected getAllUrl(): string {
    return 'payment-3dsecure-reverse' as const;
  }
}
