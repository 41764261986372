import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UtilPrintService } from '../_services/util-print.service';

@Component({
  selector: 'app-sc-get-document',
  template: `
<sc-main-toolbar>
<!--  <h1 i18n="@@menu.get-document">Stažení dokumentu</h1>-->
</sc-main-toolbar>

<div class="mango-form-page-contents">
  <h1 i18n="@@menu.get-document">Stažení dokumentu</h1>
  <ng-container *ngIf="cdIdLooksValid">
    <p>Dokument by se měl za okamžik začít stahovat.</p>
    <p>Pokud snad náhodou ne (stává se to maximálně jednou za 834 let),
    stáhnete ho <a target="_blank" href="{{downloadUrl}}">zde</a>.</p>
  </ng-container>

  <ng-container *ngIf="!cdIdLooksValid">
    <p>Promiňte, ale tohle nevypadá moc jako validní odkaz na dokument</p>
  </ng-container>

</div>

  `,
  styles: [``]
})
export class ScGetDocumentComponent implements OnInit {

  private cdId: any;

  constructor( private route: ActivatedRoute
             , private printUtil: UtilPrintService
             ) { }
    

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.cdId = params["id"] ? params["id"] : null;
      window.setTimeout(() => {
        if (this.downloadUrl) {
          window.open(this.downloadUrl, "_blank");
        }
      });
    });
  }

  public get cdIdLooksValid() {
    if (parseInt(this.cdId) > 0) {
      return true;
    }
    return typeof(this.cdId) === "string" && this.cdId.length === 32;
  }

  public get downloadUrl() {
    return this.printUtil.docLinkUrl("cd", this.cdId);
  }
}
