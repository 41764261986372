import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DataParamsServiceActive} from '../_services/data-params-service-active.service';
import {DataUserServices} from '../_services/data-user-services';
import {SessionService} from '../_services/session.service';
import {ParamControlService} from '../_libraries/param-form/_services/param-control.service';
import {ParamFormComponent} from '../_libraries/param-form/param-form/param-form.component';
import {DependencyServiceService} from '../_services/dependency-service.service';
import {FormHelperService} from '../_services/form-helper.service';
import {DialogConfig, DialogRef} from '@og_soft/dialog';
import {MangoFormControl} from '../_models/mango-form-control';

@Component({
  selector: 'app-user-service-edit',
  templateUrl: './user-service-edit.component.html',
  styleUrls: ['./user-service-edit.component.scss'],
  providers: [ ParamControlService ]
})
export class UserServiceEditComponent implements OnInit {

  private _saId: number = null;
  get saId(): number {
    return this._saId;
  }
  set saId(id: number) {
    this._saId = id;
  }

  private _caption: string = null;
  get caption(): string {
    return this._caption;
  }
  set caption(s: string) {
    this._caption = s;
  }

  @ViewChild(ParamFormComponent, {static: false}) paramForm: ParamFormComponent;

  public form: FormGroup;

  constructor(
    dconfig: DialogConfig,
    public saDataService: DataUserServices,
    public saParamsService: DataParamsServiceActive,
    private session: SessionService,
    private formHelper: FormHelperService,
    public dependencyServiceService: DependencyServiceService,
    private dlgRef: DialogRef,
  ) {
    this.saId = dconfig.data.saId;
    this.caption = dconfig.data.saData.saName +
    (DataUserServices.saIdentGet(dconfig.data.saData, false) ? ' (' + DataUserServices.saIdentGet(dconfig.data.saData) + ')' : '');

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      // Sem si dám svoje, pokud je budu potřebovat, např. jméno služby.
      // dateFrom: new MangoFormControl(''),
      // dateTo: new MangoFormControl(''),
      // phoneNum: new MangoFormControl('')
      saName: new MangoFormControl('')
    });
    this.session.processingSet(true);
  }

  public save(): void {
    const formData = this.form.value;
    formData.saId = this.saId;
    console.log('Ukládaná data ', formData);
    if (this.form.valid){
      this.saDataService.post(formData).subscribe(saData => {
        console.log('Data saved, read back.', saData);
        this.session.message( $localize`:@@UserServiceEdit.saDataService.message:OK. Všechno máme uloženo.` );
        this.saParamsService.clearCache();
        this.dlgRef.close(saData);
      }, err => {
        console.error('Tak to tak úplně nevyšlo.', err);
        this.dlgRef.close( );
      });
     // console.log('Ukládaná data ', formData);
    }else{

      this.session.message( $localize`:@@UserServiceEdit.form.validate.message:Některé položky nejsou vyplněné nebo nemají správnou hodnotu.` );
      this.formHelper.markDirty(this.form);
    }
  }

  onParamsReady(): void {
    this.session.processingSet(false);
  }

}
