
<dialog-title>
  <h3 i18n="@@ProtectedData.recallInactiveDialog.heading">Odvolat souhlas</h3>
</dialog-title>

<dialog-content>
  <ng-container>
    <p i18n="@@ProtectedData.recallInactiveDialog.text1">Opravdu chcete odvolat souhlas s nakládáním s osobními údaji?</p>
    <p class="mango-text-secondary" i18n="@@ProtectedData.recallInactiveDialog.text2">Odvoláním souhlasu dojde k vašemu zneaktivnění a odhlášení ze systému. Poté se již nebude možné znovu přihlásit.</p>
  </ng-container>
</dialog-content>

<dialog-actions>
  <button mat-flat-button dialog-close i18n="Zavřít|Tlačítko na zavření dialogu v dialogu při odvolání souhlasu@@ProtectedData.recallInactiveDialog.button.Close">Zavřít</button>
  <button mat-flat-button color="primary" (click)="save()" i18n="Odeslat|Tlačítko na uložení dialogu v dialogu při odvolání souhlasu@@ProtectedData.recallInactiveDialog.Send">Pokračovat</button>
</dialog-actions>






