import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';

@Injectable({
  providedIn: 'root'
})
export class CheckSessionService extends DataBase<number> {
  // getallUrl = 'check-session';

  protected getAllUrl(): string {
    return 'check-session' as const;
  }
}
