import {Component, Inject, Injector, LOCALE_ID, OnInit} from '@angular/core';
import {UserEditFormService, UserEditFormType} from '../user-edit/user-edit-form.service';
import {SessionService} from '../_services/session.service';
import {DialogRef} from '@og_soft/dialog';
import {DataUserEdit} from '../_services/user-edit.service';
import {UserRegistrationService} from '../_services/user-registration.service';
import {Router} from '@angular/router';
import {UserEditProtectedService} from '../user-edit/user-edit-protected.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {InstallationConfigService} from '../_services/installation-config.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
  public USER_EDIT_FORM_TYPE = UserEditFormType;
  private recaptchaV3Service: ReCaptchaV3Service;
  private useReCaptcha = false;

  constructor(
      public session: SessionService,
      // private userEditService: DataUserEdit,
      private userEditFormService: UserEditFormService,
      private registrationService: UserRegistrationService,
      private userEditProtectedService: UserEditProtectedService,
      private router: Router,
      @Inject(LOCALE_ID) public locale: string,
      private injector: Injector,
      private installationConfigService: InstallationConfigService,
  ) {
    this.installationConfigService.registrationOptions().subscribe(options => {
      if (options['RECAPTCHA.ALLOWED'] === '1'){
        this.useReCaptcha = true;
        this.recaptchaV3Service = (this.injector.get(ReCaptchaV3Service) as ReCaptchaV3Service);
      }else{
        this.recaptchaV3Service = null;
      }
    });
  }

  public register(): void {
    this.session.processingSet(true,
      $localize`:@@Registration.processing.save.message:Ukládám...`
    );
    if (this.useReCaptcha) {
      this.recaptchaV3Service.execute('submit').subscribe((token) => {
        this.doRegistration(token);
      }, () => {
        this.session.processingSet(false);
      });
    }else{
      this.doRegistration(null);
    }
  }

  public doRegistration(token: string): void {
    this.session.processingSet(true,
      $localize`:@@Registration.processing.save.message:Ukládám...`
    );
    this.userEditFormService.getAllFormsData().subscribe(data => {
      if (data) {
        // Přidám kategorie, kde jsem udělil souhlas.
        data.pdcIds = this.userEditProtectedService.getSavedConsent();
        data.lang = this.locale.substring(0, 2);
        if (token) {
          data.reCaptchaToken = token;
        }
        console.log('Data k uložení: ', data);
        this.registrationService.post(data).subscribe(userData => {
          this.session.processingSet(false);
          // Hlášku v bublině tu teď nepotřebujeme, následuje redirect,
          // kde je velká hláška vprostřed stránky. Tohle je vůči ní duplicitní
          // a navíc trochu zavádějící.
          //this.session.message($localize`:@@UserProfile.editUser.message:Změny v profilu byly úspěšně uloženy.`);
          this.router.navigate(['/verify-registration']);
        }, err => {
          this.session.processingSet(false);
          console.error('Jejda, chyba při ukládání:', err);
          return false;
        });
      }else{
        this.session.processingSet(false);
      }
    }, error => {
      this.session.processingSet(false);
    });
  }

}
