import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SessionService} from '../../_services/session.service';
import {CsobEchoService} from '../csob-echo.service';
import {PayOrderService} from '../../_services/pay-order.service';
import {Payment3dsecureRequestService} from '../../_services/payment-3dsecure-request.service';
import {Payment3dsecureResponseService} from '../../_services/payment-3dsecure-response.service';
import {CsobReverseService} from '../csob-reverse.service';

interface OneResult {
  done: boolean;
  result?: boolean;
  message?: string;
}

interface TestResults {
  echoGet: OneResult;
  echoPost: OneResult;
  successfulPayment: OneResult;
  canceledPayment: OneResult;
  expiredPayment: OneResult;
  reversedPayment: OneResult;
}

enum TestType{
  echoGet = 'echoGet',
  echoPost = 'echoPost',
  successfulPayment = 'successfulPayment',
  canceledPayment = 'canceledPayment',
  expiredPayment = 'expiredPayment',
  reversedPayment = 'reversedPayment'
}

@Component({
  selector: 'app-csob-integration-test',
  templateUrl: './csob-integration-test.component.html',
  styleUrls: ['./csob-integration-test.component.scss']
})
export class CsobIntegrationTestComponent implements OnInit {
  results: TestResults;
  actualTest: TestType;

  constructor(
    public session: SessionService,
    private route: ActivatedRoute,
    private csobEchoService: CsobEchoService,
    private csobReverseService: CsobReverseService,
    private payOrder: PayOrderService,
    private payment3dsecureRequest: Payment3dsecureRequestService,
    private payment3dsecureResponse: Payment3dsecureResponseService,
  ) {
    this.route.url.subscribe(url => {
      if ((url.length > 1) && (url[1].path === 'cardresp')) {
        console.log('Přesměrováno, nahrávám uložené výsledky testů');
        this.results = JSON.parse(window.localStorage.getItem('TEST_RESULT_' + this.session.ct)) as TestResults;
        this.session.processingSet(true);
        this.actualTest = this.getActualTest();
        this.route.queryParams.subscribe(qp => {
          const params = {...qp};
          params.signature = encodeURIComponent(qp.signature);
          params.testType = this.actualTest;
          if (this.actualTest === 'reversedPayment'){
            this.doReverseTest(params);
          }else {
            this.payment3dsecureResponse.getSingleton(params).subscribe(result => {
              this.results[this.actualTest].result = (result.result === 'OK');
              this.results[this.actualTest].message = result.message;
              this.results[this.actualTest].done = true;
              this.saveTestData();
              this.session.processingSet(false);
            }, error => {
              this.results[this.actualTest].result = false;
              this.results[this.actualTest].message = 'Chyba při ověřování platby';
              this.results[this.actualTest].done = true;
              this.saveTestData();
              this.session.processingSet(false);
            });
          }
        }, error => {
          this.results[this.actualTest].result = false;
          this.results[this.actualTest].message = 'Platební brána vrátila něco špatně';
          this.results[this.actualTest].done = true;
          this.saveTestData();
          this.session.processingSet(false);
        });
      } else {
        console.log('Připravuji nové testy');
        window.localStorage.removeItem('TEST_RESULT_' + this.session.ct);
        this.results = {
          echoGet: {done: false},
          echoPost: {done: false},
          successfulPayment: {done: false},
          canceledPayment: {done: false},
          expiredPayment: {done: false},
          reversedPayment: {done: false},
        };
        this.actualTest = this.getActualTest();
        this.saveTestData();
      }
    });
  }

  getActualTest(): TestType{
    if (!(this.results.echoGet.done && this.results.echoGet.result)){
      return TestType.echoGet;
    }else if (!(this.results.echoPost.done && this.results.echoPost.result)){
      return TestType.echoPost;
    }else if (!(this.results.successfulPayment.done && this.results.successfulPayment.result)){
      return TestType.successfulPayment;
    }else if (!(this.results.canceledPayment.done && this.results.canceledPayment.result)){
      return TestType.canceledPayment;
    }else if (!(this.results.expiredPayment.done && this.results.expiredPayment.result)){
      return TestType.expiredPayment;
    }else if (!(this.results.reversedPayment.done && this.results.reversedPayment.result)){
      return TestType.reversedPayment;
    }else{
      return null;
    }
  }

  ngOnInit(): void {
  }

  private saveTestData(): void{
    window.localStorage.setItem('TEST_RESULT_' + this.session.ct, JSON.stringify(this.results));
    this.actualTest = this.getActualTest();
  }

  public doEchoGet(): void {
    this.session.processingSet(true);
    this.csobEchoService.getSingleton().subscribe(result => {
      this.results.echoGet.result = (result.result === 'OK');
      this.results.echoGet.message = result.message;
      this.results.echoGet.done = true;
      this.saveTestData();
      this.session.processingSet(false);
    });
  }

  public doEchoPost(): void {
    this.session.processingSet(true);
    this.csobEchoService.post({}).subscribe(result => {
      this.results.echoPost.result = (result.result === 'OK');
      this.results.echoPost.message = result.message;
      this.results.echoPost.done = true;
      this.saveTestData();
      this.session.processingSet(false);
    });
  }

  public doPayment(): void {
    this.session.processingSet(true);
    const actualTest = this.getActualTest();
    this.payOrder.post({
      amount: 10,
      varSym: 0,
    }).subscribe(po => {
      if (parseInt(po.orderNumber, 10) > 0) {
        //  Na základě payOrder se pak může vytvořit request na platební bránu a provést přesměrování
        this.payment3dsecureRequest.request(10, po, this.returnUrl).subscribe(url => {
          window.location.href = url.request;
        }, error => {
          this.results[actualTest].result = false;
          this.results[actualTest].message = 'Nepodařilo se iniciovat platbu na platební bráně.';
          this.results[actualTest].done = true;
          this.saveTestData();
          this.session.processingSet(false);
        });
      }else{
        this.results[actualTest].result = false;
        this.results[actualTest].message = 'Nepodařilo se založit platbu.';
        this.results[actualTest].done = true;
        this.saveTestData();
        this.session.processingSet(false);
      }
    });
  }

  private doReverseTest(params: any): void {
    // Nejdřív ověřím platbu
    params.actualTest = 'successfulPayment'; // Ověřuji, že platba proběhla správně
    const orderNum = parseInt(atob(params.merchantData), 10);
    this.payment3dsecureResponse.getSingleton(params).subscribe(result => {
      this.csobReverseService.post({orderNum}).subscribe(reverseResult => {
        this.results.reversedPayment.result = (reverseResult.result === 'OK');
        this.results.reversedPayment.message = reverseResult.message;
        this.results.reversedPayment.done = true;
        this.session.processingSet(false);
        this.saveTestData();
      }, error => {
        this.results[this.actualTest].result = false;
        this.results[this.actualTest].message = 'Chyba při ověřování reversování platby';
        this.results[this.actualTest].done = true;
        this.saveTestData();
        this.session.processingSet(false);
      });
      this.saveTestData();
      this.session.processingSet(false);
    }, error => {
      this.results[this.actualTest].result = false;
      this.results[this.actualTest].message = 'Chyba při ověřování platby';
      this.results[this.actualTest].done = true;
      this.saveTestData();
      this.session.processingSet(false);
    });
  }

  private get returnUrl(): string {
    if (window.location.pathname.endsWith('/cardresp')){
      return window.location.origin + window.location.pathname;
    }else{
      return window.location.origin + window.location.pathname + '/cardresp';
    }
  }
}
