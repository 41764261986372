import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {SessionService} from '../_services/session.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
    private readonly insecureEndpoints = [
      'eu-session',
      'bank-codes',
      'change-password',
      'check-code-validity',
      'check-unique-email',
      'db-ready',
      'installation-config',
      'protected-data-categories-public',
      'protected-data-items-categories-registration',
      'registration',
      'reset-password',
      'search-address',
      'check-session',
    ];

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const sessionToken = JSON.parse(localStorage.getItem(SessionService.SESSION_TOKEN));
        if (sessionToken) {
            request = request.clone({
                setHeaders: {
                    'X-Session-Token': `${sessionToken}`,
                }
            });
        }else {
          const endpoint = request.url.split('/');
          if (!
            ( this.insecureEndpoints.includes(endpoint[endpoint.length - 1])
              || this.insecureEndpoints.includes(endpoint[endpoint.length - 2])) // některé endpointy mají na konci ID
            ) {
            // Requesty, které posílám na nezabezpečený endpoint mám vyjmenované
            // v seznamu povolených nezabezpečených endpointů. Pokud nemám session token a posílám request na
            // endpoint, který není v povolených nezabezpečených tokenech, tak request nepošlu a vypíšu warning.
            console.warn('Sending request without session token to secured endpoint. Canceled.', request);
            return of(null);
          }
        }

        return next.handle(request);
    }
}
