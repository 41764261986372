<div class="external-page-layout">
<div
    class="password-container"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="20px"
    fxLayoutAlign="start stretch">

  <mat-card class="login-form-card" fxFlex>
    <mat-card-header [ngSwitch]="action">
      <mat-card-title *ngSwitchCase="'change'" i18n="@@Password.change.text">Změna hesla</mat-card-title>
      <mat-card-title *ngSwitchCase="'check'" i18n="@@Password.check.text">Obnovení hesla</mat-card-title>
      <mat-card-title *ngSwitchCase="'reset'" i18n="@@Password.reset.text">Obnovení hesla</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <form *ngIf="action === 'reset' || action === 'check'" [formGroup]="formCheck">

        <div i18n="@@Password.reset.check.caption">Pokud již máte obnovovací kód, zadejte ho a pokračujte změnou hesla.</div>

<ng-container *ngIf="formHelper.isVisible(formCheck.get('code'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@Password.code.label">Kód</mat-label>
                        <input matInput formControlName="code" id="code" name="code" i18n-title="@@Password.code.title" title="">
                        <mat-error *ngIf="formCheck.get('code').invalid && (formCheck.get('code').dirty || formCheck.get('code').touched)">
                          {{formHelper.getErrorMessage(formCheck.get('code'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>

                  

      </form>

      <button mat-stroked-button color="primary" *ngIf="action === 'check' || action === 'reset'" (click)="checkCode(null)" i18n="@@Password.reset.check.button">Změnit heslo</button>

      <form *ngIf="action === 'reset'" [formGroup]="formLogin">
        <div style="margin-top: 30px;">&nbsp;</div>

        <div i18n="@@Password.reset.caption">Kód pro obnovu hesla Vám zašleme na e-mail, pod nímž Vás známe. Zadejte ho prosím zde.</div>

<ng-container *ngIf="formHelper.isVisible(formLogin.get('email'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@Password.email.label">Email</mat-label>
                        <input matInput formControlName="email" id="email" name="email" i18n-title="@@Password.email.title" title="">
                        <mat-error *ngIf="formLogin.get('email').invalid && (formLogin.get('email').dirty || formLogin.get('email').touched)">
                          {{formHelper.getErrorMessage(formLogin.get('email'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>

                  

<ng-container *ngIf="formHelper.isVisible(formLogin.get('login'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@Password.login.label">Přihlašovací jméno</mat-label>
                        <input matInput formControlName="login" id="login" name="login" i18n-title="@@Password.login.title" title="">
                        <mat-error *ngIf="formLogin.get('login').invalid && (formLogin.get('login').dirty || formLogin.get('login').touched)">
                          {{formHelper.getErrorMessage(formLogin.get('login'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>

                  

      </form>
        <button mat-stroked-button color="primary" *ngIf="action === 'reset'" (click)="resetPassword()" i18n="@@Password.reset.button">Vyžádat kód</button>

        <ng-container *ngIf="action === 'change'">
          <div i18n="@@Password.change.caption">Zde zadejte své nové heslo.</div>
          <app-password-change [parentForm]="formPassword"></app-password-change>
        </ng-container>

    <button mat-stroked-button color="primary" *ngIf="action === 'change'" (click)="changePassword()" i18n="@@Password.change.button">Obnovit heslo</button>

    <div style="margin-top: 30px;">&nbsp;</div>

      <div><ng-container i18n="@@Password.back.text1">Nebo se můžete vrátit</ng-container>&nbsp;<a routerLink="/login" i18n="@@Password.back.text2">zpět</a>&nbsp;<ng-container i18n="@@Password.back.text3">na přihlašovací stránku.</ng-container></div>
    </mat-card-content>
  </mat-card>

</div>
</div><!-- external_page_layout -->
