import {Injectable} from '@angular/core';
import {DataBase} from "@og_soft/data-base";

export interface RecPhonetCreditLoadData {
    userServices: string;
    amount: number;
}

@Injectable({
    providedIn: 'root'
})

export class PhonetCreditLoadService extends DataBase<RecPhonetCreditLoadData> {
   // getallUrl = "phonet-credit-load";

  protected getAllUrl(): string {
    return 'phonet-credit-load' as const;
  }
}




