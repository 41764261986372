<sc-main-toolbar>
</sc-main-toolbar>


<h1 *ngIf="newContact" i18n="@@Contacts.title.new">Přidání kontaktní osoby</h1>
<h1 *ngIf="editContact" i18n="@@Contacts.title.edit">Úprava kontaktu</h1>
<h1 *ngIf="deleteContact" i18n="@@Contacts.title.delete">Úprava kontaktu</h1>
<h1 *ngIf="!newContact && !editContact && !deleteContact" i18n="@@Contacts.title.all">Mé kontakty</h1>

<!-- Akce na stránce s tabulkou -->
<div actions *ngIf="!(newContact || editContact || deleteContact)">
  <button class="mgt-act-menu" mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="goAddContact()">
      <mat-icon>add</mat-icon>
      <span i18n="@@Contacts.button.add">Přidat kontakt</span>
    </button>
  </mat-menu>
</div>

<!-- Akce na stránce pro vytvoření/editaci kontaktu -->
<div actions *ngIf="newContact || editContact">
  <button *ngIf="newContact" mat-raised-button color="accent" (click)="confirmCreateContact()">Vytvořit</button>
  <button *ngIf="!newContact && editContact" mat-raised-button color="accent" (click)="confirmEditContact()">Uložit</button>
  <button mat-raised-button (click)="goCancelAnything()" i18n="@@Contacts.actions.button.cancel">Zrušit</button>
</div>

<div class="mango-form-page-contents" *ngIf="newContact || editContact">

    <form [formGroup]="form">

    <mat-card>
      <mat-card-title i18n="@@Contacts.card.contact.title">Kontaktní osoba</mat-card-title>
      <mat-card-content>

<ng-container *ngIf="formHelper.isVisible(form.get('firstName'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@Contacts.firstName.label">Jméno</mat-label>
                        <input matInput formControlName="firstName" id="firstName" name="firstName" i18n-title="@@Contacts.firstName.title" title="Křestní jméno">
                        <mat-error *ngIf="form.get('firstName').invalid && (form.get('firstName').dirty || form.get('firstName').touched)">
                          {{formHelper.getErrorMessage(form.get('firstName'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>



<ng-container *ngIf="formHelper.isVisible(form.get('lastName'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@Contacts.lastName.label">Příjmení</mat-label>
                        <input matInput formControlName="lastName" id="lastName" name="lastName" i18n-title="@@Contacts.lastName.title" title="Příjmení">
                        <mat-error *ngIf="form.get('lastName').invalid && (form.get('lastName').dirty || form.get('lastName').touched)">
                          {{formHelper.getErrorMessage(form.get('lastName'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>



      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title i18n="@@Contacts.card.contact.contacts.title">Kontaktní údaje</mat-card-title>
      <mat-card-content>

<ng-container *ngIf="formHelper.isVisible(form.get('mail'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@Contacts.mail.label">E-mail</mat-label>
                        <input matInput formControlName="mail" id="mail" name="mail" i18n-title="@@Contacts.mail.title" title="E-mail">
                        <mat-error *ngIf="form.get('mail').invalid && (form.get('mail').dirty || form.get('mail').touched)">
                          {{formHelper.getErrorMessage(form.get('mail'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>
      <fieldset [formGroup]="form.get('phone')">
<ng-container *ngIf="formHelper.isVisible(form.get('phone.gsm'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@Contacts.gsm.label">Telefon</mat-label>
                        <input matInput formControlName="gsm" id="gsm" name="gsm" i18n-title="@@Contacts.gsm.title" title="Telefon">
                        <mat-error *ngIf="form.get('phone.gsm').invalid && (form.get('phone.gsm').dirty || form.get('phone.gsm').touched)">
                          {{formHelper.getErrorMessage(form.get('phone.gsm'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>


      </fieldset>

      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title i18n="@@Contacts.card.contact.credentials.title">Přihlašovací údaje</mat-card-title>
      <mat-card-content>
      <fieldset [formGroup]="form.get('cred')">

<ng-container *ngIf="formHelper.isVisible(form.get('cred.login'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@Contacts.login.label">Přihlašovací jméno</mat-label>
                        <input matInput formControlName="login" id="login" name="login" i18n-title="@@Contacts.login.title" title="Přihlašovací jméno">
                        <mat-error *ngIf="form.get('cred.login').invalid && (form.get('cred.login').dirty || form.get('cred.login').touched)">
                          {{formHelper.getErrorMessage(form.get('cred.login'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>



<ng-container *ngIf="formHelper.isVisible(form.get('cred.password'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@Contacts.password.label">Heslo</mat-label>
                        <input matInput formControlName="password" id="password" name="password" i18n-title="@@Contacts.password.title" title="Heslo">
                        <mat-error *ngIf="form.get('cred.password').invalid && (form.get('cred.password').dirty || form.get('cred.password').touched)">
                          {{formHelper.getErrorMessage(form.get('cred.password'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>



<ng-container *ngIf="formHelper.isVisible(form.get('cred.passwordc'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@Contacts.passwordc.label">Heslo ještě jednou</mat-label>
                        <input matInput formControlName="passwordc" id="passwordc" name="passwordc" i18n-title="@@Contacts.passwordc.title" title="Heslo ještě jednou pro kontrolu">
                        <mat-error *ngIf="form.get('cred.passwordc').invalid && (form.get('cred.passwordc').dirty || form.get('cred.passwordc').touched)">
                          {{formHelper.getErrorMessage(form.get('cred.passwordc'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>



      </fieldset>

      </mat-card-content>
    </mat-card>

    </form>
</div>


<div class="mango-form-page-contents" *ngIf="deleteContact">
    <p i18n="@@Contacts.remove.title">Skutečně si přejete odstranit kontakt „{{deleteContact.firstName}} {{deleteContact.lastName}}“? </p>
    <button mat-raised-button color="accent" (click)="confirmDeleteContact()" i18n="@@Contacts.remove.button.ok">Odstranit</button>
    <button mat-raised-button (click)="goCancelAnything()" i18n="@@Contacts.remove.button.cancel">Zrušit</button>
</div>

<div class="mango-table-page-contents" *ngIf="!(newContact || editContact || deleteContact)">

	<mgt-table #tbl [service]="dataService">
	   <mgt-row
			[replacing-detail]="false"
			*ngFor="let r of tbl.scrollItems"
      [record]="r">

      <mgt-cell-group master>
        <mgt-cell i18n-title="@@Contacts.list.cell.title.name"
                  title="Jméno" role="title">{{r.firstName}} {{r.lastName}}</mgt-cell>
        <mgt-cell mobile-only>{{r.login}}</mgt-cell>
        <mgt-cell mobile-only>{{r.mail}}</mgt-cell>
      </mgt-cell-group>

      <mgt-cell-group desktop-only>
        <mgt-cell i18n-title="@@Contacts.list.cell.title.login" title="Login">{{r.login}}</mgt-cell>
      </mgt-cell-group>

      <mgt-cell-group desktop-only>
        <mgt-cell i18n-title="@@Contacts.list.cell.title.email" title="E-mail">{{r.mail}}</mgt-cell>
      </mgt-cell-group>

      <mgt-cell-group>
        <mgt-cell i18n-title="@@Contacts.list.cell.title.phone" title="Telefon">
          {{joinNonempty([r.phone.gsm, r.phone.landline, r.phone.landline2, r.phone.fax ? ("fax " + r.phone.fax) : null])}}
        </mgt-cell>
      </mgt-cell-group>

      <mgt-actions>
        <button mat-button (click)="goEditContact(r)"><mat-icon>edit</mat-icon> <span i18n="@@Contacts.list.action.button.edit">Upravit</span></button>
        <button mat-button (click)="goDeleteContact(r)"><mat-icon>delete</mat-icon> <span i18n="@@Contacts.list.action.button.delete">Odstranit</span></button>
      </mgt-actions>

      <div detail>
      <!--
        <pre>{{r|json}}</pre>
      -->
      </div>

     </mgt-row>

     <div no-data-alternate-content>
       <page-empty i18n-message="@@Contacts.PageEmpty.message" message="Není co zobrazit."></page-empty>
     </div>
  </mgt-table>
</div>

