import {Injectable} from '@angular/core';
import {DataParams} from '../param-form/_services/data-params.service';
import {ParamBase, ParamObjectType} from '../param-form/_models/param-base';
import {DataValueDomainPatterns} from '../param-form/_services/data-value-domain-pattern';
import {DataCodeList} from '../param-form/_services/data-code-list';
import {DataValueDomain} from '../param-form/_services/data-value-domain';
import {DataProcessPremises} from '../../_services/process-premises.service';
import {DataProcessServices} from '../../_services/process-services.service';
import {DataProcessUnits} from '../../_services/process-units.service';

/**
 */
@Injectable({
  providedIn: 'root'
})
export class DataParamsUser extends DataParams {
  // getallUrl = 'user-params';

  protected getAllUrl(): string {
    return 'user-params' as const;
  }

  public transformParams(paramData: any, services: {
    dataValuePatterns: DataValueDomainPatterns;
    dataCodeList: DataCodeList;
    dataValueDomain: DataValueDomain;
    dataProcessPremises: DataProcessPremises;
    dataProcessServices: DataProcessServices;
    dataProcessUnits: DataProcessUnits;
  }): ParamBase {
    const options = {
      id: paramData.id,
      defId: paramData.defId,
      objectType: ParamObjectType.User,
      value: paramData.value,
      defaultValue: paramData.defaultValue,
      name: paramData.name + (paramData.indexed ? '_' + paramData.index : ''),
      indexName: paramData.name,
      caption: paramData.caption,
      required: paramData.required,
      order: paramData.order,
      type: paramData.type,
      disabled: paramData.disabled,
      indexed: paramData.indexed,
      index: paramData.index,
      domain: paramData.domain,
      hrValue: paramData.hrValue,
      visibilityDependency: paramData.visibilityDependency,
      editDependency: paramData.editDependency,
      requireDependency: paramData.requireDependency
    };
    return new ParamBase(options, services);
  }
}
