import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'mgt-sec-head',
  template: `
    <div class="forget-table-sec-header">
      <span>
        <ng-content></ng-content>
      </span>    
    </div>
  `,
  styleUrls: ['./forget-table-sec-header.component.scss']
})
export class ForgetTableSecHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
