import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {ScNavComponent} from '../sc-nav/sc-nav.component';

@Component({
  selector: 'app-editor-block-page',
  templateUrl: './editor-block-page.component.html',
  styleUrls: ['./editor-block-page.component.scss']
})
export class EditorBlockPageComponent implements OnInit, OnDestroy {
  private subscibtionParams: Subscription = null;

  private _ttId: number = null;
  get ttId(): number {
    return this._ttId;
  }

  get editorBlockTitle(): string {
    return this.scnav.editorBlockTitle(this.ttId);
  }

  constructor(
    private route: ActivatedRoute,
    private scnav: ScNavComponent,
  ) { }

  ngOnInit() {
    this.subscibtionParams = this.route.params.subscribe(params => {
      this._ttId  = params['id'];
    });
  }

  ngOnDestroy(): void {
    this.subscibtionParams.unsubscribe();
  }

}
