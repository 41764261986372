import {Injectable} from '@angular/core';
import {DataBase, DataBaseServiceConfig, GetallResult} from "@og_soft/data-base";
import {PopupOption} from "@og_soft/popup-list-control";
import {Observable} from "rxjs";
import {Process} from "./data-process";

export interface ProcessNote {
  userName?: string;
  id: number;
  processId: number;
  note: string;
  mangoUserId?: number;
  time: string;
  user: boolean; // Pokud je true, tak je poznámka od uživatele (určuju to podle toho jestli je tvůrce poznámky operátor s L_SELFCARE.
}

@Injectable({
  providedIn: 'root'
})
export class DataProcessNotes extends DataBase<ProcessNote> {
  // getallUrl = "process-history";
  protected getAllUrl(): string {
    return 'process-history' as const;
  }

  configureUrl(config: DataBaseServiceConfig) {
    this.baseurl = config.mangoUrl;
  }

  tranformOptions(row: any): PopupOption {
    return {id: row.id.toString(), name: row.note}
  }

  fetchProcessNotes(processId: number): Observable<GetallResult<ProcessNote>> {
    return this.getall({id: processId, 'types[]': [13]});
  }

  sendNote(processId: number, noteText: string): Observable<ProcessNote>{

    const now = new Date();

    const note: ProcessNote = {
      id: 0,
      processId: processId,
      note: noteText,
      time: now.toISOString(),
      user: true,
    };

    return this.post(note)

  }
}
