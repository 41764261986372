<sc-main-toolbar>
<!--  <h1 i18n="@@menu.home">Nástěnka</h1>-->

<!--  <div actions>-->
<!--  </div>-->
</sc-main-toolbar>

<!-- overflow style is here as a fix for unwanted short "doublescroll" -->
<div class="mango-form-page-contents" style="overflow: hidden">
  <!-- Nadpis by možná na úvodní stránce být nemusel - odvádí pozornost
    od boxíků, kde je pozornosti potřeba -->
  <!-- <h1 i18n="@@menu.home">Hlavní stránka</h1> -->

  <app-dashboard>

    <!-- Zprávy z tabulky "user_info" - z modulu "Zprávy v selfcare" v Mangu -->
    <ng-container *ngIf="euMessages">
      <ng-container *ngFor="let m of euMessages">
        <app-dashboard-widget
          [priority]="m.priority"
          [title]="m.title"
          [subtitle]="m.subtitle"
          [htmlContent]="m.content">
        </app-dashboard-widget>
      </ng-container>
    </ng-container>

    <app-dashboard-widget
        priority="201"
        *ngIf="processTypes && processTypes.length > 0"
        title="Založit požadavek"
        i18n-title="@@Home.NewProcessWidget.title"
        subtitle="Co pro Vás můžeme udělat?"
        i18n-subtitle="@@Home.NewProcessWidget.subtitle">
      <div class="new-process-btn-container">
        <button
            mat-storked-button mat-stroked-button color="primary"
            *ngFor="let processType of processTypes"
            [routerLink]="['/process', 0, {type: processType.id}]">
          <mat-icon>add_circle_outline</mat-icon>
          {{processType.name}}
        </button>
      </div>
    </app-dashboard-widget>

    <app-dashboard-widget
        [priority]=priorityUc
        title="Zůstatek kreditu"
        i18n-title="@@Home.UniCreditWidget.title"
        *ngIf="ucVarSymbol !== null">
      <div [ngClass]="{'dashboard-value-big': true, 'mango-text-warn': unicreditBalance < 1}">
        {{unicreditBalance | currency:currencySymbol}}
      </div>
      <a mat-flat-button *ngIf="ucVarSymbol !== null && (this.session.getOption('SELFCARE.home.UniCreditWidget.creditCard.button')) "
         color="primary"
         class="btn-unicredit-payment"
         (click)="paymentUc(ucVarSymbol)"
         target="_blank">
        <span i18n="@@Home.UniCreditWidget.creditCard.button">Dobít kredit</span>
      </a>
      <div class="widget-bottom-links">
        <a mat-list-item *ngIf="session.canView('unicredit')" [routerLink]="['/unicredit']" i18n="@@Home.UniCreditWidget.menu.unicredit">Více o předplatném</a>
      </div>
    </app-dashboard-widget>

    <app-dashboard-widget
            [priority]=priorityOff
            title="Počet offline dní"
            i18n-title="@@Home.OffWidget.title"
            *ngIf="userOffLimit !== null && ucVarSymbol !== null">
      <div class="dashboard-value-big">
        {{userOffLimit}}
      </div>
    </app-dashboard-widget>

    <app-dashboard-widget
            [priority]=priorityPhonet
            title="Výše hovorného"
            i18n-title="@@Home.PhonetWidget.title"
            *ngIf="saPhonet > 0">
      <div class="mat-caption">
        <div *ngFor="let sa of phonetData;">
          <span>{{sa.saPhonetName}}: </span>
          <span *ngIf="sa.saPhonetAmount === undefined" i18n="@@Home.saPhonetAmount.fetching">Čekejte...</span>
          <span *ngIf="sa.saPhonetAmount === -999999" i18n="@@Home.saPhonetAmount.text">Nelze zjistit</span>
          <span *ngIf="sa.saPhonetAmount > -999999">{{sa.saPhonetAmount | currency:currencySymbol}}</span>
        </div>
      </div>
      <a mat-flat-button *ngIf="this.session.getOption('SELFCARE.home.PhonetWidget.button.creditCard')"
         color="primary"
         class="widget-button"
         (click)="phonetLoad()"
         target="_blank">
        <span i18n="@@Home.PhonetWidget.button.creditCard">Přenos kreditu do Phonetu</span>
      </a>
      <div class="widget-bottom-links">
        <a mat-list-item *ngIf="session.canView('unicredit')" [routerLink]="['/unicredit']" i18n="@@Home.PhonetWidget.menu.unicredit">Unicredit</a>
      </div>
    </app-dashboard-widget>


    <app-dashboard-widget
            [priority]=priorityDebt
            title="Vyúčtování"
            i18n-title = "@@Home.DebtWidget.title"
            *ngIf="debtAll !== null && ( debtWithoutUc === debtAll || debtWithoutUc != 0 )">
      <div *ngIf="debtAfterMaturity > 0" [ngClass]="{'dashboard-value-big' : true
                               , 'mango-text-warn':true
					           }">
        <span i18n="@@Home.DebtWidget.debtAfterMaturity.text">Dluh</span> {{debtAfterMaturity | currency:currencySymbol}}
      </div>
      <div *ngIf="debtToMaturity > 0" [ngClass]="{'dashboard-value-big' : true
                               , 'mango-text-info':true
					           }">
        <span i18n="@@Home.DebtWidget.debtToMaturity.text">Do splatnosti</span> {{debtToMaturity | currency:currencySymbol}}
      </div>
      <div *ngIf="debtAll < 0" [ngClass]="{'dashboard-value-big' : true
                               , 'mango-text-info':true
					           }">
        <span i18n="@@Home.DebtWidget.overpayment.text">Přeplatek</span> {{debtAll*-1 | currency:currencySymbol}}
      </div>
      <div *ngIf="debtAfterMaturity==0 && debtToMaturity==0 " [ngClass]="{'dashboard-value-big' : true
                               , 'mango-text-success':true
					           }">
        <span i18n="@@Home.DebtWidget.allRefunded.text">Vše uhrazeno. Děkujeme.</span>
      </div>

      <a mat-flat-button
         *ngIf="debtAll > 0 && (this.session.getOption('SELFCARE.home.DebtWidget.creditCard.button')) "
         color="primary"
         class="btn-unicredit-payment"
         (click)="paymentDebt(debtAll, userVs)"
         target="_blank">
        <span i18n="@@Home.DebtWidget.creditCard.button">Zaplatit</span>
      </a>

      <div class="widget-bottom-links">
        <a mat-list-item *ngIf="session.canView('billing')" [routerLink]="['/billing']" i18n="@@Home.DebtWidget.menu.billing">Více ve Vyúčtování</a>
      </div>
    </app-dashboard-widget>

    <app-dashboard-widget
            [priority]=priorityState
            *ngIf="widgetVisible('state') && saState !== null">
      <div [ngClass]="{'dashboard-value-big' : true
                               , 'mango-text-warn': saState == 'B'
					           , 'mango-text-info': saState == 'A'
					           , 'mango-text-success': saState == 'N'
					           }">
        <span *ngIf="saState == 'B'"><span i18n="@@Home.StateWidget.B.text">Máte zablokované služby</span></span>
        <span *ngIf="saState == 'A'"><span i18n="@@Home.StateWidget.A.text">Nemáte žádné aktivní služby</span></span>
        <span *ngIf="saState == 'N'"><span i18n="@@Home.StateWidget.N.text">Vaše služby nejsou dosud zapojeny</span></span>
      </div>
      <div class="widget-bottom-links">
        <a mat-list-item *ngIf="session.canView('user-services')" [routerLink]="['/user-services']" i18n="@@Home.StateWidget.menu.user-services">Více v Přehledu služeb</a>
      </div>

    </app-dashboard-widget>

    <app-dashboard-widget
            [priority]="401"
            title="Smlouvy k potvrzení"
            i18n-title = "@@Home.AgreementsConfirmationWidget.title"
            *ngIf="unconfirmedAgreementsCount > 0">

      <div [ngClass]="{'dashboard-value-big' : true
                               , 'mango-text-warn': unconfirmedAgreementsCount > 0
					           }">
        <span *ngIf="unconfirmedAgreementsCount == 1"><span i18n="@@Home.ConfirmAgreementWidget.A.text">Vaše smlouva čeká na potvrzení.</span> </span>
        <span *ngIf="unconfirmedAgreementsCount > 1 && unconfirmedAgreementsCount < 5">{{unconfirmedAgreementsCount}} <span i18n="@@Home.ConfirmAgreementWidget.B.text">z Vašich smluv čekají na potvrzení.</span></span>
        <span *ngIf="unconfirmedAgreementsCount > 4">{{unconfirmedAgreementsCount}} <span i18n="@@Home.ConfirmAgreementWidget.C.text">Vašich smluv čeká na potvrzení.</span></span>
      </div>

      <div class="widget-bottom-links">
        <a mat-list-item *ngIf="session.canView('documents-agreements')" [routerLink]="['/documents-agreements']" i18n="@@Home.StateWidget.menu.documents-agreements">Prohlédnout smlouvy</a>
      </div>
    </app-dashboard-widget>


  </app-dashboard>

</div>
