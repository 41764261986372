import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';

@Injectable({
  providedIn: 'root'
})
export class Payment3dsecureResponseService extends DataBase<any> {
  // getallUrl = 'payment-3dsecure-response';

  protected getAllUrl(): string {
    return 'payment-3dsecure-response' as const;
  }
}
