import { Component, OnInit } from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {DataUserEdit, UserData} from '../../_services/user-edit.service';
import {SessionService} from '../../_services/session.service';
import {FormHelperService} from '../../_services/form-helper.service';
import {debounceTime} from 'rxjs/operators';
import {AccountIbanService} from '../../_services/data-billing.service';
import {DataBankCodes} from '../../_services/data-bank-codes';
import {environment} from '../../../environments/environment';
import {UserEditFormService, UserEditFormType} from '../user-edit-form.service';
import {MangoFormControl} from '../../_models/mango-form-control';
import {moduloValidator} from '../../_directives/common-validators';

@Component({
  selector: 'app-user-edit-payments',
  templateUrl: './user-edit-payments.component.html',
  styleUrls: ['./user-edit-payments.component.scss']
})
export class UserEditPaymentsComponent implements OnInit {
  public form: FormGroup;
  private userData: UserData;

  public iban;
  public popupCondition = {ct: environment.ct}; // Zajišťuje omezení popupu bankovních kódů v případě, že jde o registraci.
  public formName: string;

  constructor(
    public session: SessionService,
    public formHelper: FormHelperService,
    private userEditService: DataUserEdit,
    public accountIbanService: AccountIbanService,
    public bankCodesService: DataBankCodes,
    private userEditFormService: UserEditFormService,
  ) {
    this.formName = UserEditFormType.payments;
    this.form = this.userEditFormService.getForm(UserEditFormType.payments);
    this.initDependencies();
    this.userEditFormService.profileDefaultConfig(this.form, UserEditFormType.payments);
  }

  ngOnInit(): void {
    if (this.session.user) {
      this.userEditService.getSingleton({}, 1800).subscribe(userData => {
        this.userData = userData;
        this.form.patchValue(this.userData);
      }, err => {
        console.log('Chyba při získání dat uživatelského profilu ' + err);
      });
    }
  }

  initDependencies(): void {
    this.form.get('accountNumber').valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.configureAccountBank();
        this.configureAccountIban();
        this.updateBankValidityStates();
      });

    this.form.get('accountBank').valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.configureAccountNumber();
        this.configureAccountIban();
        this.updateBankValidityStates();
      });

    this.form.get('accountPreNumber').valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.configureAccountNumber();
        this.configureAccountBank();
        this.configureAccountIban();
        this.updateBankValidityStates();
      });
  }

  private configureAccountNumber(): void {
    const control = this.form.get('accountNumber') as MangoFormControl;
    control.clearValidators();
    if (this.form.get('accountBank').value || this.form.get('accountPreNumber').value) {
      control.setValidators([Validators.required, Validators.min(10), Validators.max(9999999999), moduloValidator(11)]);
    }
  }

  private configureAccountBank(): void {
    const control = this.form.get('accountBank') as MangoFormControl;
    control.clearValidators();
    if (this.form.get('accountNumber').value || this.form.get('accountPreNumber').value) {
      control.setValidators(Validators.required);
    }
  }

  private configureAccountIban(): void {
    if (this.session.user) {
      const control = this.form.get('accountIban') as MangoFormControl;
      this.accountIbanService.getone(this.session.user.id, {
        accountBank: this.form.get('accountBank').value || '',
        accountNumber: this.form.get('accountNumber').value || '',
        accountPreNumber: this.form.get('accountPreNumber').value || '',
      }).subscribe(d => {
        this.iban = d.iban;
        control.setValue(this.iban);
      });
    }
  }

  private updateBankValidityStates(): void {
    ['accountNumber', 'accountBank'].forEach((name) => {
      const control = this.form.get(name) as MangoFormControl;
      control.updateValueAndValidity({onlySelf: true, emitEvent: false});
    });
  }

}
