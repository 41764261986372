import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService extends DataBase<any> {
  // getallUrl = 'registration';

  protected getAllUrl(): string {
    return 'registration' as const;
  }
}
