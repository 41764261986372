import {Injectable} from '@angular/core';
import {DataParams} from "../param-form/_services/data-params.service";
import {ParamBase, ParamObjectType} from '../param-form/_models/param-base';
import {DataValueDomainPatterns} from "../param-form/_services/data-value-domain-pattern";
import {DataCodeList} from "../param-form/_services/data-code-list";
import {DataValueDomain} from "../param-form/_services/data-value-domain";
import {DataProcessPremises} from "../../_services/process-premises.service";
import {DataProcessServices} from "../../_services/process-services.service";
import {DataProcessUnits} from "../../_services/process-units.service";

/**
 */
@Injectable({
  providedIn: 'root'
})
export class DataParamsPremise extends DataParams {
  // getallUrl = "premise-params";

  protected getAllUrl(): string {
    return 'premise-params' as const;
  }

  public transformParams(paramData: any, services: {
    dataValuePatterns: DataValueDomainPatterns;
    dataCodeList: DataCodeList;
    dataValueDomain: DataValueDomain;
    dataProcessPremises: DataProcessPremises;
    dataProcessServices: DataProcessServices;
    dataProcessUnits: DataProcessUnits;
  }) {

    let options = {
      id: paramData.id,
      defId: paramData.defId,
      value: paramData.value,
      objectType: ParamObjectType.Premise,
      defaultValue: paramData.defaultValue,
      name: paramData.name + (paramData.indexed ? '_' + paramData.index : ''),
      indexName: paramData.name,
      caption: paramData.caption,
      required: paramData.required,
      order: paramData.order,
      type: paramData.type,
      disabled: paramData.disabled,
      indexed: paramData.indexed,
      index: paramData.index,
      domain: paramData.domain,
      hrValue: paramData.hrValue,
    };

    return new ParamBase(options, services);
  }
}
