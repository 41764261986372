import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';


export interface RecUserUnicreditData {
  ucVarSymbol: string;
  ucBalance: number;
  ucPhonet: number;
  saPhonet: number;
  userOffLimit: number;
}

@Injectable({
  providedIn: 'root'
})
export class DataUserUnicredit extends DataBase<RecUserUnicreditData> {
  // getallUrl = 'user-unicredit';

  protected getAllUrl(): string {
    return 'user-unicredit' as const;
  }
}
