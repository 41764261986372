import {Injectable} from '@angular/core';
import {DataBase, DataBaseServiceConfig} from '@og_soft/data-base';
import {ProtectedDataItemsCategories} from './protected-data-items-categories.service';
import {HttpClient} from '@angular/common/http';
import {CachingInterceptor} from '@og_soft/cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ProtectedDataCategoryService} from './protected-data-category';

export interface RecProtectedDataCategoryConsentRecallData {
  pdcCatId: number;
}

@Injectable({
  providedIn: 'root'
})

export class ProtectedDataCategoryConsentRecall extends DataBase<RecProtectedDataCategoryConsentRecallData> {

  constructor(
      public http: HttpClient,
      public serviceConfig: DataBaseServiceConfig,
      private cachingInterceptor: CachingInterceptor,
      private protectedDataCategoryService: ProtectedDataCategoryService,
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  // getallUrl = 'protected-data-category-consent-recall';

  protected getAllUrl(): string {
    return 'protected-data-category-consent-recall' as const;
  }

  post(data: any, options?: any, urlExpandParams?: any): Observable<RecProtectedDataCategoryConsentRecallData> {
    return super.post(data, options, urlExpandParams).pipe(tap(() => {
      // Pokud odeberu souhlas, invaliduji cache servise kterou využívám v mém profilu na zjišťování
      // toho, pro které itemy byl udělen souhlas.
      this.protectedDataCategoryService.clearCache();
    }));
  }
}




