import {Injectable} from '@angular/core';
import {DataBase, GetallResult} from '@og_soft/data-base';
import {UserType} from './user-edit.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export interface ProtectedDataCategory {
    catId: number;
    catName: string;
    catDes: string;
    catDescription: string;
    catIcon: number;
    catInfoLevel1: string;
    catInfoLevel2: string;
    catInfoLevel3: string;
    catConsent: boolean;
    catCountConsent: number;
    catEraseInactive?: string;
    catManualErase: number;
    catLawfulness: number;
    pdcUserConsent: string;
    pdc?: any;
    userPdcConsent?: boolean;
    userTypeRaw: string;
    userType: UserType[];
}

abstract class ProtectedDataCategoryServiceGeneral extends DataBase<ProtectedDataCategory> {

  protected recordPostprocess(record: ProtectedDataCategory): ProtectedDataCategory {
    record.userType = record.userTypeRaw
                        ? record.userTypeRaw.split(',') as UserType[]
                        : [];
    return super.recordPostprocess(record);
  }
}

@Injectable({
    providedIn: 'root'
})
export class ProtectedDataCategoryService extends ProtectedDataCategoryServiceGeneral {
  // getallUrl = 'protected-data-categories';

  protected getAllUrl(): string {
    return 'protected-data-categories' as const;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProtectedDataCategoryPublicService extends ProtectedDataCategoryServiceGeneral {
  // getallUrl = 'protected-data-categories-public';

  protected getAllUrl(): string {
    return 'protected-data-categories-public' as const;
  }

  getall(params?: any, cacheTimeout?: number, urlExpandParams?: any): Observable<GetallResult<ProtectedDataCategory>> {
    if (!params) {params = {}; }
    params.ct = environment.ct;
    return super.getall(params, cacheTimeout, urlExpandParams);
  }
}




