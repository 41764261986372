import {Component, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {SessionService} from '../_services/session.service';
import {PhonetCreditService} from '../_services/phonet-credit';
import {DataUserUnicredit} from '../_services/user-unicredit.service';
import {PhonetCreditLoadService} from '../_services/phonet-credit-load';
import {FormHelperService} from '../_services/form-helper.service';
import {DialogConfig, DialogRef} from '@og_soft/dialog';
import {MangoFormControl} from '../_models/mango-form-control';
import {numberDecimalPlacesValidator} from '../_directives/common-validators';


@Component({
  selector: 'app-phonet-credit',
  templateUrl: './phonet-credit.component.html',
  styleUrls: ['./phonet-credit.component.scss']
})
export class PhonetCreditComponent implements OnInit {
  form: FormGroup;

  public balance: number;
  public userServices;

  constructor(
     dconfig: DialogConfig,
     public session: SessionService,
     public formHelper: FormHelperService,
     public dataUserUnicredit: DataUserUnicredit,
     public phonetCreditServices: PhonetCreditService,
     public phonetCreditLoad: PhonetCreditLoadService,
     private dlgRef: DialogRef,
    ) {

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      userServices: new MangoFormControl(''),
      amount: new MangoFormControl('',
        [Validators.required,
          Validators.min(0.001),
          Validators.max(999999),
          numberDecimalPlacesValidator(Number(this.session.options.PRICE_DECIMAL_PRECISION))]),
    });
    this.form.controls.userServices.setValue(0);

    this.dataUserUnicredit.getone(this.session.user.id).subscribe(next => {
          if (next !== null ) {
            this.balance = next.ucBalance;
          }
        }
        , err => {
          console.log('err', err);
        }
    );

  }

  public valuesSet(values: any): void {
    this.form.setValue(values);
  }

  public save(): void {
    const formData = this.form.value;
    if (this.form.valid){
      this.phonetCreditLoad.post(formData).subscribe(() => {
        this.session.message( $localize`:@@PhonetCredit.creditLoad.message:OK. Všechno máme uloženo.` );
      }, err => {
        console.error('Tak to tak úplně nevyšlo.', err);
      });
      this.dlgRef.close();
    }else{
      this.session.message( $localize`:@@PhonetCredit.creditLoad.validate.message:Některé položky nejsou vyplněné nebo nemají správnou hodnotu.` );
      this.formHelper.markDirty(this.form);
    }
  }

  balanceGet(): number {
    return this.balance;
  }

  getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }
}
