// Pozor, v komentarich v tomto souboru NEPOUZIVAT DIAKRITIKU. Utilita "ng"
// se ji zmate a tento soubor pocuni (pri pridavani importu v nem zacne delat
// neukoncene retezce.

import localeCs from '@angular/common/locales/cs';
import localeSk from '@angular/common/locales/sk';
import {ErrorHandler, NgModule, TRANSLATIONS_FORMAT} from '@angular/core';
import {AppComponent} from './app.component';
import {ScNavComponent, ScNavMenuItemComponent} from './sc-nav/sc-nav.component';
import {ScLoginComponent} from './sc-login/sc-login.component';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SessionInterceptor} from './_helpers/session.interceptor';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {MangoHammerConfig} from './_modules_config/mango.hammer.config';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EuUnicreditComponent} from './eu-unicredit/eu-unicredit.component';
import {DatePipe, registerLocaleData} from '@angular/common';
// import {TextMaskModule} from 'angular2-text-mask';
import {ContactsComponent} from './contacts/contacts.component';
import {ProtectedDataComponent, ProtectedDataLevel3DialogComponent} from './protected-data/protected-data.component';
import {DocumentsComponent} from './documents/documents.component';
import {EuBillingComponent} from './eu-billing/eu-billing.component';
import {UserServicesComponent} from './user-services/user-services.component';
import {UserPaymentsComponent} from './user-payments/user-payments.component';
import {TelephonyListComponent} from './telephony-list/telephony-list.component';
import {UserServiceEditComponent} from './user-service-edit/user-service-edit.component';
import {DataViewComponent} from './data-view/data-view.component';
import {CardPaymentComponent} from './card-payment/card-payment.component';
import {EditorBlockComponent} from './editor-block/editor-block.component';
import {EditorBlockPageComponent} from './editor-block-page/editor-block-page.component';
import {ChartModule} from 'angular2-chartjs';
import {environment} from '../environments/environment';
import {NodesComponent} from './nodes/nodes.component';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {ScMainToolbarComponent} from './sc-main-toolbar/sc-main-toolbar.component';
import {PhonetCreditComponent} from './phonet-credit/phonet-credit.component';
import {DocumentsAgreementsComponent} from './documents-agreements/documents-agreements.component';
import {DocumentsOtherComponent} from './documents-other/documents-other.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {DisplayEditPaneComponent, DisplayPaneItemDirective} from './display-edit-pane/display-edit-pane.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DashboardWidgetComponent} from './dashboard-widget/dashboard-widget.component';
import {HomeComponent} from './home/home.component';
import {PasswordComponent} from './password/password.component';
import {ProcessListComponent} from './process-list/process-list.component';
import {ProcessEditComponent} from './process-edit/process-edit.component';
import { ScGetDocumentComponent } from './sc-get-document/sc-get-document.component';
import {PopupListControlModule} from '@og_soft/popup-list-control';
import { RmRedirectComponent } from './rm-redirect/rm-redirect.component';
import {ParamFormComponent} from './_libraries/param-form/param-form/param-form.component';
import {ParamComponent} from './_libraries/param-form/param/param.component';
import {CachingInterceptor} from '@og_soft/cache';
// import {TdFieldMessagesModule} from '@og_soft/td-field-messages';
// import {LinkifyModule} from '@og_soft/linkify-pipe';
import {PhoneNumberModule, PhoneNumberPipe} from '@og_soft/phone-number';
// import {MemoControlModule} from '@og_soft/memo-control';
// import {DetesTableModule} from '@og_soft/detes-table';
import {OgDataBaseModule} from '@og_soft/data-base';
// import {StickyDirectiveModule} from '@og_soft/sticky-directive';
import {PasswordChangeComponent} from './password-change/password-change.component';
import {PageEmptyComponent} from './page-empty/page-empty.component';
import {PageLoadingComponent} from './page-loading/page-loading.component';
import {ForgetTableRowComponent} from './_libraries/forget-table/forget-table-row/forget-table-row.component';
import {ForgetTableComponent} from './_libraries/forget-table/forget-table/forget-table.component';
import {ForgetTableColComponent} from './_libraries/forget-table/forget-table-col/forget-table-col.component';
import {ForgetTableFiltersComponent} from './_libraries/forget-table/forget-table-filters/forget-table-filters.component';
import {ForgetTableSecHeaderComponent} from './_libraries/forget-table/forget-table-sec-header/forget-table-sec-header.component';
import {ForgetTableHeaderComponent} from './_libraries/forget-table/forget-table-header/forget-table-header.component';
import {MgtActionsComponent} from './_libraries/forget-table/mgt-actions/mgt-actions.component';
import {MgtCellGroupComponent} from './_libraries/forget-table/mgt-cell-group/mgt-cell-group.component';
import {MgtCellComponent} from './_libraries/forget-table/mgt-cell/mgt-cell.component';
import {MgtIcoComponent} from './_libraries/forget-table/mgt-ico/mgt-ico.component';
import {ForgetTableAutoDetailComponent} from './_libraries/forget-table/forget-table-auto-detail/forget-table-auto-detail.component';
import {DialogModule} from '@og_soft/dialog';
import {DragDropDirective} from './_directives/drag-and-drop.directive';
import {ScPageCaptionBlockComponent} from './sc-page-caption-block/sc-page-caption-block.component';
import {ProcessMessageDialogComponent} from './process-list/process-message-dialog/process-message-dialog.component';
import {ProcessGuideDialogComponent} from './process-edit/process-guide-dialog/process-guide-dialog.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { UserProfileDialogComponent } from './user-profile/user-profile-dialog/user-profile-dialog.component';
import { TestComponentComponent } from './test-component/test-component.component';
import { VerifyRegistrationComponent } from './verify-registration/verify-registration.component';
import {HasRequiredFieldPipe} from './_pipes/has-required-field.pipe';
// tslint:disable-next-line:max-line-length
import {ProtectedDataRecallEraseDialogComponent} from './protected-data/protected-data-recall-erase-dialog/protected-data-recall-erase-dialog.component';
// tslint:disable-next-line:max-line-length
import {ProtectedDataRecallInactiveDialogComponent} from './protected-data/protected-data-recall-inactive-dialog/protected-data-recall-inactive-dialog.component';
import { NodeEditComponent } from './nodes/node-edit/node-edit.component';
import {SessionProcessingComponent} from './core/session-processing/session-processing.component';
import { UserEditPersonalComponent } from './user-edit/user-edit-personal/user-edit-personal.component';
import { UserEditContactsComponent } from './user-edit/user-edit-contacts/user-edit-contacts.component';
import { UserEditAddressesComponent } from './user-edit/user-edit-addresses/user-edit-addresses.component';
import { UserEditPaymentsComponent } from './user-edit/user-edit-payments/user-edit-payments.component';
import { UserEditProtectedComponent } from './user-edit/user-edit-protected/user-edit-protected.component';
import { UserEditParamsComponent } from './user-edit/user-edit-params/user-edit-params.component';
import { RegistrationComponent } from './registration/registration.component';
import { ConfigSettingComponent } from './config-setting/config-setting.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {DateControlComponent} from './_libraries/date-control/date-control.component';
import {DurationPipe} from './_pipes/duration-pipe';
import {BytesPipe} from './_pipes/bytes-pipe';
import {FilterPipe} from './_pipes/filter-pipe';
import {JoinPipe} from './_pipes/join-pipe';
import * as Sentry from '@sentry/angular';
import {SentryErrorHandler} from './_handlers/sentry.error.handler';
import { UnavailablePageComponent } from './core/unavailable-page/unavailable-page.component';
import {DatetimeControlModule} from '@og_soft/datetime-control';
import { CsobIntegrationTestComponent } from './integration-tests/csob-integration-test/csob-integration-test.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import { CardResponseComponent } from './core/card-response/card-response.component';
import { DocumentAgreementDialogComponent } from './documents-agreements/document-agreement-dialog/document-agreement-dialog.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {ScrollingModule} from "@angular/cdk/scrolling";

declare const require; // Use the require method provided by webpack

if (environment.sentryDns) {
  Sentry.init({
    dsn: environment.sentryDns,
    release: environment.version
  });
}

// Potřebujeme říci ng2-pdf-vieweru, aby si knihovnu pdf.worker.js bral
// někde z našeho buildu a mít ji jako vlastní součást. Důvodem je použití
// blokovanými zákazníky (hlásil ASS...), kteří si, pokud je knihovna
// někde v CDN, například svoji smlouvu nepřečtou...
(window as any).pdfWorkerSrc = 'pdf.worker.min.js';

// Takhle bychom si staticky natahli preklady pro pouziti nize v "providers".
// Realne pouzivame factory, ktera je zavede podle LOCALE_ID.
// export const translations = require(`raw-loader!../locale/messages.en.xlf`);

@NgModule({
    declarations: [
        AppComponent,
        // ** Common
        PageEmptyComponent,
        PageLoadingComponent,
        // ** Tabulka
        ForgetTableRowComponent,
        ForgetTableComponent,
        ForgetTableColComponent,
        ForgetTableAutoDetailComponent,
        ForgetTableSecHeaderComponent,
        ForgetTableHeaderComponent,
        ForgetTableFiltersComponent,
        MgtActionsComponent,
        MgtCellGroupComponent,
        MgtCellComponent,
        MgtIcoComponent,
        // ** Ovladani
        ScNavComponent,
        ScNavMenuItemComponent,
        ScLoginComponent,
        // ** Direktivy
        DragDropDirective,
        // Pipy
        HasRequiredFieldPipe,
        DurationPipe,
        BytesPipe,
        FilterPipe,
        JoinPipe,
        // ** Moduly
        ContactsComponent,
        ProtectedDataComponent,
        ProtectedDataLevel3DialogComponent,
        DocumentsComponent,
        EuUnicreditComponent,
        EuBillingComponent,
        UserServicesComponent,
        UserPaymentsComponent,
        TelephonyListComponent,
        UserServiceEditComponent,
        DataViewComponent,
        CardPaymentComponent,
        EditorBlockComponent,
        EditorBlockPageComponent,
        NodesComponent,
        ScMainToolbarComponent,
        PhonetCreditComponent,
        DocumentsAgreementsComponent,
        DocumentsOtherComponent,
        UserProfileComponent,
        DisplayEditPaneComponent,
        DisplayPaneItemDirective,
        DashboardComponent,
        DashboardWidgetComponent,
        HomeComponent,
        PasswordComponent,
        PasswordChangeComponent,
        ProcessEditComponent,
        ProcessListComponent,
        ScGetDocumentComponent,
        RmRedirectComponent,
        ParamFormComponent,
        ParamComponent,
        ScPageCaptionBlockComponent,
        ProcessMessageDialogComponent,
        ProcessGuideDialogComponent,
        ConfirmDialogComponent,
        UserServiceEditComponent,
        PhonetCreditComponent,
        CardPaymentComponent,
        UserProfileDialogComponent,
        TestComponentComponent,
        VerifyRegistrationComponent,
        ProtectedDataRecallEraseDialogComponent,
        ProtectedDataRecallInactiveDialogComponent,
        NodeEditComponent,
        SessionProcessingComponent,
        UserEditPersonalComponent,
        UserEditContactsComponent,
        UserEditAddressesComponent,
        UserEditPaymentsComponent,
        UserEditProtectedComponent,
        UserEditParamsComponent,
        RegistrationComponent,
        ConfigSettingComponent,
        DateControlComponent,
        UnavailablePageComponent,
        CsobIntegrationTestComponent,
        CardResponseComponent,
        DocumentAgreementDialogComponent,
        // PokusComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        //   FlexLayoutModule,
        ReactiveFormsModule,
        HttpClientModule,
        // ** Knihovny vlastni
        DialogModule,
        //   TdFieldMessagesModule,
        //  LinkifyModule,
        PhoneNumberModule,
        //   BytesPipeModule,
        //   FilterPipeModule,
        //   JoinPipeModule,
        //   MemoControlModule,
        //    StickyDirectiveModule,
        PopupListControlModule,
        DatetimeControlModule,
        OgDataBaseModule.forRoot({ appUrl: environment.baseUrl, mangoUrl: environment.baseUrl }),
        //   ModalDialogModule,
        // ** Material design
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatListModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSelectModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatTableModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatChipsModule,
        MatTooltipModule,
        MatBadgeModule,
        ScrollingModule,
        // ** Other libraries
        // TextMaskModule,
        ChartModule,
        NgxMatSelectSearchModule,
        PdfViewerModule,
        // ** Veci ktere musi byt kdyz se knihovny tahaji naprimo
        VirtualScrollerModule,
        RecaptchaV3Module,
    ],
    providers: [
        DatePipe,
        CachingInterceptor,
        PhoneNumberPipe,
        { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HAMMER_GESTURE_CONFIG, useClass: MangoHammerConfig },
        // Format pouzitych prekladu:
        { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

registerLocaleData(localeCs, 'cs');
registerLocaleData(localeSk, 'sk');
