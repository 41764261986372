
<dialog-title>
  <h3 i18n="@@ProtectedData.recallEraseDialog.heading">Odvolat souhlas</h3>
</dialog-title>

<dialog-content>
  <ng-container>
    <p i18n="@@ProtectedData.recallEraseDialog.text1">Opravdu chcete odvolat souhlas s nakládáním s osobními údaji?</p>
    <p class="mango-text-secondary" i18n="@@ProtectedData.recallEraseDialog.text2">Odvoláním souhlasu budou smazány všechny chráněné údaje, vztahující se k této kategorii.</p>
  </ng-container>
</dialog-content>

<dialog-actions>
  <button mat-flat-button dialog-close i18n="Zavřít|Tlačítko na zavření dialogu v dialogu při uzavření ticketu@@ProtectedData.recallEraseDialog.button.Close">Zavřít</button>
  <button mat-flat-button color="primary" (click)="save()" i18n="Odeslat|Tlačítko na uložení dialogu v dialogu při uzavření ticketu@@ProtectedData.recallEraseDialog.Send">Pokračovat</button>
</dialog-actions>
