<dialog-title>
  <h3 i18n="@@CardPayment.message.text">Platba kartou</h3>
</dialog-title>

<dialog-content>

<form [formGroup]="form">

<ng-container *ngIf="formHelper.isVisible(form.get('amount'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@CardPayment.amount.label">Částka</mat-label>
                        <input matInput formControlName="amount" id="amount" name="amount" type="number">
                          <span matSuffix>{{session.user.locDefaults.currencySymbol}}</span>
                        <mat-error *ngIf="form.get('amount').invalid && (form.get('amount').dirty || form.get('amount').touched)">
                          {{formHelper.getErrorMessage(form.get('amount'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>



<ng-container *ngIf="formHelper.isVisible(form.get('varSym'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@CardPayment.varSym.label">Variabilní symbol</mat-label>
                        <input matInput formControlName="varSym" id="varSym" name="varSym">
                        <mat-error *ngIf="form.get('varSym').invalid && (form.get('varSym').dirty || form.get('varSym').touched)">
                          {{formHelper.getErrorMessage(form.get('varSym'))}}
                        </mat-error>
                      </mat-form-field>
                    </ng-container>



  <div *ngIf="isTest" class="mango-text-secondary">
    <div style="font-style: italic">Následující instrukce se zobrazují pouze na testovací verzi</div>
    <div>Použij kartu <b>4125010001000208</b>
    nebo něco z <a href="https://github.com/csob/paymentgateway/wiki/Testovac%C3%AD-karty">https://github.com/csob/paymentgateway/wiki/Testovací-karty</a>
    </div>
  </div>
</form>

</dialog-content>

<dialog-actions>
  <button mat-flat-button dialog-close i18n="Zavřít|Tlačítko na zavření dialogu v dialogu při uzavření ticketu@@ProcessFinish.button.Close">Zavřít</button>
  <button mat-flat-button color="primary" (click)="save()" i18n="Odeslat|Tlačítko na uložení dialogu v dialogu při uzavření ticketu@@ProcessFinish.button.Send">Uložit</button>

</dialog-actions>
<app-session-processing></app-session-processing>

