import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';

import {SessionService} from '../_services/session.service';
import {InstallationConfigService} from '../_services/installation-config.service';

@Component({
  selector: 'app-sc-login',
  templateUrl: './sc-login.component.html',
  styleUrls: ['./sc-login.component.scss']
})
export class ScLoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  message: string;
  hidePassword = true;
  displayRegistration = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public session: SessionService,
    private installationConfigService: InstallationConfigService,
  ) {
    // Před přesměrováním na login page mohlo dojít k nějaké chybě a v session
    // mohlo zůstat načítání. Pro jistotu ho tedy tady ukončím.
    this.session.processingSet(false);
    this.installationConfigService.displayRegistration().subscribe(display => {
      this.displayRegistration = display;
    });
  }

  ngOnInit(): void {
    // Při zobrazení login page musím vypláchnout chace, jinak mi v některých servisách (v závislosti na
    // tom kde mám nastavené cachování) můžou zůstat zacachovaná data dříve přihlášeného uživatele.
    // Vypláchnutí cache - cache v DataBase tříde má statickou metodu na propláchnutí kompletní cache.
    // Dostávám se k ní trochu přes ruku, prostřednictvím jakékoli třídy která dědí DataBase,
    // bylo by lepší mít ji jako statickou v DataBase. Musím navíc uměle potlačit ts chybu,
    // nicméně to funguje.
    // @ts-ignore
    this.installationConfigService.cache.clear();
    this.loginForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl; } {
    return this.loginForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    this.session.closeMessage(); // Aby mi při zalogování vždy zmizelo upozornění, že došlo k odhlášení

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.session.processingSet(true);
    this.session.login(this.f.login.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.session.processingSet(false);
          this.router.navigateByUrl(this.solveRedirect());
        },
        error => {
          this.session.message(
            $localize`:@@ScLogin.login.error.message:Špatné jméno nebo heslo`
          );
          this.session.processingSet(false);
        });
  }

  register(): void {
    this.router.navigateByUrl(SessionService.MAIN_PAGE);
  }

  private solveRedirect(): string {
    let redirect: string;
    if (this.session.redirectUrl && this.session.redirectUrl !== '/login'){
      redirect = this.session.redirectUrl;
    }else{
      redirect = SessionService.MAIN_PAGE;
    }
    this.session.redirectUrl = null;
    return redirect;
  }
}
