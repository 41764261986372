import {Component, Directive, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionService} from '../_services/session.service';
import {UserEditFormType} from '../user-edit/user-edit-form.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[displayPaneItem]'
})
export class DisplayPaneItemDirective implements OnInit {
  @Input('displayPaneItem') name: string;

  constructor(
    private pane: DisplayEditPaneComponent,
    private session: SessionService,
    private el: ElementRef
  ) {
  }

  ngOnInit(): void {
    const optionsKey = 'SELFCARE.' + this.pane.formName + '.' + this.pane.name + '.' + this.name;
    const myOptions = this.session.getOption(optionsKey);
    if (myOptions && myOptions.includes('hidden')) {
      this.el.nativeElement.style.display = 'none';
    }
    if (this.el.nativeElement.firstChild.classList) {
      this.el.nativeElement.firstChild.classList.add('mat-caption');
      this.el.nativeElement.firstChild.classList.add('mango-text-secondary');
    }
    if (this.el.nativeElement.lastChild.classList) {
      this.el.nativeElement.lastChild.classList.add('mango-text-normal');
    }
  }
}


@Component({
  selector: 'app-display-edit-pane',
  templateUrl: './display-edit-pane.component.html',
  // styleUrls: ['./display-edit-pane.component.scss']
  styles: [`
  /*
    */
    .display-type-card .pane-edit {
        position: absolute;
        top: 12px;
        right: 6px;
    }

    .pane-flex {
      display: flex;
      flex-direction: row;
    }
  @media screen and (max-width: 600px) {
    .pane-flex {
      flex-direction: column;
    }
  }


  .display-type-pane {
      & ::ng-deep .mat-caption {
        opacity: .6;
      }
    }

    /* nefunguje :-( */
    :host ::ng-deep .mat-expansion-panel-header mat-content {
      justify-content: space-between;
    }



  `],
})
export class DisplayEditPaneComponent implements OnInit {

  displayType = 'card'; // card nebo pane

  /**
   * Name of this pane (frame in the Mangospeak)
   *
   * Used for right names and when emiting the editButtonClicked event.
   */
  @Input() public name: UserEditFormType;

  /**
   * Name of the form (form on module in the Mangospeak)
   *
   * Used for right names.
   */
  @Input() public formName: string;

  /**
   * Heading text
   */
  @Input() title: string;

  /**
   * Subheading text - about a sentence long text about what this pane does.
   */
  @Input() subtitle?: string;

  /**
   * Specifies, whether this pane has an edit button
   */
  // tslint:disable-next-line:no-input-rename
  @Input('can-edit') canEdit = false;

  /**
   * Optional edit button text instead of default 'Upravit'
   */
  // tslint:disable-next-line:no-input-rename
  @Input('edit-button-text') editBtnText?: string;

  /**
   * Optional edit button icon name
   */
  // tslint:disable-next-line:no-input-rename
  @Input('edit-button-icon') editBtnIcon?: string;

  /**
   * Event na kliknutí na editační tlačítko.
   */
  @Output() doEdit: EventEmitter<UserEditFormType> = new EventEmitter();

  constructor(public session: SessionService) {
  }

  ngOnInit() {
  }

  editButtonClicked($event: UserEditFormType) {
    this.doEdit.emit(this.name);
  }

  editUserProfile() {
    return $localize`:@@DisplayEditPane.edit.caption:Upravit`;
  }

}
