import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from '../../_services/session.service';
import {FormGroup} from '@angular/forms';
import {ProtectedDataCategoryConsentAdd} from '../../_services/protected-data-category-consent-add';
import {DialogConfig, DialogService} from '@og_soft/dialog';
import {ProtectedDataLevel3DialogComponent} from '../../protected-data/protected-data.component';
import {UserEditFormService, UserEditFormType} from '../user-edit-form.service';
import {UserEditProtectedService} from '../user-edit-protected.service';
import {ProtectedDataCategory} from '../../_services/protected-data-category';
import {DataUserEdit, UserType} from '../../_services/user-edit.service';

@Component({
  selector: 'app-user-edit-protected',
  templateUrl: './user-edit-protected.component.html',
  styleUrls: ['./user-edit-protected.component.scss']
})
export class UserEditProtectedComponent implements OnInit {
  @Input() formType: UserEditFormType | 'all';

  public protectedDataCategories: ProtectedDataCategory[];
  private editForms: FormGroup[];
  public userType: UserType;

  constructor(
    public session: SessionService,
    public protectedDataCategoryConsentAdd: ProtectedDataCategoryConsentAdd,
    private dialogService: DialogService,
    private userEditFormService: UserEditFormService,
    private userEditProtectedService: UserEditProtectedService,
    private userEditService: DataUserEdit,
  ) {
  }

  ngOnInit(): void {
    if (this.formType === 'all') {
      this.editForms = this.userEditFormService.getAllForms();
    } else {
      this.editForms = [this.userEditFormService.getForm(this.formType)];
    }
    this.userEditProtectedService.resetSavedConsent();
    this.protectedDataInit();

    this.userEditFormService.userTypeChanged().subscribe(() => {
      this.updateUserType();
    });

    this.updateUserType();
  }

  private updateUserType(): void{
    if (this.formType === UserEditFormType.personal || this.formType === 'all'){
      // Pokud edituji osobní údaje nebo jsme  v registrace, aktuální typ mám ve formuláři.
      this.userType = this.userEditFormService.getForm(UserEditFormType.personal).get('type').value;
    }else{
      // Jinak si ho získám z db
      this.userEditService.getSingleton().subscribe(user => {
        this.userType = user.type;
      });
    }
  }


  public protectedDataInit(): void {
    this.userEditProtectedService.getRelevantCategories(this.editForms).subscribe(next => {
      this.protectedDataCategories = next;
    });
  }

  saveConsent(catId: number): void {
    if (this.formType === 'all') {
      // Registrace zákazníka, souhlas nemůžu ukládat průběžně, protože zákazník ještě není založen,
      // tak si potvrzený souhlas zaznamenám do servisy.
      this.userEditProtectedService.addConsent(catId);
      const changedCategory = this.protectedDataCategories.filter(category => category.catId === catId);
      changedCategory[0].catConsent = true;
    } else {
      this.consentAdd(catId);
    }
  }

  consentAdd( catId: number): void {
    if ( catId) {
      this.session.processingSet(true, $localize`:@@ProtectedData.consentAdd.message.processing:Uděluji souhlas`);
      this.protectedDataCategoryConsentAdd.post({
        pdcCatId: catId
      }).subscribe(() => {
        this.session.processingSet(false);
        this.session.message( $localize`:@@ProtectedData.consentAdd.message.ok:Souhlas byl udělen.` );
        this.protectedDataInit();
      }, () => {
        this.session.processingSet(false);
        this.session.message($localize`:@@ProtectedData.consentAdd.message.error:Nepodařilo se udělit souhlas.`);
      });
    }
  }

  displayProtectedDataLevel3Info(cat): void {
    const dialogConfig = new DialogConfig();
    dialogConfig.data = {
      infoText: cat.catInfoLevel3
    };
    this.dialogService.open(ProtectedDataLevel3DialogComponent, dialogConfig);
  }

  userTypeValid(userType: UserType[]): boolean {
    // Nejdřív okrajové podmínky - chybí nějaká data: zobrazuji vše
    if (!userType || userType.length < 1 || !this.userType){
      return true;
    }
    return userType.includes(this.userType);
  }
}
