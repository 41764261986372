import {Injectable} from '@angular/core';
import {DataBase, DataBaseServiceConfig} from '@og_soft/data-base';
import {HttpClient} from '@angular/common/http';
import {CachingInterceptor} from '@og_soft/cache';
import {ProtectedDataCategoryService} from './protected-data-category';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export interface RecProtectedDataCategoryConsentAddData {
    pdcCatId: number;
}

@Injectable({
    providedIn: 'root'
})

export class ProtectedDataCategoryConsentAdd extends DataBase<RecProtectedDataCategoryConsentAddData> {

    constructor(
        public http: HttpClient,
        public serviceConfig: DataBaseServiceConfig,
        private cachingInterceptor: CachingInterceptor,
        private protectedDataCategoryService: ProtectedDataCategoryService,
    ) {
        super(http, serviceConfig, cachingInterceptor);
    }

   // getallUrl = 'protected-data-category-consent-add';

  protected getAllUrl(): string {
    return 'protected-data-category-consent-add' as const;
  }

    post(data: any, options?: any, urlExpandParams?: any): Observable<RecProtectedDataCategoryConsentAddData> {
        return super.post(data, options, urlExpandParams).pipe(tap(() => {
            // Pokud odeberu souhlas, invaliduji cache servise kterou využívám v mém profilu na zjišťování
            // toho, pro které itemy byl udělen souhlas.
            this.protectedDataCategoryService.clearCache();
        }));
    }
}




