import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from "../../_services/session.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-session-processing',
  templateUrl: './session-processing.component.html',
  styleUrls: ['./session-processing.component.scss']
})
export class SessionProcessingComponent implements OnInit, OnDestroy {

  constructor(
    public session: SessionService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.subscriptions.push(
      this.session.processing.subscribe(processing => {
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
