<div class="container registration" style="flex-direction: column; align-items: center;">
  <mat-card class="card">
    <mat-card-header>
      <mat-card-title i18n="@@Registration.title"
      >Registrace nového zákazníka</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-user-edit-personal></app-user-edit-personal>
      <app-user-edit-addresses></app-user-edit-addresses>
      <app-user-edit-contacts></app-user-edit-contacts>
      <app-user-edit-payments></app-user-edit-payments>
      <app-password-change></app-password-change>
      <app-user-edit-params></app-user-edit-params>
      <app-user-edit-protected [formType]="'all'"></app-user-edit-protected>
      <div class="container buttons-row">
        <button mat-flat-button [routerLink]="['/login']" i18n="@@Registration.button.Close">Zpět</button>
        <button mat-flat-button color="primary" (click)="register()" i18n="@@Registration.button.Save">Odeslat</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<app-session-processing></app-session-processing>
