import {Injectable} from '@angular/core';
import {DataBase} from "@og_soft/data-base";

@Injectable({
  providedIn: 'root'
})
export class DataUserContactsService extends DataBase<any> {
  // getallUrl = "eu-contacts";

  protected getAllUrl(): string {
    return 'eu-contacts' as const;
  }

  //public delete(id) {
  //this.http.delete<any>(this.getallUrl+'/'+id);
  //}
}
