import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckDbService extends DataBase<boolean> {
  // getallUrl = 'db-ready';

  protected getAllUrl(): string {
    return 'db-ready' as const;
  }

  isDbReady(): Observable<boolean>{
    const params: any = {};
    if (environment.dbVersion === 'devel' || environment.dbVersion === 'test'){
      params.notStable = 1;
    }
    return this.getSingleton(params).pipe(map(result => {
      return !!result;
    }));
  }
}
