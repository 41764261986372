import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';

export interface PayOrder{
  orderNumber: string;
  result: string; // Je zde id do payOrder (nebo kód chyby.)
}

@Injectable({
  providedIn: 'root'
})
export class PayOrderService extends DataBase<PayOrder> {
  // getallUrl = 'pay-order';

  protected getAllUrl(): string {
    return 'pay-order' as const;
  }
}
