import {Injectable} from '@angular/core';
import {DataBase, DataBaseServiceConfig} from "@og_soft/data-base";

export interface ProcessStatesHistory {
  states: number[];
  transitions: number[];
}

@Injectable({
  providedIn: 'root'
})
export class DataProcessStatesHistory extends DataBase<ProcessStatesHistory> {
  // getallUrl = "process-states-history";
  protected getAllUrl(): string {
    return 'process-states-history' as const;
  }

  configureUrl(config: DataBaseServiceConfig) {
    this.baseurl = config.mangoUrl;
  }
}
