import {Injectable} from '@angular/core';
import {DataBase} from "@og_soft/data-base";

export interface RecInetData {
  blType: string;
  ipAddr: string;
  dataIn: number;
  dataOut: number;
}

export interface RecInetDataMeta {
}

@Injectable({
  providedIn: 'root'
})
export class InetDataService extends DataBase<RecInetData, RecInetDataMeta> {
  // getallUrl = "inet-data";

  protected getAllUrl(): string {
    return 'inet-data' as const;
  }

  private _data: any[] = [];

  private test: number = 0;

  /**
   * Načte data v závislosti na parametrech. Implementuje jednoduchou cache.
   */
  public fetchData(saId: number) {
    let hash = saId;
    if (!this._data[hash]) {
    this.getone(saId).subscribe(p => {
       // console.log("InetDataService fetched data");
       this._data[hash] = p;
      }, error => {
        console.log("InetDataService fetching error", error);
        this._data[hash] = undefined;
      })
    }
    return this._data[hash];
  }

  /**
   * Vrací sumu načtených dat podle zadaných kritérií.
   *
   * @param saId ID služby, pro kterou zjišťujeme data.
   * @param range Rozsah dat (month/week/day)
   * @param inOut Směr přenosu (in/out)
   * @param ip Ip adresa pro kterou chceme data, all pro sumu všech.
   */
  public inetSum(saId: number, range: string, inOut: string, ip: string = 'all') {
    let sum = undefined;
    let data = this.fetchData(saId);
    if (data !== undefined && data !== null) {
      sum = 0;
      for (let anIp in data[range]) {
        if (ip == anIp) {
          for (let time in data[range][anIp][inOut]) {
            sum += data[range][anIp][inOut][time];
          }
        }
      }
    }
    return sum;
  }

  /**
   * Vrací kumulovaná data podle zadaných kritérií. Kumulovaná se např. použijí v grafu.
   *
   * @param range Rozsah dat (month/week/day)
   * @param inOut Směr přenosu (in/out)
   * @param ip Ip adresa pro kterou chceme data, all pro data za celou službu bez ohledu na jednotlivé IP.
   */
  public inetDataAccumulated(saId: number, range: string, inOut: string, ip: string = 'all') :number[] {
    let accData: number[] = [];
    let data = this.fetchData(saId);
    if (data != undefined || data != null) {
      accData = [];
      let acc = 0;
      for (let time in data[range][ip][inOut]) {
        if (data[range][ip][inOut][time]) {
          acc += data[range][ip][inOut][time];
          accData.push(acc);
        } else {
          accData.push(null);
        }
      }
    }
    return accData;
  }

  /**
   * Vrací labely pro Xovou osu grafu.
   *
   * @param range Rozsah dat (month/week/day)
   */
  public inetDataLabels(saId: number, range: string): string[] {
    let labels: string[] = [];
    let data = this.fetchData(saId);
    if (data != undefined || data != null) {
      labels = [];
      let date = new Date();
      for (let time in data[range]['all']['in']) {
        switch (range) {
          case 'month': labels.push(Math.floor(+time / 100) + ". " + (date.getMonth() + 1) + "."); break;
          default: labels.push(time); break;
        }

      }
    }
    return labels;
  }

  public inetDataXMin(saId: number, range: string): number {
    let data = this.fetchData(saId);
    if (data != undefined || data != null) {
      return +Object.keys(data[range]['all']['in']).reduce((k,v) => k);
    }
    return undefined;
  }

  public inetDataXMax(saId: number, range: string): number {
    let data = this.fetchData(saId);
    if (data != undefined || data != null) {
      return +Object.keys(data[range]['all']['in']).reduce((k,v) => v);
    }
    return undefined;
  }

  /**
   * Vrací seznam IP adres v datech.
   */
  public inetIps(saId: number): string[] {
    let ips = [];
    let data = this.fetchData(saId);
    if (data != undefined || data != null) {
      for (let ip in data['month']) {
        if (ip !== 'all') {
          ips.push(ip);
        }
      }
    }
    return ips;
  }
}
