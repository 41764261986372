<form [formGroup]="form">
  <ng-container *ngIf="formHelper.isVisible(form.get('accountPreNumber'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.accountPreNumber.label">Předčíslí</mat-label>
      <input matInput formControlName="accountPreNumber" id="accountPreNumber" name="accountPreNumber" i18n-title="@@UserProfile.accountPreNumber.title" title="Předčíslí" [required]="form.get('accountPreNumber') | hasRequiredField">
      <config-setting input="accountPreNumber" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('accountPreNumber').invalid && (form.get('accountPreNumber').dirty || form.get('accountPreNumber').touched)">
        {{formHelper.getErrorMessage(form.get('accountPreNumber'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>



  <ng-container *ngIf="formHelper.isVisible(form.get('accountNumber'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.accountNumber.label">Číslo účtu</mat-label>
      <input matInput formControlName="accountNumber" id="accountNumber" name="accountNumber" i18n-title="@@UserProfile.accountNumber.title" title="Číslo účtu" [required]="form.get('accountNumber') | hasRequiredField">
      <config-setting input="accountNumber" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('accountNumber').invalid && (form.get('accountNumber').dirty || form.get('accountNumber').touched)">
        {{formHelper.getErrorMessage(form.get('accountNumber'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>



  <ng-container *ngIf="formHelper.isVisible(form.get('accountBank'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.accountBank.label">Banka</mat-label>
      <popup-list formControlName="accountBank" id="accountBank" name="accountBank"
                  [staticOptions]="" [dataSource]="bankCodesService" [filterDb]='false'
                  [defaultFilters]="popupCondition"
                  i18n-title="@@UserProfile.accountBank.title" title="Banka"
                  [required]="form.get('accountBank') | hasRequiredField"
      >
      </popup-list>
      <config-setting input="accountBank" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('accountBank').invalid && (form.get('accountBank').dirty || form.get('accountBank').touched)" >
        {{formHelper.getErrorMessage(form.get('accountBank'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>



  <ng-container *ngIf="formHelper.isVisible(form.get('accountIban'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.accountIban.label">Číslo účtu ve formátu IBAN</mat-label>
      <input matInput formControlName="accountIban" id="accountIban" name="accountIban" i18n-title="@@UserProfile.accountIban.title" title="Číslo účtu ve formátu IBAN" [required]="form.get('accountIban') | hasRequiredField">
      <config-setting input="accountIban" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('accountIban').invalid && (form.get('accountIban').dirty || form.get('accountIban').touched)">
        {{formHelper.getErrorMessage(form.get('accountIban'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>



  <ng-container *ngIf="formHelper.isVisible(form.get('accountSpecSym'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.accountSpecSym.label">Specifický symbol</mat-label>
      <input matInput formControlName="accountSpecSym" id="accountSpecSym" name="accountSpecSym" i18n-title="@@UserProfile.accountSpecSym.title" title="Specifický symbol" [required]="form.get('accountSpecSym') | hasRequiredField">
      <config-setting input="accountSpecSym" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('accountSpecSym').invalid && (form.get('accountSpecSym').dirty || form.get('accountSpecSym').touched)">
        {{formHelper.getErrorMessage(form.get('accountSpecSym'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>



  <ng-container *ngIf="formHelper.isVisible(form.get('sipo'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.sipo.label">SIPO</mat-label>
      <input matInput formControlName="sipo" id="sipo" name="sipo" i18n-title="@@UserProfile.sipo.title" title="SIPO" [required]="form.get('sipo') | hasRequiredField">
      <config-setting input="sipo" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('sipo').invalid && (form.get('sipo').dirty || form.get('sipo').touched)">
        {{formHelper.getErrorMessage(form.get('sipo'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>



  <ng-container *ngIf="formHelper.isVisible(form.get('agreementName'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.agreementName.label">Číslo smlouvy</mat-label>
      <input matInput formControlName="agreementName" id="agreementName" name="agreementName" i18n-title="@@UserProfile.agreementName.title" title="Číslo smlouvy" [required]="form.get('agreementName') | hasRequiredField">
      <config-setting input="agreementName" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('agreementName').invalid && (form.get('agreementName').dirty || form.get('agreementName').touched)">
        {{formHelper.getErrorMessage(form.get('agreementName'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>



  <ng-container *ngIf="formHelper.isVisible(form.get('accountVarSym'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.accountVarSym.label">Variabilní symbol</mat-label>
      <input matInput formControlName="accountVarSym" id="accountVarSym" name="accountVarSym" i18n-title="@@UserProfile.accountVarSym.title" title="Variabilní symbol" [required]="form.get('accountVarSym') | hasRequiredField">
      <config-setting input="accountVarSym" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('accountVarSym').invalid && (form.get('accountVarSym').dirty || form.get('accountVarSym').touched)">
        {{formHelper.getErrorMessage(form.get('accountVarSym'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>
</form>
