<mat-toolbar role="heading" [ngClass]="{'on-handset': session.isMobile, 'main-toolbar': true}">
  <mat-toolbar-row (click)="closeMenus()">
    <button #drawerButton type="button" mat-icon-button (click)="scnav.drawer.toggle()" *ngIf="session.isMobile">
      <mat-icon>menu</mat-icon>
    </button>
    <!-- <ng-content select="h1,h2,[heading]"></ng-content> -->
    <span class="fill-horiz-space"></span>
    <!-- <ng-content select="[actions]"></ng-content> -->

    <button
      *ngIf="ucVarSymbol !== null"
      mat-button
      i18n-matTooltip="@@menu.unicredit.hint.button"
      matTooltip="Zůstatek kreditu"
      [routerLink]="['/unicredit']"
      class="toolbar-credit-button"
      >
      <mat-icon>account_balance_wallet</mat-icon>
      <span [ngClass]="{'mango-text-warn': unicreditBalance < 1}">{{unicreditBalance | currency:currencySymbol}}</span>
    </button>

    <div
      id="toolbar-new-process-control"
      (click)="$event.stopPropagation()">
      <button
        mat-icon-button
        *ngIf="session.canView('process') && processTypes?.length > 0"
        [matMenuTriggerFor]="newProcess"
        #newProcessTrigger="matMenuTrigger"
        i18n-matTooltip="@@menu.newProcess.hint.button"
        matTooltip="Nový požadavek"
        (menuClosed)="onMenuClosed(newProcessTrigger)"
        (menuOpened)="onMenuOpen(newProcessTrigger)">
        <mat-icon>quickreply</mat-icon>
      </button>
      <mat-menu
        #newProcess="matMenu"
        xPosition="before"
        backdropClass="sc-main-toolbar-right-menu-backdrop">
        <button mat-menu-item *ngFor="let processType of processTypes" (click)="openProcess(0, processType.id)">
          {{processType.name}}
        </button>
      </mat-menu>
    </div>

    <div
      id="toolbar-processes-control"
      (click)="$event.stopPropagation()">
      <button
        *ngIf="session.canView('process')"
        mat-icon-button
        [matMenuTriggerFor]="processes"
        #processesTrigger="matMenuTrigger"
        i18n-matTooltip="@@menu.processes.hint.button"
        matTooltip="Požadavky"
        (menuClosed)="onMenuClosed(processesTrigger)"
        (menuOpened)="onMenuOpen(processesTrigger)">
        <mat-icon [matBadgeHidden]="unresolvedProcesses === 0" matBadgeColor="warn" [matBadge]="unresolvedProcesses.toString()" matBadgeOverlap="true">question_answer</mat-icon>
      </button>
      <mat-menu
        #processes="matMenu"
        xPosition="before"
        backdropClass="sc-main-toolbar-right-menu-backdrop">
        <button mat-menu-item *ngFor="let process of activeProcesses" (click)="openProcess(process.id, null)">
          <div class="active-process-button">
            <div class="active-process-name">#{{process.id}}&nbsp;{{process.name}}
              <span class="active-process-last-time">{{transFormLastActivity(process)}}</span>
            </div>
          </div>
        </button>
        <div mat-menu-item disabled *ngIf="!(activeProcesses && activeProcesses.length)"
             i18n="@@menu.processes.empty.caption"
        >Nic k vyřízení</div>
        <mat-divider></mat-divider>
        <button mat-menu-item class="all-process-list" [routerLink]="['/process']" routerLinkActive="active"
             i18n="@@menu.processes.button.all.caption"
        >Všechny procesy</button>
      </mat-menu>
    </div>

    <div
      (click)="$event.stopPropagation()">
      <button
        mat-button
        #userTrigger="matMenuTrigger"
        [matMenuTriggerFor]="user"
        i18n-matTooltip="@@menu.userProfile.hint.button"
        matTooltip="Můj profil"
        (menuClosed)="onMenuClosed(userTrigger)"
        (menuOpened)="onMenuOpen(userTrigger)">
        <mat-icon>account_box</mat-icon><span class="toolbar-user-name">{{session.user.fullname}}</span>
      </button>
      <mat-menu
        #user="matMenu"
        xPosition="before"
        backdropClass="sc-main-toolbar-right-menu-backdrop">
        <button mat-menu-item *ngIf="session.canView('user-profile')" [routerLink]="['/user-profile']" routerLinkActive="active" i18n="@@menu.user-profile">Můj profil</button>
        <button mat-menu-item (click)="logout()" i18n="@@menu.logout">Odhlásit se</button>
      </mat-menu>
    </div>

  </mat-toolbar-row>
</mat-toolbar>
