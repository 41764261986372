import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {SessionService} from './session.service';
import {InstallationConfigService} from './installation-config.service';

export interface Address {
  id: string;
  name: string;
  street?: string;
  houseId?: number;
  city?: string;
  zip?: number;
}

@Injectable({
  providedIn: 'root'
})
export class SearchAddressService {

  private httpClient: HttpClient;
  private country: string;

  // Http client se vytváří tímto způsobem, aby byl request ignorován session interceptorem
  // (pokud přidám do requestu X-Session-token backend mi nevrátí odpověď).
  // Opsáno: https://stackoverflow.com/questions/46469349/how-to-make-an-angular-module-to-ignore-http-interceptor-added-in-a-core-module
  constructor( public handler: HttpBackend,
               private session: SessionService,
               private installationConfigService: InstallationConfigService,
  ) {
    this.httpClient = new HttpClient(handler);
    if (this.session.user){
      this.country = this.session.user.locDefaults.countryCode;
    }else{
      this.installationConfigService.getLocDef().subscribe(locDef => {
        this.country = locDef.countryCode;
      });
    }
  }

  findAddress(text: string): Observable<Address[]>{
    if (!this.country){
      // Toto by snad nemělo nastávat.
      return of([]);
    }
    const url = environment.addressSearchUrl +
      '/autocomplete?layers=address&lang=cs-cz&boundary.country=' + this.country + '&text='
      + text;
    return this.httpClient.get(url)
      .pipe(map((data: any) => {
        const addresses: Address[] = [];
        data.features.forEach(feature => {
          addresses.push({
            id: feature.properties.id,
            name: feature.properties.name,
            street: feature.properties.street,
            houseId: feature.properties.housenumber,
            city: feature.properties.locality,
            zip: feature.properties.postalcode,
          });
        });
        return addresses;
      }));

  }
}
