import { Injectable } from '@angular/core';
import {ProtectedDataItemsCategories} from '../_services/protected-data-items-categories.service';
import {SessionService} from '../_services/session.service';
import {FormGroup} from '@angular/forms';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  ProtectedDataCategory,
  ProtectedDataCategoryPublicService,
  ProtectedDataCategoryService
} from '../_services/protected-data-category';
import {ProtectedDataItemsCategoriesRegistration} from '../_services/protected-data-items-categories-registration.service';
import {UserType} from '../_services/user-edit.service';

@Injectable({
  providedIn: 'root'
})
export class UserEditProtectedService {

  public protectedDataCategory: ProtectedDataCategory[];
  public protectedData: {};
  public savedConsent: number[] = []; // Zde si při registraci ukládám ID kategorií, ke kterým byl udělen souhlas

  constructor(
    public session: SessionService,
    private protectedDataItemsCategories: ProtectedDataItemsCategories,
    private protectedDataItemsCategoriesRegistration: ProtectedDataItemsCategoriesRegistration,
    private protectedDataCategoryService: ProtectedDataCategoryService,
    private protectedDataCategoryServicePublic: ProtectedDataCategoryPublicService,
  ) {
  }

  /**
   * Pole kategorií chráněných dat, které se vztahují k danému poli formulářů
   * tj. prvky v poli formulářů jsou z těchto chráněných kategorií.
   */
  public getRelevantCategories(editForms: FormGroup[]): Observable<ProtectedDataCategory[]> {
    return forkJoin([
      this.getCategories(),
      this.getRelevantCategoriesIds(editForms)
    ]).pipe(map(results => {
      return results[0]
          .filter(category => results[1].includes(category.catId));
    }));
  }

  /**
   * Ověří, že ke každému itemu ve formuláři existuje alespoň jedna kategorie, ke které je udělen souhlas.
   */
  public validConsentForItem(item: string, editForms: FormGroup[], userType: UserType): Observable<boolean> {
    return forkJoin([
      this.getCategories(),
      this.getItemsForForm(editForms)
    ]).pipe(map(results => {
      const itemCategories = results[1][item];
      if (!itemCategories || itemCategories.length < 1) {
        // Pokud item není u žádné kategorie,
        return true;
      }

      // Některé kategorie nejsou pro daný typ uživatele aktuální, vyfiltruji si tedy jen ty, které ano
      const relevantCategories = results[0].filter(category =>
        itemCategories.includes(category.catId) &&
        ((!category.userType || !category.userType.length || !userType) ? true : category.userType.includes(userType))
      );
      if (relevantCategories.length < 1){
        return true;
      }
      // Vyfiltruji všechny kategorie, které jsou u daného itemu a nevyžadují souhlas,
      // nebo mají souhlas udělen.
      const categoriesWithConsent = relevantCategories.filter(category =>
            itemCategories.includes(category.catId)
            && (category.catLawfulness !== 1 || category.catConsent)
        );
      return categoriesWithConsent.length > 0;
    }));
  }

  /**
   * Všechny kategorie chráněných dat pro danou lokaci.
   */
  private getCategories(): Observable<ProtectedDataCategory[]> {
    if (this.session.user) {
      return this.protectedDataCategoryService.getall({}, 3600)
          .pipe(map( data => data.data));
    } else {
      return this.protectedDataCategoryServicePublic.getall({}, 3600)
          .pipe(map(data => data.data));
    }
  }

  /**
   *  Vrací objekt, ve kterém jsou klíčem prvky ze všech formulářů pro editaci zákazníka a
   *  hodnotou id kategorie.
   */
  private getItems(items: string[]): Observable<{}> {
    if (this.session.user) {
      return  this.protectedDataItemsCategories.getSingleton({
            'items[]': items,
            form: 'eu-user'
          }
          , 3600);
    } else {
      return this.protectedDataItemsCategoriesRegistration.getSingleton({
            'items[]': items,
          }
          , 3600);
    }
  }

  /**
   *  Pole id kategorií, které se vztahují k danému poli formulářů.
   */
  private getRelevantCategoriesIds(editForms: FormGroup[]): Observable<number[]> {
    return this.getItemsForForm(editForms).pipe(map(items => {
      let protectedCategoryItems = [];
      Object.values(items).forEach(categories => {
        protectedCategoryItems = protectedCategoryItems.concat(categories);
      });
      return protectedCategoryItems;
    }));
  }

  private getItemsForForm(editForms: FormGroup[]): Observable<{}> {
    let allItems = [];
    editForms.forEach(formGroup => {
      allItems = allItems.concat((Object as any).keys(formGroup.controls));
    });
    return this.getItems(allItems);
  }

  public resetSavedConsent(): void {
    this.savedConsent = [];
  }

  public addConsent(category: number): void {
    this.savedConsent.push(category);
  }

  public getSavedConsent(): number[] {
    return this.savedConsent;
  }
}
