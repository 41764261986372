import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, AnimationEvent, style, transition, trigger} from '@angular/animations';
import {ForgetTableComponent} from '../forget-table/forget-table.component';
import {DeviceDetectorService} from "ngx-device-detector";
import {interval} from "rxjs";
import {takeWhile} from "rxjs/operators";

// XXX fixing vs


export interface ColumnDefinition {
  role: string;
  title: string;
  nativeElement?: any;
}

/**
 * Direktiva, která tvoří společného "předka" pro věci,
 * které mohou poslytovat data pro auto-detail - mgt-col
 * a mgt-cell.
 */
export class SomethingLikeColumn {
  public ElementRef: any;
  public title: any;
  public role: any;
}

@Component({
  selector: 'mgt-row',
  template: `
    <div (click)="rowClick($event)"
       [ngClass]="{
        expandable: expandable,
        expanded: expanded,
        'has-replacing-detail': replacingDetail,
        'non-replacing-detail': !replacingDetail,
        'mobile': mobile,
        'expand-finished': expandFinished,
        'collapse-finished': collapseFinished,
        'has-expand-button': true,
        'forget-table-row': true,
        'group-first': groupFirst,
        'group-last': groupLast
      }">
      <!--
        Podminka na zobrazeni rozbalovaci sipky drive:
        expandable && (mobile || expanded || !hasActions)
        Podminka na tridu has-expand-button byla: mobile || !this.hasActions,
       -->
      <div *ngIf="expandable" class="expand-btn">
        <button mat-icon-button (click)="toggleExpanded($event)">
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>

      <div
        *ngIf="(!replacingDetail || (!expanded && collapseFinished))"
        class="cell-groups">
        <!-- fxLayout.xs="column" -->
        <!-- Sloupečky postaru -->
        <ng-content select="mgt-col"></ng-content>
        <!-- Sloupečkové skupiny údajů ponovu -->
        <ng-content select="mgt-ico,mgt-cell-group"></ng-content>
      </div>

      <div
        *ngIf="expanded"
        [@enterAnimation]
        (@enterAnimation.start)="animCallback($event)"
        (@enterAnimation.done)="animCallback($event)"
        >
        <ng-content select="[detail]"></ng-content>
      </div>
      <div (click)="$event.stopPropagation()">
        <ng-content select="mgt-actions"></ng-content>
      </div>
    </div>
    `,
  styles: [`
    .collapse-finished ::ng-deep mgt-actions {
      display: none;
    }
    .forget-table-row .cell-groups {
      display: flex;
    }
    .forget-table-row:not(.mobile):not(.collapse-finished) ::ng-deep mgt-actions {
      display: block;
      text-align: right;
      /* Od upravy pro Edge nema padding cely radek, ale detail nad nami. */
      padding: 0 20px 5px;

      position: relative;
      right: 0px;
    }
    .forget-table-row.mobile:not(.collapse-finished) ::ng-deep mgt-actions .envelope {
      height: 6em;
      margin-top: 16px;
      position: relative;
    }
    .forget-table-row.mobile:not(.collapse-finished) ::ng-deep mgt-actions .envelope:not(.scrollable) .container {
      display: flex;
      justify-content: center;
    }
    .forget-table-row.mobile:not(.collapse-finished) ::ng-deep mgt-actions .envelope.scrollable .container {
      position: absolute;
      display: block;
      white-space: nowrap;
    }
    .forget-table-row ::ng-deep .actions-scroll-left {
      position: absolute;
      height: 6em;
      top: 0;
      left: -1000px;
      width: 1000px;
      background: -moz-linear-gradient(   left, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.00) 20px, rgba(0,0,0,0.22) 20px, rgba(0,0,0,0.22) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.00) 20px, rgba(0,0,0,0.22) 20px, rgba(0,0,0,0.22) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(     to left, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.00) 20px, rgba(0,0,0,0.22) 20px, rgba(0,0,0,0.22) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .forget-table-row ::ng-deep .actions-scroll-left .actions-scroll-arrow {
      width: 0;
      height: 0;
      border-top: 1.5em solid transparent;
      border-right: 10px solid rgba(0,0,0,0.08);
      border-bottom: 1.5em solid transparent;
      position: relative;
      top: 1.5em;
      left: 985px;
    }
    .forget-table-row ::ng-deep .actions-scroll-right {
      position: absolute;
      height: 6em;
      top: 0;
      right: -1000px;
      width: 1000px;
      background: -moz-linear-gradient(   right, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.00) 20px, rgba(0,0,0,0.22) 20px, rgba(0,0,0,0.22) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(right, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.00) 20px, rgba(0,0,0,0.22) 20px, rgba(0,0,0,0.22) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(     to right, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.00) 20px, rgba(0,0,0,0.22) 20px, rgba(0,0,0,0.22) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .forget-table-row ::ng-deep .actions-scroll-right .actions-scroll-arrow {
      width: 0;
      height: 0;
      border-top: 1.5em solid transparent;
      border-left: 10px solid rgba(0,0,0,0.08);
      border-bottom: 1.5em solid transparent;
      top: 1.5em;
      left: 5px;
      position: relative;
    }
    .forget-table-row.mobile:not(.collapse-finished) ::ng-deep mgt-actions .mat-button {
      display: block;
      flex: 1 0 30%;
      min-width: 25vw; /* Když jsou 2 tlačítka, tak vypadají lépe u sebe v prostřed, než roztažena na 2x50% */
      max-width: 25vw; /* Samotný width nějak nefunguje s flex */
      margin: 0;
      padding: 0;
      height: 6em;
      line-height: 1em;
      border-radius: 3px;
      background-color: transparent;
    }
    .forget-table-row.mobile:not(.collapse-finished) ::ng-deep mgt-actions .scrollable .mat-button {
      display: inline-block;
    }
    .forget-table-row.mobile:not(.collapse-finished) ::ng-deep mgt-actions .mat-button mat-icon {
      display: block;
      margin: 0px auto 8px;
      font-size: 36px;
      width: 36px;
      height: 36px;
      opacity: 0.5;
    }
    /* U tlačítek v akcích mezeru mezi ikonou a textem */
    .forget-table-row ::ng-deep mgt-actions .mat-button mat-icon {
      margin-right: 8px;
    }
  `],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({'max-height': 0, opacity: 0}),
          animate('500ms ease-in', style({'max-height': '1000px', opacity: 1}))
        ]),
        transition(':leave', [
          style({'max-height': '1000px', opacity: 1}),
          animate('500ms ease-out', style({'max-height': 0, opacity: 0}))
        ])
      ]
    )
  ],
})
export class ForgetTableRowComponent implements OnInit {
  @Input()
  public expandable: boolean = true;

  @Input()
  public expanded: boolean = false;

  @Input("replacing-detail")
  public replacingDetail: boolean = false;

  @Input()
  public groupFirst: boolean = false;

  @Input()
  public groupLast: boolean = false;

  // TODO: dodělat, součást pokusu o workaround divného vs
  @Input("record")
  public rowData: any = null;

  @Output()
  public expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Potřebujeme vědět, jestli máme řádkové akce nebo ne -
  // pokud ne, zobrazujeme rozbalovací tlačítko vpravo ve
  // sbaleném řádku. Nemůžeme to zjišťovat pomocí ViewChild,
  // protože to zakládá kruhovou závislost (protože MgtActions
  // injectují nás). Takže MgtActions necháme, aby nás o své
  // přítomnosti informovaly nastavením téhle proménné ze
  // svého ngInitu.
  public hasActions = false;

  /* Informace od deviceDetectorService, že jsme na mobilu. */
  public mobile: boolean = false;

  /* Některé věci se musí měnit až na konci rozbalovací/sbalovací
   * animace, ne hned se změnou proměnné, která dá impuls k jejímu
   * začátku - proto proměnné {collapse|expand}Finished a callback
   * na průběh animace.
   */
  public collapseFinished = !this.expanded;
  public expandFinished = this.expanded;

  animCallback(event: AnimationEvent) {
    if (event.toState === "void") {
      if (event.phaseName === "start") {
        this.expandFinished = false;
      } else if (event.phaseName === "done") {
        this.collapseFinished = true;
      }
    } else if (event.fromState === "void") {
      if (event.phaseName === "start") {
        this.collapseFinished = false;

        // XXX VirtualScroller vorkaround, viz komentář dole v této funkci.
        interval(100)
          .pipe(takeWhile(() => !this.expandFinished))
          .subscribe(val => this.refreshVScrollerWhileAnimating());
      } else if (event.phaseName === "done") {
        this.expandFinished = true;
      }
    }
    // Workaround toho, že nám vscroller nejde dobře dohromady
    // s animacemi (při expandování řádku v tabulkce, která se při
    // sbalených řádcích vejde na obrazovku, tabulka zůstala krátká).
    this.refreshVScrollerWhileAnimating();
  }

  constructor(
    private deviceDetector: DeviceDetectorService,
    private ownerTable: ForgetTableComponent,
    private elementRef: ElementRef,
  ) {
    this.mobile = deviceDetector.isMobile();
  }

  ngOnInit() {
    // When we are scrolled offscreen in VirtualScroller, we cease to exist.
    // Thus we try to save information about our expand state along the data,
    // so that we can reconstruct it from there when we are scrolled back
    // and recreated.
    if (this.rowData && this.rowData.__rowExpanded !== undefined) {
      this.expanded = this.rowData.__rowExpanded;
    }
  }

  ngAfterContentInit() {
  }

  /**
   * Handles the click anywhere in the row (expanding it if enabled and in collapsed state).
   */
  rowClick(event) {
    // if (this.expandable && !this.expanded) {
    //  this.setOrToggleExpanded(true);
    // }
    // ROSA ze SOMA je OSEL (no dobře, není, ale všechno to jsou čtyřpísmenná slova
    // a tak se k sobě hodí; i tak ale ten požadavek uvážený) a přeje si, aby se
    // rozbalená řádka opětovně sbalila při kliku kamkoli. Tak to vyzkoušíme.
    //
    if (this.expandable) {
      this.setOrToggleExpanded();
    }
  }

  toggleExpanded(event) {
    this.setOrToggleExpanded();
    event.stopPropagation();
  }

  setOrToggleExpanded(val?: boolean) {
    if (val === undefined) {
      val = !this.expanded;
    }
    this.expandedChange.emit(val);
    this.expanded = val;
    // When we are scrolled offscreen in VirtualScroller, we cease to exist.
    // Thus we try to save information about our expand state along the data,
    // so that we can reconstruct it from there when we are scrolled back
    // and recreated.
    if (this.rowData) {
      this.rowData.__rowExpanded = val;
    } else {
      console.warn("No 'record' attribute with row data on expandable row. Troubles with expanding rows near bottom of the screen are to be expected");
    }

    // Pokus zascrollovat na začátek - ještě rozhodnout, s těmi animacemi to blbne
    /*
    if (val) {
      if (this.rowData) {
        this.ownerTable.vscroller.scrollInto(this.rowData, true, -30, 0);
      }
    }
     */
  }

  refreshVScrollerWhileAnimating() {
    if (this.rowData) {
      this.ownerTable.vscroller.invalidateCachedMeasurementForItem(this.rowData);
    } else {
      this.ownerTable.vscroller.invalidateAllCachedMeasurements();
    }
  }

  /**
   * Přihlášení sloupečku
   *
   * S mgt-cell vnořenými do mgt-cell-group nejde automatický detail dělat
   * starým mechanizmem s ContentChild (šlo by sice nastavovat příznak descendants,
   * ale to by vzalo i sloupečky tabulek vnořených do detailu).
   * Tohle je proto metoda, kterou se sloupeček sám přihlásí své tabulce.
   *
   */
  columnSelfregister(title, role, nativeElement) {
    this.columns.push({ title: title, role: role, nativeElement: nativeElement});
  }

  public columns = [];
}
