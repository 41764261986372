import {Injectable} from '@angular/core';
import {PopupOption} from "@og_soft/popup-list-control";
import {DataBase} from "@og_soft/data-base";

export interface ProcessServices {
  saId: number;
  saName: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataProcessServices extends DataBase<ProcessServices> {
  // getallUrl = "process-services";

  protected getAllUrl(): string {
    return 'process-services' as const;
  }

  tranformOptions(row): PopupOption {
    return {id: row.saId, name: row.saName};
  }
}
