import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SessionService} from '../_services/session.service';
import {UnavailableService} from '../core/unavailable.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private session: SessionService,
    private unavailable: UnavailableService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      // Chyby vznikají FRONTEND/trunk/api/v1/lib/rest-api.php MyResource->handle

      // Zde je obecná reakce na různé http error kódy.
      // Kromě 403 jde všude jen o vypsání chybové hlášky. Z interceptoru potom chyba pokračuje do observable,
      // kde bude vyhodnocena v části zachytávající chyby. Tam je možné chybovou hlášku přepsat.
      switch (err.status) {
        case 400:
          // Obecná chyba, blíže nespecifikovaná (např výsledek procedury). Vypíšu obecnou chybovou hlášku.
          this.session.message(
            $localize`:@@ErrorInterceptor.error.400.message:Při zpracování požadavku došlo k chybě. Zkuste to prosím později.`
          );
          break;
        case 401:
          if (this.session.isLoggedIn()) {
            this.session.message(
              $localize`:@@ErrorInterceptor.error.401.message:Z bezpečnostních důvodů bylo provedeno odhlášení.`
            );
            console.log('Došlo k odhlášení nebo se nepodařilo přihlásit.', err);
            this.session.setLoggedOut(true);
          } else {
            // Pokud jsme poslal více requestů ve chvíli kdy mi vypršela session a nejsem přihlášený,
            // odhlásím se už při návratu prvního requestu.
            // Další requesty už tedy zahodím.
            return of(null);
          }
          break;
        case 403:
          // Chybějící práva - vypíšu hlášku a vyvolám chybu
          // Toto je chyba aplikace, aplikace nesmí dovolit poslání requestu, kde mě chybí práva
          this.session.message(
            $localize`:@@ErrorInterceptor.error.403.message:Při zpracování požadavku došlo k chybě. Zkuste to prosím později.`
          );
          console.error('Missing rights for request ', err);
          break;
        case 404:
          // Nenalezen endpoint. Vypíšu obecnou chybovou hlášku.
          this.session.message(
            $localize`:@@ErrorInterceptor.error.404.message:Při zpracování požadavku došlo k chybě. Zkuste to prosím později.`
          );
          break;
        case 405:
          // Nenalezena metoda endpointu. Vypíšu obecnou chybovou hlášku.
          this.session.message(
            $localize`:@@ErrorInterceptor.error.405.message:Při zpracování požadavku došlo k chybě. Zkuste to prosím později.`
          );
          break;
        case 409:
          // Konflikt - nelze zpracovat požadavek kvůli konfliktu na serveru.
          // Je nutné upravit zadání požadavku (mělo by být řešitelné validátory).
          this.session.message(
              $localize`:@@ErrorInterceptor.error.409.message:Při zpracování požadavku došlo k chybě. Zkuste to prosím později.`
            );
          break;
        default:
        // Neošetřené případy řešíme jako chybu na straně serveru.
        case 500:
          // Chyba na straně serveru.
          // Uživatel s tím nic neudělá, prostě to nefunguje a musíme to opravit.
          this.session.message(
            $localize`:@@ErrorInterceptor.error.500.message:Při zpracování požadavku došlo k chybě. Zkuste to prosím později.`
          );
          break;
        case 503:
          // Dočasná nedostupnost serveru.
          // Znamená to, že pravděpodobně nefunguje celá databáze.
          // this.session.message(
          //   $localize`:@@ErrorInterceptor.error.503.message:Při zpracování požadavku došlo k chybě. Zkuste to prosím později.`
          // );
          // Zajistím zvednutí dialogu se stránkou o nedostupnosti.
          this.unavailable.startUnavailable();
          return of(null);
      }
      return throwError(err); // Pokračuji ve zpracování v místě kde jsem request poslal;
    }));
  }
}
