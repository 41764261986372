import {Component, ElementRef, HostBinding, Input, LOCALE_ID, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NgControl} from "@angular/forms";
import {DeviceDetectorService} from "ngx-device-detector";
import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Subject} from "rxjs";
import {DatePipe} from '@angular/common';
import {debounceTime} from 'rxjs/operators';
import {MatLegacyFormFieldControl as MatFormFieldControl} from '@angular/material/legacy-form-field';

@Component({
  selector: 'date-control',
  templateUrl: './date-control.component.html',
  styleUrls: ['./date-control.component.scss'],
  providers: [
    {provide: MatFormFieldControl, useExisting: DateControlComponent}
  ]
})
/**
 * Třída společná pro item pro datum i pro datum a čas.
 * Jako hodnota se dosazuje datum přímo z databáze ve formátu "YYYY-MM-DD" resp. "YYYY-MM-DD hh:mm:ss"
 * Item si dosazení hodnoty sám hlídá.
 *
 * Atribut displayTime rozlišuje jestli se jedná o item s datem a časem, nebo pouze s časem.
 */
export class DateControlComponent implements MatFormFieldControl<string>, ControlValueAccessor, OnInit {
  public _dateValue: string = null; // zde si držím vnitřní hodnotu
  @Input() displayTime: boolean;

  form: FormGroup; // Toto je pouze vnitřní komponenta, pro ovládání inputu. Nemá nic společného s formulářem, ve kterém je popuplist umístěný

  public maskDate = {
    guide: true,
    showMask: true,
    mask: [/[0-3]/, /\d/, '.', /[0-1]/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
    placeholderChar: '-'
  };

  //*********************** Blok metod a atributů, kvůli implementaci rozhraní MatFormFieldControl
  private _required = false;

  @ViewChild('inpElement', {static: false}) inpElement: ElementRef;
  @ViewChild('timeInput', {static: false}) timeInput: ElementRef;

  @Input()
  get value(): string {
    return this._dateValue;
  }

  set value(val: string) {
    //Hodnota může být buď null, nebo iso formát, vše ostatní zahlásí chybu.
    if (
      (val === null) ||
      (this.displayTime && (/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/ as RegExp).test(val)) ||
      (!this.displayTime && (/\d{4}-[01]\d-[0-3]\d/ as RegExp).test(val))
    ) {
      this._dateValue = val;
      this.stateChanges.next();
      this.propagateChange(val);
    } else {
      throw "Neplatná hodnota data" + (this.displayTime ? " a času" : "") + ": " + val + ". Hodnotu lze zadávat pouze ve formátu YYYY-MM-DD" + (this.displayTime ? " hh:mm:ss" : "") + ".";
    }
  }

  stateChanges = new Subject<void>();

  static nextId = 0;

  @HostBinding() id = `date-${DateControlComponent.nextId++}`;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  private _placeholder: string;

  focused = false;

  get empty() {
    return (this._dateValue === null || this._dateValue === undefined);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    if (this.deviceService.isMobile()) {
      return this.focused || !this.empty;
    } else {
      return true;
    }
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.form.controls.inputDate.disable();
      this.form.controls.inputTime.disable();
      this.form.controls.calendarDate.disable();
    } else {
      this.form.controls.inputDate.enable();
      this.form.controls.inputTime.enable();
      this.form.controls.calendarDate.enable();
    }
    this.stateChanges.next();
  }

  private _disabled = false;

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  //FIXME: Při rozkliknutí popuplistu to okamžitě vyhodnotí jako nevalidní. Touched mi nezafungovalo.
  get errorState() {
    return this.ngControl.errors !== null && !!this.ngControl.dirty;
  }

  controlType = 'date-control';

  @HostBinding('attr.aria-describedby') describedBy = '';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  //*********************** KONEC Blok metod a atributů, kvůli implementaci rozhraní MatFormFieldControl

  constructor(
    public deviceService: DeviceDetectorService,
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    public datepipe: DatePipe
  ) {

    //*********************** Kvůli implementaci rozhraní MatFormFieldControl
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
    //*********************** KONEC Kvůli implementaci rozhraní MatFormFieldControl

    this.form = new FormGroup(
      {
        inputDate: new FormControl(),
        inputTime: new FormControl(),
        calendarDate: new FormControl(),
      });
  }

  ngOnInit() {
    if (this.deviceService.isMobile()) {
      this.form.controls.inputDate.valueChanges
        .subscribe(value => {
          if (value) {
            if (this.displayTime) {
              this.value = value + ":00";
            } else {
              this.value = value;
            }
          } else {
            this.value = null;
          }
        })

    } else {
      /**
       * Obsluha změny data
       * Pokud mám nsatvené zobrazení času, ukládám hodnotu jen v případě, že je i čas validně nastavený.
       *
       */
      this.form.controls.inputDate.valueChanges
        .pipe(debounceTime(300))
        .subscribe(value => {
          if (value) {

            //Pokud mám nastavený čas, abych o něj nepřišel, musím si ho vytáhnout taky
            let timeString = "";
            if (this.displayTime) {
              let timeValue = this.form.controls.inputTime.value;
              if (timeValue) {
                let time = timeValue.split(":");
                timeString = "T" + time[0] + ":" + time[1] + ":00";
              }
            }

            let date = value.split('.');
            if (!isNaN(date[0]) && !isNaN(date[1]) && !isNaN(date[2]) && (!this.displayTime || timeString)) {
              this.value = date[2] + "-" + date[1] + "-" + date[0] + timeString;
            } else {
              this.value = null;
            }
          } else {
            this.value = null;
          }
        });

      /**
       * Obsluha změny času (pokud je nastavené zobrazení času).
       * Pokud nemám ještě nastavené datum, neukládám, čekám až bude validně nastavené.
       */
      this.form.controls.inputTime.valueChanges
        .subscribe(value => {
          if (value && this.displayTime) {
            let dateString = "";

            let date = this.form.controls.inputDate.value.split('.');
            if (!isNaN(date[0]) && !isNaN(date[1]) && !isNaN(date[2])) {
              dateString = date[2] + "-" + date[1] + "-" + date[0];
            }

            let time = value.split(":");
            if (!isNaN(time[0]) && !isNaN(time[0]) && dateString) {
              this.value = dateString + "T" + time[0] + ":" + time[1] + ":00";
            } else {
              this.value = null;
            }
          } else {
            this.value = null;
          }
        });

      /**
       * Obsluha vybrání data v kalendáři.
       * Pokud jsme pouze vybral datum, nastavím patřičný input (nastavení hodnoty se mi provede vyvoláním obsluhy
       * nastavení data výše.
       * Po výběru buď vyskočím z itemu, nebo, pokud edituji i čas, přejdu na editaci času.
       *
       */
      this.form.controls.calendarDate.valueChanges
        .subscribe(value => {
          let date = new Date(value);
          let dateString = this.datepipe.transform(date, "dd.MM.yyyy");
          this.form.controls.inputDate.setValue(dateString);
          //FIXME: toto nefunguje
          if (this.displayTime) {
            this.timeInput.nativeElement.focus();
          } else {
            this.inpElement.nativeElement.blur();
          }
        });
    }
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  //*********************** Kvůli implementaci rozhraní ControlValueAccessor
  writeValue(value: any) {
    if (this.deviceService.isMobile()) {
      if (value) {
        this.form.controls.inputDate.setValue(value);
        this.value = value;
      } else {
        this.value = null;
      }
    } else {
      if (value) {
        let date = new Date(value);
        console.log("LOCALE_ID", LOCALE_ID);
        let dateString = this.datepipe.transform(date, "dd.MM.yyyy");
        let timeString = this.datepipe.transform(date, "hh:mm");
        this.form.controls.inputDate.setValue(dateString);
        this.form.controls.inputTime.setValue(timeString);
        this.value = value;
      } else {
        this.value = null;
      }
    }
  }


  propagateChange = (_: any) => {
  };

  test: any;

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  //*********************** Konec kvůli implementaci rozhraní ControlValueAccessor

}
