import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';

export interface RecScMessage {
  id: number;
  content: string;
  title: string;
  subtitle: string;
  priority: string;
  validFrom: string;
  validTo: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataScDashboardMessagesService extends DataBase<RecScMessage> {
  // getallUrl = '/sc-dashboard-messages';

  protected getAllUrl(): string {
    return '/sc-dashboard-messages' as const;
  }
}
