import {Injectable} from '@angular/core';
import {DataParams} from "../_libraries/param-form/_services/data-params.service";
import {ParamBase, ParamObjectType} from '../_libraries/param-form/_models/param-base';
import {DataValueDomainPatterns} from "../_libraries/param-form/_services/data-value-domain-pattern";
import {DataCodeList} from "../_libraries/param-form/_services/data-code-list";
import {DataValueDomain} from "../_libraries/param-form/_services/data-value-domain";
import {DataProcessPremises} from "./process-premises.service";
import {DataProcessServices} from "./process-services.service";
import {DataProcessUnits} from "./process-units.service";




/**
 */
@Injectable({
  providedIn: 'root'
})
export class DataParamsServiceActive extends DataParams {
  // getallUrl = "service-active-params";

  protected getAllUrl(): string {
    return 'service-active-params' as const;
  }

  public transformParams(paramData: any, services: {
    dataValuePatterns: DataValueDomainPatterns;
    dataCodeList: DataCodeList;
    dataValueDomain: DataValueDomain;
    dataProcessPremises: DataProcessPremises;
    dataProcessServices: DataProcessServices;
    dataProcessUnits: DataProcessUnits;
  }) {
    let options = {
      id: paramData.id,
      defId: paramData.defId,
      value: paramData.value,
      defaultValue: paramData.defaultValue,
      objectType: ParamObjectType.Service,
      name: paramData.name + (paramData.indexed ? '_'+paramData.index : ''),
      indexName: paramData.name,
      caption: paramData.caption,
      description: paramData.description,
      required: paramData.required,
      order: paramData.order,
      type: paramData.type,
      disabled: paramData.disabled,
      indexed: paramData.indexed,
      index: paramData.index,
      domain: paramData.domain,
      hrValue: paramData.hrValue,
      visibilityDependency: paramData.visibilityDependency,
      editDependency: paramData.editDependency,
      requireDependency: paramData.requireDependency
    };

    return new ParamBase(options, services);
  }


  protected saHasParams(paramData: any) {

  }
}
