import {Injectable} from '@angular/core';
import {DataNodesService, RecNode} from './data-nodes.service';
import {HttpClient} from '@angular/common/http';
import {DataUserServices} from './data-user-services';
import {DataBaseServiceConfig, GetallResult} from '@og_soft/data-base';
import {CachingInterceptor} from '@og_soft/cache';
import {DataNodeParamsService} from '../nodes/data-node-params.service';

@Injectable({
  providedIn: 'root'
})
export class UserNodesService extends DataNodesService {

  constructor(public http: HttpClient,
              serviceConfig: DataBaseServiceConfig,
              public saService: DataUserServices,
              public dataNodeParamsService: DataNodeParamsService,
              private cachingInterceptor: CachingInterceptor,
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected dataPostprocess(data: GetallResult<RecNode>): GetallResult<RecNode> {
    // Pozor toto sice dotáhne data z dalších servis, ale je to asynchronní. Tedy v závislosti na rychlosti odezvy se
    // nejprve zobrazí data service první a až po čase se dotáhnou data service druhé.
    let ids: number[] = [];
    for (const node of data.data) {
      ids = ids.concat(node.saIds);
      // Dotažení parametrů. Tahat je  v samotném dotaze bylo příliš komplikované. tad yse naví data zacachují a
      // při otevření dialogu se nemusejí načítat znovu.
      // Zjistím si u toho i jestli má zařízení nějaké editovatelné parametry, kvůli zobrazení tlačítka na editaci.
      this.dataNodeParamsService.getParamsForView({id: node.id, defId: undefined}, 600)
        .subscribe(params => {
          node.params = params;
          let editable = false;
          for (const param of node.params){
            if (param.editable){
              editable = true;
            }
          }
          node.editable = editable;
        });
    }

    // Natažení služeb
    this.saService.getall({saIds: ids.filter((v, i, a) => a.indexOf(v) === i).join(',')})
      .subscribe(d => {
        data.data.map(rec => {
          for (const node of d.data) {
            if (rec.saIds.indexOf(node.saId) >= 0) {
              if (!rec.sa) {
                rec.sa = [];
              }
              rec.sa.push(node);
            }
          }
        });
        this.onDataCollected.emit();
        return data;
      });

    return super.dataPostprocess(data);
  }
}
