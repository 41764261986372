import {Injectable} from '@angular/core';
import {DataBase} from "@og_soft/data-base";
import {PopupOption} from "@og_soft/popup-list-control";

export interface ValueDomainPattern {
  ID: string;
  NAME: string;
}

@Injectable({
  providedIn: 'root'
})
/**
 * Třída vrací hodnoty z tabulky value_domain_patterns
 */
export class DataValueDomainPatterns extends DataBase<ValueDomainPattern> {
  // getallUrl = "domain-pattern";

  protected getAllUrl(): string {
    return 'domain-pattern' as const;
  }

  tranformOptions(row): PopupOption {
    return {id: row.ID, name: row.NAME};
  }

}
