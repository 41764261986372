<sc-main-toolbar>

<!--  <div actions>-->
<!--  </div>-->
</sc-main-toolbar>


<div class="mango-form-page-contents" *ngIf="!userData">
  <h1 i18n="@@menu.user-profile">Můj profil</h1>
  <page-loading></page-loading>
</div>

<div class="mango-form-page-contents user-profile-display" *ngIf="userData">
  <h1 i18n="@@menu.user-profile">Můj profil</h1>
  <sc-page-caption-block></sc-page-caption-block>

  <app-display-edit-pane
      formName="user-profile.view"
      [name]="USER_EDIT_FORM_TYPE.credentials"
      title="Přihlášení"
      i18n-title="@@UserProfile.credentials.title"
      subtitle="Údaje, jimiž se přihlašujete do zákaznické sekce"
      i18n-subtitle="@@UserProfile.credentials.subtitle"
      [can-edit]="true"
      i18n-edit-button-text="@@UserProfile.credentials.edit.button"
      edit-button-text="Změnit heslo"
      edit-button-icon="lock_open"
      (doEdit)="displayDialog($event)">


    <!--edit-button-text="{{i18n({value: 'Změna hesla', id: '@@UserProfile.credentials.edit.button'})}}"-->

      <div displayPaneItem="login" class="pane-column">
        <div i18n="@@UserProfile.login.label">Přihlašovací jméno</div>
        <div>{{userData.login}}</div>
      </div>

<!--    Toto by měl být funkční formulář na parametry - vytváří už hotové inputy. Pokud bude potřeba je jen zobrazit, -->
<!--    stačí si je vytáhnout userParamsService -->
<!--    <param-form [paramsService]="userParamsService" [form]="formIdent"-->
<!--                [paramFormName]="'userParamsForm'" [objectId]="{id: session.user.id}"-->
<!--                [dependencyService]="userParamsDependencyService"-->
<!--    ></param-form>-->

      <!--
      <div fxFlex style="text-align: right">
        <a mat-button href="#" (click)="displayDialog('credentials'); $event.preventDefault()"><mat-icon>lock_open</mat-icon> Změnit heslo</a>
      </div>
      -->
  </app-display-edit-pane>

  <app-display-edit-pane
      formName="user-profile.view"
      [name]="USER_EDIT_FORM_TYPE.contacts"
      title="Kontakt"
      i18n-title="@@UserProfile.contacts.title"
      subtitle="E-mailová adresa a telefonní čísla"
      i18n-subtitle="@@UserProfile.contacts.subtitle"
      [can-edit]="this.session.getOption('SELFCARE.user-profile.view.contacts.button.edit')"
      (doEdit)="displayDialog($event)">

      <div class="pane-column">
        <div displayPaneItem="emails">
          <div i18n="@@UserProfile.emails.label">E-mail</div>
          <div>{{userData.emails}}</div>
        </div>
        <div displayPaneItem="phones">
          <div i18n="@@UserProfile.phones.label">Telefonní čísla</div>
          <div>{{userData.phones}}</div>
        </div>
        <div displayPaneItem="fax" *ngIf="userData.fax">
          <div i18n="@@UserProfile.phones.label">Fax</div>
          <div>{{userData.fax}}</div>
        </div>
      </div>

      <div class="pane-column">
        <div displayPaneItem="infoChannelsAsText">
          <div i18n="@@UserProfile.infoChannelsAsText.label">Jak se s Vámi dorozumíváme</div>
          <div>{{infoChannelsAsText}}</div>
        </div>
        <div displayPaneItem="infoMarketing">
          <div i18n="@@UserProfile.infoMarketing.label">Mám zájem o marketingová sdělení</div>
          <div>{{infoMarketingAsText}}</div>
        </div>
      </div>

  </app-display-edit-pane>

  <app-display-edit-pane
      formName="user-profile.view"
      [name]="USER_EDIT_FORM_TYPE.addresses"
      title="Adresy"
      i18n-title="@@UserProfile.addresses.title"
      subtitle="Sídlo, fakturační a korenspondenční adresa"
      i18n-subtitle="@@UserProfile.addresses.subtitle"
      [can-edit]="this.session.getOption('SELFCARE.user-profile.view.addresses.button.edit')"
      (doEdit)="displayDialog($event)">

    <div class="pane-column">
      <div displayPaneItem="address">
        <div i18n="@@UserProfile.address.label">Trvalá adresa</div>
        <div>{{userData.street}} {{userData.houseId}}, {{userData.zip}} {{userData.city}}</div>
      </div>

      <div displayPaneItem="deliveryAddress">
        <div i18n="@@UserProfile.deliveryAddress.label">Doručovací adresa</div>
        <ng-container [ngSwitch]="userData.mailingAddress">
          <div *ngSwitchCase="'OWN'">
            <span *ngIf="userData.mailingAddressRecipient">{{userData.mailingAddressRecipient}}, </span>
            <span>{{userData.mailingAddressStreet}} {{userData.mailingAddressHouseId}}, </span>
            <span><span i18n="@@UserProfile.deliveryAddress.zip.label">PSČ</span> {{userData.mailingAddressZip}} {{userData.mailingAddressCity}}</span>
          </div>
          <div *ngSwitchCase="'AS1'">
            <div i18n="@@UserProfile.deliveryAddress.AS1.label">Shodná s trvalou adresou</div>
          </div>
        </ng-container>
      </div>

      <div displayPaneItem="billingAddress">
        <div i18n="@@UserProfile.billingAddress.label">Fakturační adresa</div>
        <ng-container [ngSwitch]="userData.billingAddress">
          <div *ngSwitchCase="'OWN'">
            <span *ngIf="userData.billingAddressRecipient">{{userData.billingAddressRecipient}}, </span>
            <span>{{userData.billingAddressStreet}} {{userData.billingAddressHouseId}}, </span>
            <span>{{userData.billingAddressZip}} {{userData.billingAddressCity}}</span>
          </div>
          <div *ngSwitchCase="'AS1'">
            <div i18n="@@UserProfile.billingAddress.AS1.label">Shodná s trvalou adresou</div>
          </div>
          <div *ngSwitchCase="'AS2'">
            <div i18n="@@UserProfile.billingAddress.AS2.label">Shodná s doručovací adresou</div>
          </div>
        </ng-container>
      </div>

    </div>
  </app-display-edit-pane>

  <app-display-edit-pane
      formName="user-profile.view"
      [name]="USER_EDIT_FORM_TYPE.personal"
      title="Osobní údaje"
      i18n-title="@@UserProfile.personal.title"
      subtitle="Jméno a identifikační údaje z Vaší smlouvy"
      i18n-subtitle="@@UserProfile.personal.subtitle"
      [can-edit]="this.session.getOption('SELFCARE.user-profile.view.personal.button.edit')"
      (doEdit)="displayDialog($event)">

      <div class="pane-column" *ngIf="userData.firstName || userData.lastName || userData.personalNumber || userData.documentNumber">
        <div displayPaneItem="fullName">
          <div i18n="@@UserProfile.fullName.label">Jméno</div>
          <div>{{userData.degree}} {{userData.firstName}} {{userData.lastName}} {{userData.degreeBehind}}</div>
        </div>
        <div displayPaneItem="dayOfBirth" *ngIf="userData.dayOfBirth">
          <div i18n="@@UserProfile.dayOfBirth.label">Datum narození</div>
          <div>{{userData.dayOfBirth | date}}</div>
        </div>
        <div displayPaneItem="personalNumber" *ngIf="userData.personalNumber">
          <div i18n="@@UserProfile.personalNumber.label">Rodné číslo</div>
          <div>{{userData.personalNumber}}</div>
        </div>
        <div displayPaneItem="documentNumber" *ngIf="userData.documentNumber">
          <div i18n="@@UserProfile.documentNumber.label">Číslo občanského průkazu</div>
          <div>{{userData.documentNumber}}</div>
        </div>
      </div>

      <div class="pane-column" *ngIf="userData.companyName || userData.companyId">
        <div displayPaneItem="companyName" *ngIf="userData.companyName">
          <div i18n="@@UserProfile.companyName.label">Společnost</div>
          <div>{{userData.companyName}}</div>
        </div>
        <div displayPaneItem="companyId" *ngIf="userData.companyId">
          <div i18n="@@UserProfile.companyId.label">IČO</div>
          <div>{{userData.companyId}}</div>
        </div>
        <div displayPaneItem="companyFid" *ngIf="userData.companyFid">
          <div i18n="@@UserProfile.companyFid.label">DIČ</div>
          <div>{{userData.companyFid}}</div>
        </div>
        <div displayPaneItem="companyVatId" *ngIf="userData.companyVatId">
          <div i18n="@@UserProfile.companyVatId.label">IČ DPH</div>
          <div>{{userData.companyVatId}}</div>
        </div>
        <div displayPaneItem="vatRegistered" *ngIf="userData.vatRegistered">
          <div i18n="@@UserProfile.vatRegistered.label">Plátce DPH</div>
          <div>Ano</div>
        </div>
      </div>

  </app-display-edit-pane>

  <app-display-edit-pane
      formName="user-profile.view"
      [name]="USER_EDIT_FORM_TYPE.payments"
      title="Platební údaje"
      i18n-title="@@UserProfile.payments.title"
      subtitle="Číslo účtu, SIPO ..."
      i18n-subtitle="@@UserProfile.payments.title"
      [can-edit]="this.session.getOption('SELFCARE.user-profile.view.payments.button.edit')"
      (doEdit)="displayDialog($event)"
      *ngIf="userData.accountNumber||userData.sipo||userData.agreementName||userData.accountVarSym"
      >

      <div class="pane-column" *ngIf="userData.accountNumber || userData.accountIban">
        <div displayPaneItem="accountNumber" *ngIf="userData.accountNumber">
          <div i18n="@@UserProfile.accountNumber.label">Číslo účtu</div>
          <span *ngIf="userData.accountPreNumber">{{userData.accountPreNumber}}-</span>
          <span>{{userData.accountNumber}}/</span>
          <span>{{userData.accountBank}}</span>
        </div>

        <div displayPaneItem="accountSpecSym" *ngIf="userData.accountSpecSym">
          <div i18n="@@UserProfile.accountSpecSym.label">Specifický symbol</div>
          <div>{{userData.accountSpecSym}}</div>
        </div>

        <div displayPaneItem="accountIban" *ngIf="userData.accountIban">
          <div i18n="@@UserProfile.accountIban.label">Číslo účtu ve formátu IBAN</div>
          <div>{{userData.accountIban}}</div>
        </div>
      </div>

      <div class="pane-column">
        <div displayPaneItem="sipo" *ngIf="userData.sipo">
          <div i18n="@@UserProfile.sipo.label">SIPO</div>
          <div>{{userData.sipo}}</div>
        </div>

        <div displayPaneItem="agreementName" *ngIf="userData.agreementName">
          <div i18n="@@UserProfile.agreementName.label">Číslo smlouvy</div>
          <div>{{userData.agreementName}}</div>
        </div>

        <div displayPaneItem="accountVarSym" *ngIf="userData.accountVarSym">
          <div i18n="@@UserProfile.accountVarSym.label">Variabilní symbol</div>
          <div>{{userData.accountVarSym}}</div>
        </div>
      </div>

  </app-display-edit-pane>

  <app-display-edit-pane *ngIf="this.userHasParamsVisible()"
      formName="user-profile.view"
      [name]="USER_EDIT_FORM_TYPE.params"
      title="Další nastavení"
      i18n-title="@@UserProfile.params.title"
      [can-edit]="this.session.getOption('SELFCARE.user-profile.view.params.button.edit') && this.userHasParamsEditable() "
      (doEdit)="displayDialog($event)">

    <table class="user-params">
      <tr *ngFor="let param of userParamsForView" trackBy="param.defId">
        <td class="caption" style="padding-right: 30px">{{param.caption}}</td><td class="value mango-text-normal">{{param.hrValue}}</td>
      </tr>
    </table>
  </app-display-edit-pane>
</div>


