import {Component, OnInit} from "@angular/core";
import {FormGroup, Validators} from "@angular/forms";
import {SessionService} from "../../_services/session.service";
import {FormHelperService} from "../../_services/form-helper.service";
import {DialogConfig, DialogRef} from "@og_soft/dialog";
import {MangoFormControl} from '../../_models/mango-form-control';

@Component({
  selector: 'app-process-message-dialog',
  templateUrl: './process-message-dialog.component.html',
  styleUrls: ['./process-message-dialog.component.scss']
})
export class ProcessMessageDialogComponent implements OnInit {
  form: FormGroup;
  edit: boolean;

  constructor(
    public session: SessionService,
    public formHelper: FormHelperService,
    public config: DialogConfig,
    private dlgRef: DialogRef,
  ) {

    this.form = new FormGroup({
      note: new MangoFormControl('',{validators: [Validators.required]}),
    });
  }

  ngOnInit() {
  }

  save(){
    if(this.form.valid){
        let data = this.form.getRawValue();
        this.dlgRef.close(data);
      } else {
        this.formHelper.markDirty(this.form);
    }
  }
}
