<sc-main-toolbar>
<!--  <h1>Než budete pokračovat ...</h1>-->
</sc-main-toolbar>

<div class="mango-form-page-contents">
  <!-- <pre>{{mangoData|json}}</pre> -->
  <h1>Než budete pokračovat ...</h1>
  <ng-container *ngIf="mangoData">
    <ng-container *ngIf="mangoData.macAddress">
      <p>Přistupujete na internet ze zařízení, které neznáme. Přejete si změnit MAC adresu u Vaší služby na aktuální?</p>
     
      <p>Vaše aktuální MAC adresa je <b>{{mangoData.macAddress}}</b>?</p>

      <ng-container *ngIf="mangoData.cpes.length == 1">
        <button (click)="doMacChange(mangoData.cpes[0].id)">Změnit MAC a pokračovat</button>
      </ng-container>

      <ng-container *ngIf="mangoData.cpes.length != 1">
        <p>Pokud ano, vyberte prosím, pod které přistupové zařízení ji zapojit.</p>
        <menu>
        <li *ngFor="let cpe of mangoData.cpes">
          <a href="javascript:void(0)" (click)="doMacChange(cpe.id); $event.preventDefault();">{{cpe.name}}</a>
        </li>
        </menu>

        V případě nejasností nás prosíme kontaktujte.
      </ng-container>
    </ng-container>

    <ng-container *ngIf="mangoData.blockedServiceId">
      <p>Vážený kliente, Vaše služba je zablokována.</p>
      <p>Pro informace ke zjednání nápravy můžete chtít navštívit modul <a href="billing">Vyúčtování</a>.

      <p><mat-icon  style="font-size: 300px; color: #ccc;">accessible_forward</mat-icon></p>
    </ng-container>
  </ng-container>

</div>
