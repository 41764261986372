<sc-main-toolbar>
</sc-main-toolbar>

<div class="mango-table-page-contents">
  <h1 i18n="@@menu.billing.overview">Daňové doklady</h1>
  <sc-page-caption-block></sc-page-caption-block>

  <mgt-header [filterValues]="filterValues" [tab]="tab">
    <mgt-filters #tableFilters [formGroup]="filters" (onApplyFilters)="filterValues = $event">
      <div class="filter-row">
        <mat-form-field class="mango-form-field">
          <datetime-control [pickerType]="'calendar'"
                            formControlName="dateFrom" name="dateFrom" #df1
                            i18n-placeholder="@@EuBilling.filters.dateFrom.placeholder"
                            placeholder="Od"
                            [max]="filters.get('dateTo').value"
          ></datetime-control>
          <datetime-control-trigger matSuffix [for]="df1"></datetime-control-trigger>
          <mat-error *ngIf="filters.controls.dateFrom.errors">{{formHelper.getErrorMessage(filters.get('dateFrom'))}}</mat-error>
        </mat-form-field>

        <mat-form-field class="mango-form-field">
          <datetime-control [pickerType]="'calendar'"
                            formControlName="dateTo" name="dateTo" #df2
                            i18n-placeholder="@@EuBilling.filters.dateTo.placeholder"
                            placeholder="Do"
                            [min]="filters.get('dateFrom').value"
                            [errorStateMatcher]="errorMatcher"
          ></datetime-control>
          <datetime-control-trigger matSuffix [for]="df2"></datetime-control-trigger>
          <mat-error *ngIf="filters.controls.dateTo.errors">{{formHelper.getErrorMessage(filters.get('dateTo'))}}</mat-error>
          <mat-error *ngIf="filters.errors">{{formHelper.getErrorMessage(filters.get('dateTo'), filters)}}</mat-error>
        </mat-form-field>
      </div>
        <mat-form-field class="mango-form-field">
          <popup-list formControlName="saIds" [dataSource]="dataUserServices" [filterDb]='false'
                      i18n-placeholder="@@euBilling.filters.saId.placeholder" placeholder='Služba'
                      i18n-title="@@euBilling.filters.saId.title" title="Služba"
          ></popup-list>
        </mat-form-field>
    </mgt-filters>

    <!-- Akce -->
  </mgt-header>

  <mgt-table #tbl [service]="dataService" [filters]="filterValues">
    <mgt-row
            [replacing-detail]="false"
            *ngFor="let r of tbl.scrollItems"
            [record]="r">

      <mgt-cell-group master>
        <mgt-cell title="Doklad" i18n-title="@@EuBilling.record.name.title" role="title">
          {{r.typeTax}}
          {{r.name}} {{r.paramIdent ? "(" + r.paramIdent + ")" : ""}}
          {{r.nameTax}}
        </mgt-cell>
        <mgt-cell >
          <span> <span i18n="@@EuBilling.record.duzp.title">Zdanitelné plnění</span>  {{ r.duzp | date: "d. M. yyyy" || "-"}} </span>
        </mgt-cell>
        <mgt-cell >
          <span *ngIf="r.dateMaturity">  <span i18n="@@EuBilling.record.dateMaturity.title">Splatnost</span> {{r.dateMaturity | date: "d. M. yyyy" || "-"}} </span>
        </mgt-cell>
      </mgt-cell-group>

      <mgt-cell-group>
        <mgt-cell role="result"
                 [ngClass]="{ 'cell-monetary': true
                                 , 'mango-text-info': (r.amountPay > 0 && !this.isAfterMaturity(r))|| r.amountPay < 0
                                 , 'mango-text-warn': r.amountPay > 0 && this.isAfterMaturity(r)
                                 , 'mango-text-success': r.amountPay == 0
                                 }">
          <span *ngIf="r.amountPay == 0"> <span i18n="@@EuBilling.record.amountPay.paid.text">Uhrazeno</span> </span>
          <span *ngIf="r.amountPay < 0">  <span i18n="@@EuBilling.record.amountPay.overpayment.text">Přeplatek</span> {{r.amountPay*-1|currency:getCurrencySymbol()}} </span>
          <span *ngIf="r.amountPay > 0 && this.isAfterMaturity(r)"> <span i18n="@@EuBilling.record.amountPay.debt.text">Dluh</span> {{r.amountPay|currency:getCurrencySymbol()}} </span>
          <span *ngIf="r.amountPay > 0 && !this.isAfterMaturity(r)"> {{r.amountPay|currency:getCurrencySymbol()}} </span>
        </mgt-cell>
      </mgt-cell-group>



      <div detail>

        <div *ngIf="r.typePay && typePayVisible()" class="detail-field">
          <div class="mat-caption"> <span i18n="@@EuBilling.record.typePay.caption">Typ účtování</span></div>
          <div class="value">{{r.typePay}}</div>
        </div>

        <br>
        <span  class="mat-caption"><span i18n="@@EuBilling.documentItems.caption">Položky dokladu</span></span>
        <mgt-row *ngFor="let sa1 of r.sa" [expandable]="false" [record]="sa1"> <!--class="single-service"-->
          <!--<mgt-col>{{sa1.id}}</mgt-col>-->
          <mgt-cell-group master>
            <mgt-cell role="title">{{sa1.name || "Služba"}} {{sa1.paramIdent ? "(" + sa1.paramIdent + ")" : ""}}</mgt-cell>
            <mgt-cell class="mat-caption" *ngIf="sa1.discounts"> <span i18n="@@EuBilling.record.discounts.caption">Uplatněné slevy:</span> {{sa1.discounts}}</mgt-cell>
            <mgt-cell mobile-only>{{sa1.start | date: "d. M. yyyy"}} {{sa1.end | date: "- d. M. yyyy"}}</mgt-cell>
          </mgt-cell-group>
          <mgt-cell-group desktop-only>
            <mgt-cell>{{sa1.start | date: "dd. MM. yyyy"}} {{sa1.end | date: " - dd. MM. yyyy"}}</mgt-cell>
            </mgt-cell-group>
          <mgt-cell-group desktop-only>
            <!--to je kvůli zarovnání sloupečků-->
            <mgt-cell> </mgt-cell>
          </mgt-cell-group>
          <mgt-cell-group>
            <mgt-cell role="result" class="cell-monetary">{{sa1.price | currency:getCurrencySymbol()}}</mgt-cell>
          </mgt-cell-group>
        </mgt-row>

        <br *ngIf="r.payment">
        <span *ngIf="r.payment"  class="mat-caption" ><span i18n="@@EuBilling.payments.caption">Platby</span></span>
        <mgt-row *ngFor="let pay of r.payment" [expandable]="false" [record]="pay">
          <mgt-cell-group master>
            <mgt-cell role="title">{{pay.type}}</mgt-cell>
            <mgt-cell mobile-only>{{pay.date | date: "d. M. yyyy" }}</mgt-cell>
            <mgt-cell mobile-only>{{pay.vs }}</mgt-cell>
          </mgt-cell-group>
          <mgt-cell-group desktop-only>
            <mgt-cell>{{pay.date | date: "dd. MM. yyyy" }}</mgt-cell>
          </mgt-cell-group>
          <mgt-cell-group desktop-only>
            <mgt-cell>{{pay.vs }}</mgt-cell>
          </mgt-cell-group>

          <mgt-cell-group>
            <mgt-cell role="result" class="cell-monetary">{{pay.amount | currency:getCurrencySymbol()}}</mgt-cell>
          </mgt-cell-group>


        </mgt-row>

        <!-- <pre>{{r|json}}</pre> -->

      </div>

      <mgt-actions>
        <a mat-button
           *ngIf="debt(r.amountPay) && r.collectionType!='SIPO' && r.nameTax && (this.session.getOption('SELFCARE.eu-billing.creditCard.button')) " (click)="payment(r)"
           target="_blank">
          <mat-icon>credit_card</mat-icon>
          <span i18n="@@EuBilling.creditCard.button">Zaplatit</span>
        </a>
        <a mat-button
           *ngIf=r.expDoc
           [href]="printUtil.docLinkUrl(r.typePrintTax, r.numberTax )"
           target="_blank"><mat-icon>print</mat-icon> <span i18n="@@EuBilling.print.button">Tisk dokladu</span> </a>
      </mgt-actions>
    </mgt-row>

    <div no-data-alternate-content>
      <page-empty i18n-message="@@EuBilling.PageEmpty.message" message="Není co zobrazit."></page-empty>
    </div>

  </mgt-table>

</div>
