import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogRef} from '@og_soft/dialog';
import {CheckDbService} from '../check-db.service';
import {SessionService} from '../../_services/session.service';
import {first} from 'rxjs/operators';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-unavailable-page',
  templateUrl: './unavailable-page.component.html',
  styleUrls: ['./unavailable-page.component.scss']
})
export class UnavailablePageComponent implements OnInit {

  private refreshSubscription: Subscription;

  constructor(
    private dlgRef: DialogRef,
    private checkDbService: CheckDbService,
    public session: SessionService,
  ) {
    this.refreshSubscription = interval(10000).subscribe(() => {
      console.log('Zkouším jestli už se můžu připojit.');
      this.checkDb();
    });
  }

  ngOnInit(): void {
  }

  reload(): void {
    this.session.processingSet(true);
    this.checkDb();
  }

  private checkDb(): void{
    this.checkDbService.isDbReady().pipe(first()).subscribe(ready => {
      if (ready){
        console.log('XXXXXXXXXXXXXXXXXX už se můžu připojit');
        this.refreshSubscription.unsubscribe();
        this.dlgRef.close();
        this.session.checkLogin();
      }else{
        this.session.processingSet(false);
      }
    }, () => {this.session.processingSet(false); });
  }
}
