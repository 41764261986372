import {Injectable} from '@angular/core';

import {DatePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {DataUserServices, RecUserServices} from './data-user-services';
import {DataBase, DataBaseServiceConfig, FilterInfo, GetallResult} from '@og_soft/data-base';
import {CachingInterceptor} from '@og_soft/cache';
import {PhoneNumberPipe} from '@og_soft/phone-number';

export interface RecTelephonyList {
  type: string;
  oId: number;
  myNum: number;
  theirNum: number;
  dir: string;
  tm: string;
  duration: number;
  saId: number;
  saData?: RecUserServices;
}

export interface RecTelephonyMeta {
  saName: string;
  saIdent: any;
  saIdentType: string;
  dateFrom: string;
  dateTo: string;
  phoneNum: number;
}

@Injectable({
  providedIn: 'root'
})
export class DataTelephonyListService extends DataBase<RecTelephonyList, RecTelephonyMeta> {
  // getallUrl = 'telephony-list';

  protected getAllUrl(): string {
    return 'telephony-list' as const;
  }

  constructor(public http: HttpClient
    ,         public serviceConfig: DataBaseServiceConfig
    ,         public services: DataUserServices
    ,         private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  metaFetchFilterInfo(meta: RecTelephonyMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.saName) {
        info.push({
          label: $localize`:@@DataTelephonyService.filterInfo.service.label:Služba`,
          filterNames: ['saId'],
          value: meta.saName,
          valueDetail: meta.saIdentType === 'PHONE' ? PhoneNumberPipe.prototype.transform('+' + meta.saIdent) : meta.saIdent
        });
      }

      if (meta.dateFrom || meta.dateTo) {
        const dp = new DatePipe('cs-CZ');
        const dates = [];
        dates.push(meta.dateFrom ? dp.transform(meta.dateFrom, 'd. M. yyyy') : '');
        dates.push(meta.dateTo ? dp.transform(meta.dateTo, 'd. M. yyyy') : '');
        info.push({
          label: $localize`:@@DataTelephonyService.filterInfo.service.label:Období`,
          filterNames: ['dateFrom', 'dateTo'],
          value: dates.join(' - ')
        });
      }

      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }

  protected dataPostprocess(data: GetallResult<RecTelephonyList>): GetallResult<RecTelephonyList> {
    // Toto je dostatečně finální service, takže můžeme použít cizí service přímo zde.
    const ids: number[] = [];
    for (const service of data.data) {
      if (ids.indexOf(service.saId) < 0) {
        ids.push(service.saId);
      }
    }

    if (ids.length) {
      this.services.getall({saIds: ids.join(',')})
        .subscribe(d => {
          data.data.map(rec => {
            for (const service of d.data) {
              if (rec.saId === service.saBaseId) {
                rec.saData = service;
              }
            }
          });
          this.onDataCollected.emit();
          return data;
        });
    }

    return super.dataPostprocess(data);
  }
}
