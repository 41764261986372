<div
        *ngIf="(session.processing | async).state"
        class="session-processing-outer"
>
  <div class="session-processing-inner">
    <p>{{(session.processing | async).message}}</p>
    <mat-progress-spinner
            diameter="66"
            color="primary"
            mode="indeterminate"
    >
    </mat-progress-spinner>
  </div>
</div>
