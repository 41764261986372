import {Injectable} from '@angular/core';
import {PopupOption} from "@og_soft/popup-list-control";
import {DataBase} from "@og_soft/data-base";

export interface ProcessPremises {
  premiseId: number;
  premiseName: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataProcessPremises extends DataBase<ProcessPremises> {
  // getallUrl = "process-premises";

  protected getAllUrl(): string {
    return 'process-premises' as const;
  }

  tranformOptions(row): PopupOption {
    return {id: row.premiseId, name: row.premiseName};
  }
}
