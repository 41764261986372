<form [formGroup]="form">
  <mat-radio-group name="type" id="type" formControlName="type"
                   *ngIf="formHelper.isVisible(form.get('type'))">
    <div>
      <mat-radio-button class="mango-radio-button" value="S" i18n="@@UserProfile.radio.type.S">Soukromá osoba
      </mat-radio-button>
      <br>
      <mat-radio-button class="mango-radio-button" value="F" i18n="@@UserProfile.radio.type.F">Fyzická osoba
      </mat-radio-button>
      <br>
      <mat-radio-button class="mango-radio-button" value="P" i18n="@@UserProfile.radio.type.P">Právnická osoba
      </mat-radio-button>
    </div>

    <mat-error *ngIf="form.get('type').invalid && (form.get('type').dirty || form.get('type').touched)">
      {{formHelper.getErrorMessage(form.get('type'))}}
    </mat-error>

  </mat-radio-group><config-setting input="type" [formName]="formName"></config-setting>

  <ng-container *ngIf="formHelper.isVisible(form.get('degree'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.degree.label">Titul před</mat-label>
      <input matInput formControlName="degree" id="degree" name="degree" i18n-title="@@UserProfile.degree.title"
             title="Titul před" [required]="form.get('degree') | hasRequiredField">
      <config-setting input="degree" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('degree').invalid && (form.get('degree').dirty || form.get('degree').touched)">
        {{formHelper.getErrorMessage(form.get('degree'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('firstName'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.firstName.label">Jméno</mat-label>
      <input matInput formControlName="firstName" id="firstName" name="firstName"
             i18n-title="@@UserProfile.firstName.title" title="Jméno"
             [required]="form.get('firstName') | hasRequiredField">
      <config-setting input="firstName" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('firstName').invalid && (form.get('firstName').dirty || form.get('firstName').touched)">
        {{formHelper.getErrorMessage(form.get('firstName'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('lastName'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.lastName.label">Příjmení</mat-label>
      <input matInput formControlName="lastName" id="lastName" name="lastName" i18n-title="@@UserProfile.lastName.title"
             title="Příjmení" [required]="form.get('lastName') | hasRequiredField">
      <config-setting input="lastName" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('lastName').invalid && (form.get('lastName').dirty || form.get('lastName').touched)">
        {{formHelper.getErrorMessage(form.get('lastName'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('degreeBehind'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.degreeBehind.label">Titul za</mat-label>
      <input matInput formControlName="degreeBehind" id="degreeBehind" name="degreeBehind"
             i18n-title="@@UserProfile.degreeBehind.title" title="Titul za"
             [required]="form.get('degreeBehind') | hasRequiredField">
      <config-setting input="degreeBehind" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('degreeBehind').invalid && (form.get('degreeBehind').dirty || form.get('degreeBehind').touched)">
        {{formHelper.getErrorMessage(form.get('degreeBehind'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('companyName'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.companyName.label">Společnost</mat-label>
      <input matInput formControlName="companyName" id="companyName" name="companyName"
             i18n-title="@@UserProfile.companyName.title" title="Společnost"
             [required]="form.get('companyName') | hasRequiredField">
      <config-setting input="companyName" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('companyName').invalid && (form.get('companyName').dirty || form.get('companyName').touched)">
        {{formHelper.getErrorMessage(form.get('companyName'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('companyId'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.companyId.label">IČ</mat-label>
      <input matInput formControlName="companyId" id="companyId" name="companyId"
             i18n-title="@@UserProfile.companyId.title" title="IČ"
             [required]="form.get('companyId') | hasRequiredField">
      <config-setting input="companyId" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('companyId').invalid && (form.get('companyId').dirty || form.get('companyId').touched)">
        {{formHelper.getErrorMessage(form.get('companyId'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('companyFid'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.companyFid.label">DIČ</mat-label>
      <input matInput formControlName="companyFid" id="companyFid" name="companyFid"
             i18n-title="@@UserProfile.companyFid.title" title="DIČ"
             [required]="form.get('companyFid') | hasRequiredField">
      <config-setting input="companyFid" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('companyFid').invalid && (form.get('companyFid').dirty || form.get('companyFid').touched)">
        {{formHelper.getErrorMessage(form.get('companyFid'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('companyVatId'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.companyVatId.label">IČ DPH</mat-label>
      <input matInput formControlName="companyVatId" id="companyVatId" name="companyVatId"
             i18n-title="@@UserProfile.companyVatId.title" title="IČ DPH"
             [required]="form.get('companyVatId') | hasRequiredField">
      <config-setting input="companyVatId" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('companyVatId').invalid && (form.get('companyVatId').dirty || form.get('companyVatId').touched)">
        {{formHelper.getErrorMessage(form.get('companyVatId'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('vatRegistered'))">
    <mat-checkbox matInput formControlName="vatRegistered" id="vatRegistered" name="vatRegistered"
                  i18n-title="@@UserProfile.vatRegistered.title"
                  title="Určuje, zda je zákazník plátce DPH. Musí mít vyplněno DIČ a musí být typ fyzická nebo právnická osoba."
                  i18n="@@UserProfile.vatRegistered.label">Plátce DPH
    </mat-checkbox>
    <config-setting input="vatRegistered" [formName]="formName"></config-setting>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('vatRegisteredFrom'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.vatRegisteredFrom.label">Datum změny</mat-label>
      <datetime-control [pickerType]="'calendar'"
                        formControlName="vatRegisteredFrom" id="vatRegisteredFrom" name="vatRegisteredFrom"
                        title="Datum, od kterého platí, zda zákazník je plátce DPH nebo není. Změna musí být od 1. v měsíci."
                        [required]="form.get('vatRegisteredFrom') | hasRequiredField"
                        #df2
      ></datetime-control>
      <datetime-control-trigger matSuffix [for]="df2"></datetime-control-trigger>
      <mat-error *ngIf="form.controls.vatRegisteredFrom.errors">{{formHelper.getErrorMessage(form.get('vatRegisteredFrom'))}}</mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('statutary'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.statutary.label">Statutární orgán</mat-label>
      <input matInput formControlName="statutary" id="statutary" name="statutary"
             i18n-title="@@UserProfile.statutary.title" title="Statutární orgán"
             [required]="form.get('statutary') | hasRequiredField">
      <config-setting input="statutary" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('statutary').invalid && (form.get('statutary').dirty || form.get('statutary').touched)">
        {{formHelper.getErrorMessage(form.get('statutary'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('personalNumber'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.personalNumber.label">Rodné číslo</mat-label>
      <input matInput formControlName="personalNumber" id="personalNumber" name="personalNumber"
             i18n-title="@@UserProfile.personalNumber.title" title="Rodné číslo"
             [required]="form.get('personalNumber') | hasRequiredField">
      <config-setting input="personalNumber" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('personalNumber').invalid && (form.get('personalNumber').dirty || form.get('personalNumber').touched)">
        {{formHelper.getErrorMessage(form.get('personalNumber'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('dayOfBirth'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.dayOfBirth.label">Datum narození</mat-label>
      <datetime-control [pickerType]="'calendar'"
                        formControlName="dayOfBirth" id="dayOfBirth" name="dayOfBirth"
                        i18n-title="@@UserProfile.dayOfBirth.title" title="Datum narození"
                        [required]="form.get('dayOfBirth') | hasRequiredField"
                        #df1
      ></datetime-control>
      <datetime-control-trigger matSuffix [for]="df1"></datetime-control-trigger>
      <mat-error *ngIf="form.controls.dayOfBirth.errors">{{formHelper.getErrorMessage(form.get('dayOfBirth'))}}</mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('documentNumber'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.documentNumber.label">Číslo občanského průkazu</mat-label>
      <input matInput formControlName="documentNumber" id="documentNumber" name="documentNumber"
             i18n-title="@@UserProfile.documentNumber.title" title="Číslo občanského průkazu"
             [required]="form.get('documentNumber') | hasRequiredField">
      <config-setting input="documentNumber" [formName]="formName"></config-setting>
      <mat-error
          *ngIf="form.get('documentNumber').invalid && (form.get('documentNumber').dirty || form.get('documentNumber').touched)">
        {{formHelper.getErrorMessage(form.get('documentNumber'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


</form>

