import {Component, OnInit, ViewChild} from '@angular/core';
import {DataUnicredit} from '../_services/data-unicredit.service';
import {SessionService} from '../_services/session.service';
import {CardPaymentComponent} from '../card-payment/card-payment.component';
import {DataUserUnicredit} from '../_services/user-unicredit.service';
import {PhonetCreditComponent} from '../phonet-credit/phonet-credit.component';
import {DialogConfig, DialogService} from '@og_soft/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Payment3dsecureResponseService} from '../_services/payment-3dsecure-response.service';

@Component({
  selector: 'app-eu-unicredit',
  templateUrl: './eu-unicredit.component.html',
  styleUrls: ['./eu-unicredit.component.scss']
})

export class EuUnicreditComponent implements OnInit {

  constructor(
    public dataService: DataUnicredit,
    public dataUserUnicredit: DataUserUnicredit,
    public session: SessionService,
    public dialog: DialogService,
    private router: Router,
    private payment3dSecureResponse: Payment3dsecureResponseService,
    private route: ActivatedRoute,
  ) {
  }
  public varSym: string;
  public balance: number;
  public phonet: number;
  public saPhonet: number;

  @ViewChild('formCardPay', {static: false}) formCardPay: CardPaymentComponent;
  @ViewChild('formPhonetCredit', {static: false}) formPhonetCredit: PhonetCreditComponent;

  ngOnInit(): void {
    this.dataUserUnicredit.getone(this.session.user.id).subscribe(next => {
        if (next !== null ) {
          this.varSym = next.ucVarSymbol;
          this.balance = next.ucBalance;
          this.phonet = next.ucPhonet;
          this.saPhonet = next.saPhonet;
        }
      }
      , err => { console.log('err', err); }
    );
  }

  payment(): void {
    const dconfp = new DialogConfig();
    dconfp.data = {
      amount: 0,
      varsym: this.varSym,
      typeAttach: null,
      saId: 0,

    };
    const dref = this.dialog.open( CardPaymentComponent, dconfp);
  }

  phonetCredit(): void {
    const dconf = new DialogConfig();
    dconf.data = {
      amount: 0,
      userServices: 0,
    };

    const dref = this.dialog.open(PhonetCreditComponent, dconf);

    dref.afterClosed.subscribe(result => {
    });


  }

  balanceGet(): number {
    return this.balance;
  }

  varSymbolGet(): string {
    return this.varSym;
  }

  proxyPhonetGet(): number {
    return this.phonet;
  }

  saPhonetGet(): number {
    return this.saPhonet;
  }

  getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }
}

