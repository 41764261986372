import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DataRmRedirectService {

  constructor(private http: HttpClient) { }

  private url = environment.baseUrl + `/rm-redir`;

  getSetup(encdata) {
    return this.http.get<any>(this.url + "/" + encdata);
  }

  doMacChange(data) {
    return this.http.post<any>(this.url, data);
  }
}
