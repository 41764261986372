import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DialogConfig, DialogRef} from '@og_soft/dialog';
import {SessionService} from '../../_services/session.service';
import {FormHelperService} from '../../_services/form-helper.service';
import {DataNodesService} from '../../_services/data-nodes.service';
import {DataNodeParamsService} from '../data-node-params.service';
import {DependencyNodesService} from '../../_services/dependency-nodes.service';
import {ParamFormComponent} from '../../_libraries/param-form/param-form/param-form.component';
import {ParamControlService} from '../../_libraries/param-form/_services/param-control.service';
import {Subject} from 'rxjs';
import {filter, startWith, switchMap, take, tap} from 'rxjs/operators';

@Component({
  selector: 'app-node-edit',
  templateUrl: './node-edit.component.html',
  styleUrls: ['./node-edit.component.scss'],
  providers: [ ParamControlService ]
})
export class NodeEditComponent implements OnInit {

  private _id: number = null;
  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    if (this._id !== value) {
      this._id = value;
    }
  }

  private _caption: string = null;
  public get caption(): string {
    return this._caption;
  }
  public set caption(value: string) {
    if (this._caption !== value) {
      this._caption = value;
    }
  }

  @ViewChild(ParamFormComponent, {static: false}) paramForm: ParamFormComponent;

  public form: FormGroup;

  public formSubmitSubject$ = new Subject();

  constructor(
    public dialogConfig: DialogConfig,
    private dialogRef: DialogRef,
    private session: SessionService,
    private formHelper: FormHelperService,
    public dataNodesService: DataNodesService,
    public dataNodeParamsService: DataNodeParamsService,
    public dependencyNodesService: DependencyNodesService,
  ) {
    this.id = dialogConfig.data.id;
    this.caption = dialogConfig.data.nodeData.name + (dialogConfig.data.nodeData.parIdent ? (', ' + dialogConfig.data.nodeData.parIdent) : '');
  }

  ngOnInit(): void {
    this.form = new FormGroup({});
    this.session.processingSet(true);

    /**
     * Handles pending async validation on submit
     * https://stackoverflow.com/questions/49516084/reactive-angular-form-to-wait-for-async-validator-complete-on-submit
     */
    this.formSubmitSubject$
      .pipe(
        tap(() => () => this.form.markAsDirty()),
        switchMap(() =>
          this.form.statusChanges.pipe(
            startWith(this.form.status),
            filter(status => status !== 'PENDING'),
            take(1)
          )
        ),
        filter(status => status === 'VALID')
      )
      .subscribe(() => this.save());
  }

  protected save(): void {
    const formData = this.form.value;
    formData.id = this.id;
    console.log('Ukládaná data ', formData);
    if (this.form.valid) {
      this.dataNodesService.post(formData).subscribe(() => {
        this.dataNodeParamsService.clearCache();
        this.session.message($localize`:@@NodeEdit.form.save.ok:OK. Všechno máme uloženo.`);
        this.dialogRef.close(formData);
      }, error => {
        this.session.message($localize`:@@NodeEdit.form.save.error:Tak to tak úplně nevyšlo.`);
        console.error('Tak to tak úplně nevyšlo.', error);
        this.dialogRef.close(null);
      });
    } else {
      this.session.message($localize`:@@NodeEdit.form.validate.message:Některé položky nejsou vyplněné nebo nemají správnou hodnotu.`);
      this.formHelper.markDirty(this.form);
    }
  }

  onParamsReady(): void {
    this.session.processingSet(false);
  }
}
