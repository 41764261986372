import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {appVersion, buildDate, appSetupName, vcsLastCommit} from '../../environments/version';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {CheckSessionService} from '../core/check-session.service';

export interface User {
  backendLoginId: number;
  fullname: string;
  guardianOperatorId?: number;
  guardianOperatorName?: string;
  id: number;
  ct: number;
  login: string;
  logLevel: string;
  options: any;
  locDefaults: any;
  token: any;
  atb: string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  get hideNav(): boolean {
    return this._hideNav;
  }

  set hideNav(value: boolean) {
    this._hideNav = value;
  }

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private deviceDetector: DeviceDetectorService,
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    private checkSessionService: CheckSessionService,
  ) {
    console.log('App version: ' + appSetupName + ' ' + appVersion + ', built at ' + buildDate + ', last revision: ' + vcsLastCommit);

    this.isMobile = deviceDetector.isMobile();

    // Na malých obrazovkách se chováme jako na mobilu
    this.breakpointObserver.observe([
      '(max-width: 959px)',
    ]).subscribe(result => {
      this.isMobile = (deviceDetector.isMobile() || result.matches);
    });

    this.breakpointObserver.observe([
      '(min-width: 1440px)',
    ]).subscribe(result => {
      this.isHugeScreen = result.matches;
    });

    // add listener for logout/login event for other tabs
    window.addEventListener('storage', event => {
      this.detectLoginStatusForOtherTabs();
    });

    this._loginChanged = new BehaviorSubject<boolean>(this.isLoggedIn());

    setTimeout(() => {
      if (this.isLoggedIn()) {this.redirectToMainPage(); }
    }); // Bez timeoutu ještě není nastavená routa.

    this.ct = environment.ct;
  }

  get user(): User {
    if (!this._user) {
      this._user = JSON.parse(localStorage.getItem(SessionService.USER));
    }
    return this._user;
  }

  get options(): any {
    return this.user.options;
  }

  get locDefaults(): any {
    return this.user.locDefaults;
  }

  get processing(): Observable<{ state: boolean; message: string }> {
    return this._processing.asObservable();
  }

  // ct přidávám proto,abych technicky mohl být přihlášený ke dvěma SC v jednom prohlížeči
  static readonly SESSION_TOKEN = 'X-Session-Token' + '_' + environment.ct;
  static readonly USER = 'USER_DATA' + '_' + environment.ct;
  static readonly MAIN_PAGE = 'home'; // Do budoucna je možné udělat nastavením

  static readonly RIGHT_BASE = 'SELFCARE'; // Tímto názvem začínají všechna práva, tykající se této aplikace

  private url = environment.baseUrl + `/eu-session`;
  redirectUrl: string;
  private readonly _loginChanged: BehaviorSubject<boolean>;
  private _user: User;
  private configUser = false;
  private _hideNav = false; // Některé stránky chci zobrazit bez menu, na celou obrazovku.
  // Při otevření stránky v prohlížeči, se často stává, že v local storage máme uložený token
  // ale jeho platnost na serveru už vypršela. Aplikace se potom tváří, jako by byl uživatel přihlášený
  // otevře naposledy otevřenou stránku a pošle všechny requesty které potřebuje k jejímu zobrazení.
  // Všechny tyto requesty se vrátí s 401, což je validní, dojde k odhlášení a pro uživatele se jakoby nic neděje.
  // Nicméně - posílají se zbytečně requesty, a nakonec i aplikace se nechová úplně nejlíp, něco se zobrazí,
  // pak se přesměruje na přihlášení...
  // Řeším to tedy tak, že už při initu samotné aplikace si ověřím, jestli je session v local storage
  // platná. Dokud to nemám ověřené, tak nic nevykresluju.
  public loginChecked = false;

  /**
   * Shorthand to global mobile flag - as we usually already have
   * Session injected for other reasons.
   */
  public isMobile = false;
  public isHugeScreen = false;

  public ct = 0;

  // Seznam rout, které jsou viditelné bez přihlášení.
  // Pokud jsem přihlášen, budu z nich automaticky přesměrován.
  private publicUrl = [
    '/login',
    '/password',
    '/registration',
    '/verify-registration',
  ];


  /**
   * Session ví o probíhající operaci, která trvá. Zobrazuje se overlay, message a progres indikátor (kolečko).
   */
  private _processing = new BehaviorSubject<{state: boolean, message: string}>({state: false, message: ''});

  public isLoggedIn(): boolean {
    return !!this.getToken();
  }

  public loginChanged(): Observable<boolean> {
    return this._loginChanged;
  }

  /**
   * Vrací hodnotu práva, pokud existuje a null pokud neexistuje.
   * V byckendu by se hodnota práv měla přetypovat podle rights_def na adekvátní typ, takže tady by o nemělo být potřeba.
   * Neplatí to samozřejmě pro optiony, které záznam v rights_def nemají.
   */
  getOption(name: string): any {
    let right;

    if (this.user && this.user.options) {
      right = this.user.options;
    } else {
      return null;
    }

    // Ošetření toho, aby hledaný atribut objektu vůbec existoval (jinak to vrací chybu)
    // Rozdělím si název práva do stromu objektů a projdu optiony, zda obsahují všechny úrovně
    // do proměnné right si průběžně ukládám objekty strom objektů, takže na konci bych tam měl mít přímo právo.
    // Př. Právo Nazevmodulu.Nazevkomponenty.Akce.NazevAkce znamená, že postupným ukládáním do proměnné right testuji jestli existuje
    // this.user.options.Nazevmodulu, this.user.options.Nazevmodulu.Nazevkomponenty,
    // this.user.options.Nazevmodulu.Nazevkomponenty.Akce atd...
    // když tyto testy projdou na konci mám v rights uloženou hodnotu this.user.options.Nazevmodulu.Nazevkomponenty.Akce.NazevAkce.
    const rightParts = name.split('.');
    for (const rightPart of rightParts) {
      if (right.hasOwnProperty(rightPart)) {
        right = right[rightPart];
      } else {
        return null;
      }
    }
    return right;
  }

  public login(username: string, password: string): Observable<any> {
    let params;
    params = {username, password, ct: environment.ct};
    return this.doLogin(params);
  }

  public autoLogin(registrationToken: string): Observable<any> {
    let params;
    params = {registration_token: registrationToken, ct: environment.ct};
    return this.doLogin(params);
  }

  private doLogin(params: any): Observable<any> {
    return this.http.post<any>(this.url, params)
      .pipe(tap(user => {
        // console.log("Přihlásil jsem uživatele:", user);
        if (user) {
          this.storeUser(user);
          this._loginChanged.next(this.isLoggedIn());
          this.configUser = this.user && this.getOption(SessionService.RIGHT_BASE + '.config.user') === this.user.id.toString();
        }
      }));
  }


  private getToken(): string {
    return JSON.parse(localStorage.getItem(SessionService.SESSION_TOKEN));
  }

  private storeUser(user: User): void {
    localStorage.setItem(SessionService.USER, JSON.stringify(user));
    localStorage.setItem(SessionService.SESSION_TOKEN, JSON.stringify(user.token));
  }

  private removeUser(): void {
    this._user = null;
    this.configUser = false;
    localStorage.removeItem(SessionService.USER);
    localStorage.removeItem(SessionService.SESSION_TOKEN);
  }

  /**
   * Kompletní odhlášení (uživatelská akce). Zajišťuji odhlášení i na serveru.
   */
  logout(): void {
    // Smazání session neošetřuji - pokud nedopadne, zajistí smazání garbage collector.
    // parametr mine je dummy - metoda delete je definovaná vždy s parametrem, což ale u session (jako jediné) není potřeba,
    // protože session si držíme jinde. Nicméně něco tam musí být aby to fungovalo.
    this.http.delete<any>(this.url + '/mine').subscribe();
    this.setLoggedOut();
  }


  /**
   * Odhlášení uvnitř aplikace.
   * Především jako reakce na výsledek requestu 401.
   * V takovém případě předpokládám že jsem na serveru již odhlášený a pouze aktualizuji stav aplikace.
   *
   * @param boolean auto Pokud jde o automatické odhlášení, tak si ještě před přesměrováním uložím aktuální routu,
   * abych se na ni po přihlášení přesměroval.
   */
  public setLoggedOut(auto = false): void {
    this.removeUser();
    if (auto) {
      console.log('Automatické odhlášení, pak se chci vrátit na: ', this.router.url);
      this.redirectUrl = this.router.url;
    }
    this._loginChanged.next(this.isLoggedIn());
    this.router.navigate(['/login']);
  }

  /**
   * Nastavuje stav zpracovávání dlouho trvajícího požadavku (wait animace).
   * @param state Stav zpracování true = probíhá.
   * @param message Zpráva popisující co se děje.
   */
  public processingSet(state: boolean, message?: string): void {
    this._processing.next({state, message: message !== undefined ? message : $localize`:@@SessionService.processing.general.message:Probíhá zpracování, strpení prosím.`});
  }

  // Aby bylo vždy přesměrováno na login page (typicky případ, kdy mám otevřeno víc oken a v jednom se odhlásím),
  // přesměrovalo na home, pokud je přihlášen
  public detectLoginStatusForOtherTabs(): void {
    const url = this.router.url;
    if (url !== '/') { // Prevence přesměrování na password page
      if (this.isLoggedIn()) {
        this.redirectToMainPage();
      } else {
        this.redirectToLoginPage();
      }
    }
  }

  private redirectToMainPage(): void {
    if (this.isPublicUrl(this.router.url)) {
      this.router.navigateByUrl(SessionService.MAIN_PAGE);
    }
  }

  protected redirectToLoginPage(): void {
    if (!this.isPublicUrl(this.router.url)) {
      this.router.navigateByUrl('/login'); // redirect
    }
  }

  public isPublicUrl(url: string): boolean {
    for (const publicUrl of this.publicUrl) {
      if (url.startsWith(publicUrl)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Zobrazí chybovou (nebo jakoukoli jinou hlášku).
   * @param duration number Po kolika milisekundách má hláška sama zmizet. (Není-li zadáno, musí si ji změnit uživatel.)
   */
  public message(message: string, duration?: number): void {
    this.snackBar.open(message, $localize`:@@SessionService.message.close:Zavřít`, {verticalPosition: 'top', duration: duration || 0});
  }

  public closeMessage(): void {
    this.snackBar.dismiss();
  }

  /**
   * V db můžu nastavit konfiguračního uživatele - ten potom vidí všechny moduly a všechny itemy
   * včetně jejich názvů.
   * Viz koment u ConfigSettingComponent
   */
  public isConfigUser(): boolean {
    // return this.user && this.getOption(SessionService.RIGHT_BASE + '.config.user') === this.user.id.toString();
    return this.configUser;
  }


  /**
   * Vyhodnocuje jestli může uživatel vidět daný modul
   */
  canView(path: string): boolean {
    if (this.isConfigUser()) {
      return true;
    }
    const modulePath = path.split('/:')[0];
    return !!this.getOption(SessionService.RIGHT_BASE + '.PAGE.' + modulePath);
  }

  /**
   * Metoda, která se volá při otevření aplikace a po případném připojení po nedostupnosti databáze.
   * Zjistí, jestli jsem ještě přihlášený, pokud ano, pustí mě do aplikace, pokud ne, vynutí
   * přihlášení, s tím že si zapamatuje původní routu.
   */
  public checkLogin(): void {
    this.loginChecked = false;
    if (this.isLoggedIn()){
      this.checkSessionService.getSingleton().pipe(first()).subscribe(logged => {
        this.loginChecked = true;
        this.processingSet(false);
      }, () =>
      {
        // Chyby zachytí error interceptor, který zajistí odhlášení a přesměrování,
        // nicméně já zde potřebuji říct aplikaci, že už se může vykreslit.
        this.loginChecked = true;
        this.processingSet(false);
      });
    } else {
      if (!this.isPublicUrl(this.router.url)) {
        // Nejsem přihlášený, ale přistupuji na zabezpečenou routu - poznamenám si kam jsme chtěl
        // jít aby mě to po přihlášení přesměrovalo.
        // Pak se přesměruji na login.
        this.redirectUrl = this.router.url;
        this.router.navigateByUrl('/login');
      }
      this.loginChecked = true; // Nejsem přihlášený, nemusím nic ověřovat.
      this.processingSet(false);
    }
  }
}
