import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SessionService} from './session.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Servisa slouží primárně k tomu, aby zajistila, že když si otevřu proces, tak
 * po jeho uzavření mě to přesměruje zpátky na stránku, z které jsem ho otevřel.
 * Je to především proto, protože procesy můžu otevřít z toolbaru z jakéhokoli místa.
 *
 * Nechtěl jsme kvůli tomu rozšiřovat session.service.
 * Přemýšlel jsem o možné abstrakci směrem k tomu, že takto by to šlo udělat i pro jiné otevírané moduly,
 * ale my z toolbaru nic jiného neotevíráme. Nechávám tedy případnou abstrakci jen pro případ, že něco takového
 * bude potřeba i pro jiný modul. V tuto chvíli bych si myslel, že by tato servisa mohla spíš sloužit na nějaké další
 * další specifické věci, které můžeme řešit v procesech.
 */
export class ProcessRedirectService{
  private returnUrl: string;

  constructor(
    public session: SessionService,
    private router: Router,
  ) {
  }

  openProcess(id: number, typeId: number): void {
    this.returnUrl = this.router.url;
    this.session.hideNav = true;
    this.router.navigate(['/process', id, typeId ? {type: typeId} : {}]);
  }

  returnFromProcess(): void{
    console.log('----------LEAVE PROCESS NAVIGATION RETURN ------');
    this.session.hideNav = false;
    const url = this.returnUrl || '/process';
    this.returnUrl = null;
    this.router.navigateByUrl(url);
  }
}
