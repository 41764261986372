import {Injectable} from '@angular/core';
import {RecUserServices} from './data-user-services';
import {DataBase, FilterInfo} from '@og_soft/data-base';
import {PhoneNumberPipe} from '@og_soft/phone-number';
import {PopupOption} from '@og_soft/popup-list-control';
import {DataUnitsService} from './data-units.service';

export interface RecNodeParam {
  name: string;
  index?: number;
  title?: string;
  value?: string;
  editable?: boolean;
}

export interface RecNode {
  id: number;
  icon: string;
  name: string;
  uname?: string;
  parIdent: string;
  saIds: number[];
  editable: boolean;
  sa?: RecUserServices[];
  params?: RecNodeParam[];
  address?: string;
}

export interface RecNodeMeta {
  saName: string;
  saIdent: any;
  saIdentType: string;
  unitAddress?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataNodesService extends DataBase<RecNode, RecNodeMeta> {

  // getallUrl = 'nodes';

  protected getAllUrl(): string {
    return 'nodes' as const;
  }

  metaFetchFilterInfo(meta: RecNodeMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.saName) {
        const saName = meta.saName;
        let detail = '';
        if (meta.saIdent) {
          detail = ' (' + (meta.saIdentType === 'PHONE' ? PhoneNumberPipe.prototype.transform('+' + meta.saIdent) : meta.saIdent) + ')';
        } else if (meta.unitAddress) {
          detail = ' (' + DataUnitsService.shortenAddress(meta.unitAddress) + ')';
        }
        info.push({
          label: $localize`:@@DataNodesService.filterInfo.service.label:Služba`,
          filterNames: ['saId'],
          value: saName,
          valueDetail: detail
        });
      }

      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }

  tranformOptions(row: any): PopupOption {
    return {
      id: row.id,
      name: row.name + (row.parIdent ? (', ' + row.parIdent) : '')
    };
  }
}
