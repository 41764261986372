import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';
import {PopupOption} from '@og_soft/popup-list-control';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';

export interface RecPhonetCreditData {
  id: string;
  name: string;
}

@Injectable({
    providedIn: 'root'
})

export class PhonetCreditService extends DataBase<RecPhonetCreditData> {
   // getallUrl = "phonet-credit";

  protected getAllUrl(): string {
    return 'phonet-credit' as const;
  }

  tranformOptions(row): PopupOption {
    const phoneUtil = PhoneNumberUtil.getInstance();
    let name = row.saPhonetName;
    if (row.saPhonetNum) {
      const phoneNum = phoneUtil.parse('+' + row.saPhonetNum);
      name += ' (' + phoneUtil.format(phoneNum, PhoneNumberFormat.INTERNATIONAL) + ')';
    }
    return {id: row.saPhonetId, name};
  }
}
