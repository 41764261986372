import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {DataUserServices} from './data-user-services';
import {DataBase, DataBaseServiceConfig, FilterInfo, GetallResult} from '@og_soft/data-base';
import {CachingInterceptor} from '@og_soft/cache';
import {PhoneNumberPipe} from '@og_soft/phone-number';



/**
 * Interface pro jeden záznam z billingu, jak ho vrací API.
 *
 * TypeScript používá interfaces pro typovou kontrolu. Je otázka, zda
 * to kromě toho má pozitivní vliv ma schopnost IDE napovídat. Pokud
 * se to nepotvrdí, tak se s těmi interfaces psát nebudeme a budeme
 * prostě používat typ "any".
 */
export interface RecBilling {
  typeTax: string;
  duzp: string;
  typePay: string;
  numberTax: number;
  nameTax: string;
  dateMaturity: string;
  currentDate: string;
  amountPay: string;
  priceTax: string;
  expDoc: number;
  cdId: number;
  saId: string;
  name: string;
  typePrintTax: string;
  collectionType: string;
  sa: any[]; // FIXME přesnější specifikaci typu místo any
  payment: any[]; // FIXME přesnější specifikaci typu místo any
  paramIdent: string;
}

export interface RecBillingMeta {
  dateFrom: string;
  dateTo: string;
  saNames: {
    saIdent: string;
    saIdentType: string;
    saName: string;
    unitAddress: string;
  };
}


export interface RecUserDebt {
  debtAll: number;
  debtAfterMaturity: number;
  debtWithoutUc: number;
  userVs: number;
}

export interface RecAccountIban {
  iban: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataBilling extends DataBase<RecBilling> {
  // getallUrl = 'eu-billing';

  protected getAllUrl(): string {
    return 'eu-billing' as const;
  }

  constructor(public http: HttpClient,
              serviceConfig: DataBaseServiceConfig,
              public userServices: DataUserServices,
              private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected dataPostprocess(data: GetallResult<RecBilling>): GetallResult<RecBilling> {
    // FIXME optimalizovat volání getone nahradit za get all. Viz např. user-nodes.service.ts
    // Pak se bude méně často volat ten onDataCollected event.
    for (const result of data.data) {
      for (const service of result.sa) {
        this.userServices.getone(service.id).subscribe(d => {
          if (d) {
            service.name = d.saName;
            service.paramIdent = DataUserServices.saIdentGet(d);
            this.onDataCollected.emit();
          }
        });
      }
      this.userServices.getone(result.saId).subscribe(d => {
        if (d) {
          result.name = d.saName;
          result.paramIdent = DataUserServices.saIdentGet(d);
          this.onDataCollected.emit();
        }
      });
    }
    return super.dataPostprocess(data);
  }

  metaFetchFilterInfo(meta: RecBillingMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.dateFrom || meta.dateTo) {
        const dp = new DatePipe('cs-CZ');
        const dates = [];
        dates.push(meta.dateFrom ? dp.transform(meta.dateFrom, 'd. M. yyyy') : '');
        dates.push(meta.dateTo   ? dp.transform(meta.dateTo,   'd. M. yyyy') : '');
        info.push({
          label: $localize`:@@DataBillingService.filterInfo.date.label:Období`,
          filterNames: ['dateFrom', 'dateTo'],
          value: dates.join(' - ')
        });
      }
      if (meta.saNames){
        info.push({
          label: $localize`:@@DataBillingService.filterInfo.service.label:Služba`,
          filterNames: ['saIds'],
          value: meta.saNames[0].saName,
          valueDetail: meta.saNames[0].saIdentType === 'PHONE' && meta.saNames[0].saIdent
            ? PhoneNumberPipe.prototype.transform('+' + meta.saNames[0].saIdent)
            : meta.saNames[0].unitAddress
        });
      }
      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }
}


@Injectable({
  providedIn: 'root'
})

export class UserDebtService extends DataBase<RecUserDebt> {
  // getallUrl = 'user-debt';

  protected getAllUrl(): string {
    return 'user-debt' as const;
  }

}

@Injectable({
  providedIn: 'root'
})

export class AccountIbanService extends DataBase<RecAccountIban> {
  // getallUrl = 'account-iban';

  protected getAllUrl(): string {
    return 'account-iban' as const;
  }

}

