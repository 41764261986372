import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataUserServices, RecUserServiceMeta} from './data-user-services';
import {DataBase, DataBaseServiceConfig, FilterInfo, GetallResult} from '@og_soft/data-base';
import {CachingInterceptor} from '@og_soft/cache';


export interface RecDocumentsAgreements {
  cdId: number;
  cdDocId: number;
  cdType: string;
  cdDocType: string;
  cdSysTime: string;
  cdTpl: string;
  atTypeName: string;
  aValid: number;
  aNumber: string;
  atConfirm: number;
  aId: number;
  dateConfirmed: string;
  sa: any[]; // FIXME přesnější specifikaci typu místo any
}

export interface RecDocumentsAgreementsMeta {
  valid: string;
}

@Injectable({
  providedIn: 'root'
})

export class DataDocumentsAgreements extends DataBase<RecDocumentsAgreements> {
  // getallUrl = 'documents-agreements';

  protected getAllUrl(): string {
    return 'documents-agreements' as const;
  }

  constructor(public http: HttpClient
    ,         public serviceConfig: DataBaseServiceConfig
    ,         public userServices: DataUserServices,
              private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  protected dataPostprocess(data
                    :
                    GetallResult<RecDocumentsAgreements>
  ):
    GetallResult<RecDocumentsAgreements> {
    // FIXME optimalizovat, aby se to dělalo jedním getAll s parametry - viz. např. user-nodes.service.ts
    for (let i = 0;
         i < data.data.length;
         i++
    ) {
      for (let iSa = 0; iSa < data.data[i].sa.length; iSa++) {
        this.userServices.getone(data.data[i].sa[iSa].saId).subscribe(d => {
          data.data[i].sa[iSa].name = d.saName;
          data.data[i].sa[iSa].paramIdent = DataUserServices.saIdentGet(d);
          this.onDataCollected.emit();
        });
      }
    }
    return super.dataPostprocess(data);
  }

  metaFetchFilterInfo(meta: RecDocumentsAgreementsMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.valid && (meta.valid === 'true' || meta.valid === 'false')) {
        info.push({
          label: $localize`:@@DataDocumentsAgreementsServices.filterInfo.valid.label:Stav`,
          filterNames: ['valid'],
          value: (meta.valid === 'true'
                      ? $localize`:@@DataDocumentsAgreementsServices.filterInfo.valid.valid:Platný`
                      : $localize`:@@DataDocumentsAgreementsServices.filterInfo.valid.invalid:Naplatný`
          )
        });
      }
      return info;
    }

    return super.metaFetchFilterInfo(meta);
  }


}
