import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'page-empty',
  template: `
    <div class="page-empty">
      <p>{{message}}</p>
      <mat-icon>sentiment_dissatisfied</mat-icon>
    </div>
  `,
  styleUrls: ['./page-empty.component.scss']
})
export class PageEmptyComponent implements OnInit {

  @Input()
  message: string = "Bohužel zde nic není";

  constructor() { }

  ngOnInit() {
  }

}
