import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {InetDataService} from "../_services/inet-data.service";
import {FormGroup} from "@angular/forms";
import {DataUserServices, RecUserServices} from "../_services/data-user-services";
import {DataUnitsService, RecUnit} from "../_services/data-units.service";
import {MangoFormControl} from '../_models/mango-form-control';
import {BytesPipe} from '../_pipes/bytes-pipe';
import {MatLegacyTab as MatTab, MatLegacyTabGroup as MatTabGroup} from '@angular/material/legacy-tabs';

export interface saParam {
  name: string;
  type: string;
  title: string;
  value: string;
  saIdent: boolean;
}

export interface InetDataInOut {
  in: number;
  out: number;
}
export interface InetDataSum {
  ip: string;
  day: InetDataInOut;
  week: InetDataInOut;
  month: InetDataInOut;
}
export interface InetDataSums {
  total: InetDataSum;
  ips: InetDataSum[];
}

export interface fupRecord {
  enabled: boolean;
  limit: number;
  period: string;
  countType: string;
}

@Component({
  selector: 'app-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.scss']
})
export class DataViewComponent implements OnInit {

  form: FormGroup;

  private _services: RecUserServices[] = null;

  @Input()
  public get services(): RecUserServices[] {
    return this._services;
  }
  public get servicesLength(): number {
    return this.services === null ? null : this.services.length;
  }

  @ViewChild(MatTabGroup, {static: false}) public tabGroup: MatTabGroup;
  @ViewChild(MatTab, {static: false}) public tab: MatTab;

  private _inetData: any[] = [];
//  private _inetData: InetDataSums[] = [];

  constructor(public dataService: DataUserServices
              , public inetData: InetDataService
              , public unitsService: DataUnitsService
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
        ips: new MangoFormControl('')
      });
    this.form.controls.ips.valueChanges
        // .debounceTime(100)
        .subscribe((value) =>{
          this.ipChange(value)
        });

    /* Pokud bychom potřebovali jen aktivní služby, tak do params v gett all přidat saState: 1 */
    this.dataService.getall({scFlags: "SCF_DATA", saStates: "0,1"}).subscribe(p => {
      this._services = p.data;
    }
    , error => {
      console.log("DataViewComponent error", error);
    }
    );
  }

  protected tabSaIdToIndex(saId: number): number {
    let i = 0;
    for (i = 0; this.services !== null && i < this.services.length; i++) {
      if (this.services[i].saBaseId == saId) {
        return i;
      }
    }
    return undefined;
  }

  /**
   * Podle aktuálně vybrané záložky vrací id služby.
   */
  protected get tabSaId(): number {
    if (!this.tabGroup) {
      return null; // Asking too early.
    }
    return this.services[this.tabGroup.selectedIndex].saBaseId;
  }

  // FIXME nějak reagovat na změnu tab - https://stackoverflow.com/questions/42059151/angular-2-how-to-watch-for-tab-changes

  /**
   * Vrací seznam jednotek na kterých je služba poskytována. Pokud ještě informace o jednotkách nejsou k dispozici, tak
   * je asynchronně načítá ze serveru.
   * @param index
   */
  public unitsGet(index: number = null): RecUnit[] {
    if (index === null) {
      if (!this.tabGroup) {
        return null;
      }
      index = this.tabGroup.selectedIndex;
    }
    if (!this.services[index].units) {
      this.unitsService.getall({saId: +this.services[index].saId}).subscribe(units => {
        this._services[index].units = units.data;
      });
    }
    return this.services[index].units;
  }

  /**
   * Pro zadanou záložku (identifikovanou indexem) vrací text adresy pro zobrazení v záložce.
   * @param index
   */
  public labelUnitGet(index: number): string {
    const units = this.unitsGet(index);
    return units ? units.find(u => u !== undefined).addressShort : "";
  }

  /**
   * Vrací záznamy jednotek, na kterých je daná služba.
   */
  public get units(): RecUnit[] {
    return this.unitsGet();
  }

  /**
   * Vrací parametry, které jsou k zobrazení v selfCare.
   */
  public get serviceParams(): any[] {
    if (!this.tabGroup) {
      return null; // Asking too early.
    }
    return this.services[this.tabGroup.selectedIndex].params
      .filter(p => p.view.indexOf('selfcare') >= 0)
      .sort((a, b) => a.title.localeCompare(b.title));
      ;
  }

  public ipChange(value) {
    let labels = this.inetData.inetDataLabels(this.tabSaId, 'month');
    if (labels.length) {
      this.monthGraph2DataSet(this.tab.position
          , labels
          , this.inetData.inetDataAccumulated(this.tabSaId, 'month', 'in', value === undefined ? 'all' : this.inetData.inetIps(this.tabSaId)[value])
          , this.inetData.inetDataAccumulated(this.tabSaId, 'month', 'out', value === undefined ? 'all' : this.inetData.inetIps(this.tabSaId)[value])
      );
      if (this.services !== null) {
        this.monthGraph2OptionsSet(this.services[this.tab.position].saBaseId);
      }
    }
  }

  public inetIps(): {id: number, name: string}[] {
    return this.inetData.inetIps(this.tabSaId).map((v, i) => ({id: i, name: v}));
  }

  public inetDataGet(saId: number, ip?: string) {
    let i = this.tabSaIdToIndex(saId);
    if ((i !== undefined) && (!this._inetData[i])) {
      this.inetData.getone(saId).subscribe(p => {
        let labels = this.inetData.inetDataLabels(saId, 'month');
        if (labels.length) {
          this._inetData[i] = p;
          this.monthGraph2DataSet(i, labels, this.inetData.inetDataAccumulated(saId, 'month', 'in'), this.inetData.inetDataAccumulated(saId, 'month', 'out'));
          this.monthGraph2OptionsSet(saId);
        }
      }
      , error => {
        console.log("Error fetching inetData for service", saId, '/', i, 'error', error); // Just debug
      });
      this.inetData.fetchData(saId);
    }
    return this._inetData[i];
  }

  public inetDataSumGet(saId: number, typ: string, inOut: string, ip?: number) {
    return this.inetData.inetSum(saId, typ, inOut, ip === undefined ? 'all' : this.inetData.inetIps(saId)[ip]);
  }

  public graphMaxYGet(saId) {
    let dataIn = this.inetDataSumGet(saId, 'month', 'in', this.form.controls.ips.value);
    let dataOut = this.inetDataSumGet(saId, 'month', 'out', this.form.controls.ips.value);

    let calculated = BytesPipe.prototype.calculate(Math.max(dataIn, dataOut));
    return Math.ceil(calculated.value / 9) * 10 * Math.pow(1024, calculated.scale);
  }

  /**
   * Samotný graf pomocí angular2-chartjs (interface na chart.js)
   * - data, a manipulace s nimi
   * - options a manipulace s nimi
   * Dokumentace: https://www.chartjs.org/docs/latest/
   * nebo taky https://valor-software.com/ng2-charts/
   */
  public monthGraph2Data: any[] = [];

  protected monthGraph2DataSet(i: number, labels, dataIn, dataOut) {

    let maxIn = Math.max(...dataIn);
    let maxOut = Math.max(...dataOut);

    this.monthGraph2Data[i] = {
      labels: labels,
      datasets: [{
        label: 'In',
        lineTension: 0,
        pointRadius: 0,
        borderColor: 'rgba(0, 0, 255, 1)',
        backgroundColor: 'rgba(0, 0, 255, 0.1)',
        fill: (maxIn > maxOut) ? 1 : 'origin',
        data: dataIn,
      }, {
        label: 'Out',
        lineTension: 0,
        pointRadius: 0,
        borderColor: 'rgba(0, 192, 0, 1)',
        backgroundColor: 'rgba(0, 192, 0, 0.1)',
        fill: (maxOut >= maxIn) ? 0 : 'origin',
        data: dataOut,
      }]
    }
  }

  protected monthGraphDataGet(saId: number) {
    let i = this.tabSaIdToIndex(saId);
    return this.monthGraph2Data[i];
  }

  public monthGraph2Options: any[] = [];
  protected monthGraph2OptionsSet(saId: number) {
    let i = this.tabSaIdToIndex(saId);
    this.monthGraph2Options[i] = {
      responsive: true,
      maintainAspectRatio: true,
      // layout: {
      //   padding: 20,
      // },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: true,
          },
          ticks: {
            maxTicksLimit: 2,
            maxRotation: 0,
            display: true,
          },
        }],
        yAxes: [{
          positon: 'right',
          display: true,
          gridLines: {
            display: true,
          },
          ticks: {
            maxTicksLimit: 2,
            max: this.graphMaxYGet(saId),
            display: true,
            callback: (value, index, values) => {
              return BytesPipe.prototype.transform(value, 0);
            },
          },
        }],
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: (tooltipItems, data) => {
            return data.datasets[tooltipItems.datasetIndex].label + ": " + BytesPipe.prototype.transform(tooltipItems.yLabel);
          }
        },
      }
    }
  }

  protected monthGraph2OptionsGet(saId: number) {
    let i = this.tabSaIdToIndex(saId);
    return this.monthGraph2Options[i];
  }

  /**
   * FUP
   */
  public fupGet(saId: number) {
    let fup = this.services === null ? null : this.services[this.tabSaIdToIndex(saId)].fup;
    return fup;
  }

  private fupGraphData: any[] = [];


  public fupGraphDataGet(saId: number) {
    let i = this.tabSaIdToIndex(saId);
    if (this.fupGraphData[i] === null || this.fupGraphData[i] === undefined) {
      let fup = this.fupGet(saId);
      if (fup !== null) {
        let colors = [];
        let data = [];
        if (fup.data > fup.limit) {
          colors = ['#0000ff', '#ff0000'];
          data = [fup.limit, fup.data - fup.limit];
        } else {
          colors = ['#0000ff'];
          data = [fup.data, fup.limit - fup.data];
        }
        this.fupGraphData[i] = {
          labels: [],
          datasets: [{backgroundColor: colors, data: data}],
        };
      }
    }
    return this.fupGraphData[i];
  }

  public goPrevious() {
    // Stránkovadlo si samo hlídá meze, nemusíme testovat přetečení
    if (this.tabGroup && this.tabGroup.selectedIndex !== null) {
      this.tabGroup.selectedIndex = this.tabGroup.selectedIndex??1 - 1;
    }
  }

  public goNext() {
    // Stránkovadlo si samo hlídá meze, nemusíme testovat přetečení
    if (this.tabGroup && this.tabGroup.selectedIndex !== null) {
      this.tabGroup.selectedIndex = this.tabGroup.selectedIndex??0 + 1;
    }
  }
}
