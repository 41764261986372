import {Component, ContentChild, Input, OnInit} from '@angular/core';
import {ForgetTableFiltersComponent} from '../forget-table-filters/forget-table-filters.component';
import {ForgetTableComponent} from '../forget-table/forget-table.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
  template: `
    <div class="mobile-button" *ngIf="isMobile">
      <ng-container *ngIf="tableFilters">
        <ng-container *ngTemplateOutlet="addFilterButton"></ng-container>
      </ng-container>
    </div>
    <div class="forget-table-header">
      <ng-content select="h1,h2,h3,h4,h5,h6"></ng-content>
      <ng-container *ngIf="tableFilters">
        <mat-chip-list *ngIf="tab && tab.filterInfo && tab.filterInfo.length">
          <mat-chip class="filter-chip" *ngFor="let f of (tab && tab.filterInfo ? tab.filterInfo : [])"
                    [removable]="true" (removed)="removeFilters(f.filterNames)"
          >
            {{f.value}}
            <span *ngIf="f.valueDetail" class="filter-chip-detail">
            &nbsp;{{f.valueDetail}}
            </span>
            <mat-icon matChipRemove>cancel</mat-icon>
            <span class="filter-chip-label">
            {{f.label}}
            </span>
          </mat-chip>
        </mat-chip-list>

        <ng-container *ngIf="!isMobile">
          <div class="filler"></div>
          <ng-container *ngTemplateOutlet="addFilterButton"></ng-container>
        </ng-container>
      </ng-container>
      <ng-content></ng-content>
      <ng-content select=".mgt-act-menu"></ng-content>
    </div>
    <ng-content select="mgt-filters"></ng-content>
    <ng-template #addFilterButton>
      <button mat-button
              disableRipple="true"   
              (click)="tableFilters.toggleFiltering(filterValues); $event.stopPropagation()"
              i18n="@@table.filters-toggle">
        Přidat filtry
      </button>
    </ng-template>
  `,
  selector: 'mgt-header',
  styleUrls: ['./forget-table-header.component.scss']
})
export class ForgetTableHeaderComponent implements OnInit {
  @Input() public filterValues: any;
  @Input() public tab: ForgetTableComponent;

  @ContentChild(ForgetTableFiltersComponent, {static: false}) public tableFilters !: ForgetTableFiltersComponent;

  public isMobile: boolean;

  constructor(
    private deviceDetector: DeviceDetectorService,
    private breakpointObserver: BreakpointObserver,
  ) {
    // Tohle bylo dobře udělané na jakýkoli mobil, leč bylo 
    // připomínkováno, že na těch větších by to pořád mohlo
    // vypadat jako na desktopu.
    //this.isMobile = this.deviceDetector.isMobile();
    this.breakpointObserver.observe(['(max-width: 599px)']).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
  }

  removeFilters(filters: string[]): void{
    if (this.filterValues){
      let changed = false;
      filters.forEach(filter => {
        if (this.filterValues.hasOwnProperty(filter)) {
          changed = true;
          this.filterValues[filter] = '';
        }else{
          throw new Error('Filter ' + filter + ' not found! Check setting filterNames in metaFetchFilterInfo()');
        }
      });
      if (changed){
        this.tab.filtersApply();
      }
    }else{
      throw new Error('Table header need access to filterValues.');
    }
  }
}
