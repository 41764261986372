import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from '../_services/session.service';
import {Router} from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'config-setting',
  templateUrl: './config-setting.component.html',
  styleUrls: ['./config-setting.component.scss']
})
/**
 * Speciální komponenta sloužící ke konfiguraci selfcare
 * (zjištění názvů inputů a tlačítek pro nastavení práv).
 * V db je potřeba nastavit option SELFCARE.config.user na hodnotu id zákazníka, přes kterého
 * má jít selfcare konfigurovat. Po přihlášení na tohoto zákazníka by potom v sc:
 *  - u všech inputů zobrazená ikona (k tomu je tato komponeta) s kompletním názvem inputu (pro právo na disablování atd.)
 *  - u tlačítek s právy taky
 *  - měly by být vidět a jít otevřít všechyn moduly
 *  - kde není implementováno, tak doplnit
 */
export class ConfigSettingComponent implements OnInit {
  @Input() input: string;
  @Input() formName: string;
  @Input() fullName: string;

  show = false;
  configName: string;

  constructor(
      public session: SessionService,
      private router: Router
  ) {
    if (this.session.isConfigUser()) {
      this.show = true;
    }
  }

  ngOnInit() {
    if (this.fullName) {
      this.configName = this.fullName;
    } else {
      switch (this.router.url) {
        case '/user-profile':
          this.configName = SessionService.RIGHT_BASE + '.user-profile.edit.' + this.formName + '.' + this.input;
          break;
        case '/registration-config':
          this.configName = SessionService.RIGHT_BASE + '.registration.' + this.formName + '.' + this.input;
          break;
        default:
          this.configName = SessionService.RIGHT_BASE + '.' + this.formName + '.' + this.input;
          break;
      }
    }
  }

  copyToClipboard(): void {
    console.log('Název byl zkopírován do schránky: ', this.configName);
    navigator.clipboard.writeText(this.configName).then().catch(e => console.log(e));
  }

}
