import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import * as Sentry from '@sentry/angular';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error): void {
    const err = error.originalError || error;
    // Prozatím nechme chyby logovat do konzole, pro debugování je to důlěžité
    // Až nasadíme k používání a bude dobře fungovat sentry, tak můžeme potlačit
    //   if (!environment.production) console.error(err);
    console.error(err);
    if (environment.sentryDns) {
      Sentry.captureException(error.originalError || error);
    }
    // Sentry.showReportDialog({eventId});
  }
}
