import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';
import {Observable} from 'rxjs';
import {PayOrder} from './pay-order.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Servisa pro zpracování platby kartou
 */
export class Payment3dsecureRequestService extends DataBase<{request: string}>{
  // getallUrl = 'payment-3dsecure-request';

  protected getAllUrl(): string {
    return 'payment-3dsecure-request' as const;
  }

  /**
   * Vytvoří URL requestu ze zadaných dat v závislosti na implementované platební bráně pro danou lokaci/CP.
   * @param amount částka k zaplacení
   * @param payOrder data o payOrder vytvořená v předchozím kroku.
   * @param returnUrl - pokud není zadáno, je vytvořeno automaticky
   * @return Observable<string> URL požadavku na danou platební bránu.
   */
  public request(amount: number, payOrder: PayOrder, returnUrl: string): Observable<{request: string}> {
    return this.post({
      poId: payOrder.result,
      amount,
      returnUrl
    });
  }
}
