import {Component, OnInit} from '@angular/core';
import {DialogConfig, DialogRef} from '@og_soft/dialog';
import {SessionService} from '../../_services/session.service';
import {UserEditFormService, UserEditFormType} from '../../user-edit/user-edit-form.service';
import {DataUserEdit, UserType} from '../../_services/user-edit.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataParamsUser} from '../../_libraries/mango-users/data-params-user.service';

@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss']
})
export class UserProfileDialogComponent implements OnInit {
  public formType: UserEditFormType;
  public heading = 'Confirm';

  public USER_EDIT_FORM_TYPE = UserEditFormType;

  private userEditConfig: {} = {
      personal: {
        title: $localize`:@@UserProfile.personal.dialog.title:Změna osobních údajů`
      },
      addresses: {
        title: $localize`:@@UserProfile.addresses.dialog.title:Změna adresy`
      },
      contacts: {
        title: $localize`:@@UserProfile.contacts.dialog.title:Změna kontaktních údajů`
      },
      credentials: {
        title: $localize`:@@UserProfile.credentials.dialog.title:Změna přihlašovacích údajů`
      },
      payments: {
        title: $localize`:@@UserProfile.payments.dialog.title:Změna platebních údajů`
      },
      params: {
        title: $localize`:@@UserProfile.params.dialog.title:Další nastavení`
      },
    };


  constructor(public config: DialogConfig,
              public session: SessionService,
              private dlgRef: DialogRef,
              private userEditService: DataUserEdit,
              private userEditFormService: UserEditFormService,
              public userParamsService: DataParamsUser,
  ) {

  }

  ngOnInit(): void {
    this.formType = this.config.data.editMode;
    this.heading = this.userEditConfig[this.formType].title;
  }

  saveProfile(): void {
    this.session.processingSet(true,
      $localize`:@@UserProfileDialog.processing.save.message:Ukládám...`
    );
    this.getUserType().subscribe(userType => {
      this.userEditFormService.getFormData(this.formType, userType).subscribe(data => {
        if (data) {
          console.log('Data k uložení: ', data);
          this.userEditService.post(data).subscribe(userData => {
            this.session.processingSet(false);
            this.session.message($localize`:@@UserProfile.editUser.message:Změny v profilu byly úspěšně uloženy.`);
            this.userParamsService.clearCache();
            this.dlgRef.close();
          }, err => {
            this.session.processingSet(false);
            console.error('Jejda, chyba při ukládání:', err);
            return false;
          });
        }else{
          this.session.processingSet(false);
        }
      }, error => {
        this.session.processingSet(false);
      });
    });
  }

  private getUserType(): Observable<UserType>{
    if (this.formType === UserEditFormType.personal){
      // Pokud edituji osobní údaje nebo jsme  v registrace, aktuální typ mám ve formuláři.
      return of(this.userEditFormService.getForm(UserEditFormType.personal).get('type').value);
    }else{
      // Jinak si ho získám z db
      return this.userEditService.getSingleton().pipe(map(user => user.type));
    }
  }
}
