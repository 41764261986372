<form [formGroup]="form">
<p class="title" i18n="@@PasswordChange.password.title">Heslo buď musí být alespoň 12 znaků dlouhé, anebo musí být nejméně 8 znaků dlouhé a obsahovat malá i velká písmena a číslice a žádný znak se nesmí opakovat více než dvakrát za sebou.</p>
<div *ngIf="formHelper.isVisible(form.get('password'))">
                      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
                        <mat-label i18n="@@PasswordChange.password.label">Heslo</mat-label>
                        <input matInput type=password formControlName="password" id="password" name="password" i18n-title="@@PasswordChange.password.title" title="Heslo buď musí být alespoň 12 znaků dlouhé, anebo musí být nejméně 8 znaků dlouhé a obsahovat malá i velká písmena a číslice a žádný znak se nesmí opakovat více než dvakrát za sebou."
                               [required]="form.get('password') | hasRequiredField"
                        >
                        <config-setting input="password" [formName]="formName"></config-setting>
                        <mat-error *ngIf="form.get('password').invalid && (form.get('password').dirty || form.get('password').touched)">
                          {{getErrorMessage(form.get('password'))}}
                        </mat-error>
                      </mat-form-field>
                    </div>



  <div *ngIf="formHelper.isVisible(form.get('passwordCheck'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@PasswordChange.passwordCheck.label">Heslo ještě jednou</mat-label>
      <input matInput type=password formControlName="passwordCheck" id="passwordCheck" name="passwordCheck"
             i18n-title="@@PasswordChange.passwordCheck.title" title="" [errorStateMatcher]="errorMatcher"
             [required]="form.get('passwordCheck') | hasRequiredField"
      >
      <config-setting input="passwordCheck" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('passwordCheck').invalid && (form.get('passwordCheck').dirty || form.get('passwordCheck').touched)">
        {{getErrorMessage(form.get('passwordCheck'))}}
      </mat-error>
      <mat-error *ngIf="form.errors?.passwordCheck && (form.get('passwordCheck').dirty || form.get('passwordCheck').touched)">
        <span i18n="@@PasswordChange.error.notIdentical">Heslo musí být zadáno dvakrát stejné.</span>
      </mat-error>
    </mat-form-field>
  </div>

</form>

