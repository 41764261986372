import {Injectable} from '@angular/core';
import {DataBase} from "@og_soft/data-base";

export interface RecUnit {
  id: number;
  name: string;
  address: string;
  addressShort?: string;
  socket: string;
  saId: number;
}

@Injectable({
  providedIn: 'root'
})
export class DataUnitsService extends DataBase<RecUnit> {
  // getallUrl = "units";

  protected getAllUrl(): string {
    return 'units' as const;
  }

  public static shortenAddress(address?: string): string {
    if (address) {
      return address.replace(/,\s*\d+$/, '');
    }
    return address;
  }

  protected recordPostprocess(record: RecUnit): RecUnit {
    record.addressShort = DataUnitsService.shortenAddress(record.address);
    return super.recordPostprocess(record);
  }
}
