import {Component, Input, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import {IPageInfo, VirtualScrollerComponent} from 'ngx-virtual-scroller';

import {animate, style, transition, trigger} from '@angular/animations';
import {BehaviorSubject, Observable} from 'rxjs';
import {DataBase, GetallResult} from '@og_soft/data-base';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mgt-table',
  template: `
      <button *ngIf="scrollToTopVisible"
              mat-icon-button
              [@animateScrollControls]="scrollToTopVisible ?'in' :'out'"
              class="forget-table-navigate-top"
              (click)="vscroller.window.scrollTo(0, 0)"
      >
          <mat-icon>first_page</mat-icon>
      </button>
      <virtual-scroller #vscroller
                        class="forget-table xmat-elevation-z3"
                        [items]="buffer"
                        [parentScroll]="localScroll ? null : vscroller.window"
                        [enableUnequalChildrenSizes]="true"
                        [useMarginInsteadOfTranslate]="true"
                        [bufferAmount]="32"
                        (vsUpdate)="maybeUpdate($event)"
                        (vsEnd)="fetchMore($event)">
          <!-- bufferAmount je tam proto, aby expandování prvního a hlavně posledního záznamu nekmitalo. Pokud bude i přes to kmitat, je možné, že je ta hodnota malá a je potřeba přidat. -->

          <ng-content></ng-content>

          <div *ngIf="loading" class="loading-placeholder">
              <mat-spinner diameter="20"></mat-spinner>
          </div>

      </virtual-scroller>

      <div #nodataalt><ng-container *ngIf="noData"><ng-content select="[no-data-alternate-content]"></ng-content></ng-container></div>

      <div *ngIf="atEnd && (!noData || !nodataalt || !nodataalt.childelementCount)" class="forget-table-loading-finished">
          <div class="forget-table-plug-wrapper">
              <ng-content select="[plug]"></ng-content>
          </div>
          <div class="forget-table-plug mango-text-secondary"><!-- Víc už toho není --></div>
      </div>
  `,
  styleUrls: ['./forget-table.component.scss'],
  animations: [
    trigger('animateScrollControls', [
      transition('void=>in,*=>in', [
        style({opacity: 0}),
        animate('150ms ease-in', style({opacity: '*'}))
      ]),
      transition('in=>*,in=>void', [
        style({opacity: '*'}),
        animate('1000ms ease-out', style({opacity: 0}))
      ])
    ])
  ],
})
export class ForgetTableComponent implements OnChanges {
  @Input()
  get service(): any {
    return this._service;
  }

  set service(value: any) {
    this._service = value;
    if (this._service && this._service instanceof DataBase) {
      this._service.onDataCollected.subscribe(() => this.vscroller.invalidateAllCachedMeasurements());
    }
  }

  /**
   * Počet všech záznamů, jak ho vrací API v atributu "total" výsledku.
   */
  public get nrecords(): number {
    return this._nrecords;
  }

  public get filterInfo(): string {
    return this._filterInfo;
  }

  /**
   * Jsme na definitivním konci?
   */
  public get atEnd(): boolean {
    return this._nrecords !== undefined && this._nrecords <= this.buffer.length;
  }

  /**
   * Je množina dat od začátku prázdná? Pokud ano, můžeme na základě toho zobrazit
   * alternativní obsah.
   */
  public get noData(): boolean {
    return this._nrecords == 0;
  }

  /**
   * Nastavuje, že tabulka bude používat vlastní posuvník místo posuvníku okna.
   *
   * Výchozí je false (používá se posuvník okna). Je-li nastaveno na true, musí mít
   * tabulka omezenou výšku (css vlastností height nebo max-height). Jinak by se na
   * server nekonečně posílal jeden request za druhým.
   */
  @Input()
  public get localScroll(): boolean {
    return this._localScroll;
  }

  public set localScroll(val: boolean) {
    this._localScroll = val;
  }

  // Venkovní příznak zda je zobrazeno tlačítko pro posun na začátek tabulky.
  @Input()
  public get scrollToTopVisible(): boolean {
    return this._scrollToTopVisible;
  }
  public get bufferSubj(): Observable<any[]> {
    if (!this._bufferSubj) {
      this._bufferSubj = (new BehaviorSubject([]) as BehaviorSubject<any[]>);
    }
    return this._bufferSubj.asObservable();
  }


  /**
   * @ignore
   */
  constructor() {
  }
  /**
   * Udává, že právě načítáme záznamy - na základě toho zobrazujeme uživateli spinner.
   */
  public loading: boolean;

  /**
   * Hodnoty filtrů
   *
   * Stav filtrů by měla udržovat komponenta, která nás používá.
   *
   * "Nejsložitější" případ, kdy existují statické hodnoty filtrů
   * a zároveň jde některé filtry měnit uživatelsky formulářem a/nebo
   * nastavovat z routy, jde udělat třeba takto:
   *
   * export class MojeKomponenta implements OnInit {
   *   filtersFormGroup: FormGroup;
   *   filterValues: object = { statickyFiltr: 'hodnota' };
   *
   *   ngOnInit() {
   *     this.filtersFormGroup = new FormGroup({
   *        statickyFiltr: new FormControl(''),
   *        uzivatelskyFiltr: new FormControl(''),
   *      });
   *      this.filtersFormGroup.patchValue(this.filterValues);
   *
   *      this.route.params.subscribe(params => {
   *        this.filterValues['uzivatelskyFiltr'] = params['id'] || null;
   *        this.filtersFormGroup.patchValue(this.filterValues);
   *      });
   *    }
   *  }
   */
  @Input() filters: object;

  /**
   * Privátní proměnná, kde si držíme načtené záznamy.
   */
  public buffer: any[] = [];

  /**
   * Ty záznamy, které se skutečně zobrazují.
   *
   * Nastavuje je událost vsUpdate - naše metoda maybeUpdate na buffer se záznamy,
   * načtenými ze služby.
   */
  public scrollItems: any;

  /**
   * Nechceme přeplácnout proměnnou scrollItems, pokud jsme skutečně něco nového
   * nenačetli - jinak se to celé přerenderuje s následkem ztráty stavu (sbalení
   * rozbalených záznamů a pod).
   */
  protected ignoreThisBoringUpdate = false;

  /**
   * Služba, která poskytuje data pro tabulku.
   */
  private _service = null; // TODO: typ - asi udělat interface

  protected _nrecords: number = undefined;

  protected _filterInfo: any = undefined;

  protected _localScroll = false;

  // Interní příznak zobrazení tlačítka na posun na začátek tabulky.
  protected _scrollToTopVisible = false;

  // Ovládá automatické skrývání tlačítka na posun na začátek tabulky.
  private _scrollToTopTimer: any = undefined;

  // Z tohohle je možné ve vlastnosti viewPortInfo získat mj. indexy aktuálně
  // zobrazených záznamů - s nastavenou vlastností localScroll bychom je mohli
  // zobrazovat.
  @ViewChild('vscroller', {static: false}) public vscroller: VirtualScrollerComponent;

  private _bufferSubj: BehaviorSubject<any[]>;

  protected oldStart: any = undefined;

  public scrollToTopShow() {
    if (this.localScroll) {
      this._scrollToTopVisible = false;
      return;
    }
    if (this._scrollToTopTimer) {
      clearTimeout(this._scrollToTopTimer);
      this._scrollToTopTimer = undefined;
    }
    if (this.vscroller) {
      this._scrollToTopVisible = this.vscroller.viewPortInfo.startIndex > 0;
    }
    if (this._scrollToTopVisible) {
      const self = this;
      this._scrollToTopTimer = setTimeout(() => {
        self.scrollToTopHide();
      }, 5000);
    }
  }

  public scrollToTopHide() {
    this._scrollToTopVisible = false;
    if (this._scrollToTopTimer) {
      clearTimeout(this._scrollToTopTimer);
    }
  }

  public scrollToTop() {
    if (this.localScroll) {
      this.vscroller.scrollToPosition(0, 300);
    } else {
      this.vscroller.window.scrollTo(0, 0);
    }
  }

  /**
   * Metoda, kterou volá VirtualScroller, aby načetla nové záznamy,
   * když uživatel doscrolluje na konec.
   *
   * @param {ChangeEvent} event Událost z VirtualScrolleru
   * @param filter
   */
  public fetchMore(event?: IPageInfo, filter?: object) {
    // console.log("ForgetTable: ** In fetchMore, event:", event);
    this.scrollToTopShow();
    if (this.atEnd) {
      return;
    }
    // Je potřeba přijít na to, jak to zarážkovat správně už z té knihovny.
    // Ono se to zdá dobré - ale ještě si to pohlídáme.
    if (this.loading) {
      return;
    }
    if (event && (event.endIndex !== this.buffer.length - 1)) {
      // console.log("ForgetTable: uninteresting vsEnd event - end is", event.end, ", buffer length ", this.buffer.length);
      this.ignoreThisBoringUpdate = true;
      return;
    }
    this.loading = true;
    const offset = this.buffer.length;
    const chunksize = 10;
    this.fetchNextChunk(offset, 10, filter).then(chunk => {
      if (! this.loading) {
        // Tohle je tu nově kvůli tomu, abychom při
        // aplikování filtrů mohli zrušit případný právě
        // probíhající request (fakticky ho nezrušíme,
        // ale můžeme nastavit this.loading na false -
        // jinak by nám právě probíhající request nepustil
        // ten se změněnými filtry ke slovu), a zároveň
        // aby se ta data ze starého requestu
        // nepřeplácla k těm novým.
        return;
      }
      // XXX Tohle je v originalni dokumentaci. Nepoužívat raději splice?
      this.buffer = this.buffer.concat(chunk.data);
      this._nrecords = chunk.total;
      this._filterInfo = chunk.filterInfo;
      this.loading = false;
      if (this._bufferSubj) {
        this._bufferSubj.next(chunk.data);
      }
    }, (e) => {
      this.loading = false;
      // Pokud dojde k chybě uvnitř této promisy, tak se chyba nepropaguje dál (nevypíše se do
      // konzole, nepošle se do sentry). Proto ji tu musím explicitně vyvolat.
      throw e;
    });
  }

  protected fetchNextChunk(skip: number, limit: number, filter?: object): Promise<GetallResult<any>> {
    return new Promise((resolve, reject) => {
      // Z filters potřebujeme vyhodit věci s nulovými hodnotami (hodnotami nenastavených filtrů)
      const params = { offset: skip, limit };
      for (const prop in this.filters) {
        if (Object.prototype.hasOwnProperty.call(this.filters, prop)) {
          if (this.filters[prop] !== null && this.filters[prop] !== undefined) {
            params[prop] = this.filters[prop];
          }
        }
      }
      // console.log("In fetchNextChunk, this.filters=", this.filters, ", params=", params);
      this.service.getall(params)
        .subscribe(p => {
          resolve(p);
        }, err => {
          reject(err);
        });
    });
  }

  public maybeUpdate(e) {
    // Snažíme se tady ošetřovat nechtěné okamžité sbalování
    // záznamu, který se uživatel pokouší rozbalit, a konec rozbaleného
    // detailu řádku přeleze konec okna prohlížeče.
    // console.log("ForgetTable: in vsUpdate, evnet: ", e, ", scrollItems: ", this.scrollItems, ", old start: ", this.oldStart);
    if (this.oldStart && e[0] == this.oldStart && this.scrollItems && this.scrollItems.length >= e.length) {
      // console.log("  - IGNORING");
      return;
    } else {
      // console.log("  - PROCESSING");
      this.oldStart = e[0];
    }
    this.scrollItems = e;
  }

  /**
   * Metoda pro celkové opakované načtení dat
   */
  public fetchAgain() {
    this._nrecords = undefined;
    this.buffer = [];
    this.fetchMore();
  }

  public cancelLoading() {
    console.log('Table: loading canceled');
    this.loading = false;
  }

  /**
   * Aplikuje nové hodnoty filtrů.
   * Nově by ji nemělo být třeba externě volat. Dělá jen
   * to, že smaže buffery a způsobí nové načtení dat.
   * Filtry nově nemáme jako svůj interní stav (dělalo to
   * problémy při jejich nastavování například na základě
   * URL při inicializaci komponenty). Místo toho jejich
   * stav udržuje controler komponenty, která tabulku
   * používá. Příklad na fungující použití spolu s implicitními
   * filtry, které tam mají být vždycky, je teď v isp-sc
   * v user-services.component. (Myšlenka je, že
   * ve FormGroup toho filtrového formuláře pro
   * ty statické filtry existuje FormControl a v ngInit
   * komponenty, která tabulku používá se té FormGroup
   * udělá patchValue na výchozí stav filtrů (který tam
   * už v filterValues je). Při potvrzení filtrového
   * dialogu (udělá se this.filterValues = $event)
   * tam jde i ta hodnota toho "statického" filtru.
   *
   */
  public filtersApply() {
    this.fetchAgain();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("In table.ngOnChanges: changes=", changes);
    if (changes.filters) {
      this.filtersApply();
    }
  }

  myCompareItems(a: any, b: any) {
    return a == b;
  }

  /**
   * Vymění jeden záznam v buferech tabulky. Pokud nenajde, přidá na konec.
   * @param recordNew Nový záznam pro vložení do tabulky.
   * @param fnCompare Funkcionální callback pro porovnání záznamů (každý má jiné ID).
   */
  public recordUpdate(recordNew: any, fnCompare: (a: any, b: any) => boolean): void {
    // Primární interní buffer
    let bufferFound = false;
    for (let i = 0; i < this.buffer.length; i++) {
      if (fnCompare(recordNew, this.buffer[i])) {
        recordNew.__rowExpanded = this.buffer[i].__rowExpanded;
        this.buffer[i] = recordNew;
        bufferFound = true;
      }
    }
    if (!bufferFound) {
      this.buffer.push(recordNew);
    }

    // To samé pro buffer virutalScrolleru
    if (!Array.isArray(this.scrollItems)) {
      this.scrollItems = [];
    }
    let scrollerFound = false;
    for (let i = 0; i < this.scrollItems.length; i++) {
      if (fnCompare(recordNew, this.scrollItems[i])) {
        // Snad toto nebude nutit virtualScroller dělat reaload.
        this.scrollItems[i] = recordNew;
        scrollerFound = true;
      }
    }
    if (!scrollerFound) {
      this.scrollItems.push(recordNew);
    }
  }

  public recordRemove(id: any, fnCompareId: (a: any) => boolean): void {
    this.buffer = this.buffer.filter(record => !fnCompareId(record));
    this.scrollItems = this.scrollItems.filter(record => !fnCompareId(record));
  }
}
