import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';


export function dateRangeValidator(min: Date, max: Date): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    const date = new Date(control.value);
    let fail = 0;

    if (min && date < min) {
      fail = 1;
    }

    if (max && date > max) {
      fail = 1;
    }

    return fail ? {dateRange: {value: control.value, min, max}} : null;
  };
}


export function numberDecimalPlacesValidator(maxPrecision: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    const num = control.value;

    // Pozor, zde narážíme na problém se zaokrouhlováním
    // Pokud jde o hodnotu z inputu typu number, tak ji JS v nějakou chvíli musí zaokrouhlit
    // - zaokrouhlená hodnoty přijde už z toho value controlu (samotné textové hodnotě inputu
    // nemáme v tuto chvíli jednoduchý přístup). Proto validace nemusí vždy vrátit očekávaný výstup.
    // Předpokládám, že pro jednoduché případy to bude fungovat v pořádku.
    // Ale např. pro 0,99999999999999999 (to už bude js reprezentovat jako 1) validace projde,
    // i když je omezená na menší množství desetinných míst.

    return (((num * 10 ** maxPrecision) % 1) !== 0) ? {
      decimalPlaces: {
        value: control.value,
        precision: maxPrecision
      }
    } : null;
  };
}

/**
 * Tento validátor odpovídá validátoru TModuloValidator z frontendu.
 */
export function moduloValidator( modulo: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {


    if ( control.value ) {
      const value = control.value;
      const length = control.value.length;
      let base = 1;
      let total = 0;
      for (let i = length - 1; i >= 0; i--) {
        total += parseInt(value[i], 10) * base;
        base *= 2;
      }
      const valid = !Number.isNaN(total) && !(total % modulo);
      // console.log('Jak je to dlouheeeeeeeeeeeeeeeee', total, ", valid=", valid, ", total neni NaNa=", !Number.isNaN(total));
      if (! valid) {
        return { modulo : {value: control.value}};

      }
    }

    return null;

  };
}

/**
 * Tento validátor odpovídá validátoru TBornNumValidator z frontendu.
 */
export function personalIdValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!control.value){
      return null;
    }

    const born = (control.value as string).replace('/', '').replace(' ', '');

    let year: number;

    if (born.length === 9){
      year = +born.substring(0, -7);
      if (year >= 54){
        return {bornNum: {value: control.value}};
      }else{
        return null;
      }
    }else if (born.length === 10){
      if (((+born) / 11) === Math.floor(+born / 11)){
        return null;
      }else{
        return {bornNum: {value: control.value}};
      }
    }
    return {bornNum: {value: control.value}};
  };
}

export const passwordCheckValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const passwordCheck = control.get('passwordCheck');
  // console.log('XXXXXXXXXXXXXXXXXXXXXX validuji pass: '+password.value+' check: '+passwordCheck.value);
  return password.value !== passwordCheck.value ? {passwordCheck: true} : null;
};

/**
 * Třída, která zajišťuje, aby v případě validace napříč více itemy (validace FormGroup) se mohli tyto itemy zobrazovat
 * jako nevalidní. V opačném případě nezafunguje mat-error
 */
export class CrossFieldErrorMatcher implements ErrorStateMatcher {

  /**
   * @param errorsFilter List of errors to match with the assigned field, otherwise will get invalid on any error at form group level
   */
  constructor(protected errorsFilter: string[] = []) {}

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    // console.log('Control:', control, 'Form:', form, 'Form invalid:', form.invalid, 'control touched:', control.touched, 'control invalid:', control.invalid)
    return ((control.dirty && form.invalid && (this.errorsFilter.length === 0 || this.errorsFilter.some((error) => {
      return form.hasError(error);
    }))) || ((control.dirty || control.touched) && control.invalid));
  }
}

export const emailCommunicationValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const infoEmail = control.get('infoEmail');
  const emails = control.get('emails');
  return infoEmail.value && !emails.value ? {emailCommunication: true} : null;
};

export const phoneCommunicationValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const infoSms = control.get('infoSms');
  const phones = control.get('phones');
  return infoSms.value && !phones.value ? {phoneCommunication: true} : null;
};

export const communicationChannelValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const infoEmail = control.get('infoEmail');
  const infoPost = control.get('infoPost');
  return !infoEmail.value && !infoPost.value ? {communicationChannel: true} : null;
};
