<dialog-title>
  <button mat-icon-button dialog-close>
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span *ngIf="documentNameTpl" class="agreement-name">
    <span i18n="@@DocumentsAgreements.confirmAgree.confirmDocumentTitle">Potvrzení smlouvy</span>: <ng-container *ngTemplateOutlet="documentNameTpl"></ng-container>
  </span>
</dialog-title>

<dialog-content>
  <div class="pdf-viewer-wrapper">
    <pdf-viewer
      [src]="agreementDocumentUrl"
      [render-text]="true"
      [stick-to-page]="true"
      [fit-to-page]="true"
      [show-all]="true"
      (after-load-complete)="handleLoadComplete()"
    ></pdf-viewer>
  </div>
  <div class="agree-button">
    <mat-checkbox
      *ngIf="isDocumentLoaded"
      [(ngModel)]="isConfirmed"
      i18n="@@DocumentsAgreements.confirmAgree.button"
    >Potvrzuji, že jsem smlouvu četl a souhlasím s jejím obsahem.</mat-checkbox>
  </div>
</dialog-content>

<dialog-actions>
  <div *ngIf="isConfirmed; else scrollToConfirmHintTpl" class="actions">
    <button
      mat-flat-button
      color="primary"
      (click)="handleSubmit()"
      [disabled]="isConfirmed !== true"
      i18n="@@DocumentsAgreements.confirm.button"
    >Potvrdit</button>
  </div>
  <ng-template #scrollToConfirmHintTpl>
    <div class="scroll-to-confirm-hint" i18n="@@DocumentsAgreements.confirmAgree.scrollToConfirm">Pro potvrzení musíte pod poslední stránkou smlouvy potvrdit, že jste ji četli.</div>
  </ng-template>
</dialog-actions>
