import {Injectable} from '@angular/core';
import {DataParams} from '../_libraries/param-form/_services/data-params.service';
import {ParamBase, ParamObjectType} from '../_libraries/param-form/_models/param-base';
import {DataValueDomainPatterns} from '../_libraries/param-form/_services/data-value-domain-pattern';
import {DataCodeList} from '../_libraries/param-form/_services/data-code-list';
import {DataValueDomain} from '../_libraries/param-form/_services/data-value-domain';
import {DataProcessPremises} from '../_services/process-premises.service';
import {DataProcessServices} from '../_services/process-services.service';
import {DataProcessUnits} from '../_services/process-units.service';
import {Observable} from 'rxjs';
import {RecNodeParam} from '../_services/data-nodes.service';
import {map} from 'rxjs/operators';
import {DependencyNodesService} from "../_services/dependency-nodes.service";


/**
 */
@Injectable({
  providedIn: 'root'
})
export class DataNodeParamsService extends DataParams {
  // getallUrl = 'node-params';

  protected getAllUrl(): string {
    return 'node-params' as const;
  }

  public transformParams(paramData: any, services: {
    dataValuePatterns: DataValueDomainPatterns;
    dataCodeList: DataCodeList;
    dataValueDomain: DataValueDomain;
    dataProcessPremises: DataProcessPremises;
    dataProcessServices: DataProcessServices;
    dataProcessUnits: DataProcessUnits;
    dependencyNodesService: DependencyNodesService;
  }): ParamBase {
    const options = {
      id: paramData.id,
      defId: paramData.defId,
      value: paramData.value,
      defaultValue: paramData.defaultValue,
      objectType: ParamObjectType.Node,
      objId: paramData.objId,
      name: paramData.name + (paramData.indexed ? '_' + paramData.index : ''),
      indexName: paramData.name,
      caption: paramData.caption,
      required: paramData.required,
      order: paramData.order,
      type: paramData.type,
      disabled: paramData.disabled,
      indexed: paramData.indexed,
      index: paramData.index,
      domain: paramData.domain,
      hrValue: paramData.hrValue,
      visibilityDependency: paramData.visibilityDependency,
      editDependency: paramData.editDependency,
      requireDependency: paramData.requireDependency,
      visibility: paramData.visibility,
    };

    return new ParamBase(options, services);
  }

  /**
   * Transformuje parametry pro zobrazení v tabulce, už i seřazené.
   */
  public getParamsForView(params: any, cacheTimeout?: number): Observable<RecNodeParam[]>{
    return this.getall(params, cacheTimeout).pipe(map(nodeParams => {
      return nodeParams.data.map(paramData => {
        return {
          name: paramData.name,
          index: paramData.index,
          title: paramData.caption,
          value: paramData.hrValue,
          editable: paramData.editDependency !== '',
          visible: paramData.visibility,
        };
      }).sort((a, b) => a.title.localeCompare(b.title));
    }));
  }
}
