import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';

export interface ProcessParamDef {
  name: string;
  type: string;
  domain: string;
  visibilityDependency: string;
  editDependency: string;
  requireDependency: string;
  caption: string;
  defaultValue: string;
  description: string;
  mttId: number;
  groupType: string;
  defId: number;
  indexed: boolean;
  unique: boolean;
  order: number;
  group: [
    {
      id: number;
      position: string;
      type: string;
      caption: string;
    }
  ];
}


@Injectable({
  providedIn: 'root'
})
export class ProcessParamsDefService extends DataBase<ProcessParamDef> {
  // getallUrl = 'process-params-def';

  protected getAllUrl(): string {
    return 'process-params-def' as const;
  }
}
