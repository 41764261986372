import {Injectable} from '@angular/core';
import {DataBase} from "@og_soft/data-base";
import {PopupOption} from "@og_soft/popup-list-control";

export interface BankCodes {
  bankCode: string;
  shortName: string;
  fullName: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataBankCodes extends DataBase<BankCodes> {
  // getallUrl = "bank-codes";

  protected getAllUrl(): string {
    return 'bank-codes' as const;
  }

  tranformOptions(row): PopupOption {
    return {id: row.bankCode, name: [row.bankCode, row.fullName].join(' ')};
  }

}
