<form [formGroup]="form" class="contacts-chiplist-is-a-fool">
  <!--*********** !!! Pokud by bylo potřeba mat-chip použít někde jinde, měla by se na ně už vytvořit komponenta-->
  <!--        Tato kompoenenta se využívá jen při editaci, při registraci využívám jednoduchý input-->
  <mat-form-field class="mango-form-field" *ngIf="formHelper.isVisible(form.get('emails')) && !registration">
    <mat-chip-list #emailList>
      <mat-chip *ngFor="let email of emails" [selectable]="selectable"
                [removable]="removable" (removed)="removeEmail(email)">
        {{email}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input placeholder="Emaily"
             i18n-placeholder="@@UserProfile.emails.input.placeholder"
             [matChipInputFor]="emailList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodesEmail"
             [matChipInputAddOnBlur]="addOnBlur"
             (matChipInputTokenEnd)="addEmail($event)"
             [required]="form.get('emails') | hasRequiredField"
      >
    </mat-chip-list>
    <config-setting input="emails" [formName]="formName"></config-setting>
    <mat-error *ngIf="form.get('emails').invalid && (form.get('emails').dirty || form.get('emails').touched)">
      <span i18n="@@UserProfile.email.format.error">Některý ze zadaných emailů nemá správný formát</span>
    </mat-error>
    <mat-error *ngIf="form.errors?.emailCommunication && (form.get('emails').dirty || form.get('emails').touched)">
      <span i18n="@@UserProfile.email.emailCommunication.error">Email je povinný pro zajištění komunikace prostřednictvím emailu</span>
    </mat-error>
  </mat-form-field>


  <mat-form-field class="mango-form-field" *ngIf="formHelper.isVisible(form.get('emails')) && registration" [appearance]="formHelper.appearance()">
    <mat-label i18n="@@UserProfile.emails.label">Email</mat-label>
    <input matInput formControlName="emails" id="emails" name="emails" i18n-title="@@UserProfile.emails.title" title="Email" [required]="form.get('emails') | hasRequiredField">
    <config-setting input="emails" [formName]="formName"></config-setting>
    <mat-error *ngIf="form.get('emails').invalid && (form.get('emails').dirty || form.get('emails').touched)">
      {{formHelper.getErrorMessage(form.get('emails'))}}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="mango-form-field" *ngIf="formHelper.isVisible(form.get('phones'))">
    <mat-chip-list #phoneList>
      <mat-chip *ngFor="let phone of phones" [selectable]="selectable"
                [removable]="removable" (removed)="removePhone(phone)">
        {{phone | phoneNumber}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input placeholder="Telefonní čísla"
             i18n-placeholder="@@UserProfile.phones.input.placeholder"
             [matChipInputFor]="phoneList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodesPhones"
             [matChipInputAddOnBlur]="addOnBlur"
             (matChipInputTokenEnd)="addPhone($event)"
             [required]="form.get('phones') | hasRequiredField"
      >
    </mat-chip-list>
    <config-setting input="phones" [formName]="formName"></config-setting>
    <mat-error *ngIf="form.get('phones').invalid && (form.get('phones').dirty || form.get('phones').touched)">
      <span i18n="@@UserProfile.phones.format.error">Špatný formát telefonního čísla</span>
    </mat-error>
    <mat-error *ngIf="form.errors?.phoneCommunication && (form.get('phones').dirty || form.get('phones').touched)">
      <span i18n="@@UserProfile.phone.phoneCommunication.error">Telefon je povinný pro zajištění komunikace prostřednictvím SMS</span>
    </mat-error>
  </mat-form-field>

  <ng-container *ngIf="formHelper.isVisible(form.get('fax'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.fax.label">Fax</mat-label>
      <input matInput formControlName="fax" id="fax" name="fax" i18n-title="@@UserProfile.fax.title" title="Fax" [required]="form.get('fax') | hasRequiredField">
      <config-setting input="fax" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('fax').invalid && (form.get('fax').dirty || form.get('fax').touched)">
        {{formHelper.getErrorMessage(form.get('fax'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>



  <ng-container
    *ngIf="(formHelper.isVisible(form.get('infoEmail')) ||
    formHelper.isVisible(form.get('infoPost')) ||
    formHelper.isVisible(form.get('infoSms')))">
    <h3 class="mat-h3 mango-text-secondary" i18n="@@UserProfile.contact.type.text">Jak si přeji být kontaktován:</h3>

    <div>
      <ng-container *ngIf="formHelper.isVisible(form.get('infoPost'))">
        <mat-checkbox matInput formControlName="infoPost" id="infoPost" name="infoPost" i18n-title="@@UserProfile.infoPost.title" title="Poštou ve vytištěné podobě" i18n="@@UserProfile.infoPost.label">Poštou ve vytištěné podobě</mat-checkbox>
        <config-setting input="infoPost" [formName]="formName"></config-setting>
      </ng-container>
    </div>

    <div>
      <ng-container *ngIf="formHelper.isVisible(form.get('infoEmail'))">
        <mat-checkbox matInput formControlName="infoEmail" id="infoEmail" name="infoEmail" i18n-title="@@UserProfile.infoEmail.title" title="E-mailem" i18n="@@UserProfile.infoEmail.label">E-mailem</mat-checkbox>
        <config-setting input="infoEmail" [formName]="formName"></config-setting>
      </ng-container>
    </div>
    <mat-error *ngIf="form.errors?.communicationChannel && (form.get('infoEmail').dirty || form.get('infoEmail').touched || form.get('infoPost').dirty || form.get('infoPost').touched) ">
      <span i18n="@@UserProfile.email.emailCommunication.error">Pro zasílání dokumentů je potřeba mít vybraný alespoň jeden způsob komunikace - emailem nebo poštou.</span>
    </mat-error>

    <div>
      <ng-container *ngIf="formHelper.isVisible(form.get('infoSms'))">
        <mat-checkbox matInput formControlName="infoSms" id="infoSms" name="infoSms" i18n-title="@@UserProfile.infoSms.title" title="Prostřednictvím SMS" i18n="@@UserProfile.infoSms.label">Prostřednictvím SMS</mat-checkbox>
        <config-setting input="infoSms" [formName]="formName"></config-setting>
      </ng-container>


    </div>
  </ng-container>

  <div>
    <ng-container *ngIf="formHelper.isVisible(form.get('infoMarketing'))">
      <mat-checkbox matInput formControlName="infoMarketing" id="infoMarketing" name="infoMarketing" i18n-title="@@UserProfile.infoMarketing.title" title="Mám zájem o marketingová sdělení" i18n="@@UserProfile.infoMarketing.label">Mám zájem o marketingová sdělení</mat-checkbox>
      <config-setting input="infoMarketing" [formName]="formName"></config-setting>
    </ng-container>

  </div>
</form>
