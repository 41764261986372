import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTelephonyListService, RecTelephonyList} from '../_services/data-telephony-list.service';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DataUserServices} from '../_services/data-user-services';
import {Location} from '@angular/common';
import {ForgetTableComponent} from '../_libraries/forget-table/forget-table/forget-table.component';
import {MangoFormControl} from '../_models/mango-form-control';
import {FormHelperService} from '../_services/form-helper.service';
import {CrossFieldErrorMatcher} from '../_directives/common-validators';
import {SessionService} from '../_services/session.service';
import {dateRangeValidate} from '@og_soft/datetime-control';

@Component({
  selector: 'app-telephony-list',
  templateUrl: './telephony-list.component.html',
  styleUrls: ['./telephony-list.component.scss']
})
export class TelephonyListComponent implements OnInit {

  filters: FormGroup;
  filterValues: any = {};

  private subParams: any;

  @ViewChild(ForgetTableComponent, {static: false}) public tab: ForgetTableComponent;

  // just specific error type, otherwise it will highlight on any error within the group
  errorMatcher = new CrossFieldErrorMatcher(['dateRangeInvalid']);

  constructor(private route: ActivatedRoute
    ,         public dataService: DataTelephonyListService
    ,         public dataUserServices: DataUserServices
    ,         private location: Location
    ,         public formHelper: FormHelperService
    ,         public session: SessionService,
  ) {
  }

  ngOnInit(): void {
    this.filters = new FormGroup({
      dateFrom: new MangoFormControl(''),
      dateTo: new MangoFormControl(''),
      saId: new MangoFormControl('')
    }, { validators: dateRangeValidate('dateFrom', 'dateTo') });
    this.filters.patchValue(this.filterValues);

    this.subParams = this.route.params.subscribe(params => {
      this.filterValues = params.saId ? {saId: params.saId} : {};
      this.filters.patchValue(this.filterValues);
    });
  }

  public updateFilter(param: any): void {
    const currentRoute = '/' + this.route.snapshot.url[0].path + '/';
    if (param && param.saId != null) {
      this.location.go(currentRoute + param.saId);
    }else {
      this.location.go(currentRoute);
    }
    // this.tab.filtersApply(param);
    this.filterValues = param;
  }

  protected secDiff(i: number, j: number, items: RecTelephonyList[]): boolean {
    if (i < 0 || i >= items.length || j < 0 || j >= items.length) {
      return true;
    }
    return new Date(items[i].tm).toDateString() !== new Date(items[j].tm).toDateString();
  }

  public secHeaderShow(i: number, items: RecTelephonyList[]): boolean {
    return this.secDiff(i - 1, i, items);
  }

  public secFirst(i: number, items: RecTelephonyList[]): boolean {
    return this.secDiff(i - 1, i, items);
  }

  public secLast(i: number, items: RecTelephonyList[]): boolean {
    return this.secDiff(i, i + 1, items);
  }

  public getCurrencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }
}
