import {Component, OnInit} from '@angular/core';
import {DataUserEdit} from '../_services/user-edit.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {SessionService} from '../_services/session.service';
import {DataParamsUser} from '../_libraries/mango-users/data-params-user.service';
import {FormHelperService} from '../_services/form-helper.service';
import {UserProfileDialogComponent} from './user-profile-dialog/user-profile-dialog.component';
import {DialogConfig, DialogService} from '@og_soft/dialog';
import {UserEditFormType} from '../user-edit/user-edit-form.service';
import {PhoneNumberPipe} from '@og_soft/phone-number';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  // styleUrls: ['./user-profile.component.scss']
  styles: [`
    .item, [displayPaneItem] {
      margin-bottom: .5em;
      color: black;

      .mat-caption {
        color: #777 !important;  /* FIXME: z tématu */
      }
    }

    .protected-data-category-info {
      margin-top: 1em;
    }

    .protected-data-category-info ::ng-deep mat-expansion-panel-header {
      padding-left: 10px !important;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .gdpr-eu-flag {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
    }

    .consent-controls {
      display: flex;
    }

    .pane-column {
      flex-grow: 5;
    }
  `],
})
export class UserProfileComponent implements OnInit {

  public userData?;

  userParamsForView = null;
  USER_EDIT_FORM_TYPE = UserEditFormType;

  // ***********************************************************

  constructor(
    private dsrv: DataUserEdit,
    private deviceService: DeviceDetectorService,
    public session: SessionService,
    public formHelper: FormHelperService,
    public userParamsService: DataParamsUser,
    private dialog: DialogService,
    protected phoneNumber: PhoneNumberPipe,
  ) { }

  displayDialog(name: UserEditFormType) {

    const dconf = new DialogConfig();
    dconf.data = {
      editMode: name,
    };
    const dref = this.dialog.open(UserProfileDialogComponent, dconf);
    dref.afterClosed.subscribe(result => {
      console.log('Got user data:', result);
      this.loadData();
    });

  }

  loadUserParamsForView() {
    this.userParamsService.getall({}, 1800).subscribe(val => {
      this.userParamsForView = val.data;
    });
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loadUserParamsForView();
    this.dsrv.getSingleton({}, 1800).subscribe(userData => {
      this.userData = userData;
      if (this.userData.phones) {
        this.userData.phones = this.userData.phones.split(/, */).map(phone => {
          return this.phoneNumber.transform(phone);
        }).join(', ');
      }
      // console.log("Got user data:", this.userData);
    }, err => {
      console.log('Chyba při získání dat uživatelského profilu ' + err);
    });
  }

  public get infoChannelsAsText() {
    const a = [];
    if (this.userData.infoPost) {
      a.push($localize`:@@UserProfile.infoChannels.message:tištěná pošta`);
    }
    if (this.userData.infoEmail) {
      a.push('e-mail');
    }
    if (this.userData.infoSms) {
      a.push('SMS');
    }
    const s = a.join(', ');
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  public get infoMarketingAsText() {
    let text = '';
    if (this.userData.infoMarketing) {
      text = $localize`:@@UserProfile.infoMarketing.positive:Ano`;
    } else {
      text = $localize`:@@UserProfile.infoMarketing.negative:Ne`;
    }
    return text;
  }

  public userHasParamsEditable() {
    let editable = 0;
    if (!this.userParamsForView) {
      return false;
    }
    this.userParamsForView.forEach( value => {
      if (value.disabled === false) {
        editable = editable + 1;
      }
    });
    return editable > 0;
  }

  public userHasParamsVisible() {
    return this.userParamsForView && this.userParamsForView.length > 0;
  }


}
