import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';

@Injectable({
    providedIn: 'root'
})

export class ProtectedDataItemsCategories extends DataBase<{}> {
   // getallUrl = 'protected-data-items-categories';

  protected getAllUrl(): string {
    return 'protected-data-items-categories' as const;
  }
}




