import {Component, OnInit, ViewChild} from '@angular/core';
import {ForgetTableComponent} from '../../_libraries/forget-table/forget-table/forget-table.component';
import {SessionService} from '../../_services/session.service';
import {ProtectedDataCategoryConsentRecall} from '../../_services/protected-data-category-consent-recall';
import {DialogConfig, DialogRef} from '@og_soft/dialog';

@Component({
  selector: 'app-protected-data-recall-inactive-dialog',
  templateUrl: './protected-data-recall-inactive-dialog.component.html',
  styleUrls: ['./protected-data-recall-inactive-dialog.component.scss']
})
export class ProtectedDataRecallInactiveDialogComponent {

  constructor(public config: DialogConfig,
              private session: SessionService,
              private dlgRef: DialogRef,
              public protectedDataCategoryConsentRecall: ProtectedDataCategoryConsentRecall,
  ) { this.dataconf = config.data; }
   public dataconf;

  @ViewChild('tbl', {static: false}) tbl: ForgetTableComponent;

   save(): void {
    const catId = this.dataconf.data.catId;
    if (catId) {
      this.protectedDataCategoryConsentRecall.post({
        pdcCatId: catId
      }).subscribe(po => {
        this.session.message($localize`:@@ProtectedDataRecallInactiveDialog.consentRecall.message.ok:Souhlas byl odvolán.`);
      //  this.tbl.fetchAgain();
        this.logout();
      }, err => {
         this.session.message($localize`:@@ProtectedDataRecallInactiveDialog.consentRecall.message.error:Nepodařilo se odvolat souhlas.`);
      });
      this.dlgRef.close();
    }
  }

  logout(): void {
    this.session.logout();
  }


}
