import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataBase, DataBaseServiceConfig, FilterInfo} from '@og_soft/data-base';
import {CachingInterceptor} from '@og_soft/cache';


/**
 * Interface pro jeden záznam ze služeb zákazníka, jak ho vrací API.
 *
 * TypeScript používá interfaces pro typovou kontrolu. Je otázka, zda
 * to kromě toho má pozitivní vliv ma schopnost IDE napovídat. Pokud
 * se to nepotvrdí, tak se s těmi interfaces psát nebudeme a budeme
 * prostě používat typ "any".
 */
export interface RecDocumentsOther {
  cdId: number;
  cdDocId: number;
  cdType: string;
  cdDocType: string;
  docTitle: string;
  cdSysTime: string;
  cdTpl: string;
  docData: any;

}

export interface RecDocumentsOtherMeta {
  docType: string;
  cddtTitle: string;
}

@Injectable({
  providedIn: 'root'
})


export class DataDocumentsOther extends DataBase<RecDocumentsOther, RecDocumentsOtherMeta> {
  // getallUrl = 'documents-other';

  protected getAllUrl(): string {
    return 'documents-other' as const;
  }

  constructor(public http: HttpClient
    ,         public serviceConfig: DataBaseServiceConfig
    ,         private cachingInterceptor: CachingInterceptor
  ) {
    super(http, serviceConfig, cachingInterceptor);
  }

  metaFetchFilterInfo(meta: RecDocumentsOtherMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.docType) {
        info.push({
          label: $localize`:@@DataDocumentOther.filterInfo.type.label:Typ dokumentu`,
          filterNames: ['docType'],
          value: meta.cddtTitle
        });

      }

      return info;
    }
    return super.metaFetchFilterInfo(meta);
  }

}
