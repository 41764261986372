import {Component, OnInit, ViewChild} from '@angular/core';
import {DataUsersPayments} from "../_services/data-users-payments";
import {FormGroup} from "@angular/forms";
import {UtilPrintService} from '../_services/util-print.service';
import {SessionService} from "../_services/session.service";
import {ForgetTableComponent} from "../_libraries/forget-table/forget-table/forget-table.component";
import {MangoFormControl} from '../_models/mango-form-control';
import {CrossFieldErrorMatcher} from '../_directives/common-validators';
import {FormHelperService} from '../_services/form-helper.service';
import {dateRangeValidate} from '@og_soft/datetime-control';

@Component({
  selector: 'app-user-payments',
  templateUrl: './user-payments.component.html',
  styleUrls: ['./user-payments.component.scss']
})
export class UserPaymentsComponent implements OnInit {
  filters: FormGroup;
  filterValues: any = {};

  @ViewChild(ForgetTableComponent, {static: false}) public tab: ForgetTableComponent;

  // just specific error type, otherwise it will highlight on any error within the group
  errorMatcher = new CrossFieldErrorMatcher(['dateRangeInvalid']);

  constructor(public dataService: DataUsersPayments,
    public session: SessionService,
    public printUtil: UtilPrintService,
    public formHelper: FormHelperService,) { }


  ngOnInit() {
    this.filters = new FormGroup({
      dateFrom: new MangoFormControl(''),
      dateTo: new MangoFormControl(''),
    }, { validators: dateRangeValidate('dateFrom', 'dateTo') });
    this.filters.patchValue(this.filterValues);
  }

  getCurrencySymbol() {

    return this.session.locDefaults.currencyCode;


  }

  notePaymentVisible(): boolean {
    const opt = this.session.getOption('SELFCARE.user-payments.notePayment');
    return ! (opt && opt.includes('hidden'));
  }



}
