import { Component, OnInit } from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DataProcess, Process} from "../_libraries/mango-processes/data-process";
import { ProcessType} from "../_libraries/mango-processes/data-process-types";
import {FormHelperService} from "../_services/form-helper.service";
import {PopupOption} from "@og_soft/popup-list-control";
import {MangoFormControl} from '../_models/mango-form-control';

@Component({
  selector: 'app-test-component',
  templateUrl: './test-component.component.html',
  styleUrls: ['./test-component.component.scss']
})
export class TestComponentComponent implements OnInit {
  form: FormGroup;

  processOptions: PopupOption[] = [];

  processTypes: ProcessType[];
  activeProcesses: Process[];

  test = true;
  deafautlTestFilter: any;

  constructor(
    public dataService: DataProcess,
    public formHelper: FormHelperService,
  ) {
    this.form = new FormGroup({
      dbFilter: new MangoFormControl(false),
      classicSelect: new MangoFormControl(''),
      classicMultiSelect: new MangoFormControl(''),
      simpleStatic: new MangoFormControl(''),
      simpleDB: new MangoFormControl(''),
      simpleCombination: new MangoFormControl(''),
      multiStatic: new MangoFormControl(''),
      multiDB: new MangoFormControl(''),
      multiCombination: new MangoFormControl(''),
    });
  }

  ngOnInit() {
    this.processOptions = [
      {id: '1', name: 'První'},
      {id: '2', name: 'Druhý disablovaný', disabled: true},
      {id: '3', name: 'Třetí s barvou', color: '#ff0000'},
      {id: '3b', name: 'Třetí s barvou disablovaný', color: '#ff0000', disabled: true},
      {id: '4', name: 'Čtvrtý s barvou', color: '#f0850b'},
      {id: '5', name: 'Pátý s nevalidní barvou', color: 'test'},
    ]
  }
}
