import {Injectable} from '@angular/core';
import {PopupOption} from '@og_soft/popup-list-control';
import {DataBase} from '@og_soft/data-base';

export interface ProcessUnits {
  nodeId: number;
  nodeCacheName: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataProcessUnits extends DataBase<ProcessUnits> {
  // getallUrl = 'process-units';

  protected getAllUrl(): string {
    return 'process-units' as const;
  }

  tranformOptions(row): PopupOption {

    return {id: row.nodeId, name: row.nodeCacheName};
  }
}
