import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UserNodesService} from '../_services/user-nodes.service';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {DataUserServices} from '../_services/data-user-services';
import {ForgetTableComponent} from '../_libraries/forget-table/forget-table/forget-table.component';
import {DialogConfig, DialogService} from '@og_soft/dialog';
import {NodeEditComponent} from './node-edit/node-edit.component';
import {RecNode} from '../_services/data-nodes.service';
import {MangoFormControl} from '../_models/mango-form-control';
import {DataNodeParamsService} from './data-node-params.service';

@Component({
  selector: 'app-nodes',
  templateUrl: './nodes.component.html',
  styleUrls: ['./nodes.component.scss']
})
export class NodesComponent implements OnInit, AfterViewInit {

  filters: FormGroup;
  filterValues: any = {};

  constructor(
    public dataService: UserNodesService,
    private route: ActivatedRoute,
    public dataUserServices: DataUserServices,
    public dialogService: DialogService,
    public dataNodeParamsService: DataNodeParamsService,
  ) {

  }

  private _urlImg = '';
  public get urlImg(): string {
    // FIXME is this proper way to access the images?
    return environment.baseUrl.replace(/\/api\/v1.*/, '');
  }

  @ViewChild('tbl', {static: false}) tbl: ForgetTableComponent;

  private subParams: any;

  ngOnInit(): void {
    this.filters = new FormGroup({
      saId: new MangoFormControl(''),
    });
    this.subParams = this.route.params.subscribe(params => {
      this.filterValues = { saId: params.saId ? params.saId : null };
      this.filters.patchValue(this.filterValues);
    });
  }

  ngAfterViewInit(): void {
    this.tbl.bufferSubj.subscribe(next => {
      // HOM INFO: Dotáhneme adresu zařízení. Docela krkolomně. Vezmeme navázané služby a k nim navázané jednotky.
      // Z jednotek vezmu první a její adresu zobrazím.
      // Nejprve vytaháme pole polí s ID služeb. To se následně zploští a udělá unikátní a použije se jako filtr pro
      // request na jednotky. Od nich se pak vytahují ty adresy.
      const nodeSaIds = next.map(node => node.saIds);
      const saIds = nodeSaIds.map(ids => ids.join(','))
        .join(',')
        .split(',')
        .filter((id, index, array) => id && array.indexOf(id) === index);
      if (saIds.length) {
        this.dataUserServices.getall({saIds}).subscribe(data => {
          data.data.forEach(saData => {
            next.forEach(node => {
              if (!node.address && node.saIds.indexOf(saData.saId) >= 0) {
                node.address = saData.unitAddress;
              }
            });
          });
        });
      }
    });
  }

  public nodeEdit(id: number, data: RecNode): void {
    const dialogConfig = new DialogConfig();
    dialogConfig.data = {
      id,
      nodeData: data,
    };
    const dialogRef = this.dialogService.open(NodeEditComponent, dialogConfig);
    dialogRef.afterClosed.subscribe(result => {
      if (result && result.id) {
        // Volá se getall i když by se nabízelo spíš getone. Důvod je ten, že getall načte přesně záznam tak, jak si
        // jej načítá tabulka. Záznam(y) pak vložíme do tabulky. Načítáme znovu ze serveru, protože data se mohla při
        // uložení modifikovat vlivem workflow, apod.
        this.dataService.getall({id}).subscribe(next => {
          next.data.forEach(record => this.tbl.recordUpdate(record, (a, b) => a.id === b.id));
        });
      }
    });

  }
}
