<form [formGroup]="form">
<!--  <div *ngIf="formHelper.isVisible(form.get('addressSearch'))">-->
<!--    <mat-form-field class="mango-form-field">-->
<!--      <popup-list formControlName="addressSearch" id="addressSearch" name="addressSearch" [dataSource]="dataAddressSearch" [filterDb]='true' [noValue]="null" [defaultLimit]="999" [exactOnly]="true"-->
<!--                  i18n-placeholder="@@UserProfile.addressSearch.placeholder" placeholder="Vyhledání adresy" i18n-title="@@UserProfile.addressSearch.title" title="Zde jednoduše vyhledejte adresu"></popup-list>-->
<!--      <mat-icon *ngIf="form.get('addressSearch').value !== null" matSuffix (click)="clearAddress('addressSearch')">cancel</mat-icon>-->
<!--      <config-setting input="addressSearch" [formName]="formName"></config-setting>-->
<!--    </mat-form-field>-->
<!--  </div>-->

<!--  <div *ngIf="formHelper.isVisible(form.get('addressSearch'))">-->
<!--    <mat-form-field class="mango-form-field">-->
<!--      <mat-select formControlName="addressSearch" id="addressSearch" name="addressSearch"-->
<!--                  i18n-placeholder="@@UserProfile.addressSearch.placeholder" placeholder="Vyhledání adresy">-->
<!--        <mat-option>-->
<!--          <ngx-mat-select-search formControlName="addressFiltering"-->
<!--                                 i18n-placeholderLabel="@@UserProfile.addressSearch.searching.placeholder"-->
<!--                                 placeholderLabel="Vyhledejte adresu"-->
<!--                                 i18n-noEntriesFoundLabel="@@UserProfile.addressSearch.noEntriesFoundLabel.placeholder"-->
<!--                                 noEntriesFoundLabel="Žádná adresa nenalezena"-->
<!--          ></ngx-mat-select-search>-->
<!--        </mat-option>-->
<!--        <mat-option *ngFor="let option of suggestedAddresses" [value]="option.id">-->
<!--          {{option.name}}-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--      <config-setting input="addressSearch" [formName]="formName"></config-setting>-->
<!--    </mat-form-field>-->
<!--  </div>-->

  <div *ngIf="formHelper.isVisible(form.get('addressSearch'))">
    <mat-form-field class="mango-form-field">
      <input type="text"
             formControlName="addressSearch"
             id="addressSearch"
             name="addressSearch"
             i18n-placeholder="@@UserProfile.addressSearch.placeholder"
             placeholder="Vyhledání adresy"
             aria-label="Number"
             matInput
             [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of suggestedAddresses" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
      <config-setting input="addressSearch" [formName]="formName"></config-setting>
    </mat-form-field>
  </div>
  <ng-container *ngIf="formHelper.isVisible(form.get('street'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.street.label">Ulice</mat-label>
      <input matInput formControlName="street" id="street" name="street" i18n-title="@@UserProfile.street.title" title="Ulice" [required]="form.get('street') | hasRequiredField">
      <config-setting input="street" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('street').invalid && (form.get('street').dirty || form.get('street').touched)">
        {{formHelper.getErrorMessage(form.get('street'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="formHelper.isVisible(form.get('houseId'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.houseId.label">Číslo</mat-label>
      <input matInput formControlName="houseId" id="houseId" name="houseId" i18n-title="@@UserProfile.houseId.title" title="Č.p." [required]="form.get('houseId') | hasRequiredField">
      <config-setting input="houseId" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('houseId').invalid && (form.get('houseId').dirty || form.get('houseId').touched)">
        {{formHelper.getErrorMessage(form.get('houseId'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>



  <ng-container *ngIf="formHelper.isVisible(form.get('zip'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.zip.label">PSČ</mat-label>
      <input matInput formControlName="zip" id="zip" name="zip" i18n-title="@@UserProfile.zip.title" title="PSČ" [required]="form.get('zip') | hasRequiredField">
      <config-setting input="zip" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('zip').invalid && (form.get('zip').dirty || form.get('zip').touched)">
        {{formHelper.getErrorMessage(form.get('zip'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>



  <ng-container *ngIf="formHelper.isVisible(form.get('city'))">
    <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
      <mat-label i18n="@@UserProfile.city.label">Obec</mat-label>
      <input matInput formControlName="city" id="city" name="city" i18n-title="@@UserProfile.city.title" title="Obec" [required]="form.get('city') | hasRequiredField">
      <config-setting input="city" [formName]="formName"></config-setting>
      <mat-error *ngIf="form.get('city').invalid && (form.get('city').dirty || form.get('city').touched)">
        {{formHelper.getErrorMessage(form.get('city'))}}
      </mat-error>
    </mat-form-field>
  </ng-container>




  <div
      *ngIf="formHelper.isVisible(form.get('mailingAddress'))">

    <h3 class="mat-h3 mango-text-secondary">
      <span i18n="@@UserProfile.deliveryAddress.text">Doručovací adresa</span>
    </h3>
    <mat-radio-group
        name="mailingAddress"
        id="mailingAddress"
        formControlName="mailingAddress">
      <div>
        <mat-radio-button class="mango-radio-button" matInput value="AS1" i18n="@@UserProfile.radio.mailingAddress.AS1">Jako trvalé bydliště/sídlo</mat-radio-button><br>
        <mat-radio-button class="mango-radio-button" matInput value="OWN" i18n="@@UserProfile.radio.mailingAddress.OWN">Vlastní</mat-radio-button>
      </div>
    </mat-radio-group>
    <config-setting input="mailingAddress" [formName]="formName"></config-setting>
  </div>
  <p></p>

  <div *ngIf="mailingAddressVisible()">

<!--    <div *ngIf="formHelper.isVisible(form.get('mailingAddressSearch'))">-->
<!--      <mat-form-field class="mango-form-field">-->
<!--        <popup-list formControlName="mailingAddressSearch" id="mailingAddressSearch" name="mailingAddressSearch" [dataSource]="dataAddressSearch" [filterDb]='true' [noValue]="null" [defaultLimit]="999" [exactOnly]="true"-->
<!--                    i18n-placeholder="@@UserProfile.mailingAddressSearch.placeholder" placeholder="Vyhledání doručovací adresy" i18n-title="@@UserProfile.mailingAddressSearch.title" title="Zde jednoduše vyhledejte adresu"></popup-list>-->
<!--        <mat-icon *ngIf="form.get('mailingAddressSearch').value !== null" matSuffix (click)="clearAddress('mailingAddressSearch')">cancel</mat-icon>-->
<!--        <config-setting input="mailingAddressSearch" [formName]="formName"></config-setting>-->
<!--      </mat-form-field>-->
<!--    </div>   -->

<!--    <div *ngIf="formHelper.isVisible(form.get('mailingAddressSearch'))">-->
<!--      <mat-form-field class="mango-form-field">-->
<!--        <mat-select formControlName="mailingAddressSearch" id="mailingAddressSearch" name="mailingAddressSearch"-->
<!--                    i18n-placeholder="@@UserProfile.mailingAddressSearch.placeholder" placeholder="Vyhledání doručovací adresy">-->
<!--          <mat-option>-->
<!--            <ngx-mat-select-search formControlName="addressFiltering"-->
<!--                                   i18n-placeholderLabel="@@UserProfile.addressSearch.searching.placeholder"-->
<!--                                   placeholderLabel="Vyhledejte adresu"-->
<!--                                   i18n-noEntriesFoundLabel="@@UserProfile.addressSearch.noEntriesFoundLabel.placeholder"-->
<!--                                   noEntriesFoundLabel="Žádná adresa nenalezena"-->
<!--            ></ngx-mat-select-search>-->
<!--          </mat-option>-->
<!--          <mat-option *ngFor="let option of suggestedAddresses" [value]="option.id">-->
<!--            {{option.name}}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
<!--    </div>-->

    <div *ngIf="formHelper.isVisible(form.get('mailingAddressSearch'))">
      <mat-form-field class="mango-form-field">
        <input type="text"
               formControlName="mailingAddressSearch"
               id="mailingAddressSearch"
               name="mailingAddressSearch"
               i18n-placeholder="@@UserProfile.mailingAddressSearch.placeholder"
               placeholder="Vyhledání adresy"
               aria-label="Number"
               matInput
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of suggestedAddresses" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
        <config-setting input="mailingAddressSearch" [formName]="formName"></config-setting>
      </mat-form-field>
    </div>

    <ng-container *ngIf="formHelper.isVisible(form.get('mailingAddressRecipient'))">
      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
        <mat-label i18n="@@UserProfile.mailingAddressRecipient.label">Příjemce</mat-label>
        <input matInput formControlName="mailingAddressRecipient" id="mailingAddressRecipient" name="mailingAddressRecipient" i18n-title="@@UserProfile.mailingAddressRecipient.title" title="Příjemce" [required]="form.get('mailingAddressRecipient') | hasRequiredField">
        <config-setting input="mailingAddressRecipient" [formName]="formName"></config-setting>
        <mat-error *ngIf="form.get('mailingAddressRecipient').invalid && (form.get('mailingAddressRecipient').dirty || form.get('mailingAddressRecipient').touched)">
          {{formHelper.getErrorMessage(form.get('mailingAddressRecipient'))}}
        </mat-error>
      </mat-form-field>
    </ng-container>



    <ng-container *ngIf="formHelper.isVisible(form.get('mailingAddressStreet'))">
      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
        <mat-label i18n="@@UserProfile.mailingAddressStreet.label">Ulice</mat-label>
        <input matInput formControlName="mailingAddressStreet" id="mailingAddressStreet" name="mailingAddressStreet" i18n-title="@@UserProfile.mailingAddressStreet.title" title="Ulice" [required]="form.get('mailingAddressStreet') | hasRequiredField">
        <config-setting input="mailingAddressStreet" [formName]="formName"></config-setting>
        <mat-error *ngIf="form.get('mailingAddressStreet').invalid && (form.get('mailingAddressStreet').dirty || form.get('mailingAddressStreet').touched)">
          {{formHelper.getErrorMessage(form.get('mailingAddressStreet'))}}
        </mat-error>
      </mat-form-field>
    </ng-container>



    <ng-container *ngIf="formHelper.isVisible(form.get('mailingAddressHouseId'))">
      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
        <mat-label i18n="@@UserProfile.mailingAddressHouseId.label">Číslo</mat-label>
        <input matInput formControlName="mailingAddressHouseId" id="mailingAddressHouseId" name="mailingAddressHouseId" i18n-title="@@UserProfile.mailingAddressHouseId.title" title="Č.p." [required]="form.get('mailingAddressHouseId') | hasRequiredField">
        <config-setting input="mailingAddressHouseId" [formName]="formName"></config-setting>
        <mat-error *ngIf="form.get('mailingAddressHouseId').invalid && (form.get('mailingAddressHouseId').dirty || form.get('mailingAddressHouseId').touched)">
          {{formHelper.getErrorMessage(form.get('mailingAddressHouseId'))}}
        </mat-error>
      </mat-form-field>
    </ng-container>



    <ng-container *ngIf="formHelper.isVisible(form.get('mailingAddressZip'))">
      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
        <mat-label i18n="@@UserProfile.mailingAddressZip.label">PSČ</mat-label>
        <input matInput formControlName="mailingAddressZip" id="mailingAddressZip" name="mailingAddressZip" i18n-title="@@UserProfile.mailingAddressZip.title" title="PSČ" [required]="form.get('mailingAddressZip') | hasRequiredField">
        <config-setting input="mailingAddressZip" [formName]="formName"></config-setting>
        <mat-error *ngIf="form.get('mailingAddressZip').invalid && (form.get('mailingAddressZip').dirty || form.get('mailingAddressZip').touched)">
          {{formHelper.getErrorMessage(form.get('mailingAddressZip'))}}
        </mat-error>
      </mat-form-field>
    </ng-container>



    <ng-container *ngIf="formHelper.isVisible(form.get('mailingAddressCity'))">
      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
        <mat-label i18n="@@UserProfile.mailingAddressCity.label">Obec</mat-label>
        <input matInput formControlName="mailingAddressCity" id="mailingAddressCity" name="mailingAddressCity" i18n-title="@@UserProfile.mailingAddressCity.title" title="Obec" [required]="form.get('mailingAddressCity') | hasRequiredField">
        <config-setting input="mailingAddressCity" [formName]="formName"></config-setting>
        <mat-error *ngIf="form.get('mailingAddressCity').invalid && (form.get('mailingAddressCity').dirty || form.get('mailingAddressCity').touched)">
          {{formHelper.getErrorMessage(form.get('mailingAddressCity'))}}
        </mat-error>
      </mat-form-field>
    </ng-container>



  </div>

  <div
      *ngIf="formHelper.isVisible(form.get('billingAddress'))">
    <h3 class="mat-h3 mango-text-secondary">
      <span i18n="@@UserProfile.billingAddress.text">Fakturační adresa</span>
    </h3>
    <mat-radio-group
        name="billingAddress"
        id="billingAddress"
        formControlName="billingAddress">
      <div>
        <mat-radio-button class="mango-radio-button" matInput value="AS1" i18n="@@UserProfile.radio.billingAddress.AS1">Jako trvalé bydliště/sídlo</mat-radio-button><br>
        <mat-radio-button class="mango-radio-button" matInput value="AS2" i18n="@@UserProfile.radio.billingAddress.AS2">Jako doručovací</mat-radio-button><br>
        <mat-radio-button class="mango-radio-button" matInput value="OWN" i18n="@@UserProfile.radio.billingAddress.AS3">Vlastní</mat-radio-button>
      </div>
    </mat-radio-group>
    <config-setting input="billingAddress" [formName]="formName"></config-setting>
  </div>
  <div *ngIf="billingAddressVisible()">

<!--    <div *ngIf="formHelper.isVisible(form.get('billingAddressSearch'))">-->
<!--      <mat-form-field class="mango-form-field">-->
<!--        <popup-list formControlName="billingAddressSearch" id="billingAddressSearch" name="billingAddressSearch" [dataSource]="dataAddressSearch" [filterDb]='true' [noValue]="null" [defaultLimit]="999" [exactOnly]="true"-->
<!--                    i18n-placeholder="@@UserProfile.billingAddressSearch.label" placeholder="Vyhledání fakturační adresy" i18n-title="@@UserProfile.billingAddressSearch.title" title="Zde jednoduše vyhledejte adresu"></popup-list>-->
<!--        <mat-icon *ngIf="form.get('billingAddressSearch').value !== null" matSuffix (click)="clearAddress('billingAddressSearch')">cancel</mat-icon>-->
<!--        <config-setting input="billingAddressSearch" [formName]="formName"></config-setting>-->
<!--      </mat-form-field>-->
<!--    </div>-->
<!--    -->

<!--    <div *ngIf="formHelper.isVisible(form.get('billingAddressSearch'))">-->
<!--      <mat-form-field class="mango-form-field">-->
<!--        <mat-select formControlName="billingAddressSearch" id="billingAddressSearch" name="billingAddressSearch"-->
<!--                    i18n-placeholder="@@UserProfile.addressSearch.placeholder" placeholder="Vyhledání fakturační adresy">-->
<!--          <mat-option>-->
<!--            <ngx-mat-select-search formControlName="addressFiltering"-->
<!--                                   i18n-placeholderLabel="@@UserProfile.addressSearch.searching.placeholder"-->
<!--                                   placeholderLabel="Vyhledejte adresu"-->
<!--                                   i18n-noEntriesFoundLabel="@@UserProfile.addressSearch.noEntriesFoundLabel.placeholder"-->
<!--                                   noEntriesFoundLabel="Žádná adresa nenalezena"-->
<!--            ></ngx-mat-select-search>-->
<!--          </mat-option>-->
<!--          <mat-option *ngFor="let option of suggestedAddresses" [value]="option.id">-->
<!--            {{option.name}}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--        <config-setting input="billingAddressSearch" [formName]="formName"></config-setting>-->
<!--      </mat-form-field>-->
<!--    </div>-->

    <div *ngIf="formHelper.isVisible(form.get('billingAddressSearch'))">
      <mat-form-field class="mango-form-field">
        <input type="text"
               formControlName="billingAddressSearch"
               id="billingAddressSearch"
               name="billingAddressSearch"
               i18n-placeholder="@@UserProfile.billingAddressSearch.placeholder"
               placeholder="Vyhledání adresy"
               aria-label="Number"
               matInput
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of suggestedAddresses" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
        <config-setting input="billingAddressSearch" [formName]="formName"></config-setting>
      </mat-form-field>
    </div>

    <ng-container *ngIf="formHelper.isVisible(form.get('billingAddressRecipient'))">
      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
        <mat-label i18n="@@UserProfile.billingAddressRecipient.label">Příjemce</mat-label>
        <input matInput formControlName="billingAddressRecipient" id="billingAddressRecipient" name="billingAddressRecipient" i18n-title="@@UserProfile.billingAddressRecipient.title" title="Příjemce" [required]="form.get('billingAddressRecipient') | hasRequiredField">
        <config-setting input="billingAddressRecipient" [formName]="formName"></config-setting>
        <mat-error *ngIf="form.get('billingAddressRecipient').invalid && (form.get('billingAddressRecipient').dirty || form.get('billingAddressRecipient').touched)">
          {{formHelper.getErrorMessage(form.get('billingAddressRecipient'))}}
        </mat-error>
      </mat-form-field>
    </ng-container>



    <ng-container *ngIf="formHelper.isVisible(form.get('billingAddressStreet'))">
      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
        <mat-label i18n="@@UserProfile.billingAddressStreet.label">Ulice</mat-label>
        <input matInput formControlName="billingAddressStreet" id="billingAddressStreet" name="billingAddressStreet" i18n-title="@@UserProfile.billingAddressStreet.title" title="Ulice" [required]="form.get('billingAddressStreet') | hasRequiredField">
        <config-setting input="billingAddressStreet" [formName]="formName"></config-setting>
        <mat-error *ngIf="form.get('billingAddressStreet').invalid && (form.get('billingAddressStreet').dirty || form.get('billingAddressStreet').touched)">
          {{formHelper.getErrorMessage(form.get('billingAddressStreet'))}}
        </mat-error>
      </mat-form-field>
    </ng-container>



    <ng-container *ngIf="formHelper.isVisible(form.get('billingAddressHouseId'))">
      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
        <mat-label i18n="@@UserProfile.billingAddressHouseId.label">Číslo</mat-label>
        <input matInput formControlName="billingAddressHouseId" id="billingAddressHouseId" name="billingAddressHouseId" i18n-title="@@UserProfile.billingAddressHouseId.title" title="Č.p." [required]="form.get('billingAddressHouseId') | hasRequiredField">
        <config-setting input="billingAddressHouseId" [formName]="formName"></config-setting>
        <mat-error *ngIf="form.get('billingAddressHouseId').invalid && (form.get('billingAddressHouseId').dirty || form.get('billingAddressHouseId').touched)">
          {{formHelper.getErrorMessage(form.get('billingAddressHouseId'))}}
        </mat-error>
      </mat-form-field>
    </ng-container>



    <ng-container *ngIf="formHelper.isVisible(form.get('billingAddressZip'))">
      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
        <mat-label i18n="@@UserProfile.billingAddressZip.label">PSČ</mat-label>
        <input matInput formControlName="billingAddressZip" id="billingAddressZip" name="billingAddressZip" i18n-title="@@UserProfile.billingAddressZip.title" title="PSČ" [required]="form.get('billingAddressZip') | hasRequiredField">
        <config-setting input="billingAddressZip" [formName]="formName"></config-setting>
        <mat-error *ngIf="form.get('billingAddressZip').invalid && (form.get('billingAddressZip').dirty || form.get('billingAddressZip').touched)">
          {{formHelper.getErrorMessage(form.get('billingAddressZip'))}}
        </mat-error>
      </mat-form-field>
    </ng-container>



    <ng-container *ngIf="formHelper.isVisible(form.get('billingAddressCity'))">
      <mat-form-field class="mango-form-field" [appearance]="formHelper.appearance()">
        <mat-label i18n="@@UserProfile.billingAddressCity.label">Obec</mat-label>
        <input matInput formControlName="billingAddressCity" id="billingAddressCity" name="billingAddressCity" i18n-title="@@UserProfile.billingAddressCity.title" title="Obec" [required]="form.get('billingAddressCity') | hasRequiredField">
        <config-setting input="billingAddressCity" [formName]="formName"></config-setting>
        <mat-error *ngIf="form.get('billingAddressCity').invalid && (form.get('billingAddressCity').dirty || form.get('billingAddressCity').touched)">
          {{formHelper.getErrorMessage(form.get('billingAddressCity'))}}
        </mat-error>
      </mat-form-field>
    </ng-container>



  </div>
</form>
