import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';
import {Address} from './search-address.service';

@Injectable({
  providedIn: 'root'
})
export class DataAddresses extends DataBase<Address> {
  // getallUrl = 'search-address';

  protected getAllUrl(): string {
    return 'search-address' as const;
  }
}
