import {Injectable} from '@angular/core';
import {DataBase} from "@og_soft/data-base";

export interface AgreementConfirmData {
  aId? : number;
  documentId? : number;
}

@Injectable({
  providedIn: 'root'
})
export class AgreementConfirmService extends DataBase<AgreementConfirmData> {
  // getallUrl = "documents-agreements-confirmed";

  protected getAllUrl(): string {
    return 'documents-agreements-confirmed' as const;
  }
}
