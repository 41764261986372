import {Component, ElementRef, Input} from '@angular/core';
import {ForgetTableRowComponent} from "../forget-table-row/forget-table-row.component";

/**
  Třída, která definuje jeden sloupec v Zapomenutíhodné Tabulce.
 */
@Component({
	selector: 'mgt-col',
	template: `
		<ng-content></ng-content>
	`,
	styles: [`
		:host {
            flex: 1 1 10%;
        }
		:host[role=description] {
			flex: 3 1 30%;
		}
		:host[role=detailonly] {
			display: none;
		}
		:host.cell-monetary {
			text-align: right;
			white-space: nowrap;
		}
		:host.cell-right {
			text-align: right;
		}
		/* Breakpointy podle aliasů tady: https://github.com/angular/flex-layout/wiki/Responsive-API */
		@media screen and (max-width: 959px) {
			:host[role=title] {
			  flex: 1 1 100%;
			  order: -1;
			  margin-bottom: .5em;
			}
			:host[role=description] {
			  flex: 1 1 100%;
			  order: 98;
			  margin-top: .5em;
			  margin-bottom: .5em;
			}
			:host[role=freaky] {
			  display: none;
			}
			:host[role=result] {
			  margin-top: .5em;
			  flex: 1 1 100%;
			  order: 100;
			}
		}
		@media screen and (max-width: 599px) {
			:host[role=description] {
				display: none;
			}
			:host[role=detail] {
				display: none;
			}
		}
  `],
})
export class ForgetTableColComponent {
	/**
	 * Název sloupce (nyní se používá v "automatickém detailu").
	 */
	@Input()
	title: string;

	/**
	 * Role sloupce - buď nezadaná, nebo jedna z "title", "description", "result", "freaky", "detailonly".
	 */
	@Input()
	role: string;

	/**
	 * @ignore
	 */
  constructor
    ( protected ownerRow: ForgetTableRowComponent
    , protected elementRef: ElementRef
    ) { }

	/**
	 * @ignore
	 */
  ngOnInit() {
    this.ownerRow.columnSelfregister(this.title, this.role, this.elementRef.nativeElement);
  }
}
