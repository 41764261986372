import { Component, OnInit } from '@angular/core';
import {SessionService} from "../_services/session.service";
import {ActivatedRoute, Router} from "@angular/router";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-verify-registration',
  templateUrl: './verify-registration.component.html',
  styleUrls: ['./verify-registration.component.scss']
})
export class VerifyRegistrationComponent implements OnInit {
  private code: string;
  public state: 'wait' | 'check' | 'failed' | 'ok' = 'check';

  constructor(
    private session: SessionService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      if(this.code) {
        this.session.autoLogin(this.code).pipe(first())
          .subscribe(
            data => {
              if (data) {
                this.state = 'ok';
                this.router.navigateByUrl(SessionService.MAIN_PAGE);
              } else {
                this.state = 'failed';
              }
            },
            error => {
              this.state = 'failed';
            });
      }else{
        this.state = 'wait';
      }
    });
  }

  ngOnInit() {
  }



}
