import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective {

  @Input() highlightColor: string = '#cbd3d8';
  @Input() dragDropEnabled: boolean = true;
  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding('style.background-color') private background; // = '#cbd3d8'
  @HostBinding('style.opacity') private opacity; // = '0';

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.dragDropEnabled) {
      this.opacity = '0.75';
      this.background = this.highlightColor;
    }
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = undefined;
    this.background = undefined;
  }

  //Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    evt.preventDefault();
    if (this.dragDropEnabled) {
      let files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.onFileDropped.emit(files)
      }
      this.opacity = undefined;
      this.background = undefined;
    }
    evt.stopPropagation();
  }

}