import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {environment} from '../../environments/environment';
import {SessionService} from './session.service';
import {MangoFormControl} from '../_models/mango-form-control';

/**
 * Zde jsou různé metody, které slouží ke snadnějšímu ovládání formulářů
 */
@Injectable({
  providedIn: 'root'
})
export class FormHelperService {
  constructor(
    public session: SessionService,
  ) {

  }

  // Slouží k okamžitému zvýraznění všech nevalidních itemů, které nezobrazují chybu,
  // protože ještě nebyly dotknuté.
  // Využití typicky při odeslání formuláře.
  public markDirty(formGroup: FormGroup): void {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsDirty();
      control.markAsTouched();
      control.updateValueAndValidity();
      if (control.controls) {
        this.markDirty(control);
      }
    });
  }

  /**
   * Helper handles translation of error messages related to the given form control
   *
   * @param component Form control, where the errors are handled
   * @param form In case there are errors on parent level, that should be displayed with the control as well.
   */
  public getErrorMessage(component: AbstractControl, form: FormGroup | NgForm | null = null): string {
    if (component.invalid) {
      if (component.hasError('required')) {
        return $localize`:@@FormHelper.required.error.message:Položka musí být vyplněna`;
      }
      if (component.hasError('pattern')) {
        return $localize`:@@FormHelper.pattern.error.message:Chybný formát`;
      }
      if (component.hasError('dateRange')) {
        let min = $localize`:@@FormHelper.dateRange.error.text:neomezeno`;
        let max = $localize`:@@FormHelper.dateRange.error.text:neomezeno`;
        if (component.errors.dateRange.min) {
          min = formatDate(component.errors.dateRange.min, 'd.M.yyyy', 'cs');
        }
        if (component.errors.dateRange.max) {
          max = formatDate(component.errors.dateRange.max, 'd.M.yyyy', 'cs');
        }
        return $localize`:@@FormHelper.dateRange.error.message:Datum musí být v rozmezí` + ' ' + min + ' - ' + max ;
      }
      if (component.hasError('decimalPlaces')) {
        return $localize`:@@FormHelper.decimalPlaces1.error.message:Hodnota může být s přesností maximálně na `
          + component.errors.decimalPlaces.precision
          + $localize`:@@FormHelper.decimalPlaces2.error.message: desetinná místa.`;
      }
      if (component.hasError('duplicate')) {
        return $localize`:@@FormHelper.duplicate.error.message:Duplicitní hodnota`;
      }

      if (component.hasError('minlength') && component.errors.minlength) {
        return $localize`:@@FormHelper.minlength.error.message:Položka musí obsahovat minimální počet znaků (`
          + component.errors.minlength.requiredLength + ')';
      }

      if (component.hasError('noConsent')) {
        return $localize`:@@FormHelper.consent.error.message:K uložení této hodnoty je nutné potvrdit souhlas se zpracováním osobních dat`;
      }

      if (component.hasError('bornNum')) {
        return $localize`:@@FormHelper.bornNum.error.message:Chybný formát`;
      }

      if (component.hasError('dateTimeInvalid')) {
        return $localize`:@@FormHelper.dateTimeInvalid.error.message:Neplatné datum`;
      }

      if (component.hasError('notUnique')) {
        return $localize`:@@FormHelper.notUnique.error.message:Tato hodnota je již v systému obsazena, prosím vyberte jinou.`;
      }

      return $localize`:@@FormHelper.other.error.message:Chybný formát`;
    }
    else if (form && form.hasError('dateRangeInvalid')) {
      return $localize`:@@FormHelper.dateRangeInvalid.error.message:Datum konce intervalu nesmí být menší, než datum počátku`;
    }

    return '';
  }

  public isVisible(component: AbstractControl): boolean {
    return (component as MangoFormControl).visible;
  }

  public appearance(): string {
    return environment.formAppearance;
  }

  // Nakonfiguruje formulář podle defaultního nastavení - viditelnost, editovatelnost, defaultní hodnoty, povinný údaj.
  // V db si nastavení pro formuláře definuji "[SessionService.RIGHT_BASE].formName.controlName",
  // (v konstantě SessionService.RIGHT_BASE mám centrálně uložený string, kterým všechna SC práva začínají)
  // např.: "SELFCARE.userEdit.firstName"
  // formName si definuji ve formuláři při volání této metody, controlName získávám procházením FormGroup
  // TODO: Práce s hvězdičkovými hodnotami.
  // TODO: Předpokládám, že toto bude využívat primárně Mango, takže to je svázané s ním.
  public defaultConfig(formGroup: FormGroup, formName: string, options?: any, preparedConf?: any): void {
    // Vytáhnu si data (pro zanořené formuláře to udělám jen jednou)
    if (!preparedConf && options && options[SessionService.RIGHT_BASE] && options[SessionService.RIGHT_BASE][formName]) {
      preparedConf = options[SessionService.RIGHT_BASE][formName];
    }

    // Pokud žádné nastavení neexistuje, nic neřeším a končím
    if (!preparedConf) {
      return;
    }
    (Object as any).keys(formGroup.controls).forEach(key => {

      if ((formGroup.controls[key] as FormGroup).controls) {
        this.defaultConfig((formGroup.controls[key] as FormGroup), formName, options, preparedConf);
        return;
      } else {
        const control = formGroup.controls[key] as MangoFormControl;

        if (preparedConf[key]) {
          const confValue = preparedConf[key];
          if (confValue.includes('hidden')) {
            control.hide('default-form-config');
          }

          if (confValue.includes('disabled')) {
            control.disable();
          }

          if (confValue.includes('required')) {
            control.setValidators([Validators.required]);
          }

          // Opsáno ze Session->LoadUserRights()
          // Default má formát default=>hodnota, kde hodnota je buď přímá
          // hodnota jako 10, Ahoj. Pokud by se v hodnotě měla vyskytovat
          // mezera, je potřeba psát hodnotu do uvozovek - např. "Michal
          // Hornych". Pokud v hodnotě má být uvozovka, tak se musí napsat
          // do uvozovek a ještě ta uvozovka oescapovat - tedy. např.
          // "Uvozovka je \"."
          const matches = confValue.match('default=>((?:[^ "]+)|(?:"(?:[^"]|\")*"))');
          if (matches) {
            control.setValue(matches[1]);
          }
        }
      }
    });
  }

}


