import {Injectable} from '@angular/core';
import {DataBase} from '@og_soft/data-base';

@Injectable({
  providedIn: 'root'
})
export class NodeParamUniqueValidatorService extends DataBase<boolean> {
  // getallUrl = 'node-params-validation';

  protected getAllUrl(): string {
    return 'node-params-validation' as const;
  }
}
