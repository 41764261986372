<span [formGroup]="form">
  <span *ngIf="isVisible()">

    <!--Důvod proč není v mat-form-field - viz komentář u onCheckChange-->
    <ng-container *ngIf="param.controlType === PARAM_CONTROL_TYPE.check">
      <div class="mat-form-field-wrapper mango-form-field-wrapper-checkbox">
        <mat-checkbox [indeterminate]="param.value===null"
                      [id]="param.name"
                      [formControlName]="param.name"
                      [ngClass]="((!isValid && isDirty)?'mat-error':'')"
                      (change)="onCheckChange($event)"
        >{{param.caption + (required ? '*' : '')}}
        </mat-checkbox>
        <div class="mat-form-field-subscript-wrapper">
          <mat-hint>
            {{param.description}}
          </mat-hint>
        </div>
      </div>
    </ng-container>

    <!--
    Důvod, proč je datetime komponenta zvlášť, je detekce matsuffix, tato musí být přímo v  viz.
    https://github.com/angular/components/issues/19268
     -->
    <ng-container *ngIf="isDateTimeControlType(param.controlType)">
      <mat-form-field class="mango-form-field">
        <datetime-control [pickerType]="getDateTimeControlType(param.controlType)"
                          [formControlName]="param.name"
                          [id]="param.name" [placeholder]="param.caption" [required]="required"
                          #df1
        ></datetime-control>
        <datetime-control-trigger matSuffix [for]="df1"></datetime-control-trigger>
        <mat-hint>
        {{param.description}}
        </mat-hint>
      <mat-error *ngIf="!isValid && isDirty">
        {{getErrorMessage()}}
      </mat-error>
      </mat-form-field>
    </ng-container>

    <mat-form-field *ngIf="param.controlType !== PARAM_CONTROL_TYPE.check && !this.isDateTimeControlType(param.controlType)" [ngSwitch]="param.controlType" class="mango-form-field param-form-field">

      <input *ngSwitchCase="PARAM_CONTROL_TYPE.string" matInput [formControlName]="param.name"
             [id]="param.name" [placeholder]="param.caption" [required]="required">

      <textarea *ngSwitchCase="PARAM_CONTROL_TYPE.textBox" matInput [formControlName]="param.name"
                [id]="param.name" [placeholder]="param.caption" [required]="required"></textarea>

      <input *ngSwitchCase="PARAM_CONTROL_TYPE.number" matInput [formControlName]="param.name"
             [id]="param.name" [placeholder]="param.caption" [required]="required">

<!--       <popup-list *ngSwitchCase="PARAM_CONTROL_TYPE.popupList"-->
<!--                   [id]="param.name" [placeholder]="param.caption"-->
<!--                   [staticOptions]="param.getOptions()"-->
<!--                   [formControlName]="param.name"-->
<!--                   [filterDb]='false' [noValue]="param.required ? null : '&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;'"-->
<!--       ></popup-list>-->

      <mat-select  *ngSwitchCase="PARAM_CONTROL_TYPE.popupList"
                   [formControlName]="param.name" [id]="param.name" [placeholder]="param.caption" [required]="required"
      >
        <mat-option *ngIf="!required" [value]="">--------</mat-option>
        <mat-option *ngFor="let option of options" [value]="option.id">{{option.name}}</mat-option>
      </mat-select>

      <mat-select  *ngSwitchCase="PARAM_CONTROL_TYPE.multiSelect" [formControlName]="param.name" [id]="param.name" [placeholder]="param.caption" [required]="required" multiple>
        <mat-option *ngFor="let option of options" [value]="option.id">{{option.name}}</mat-option>
      </mat-select>

      <!--TODO: Input pro parametry PRICE a FILE-->
      <ng-container *ngIf="param.indexed && !param.isMultiSelectParam(true)">
      <button mat-icon-button
              *ngIf="param.index > 0 && !(param.group && param.group.type && param.group.type === 'TABLE')"
              matSuffix (click)="deleteParam($event)"><mat-icon>remove_circle</mat-icon></button>
      <button mat-icon-button
              *ngIf="!(param.group && param.group.type && param.group.type === 'TABLE')" matSuffix
              (click)="addParam($event)"><mat-icon>add_circle</mat-icon></button>
      </ng-container>
      <mat-hint>
        {{param.description}}
      </mat-hint>
      <mat-error *ngIf="!isValid && isDirty">
        {{getErrorMessage()}}
      </mat-error>
    </mat-form-field>
<!--    <mat-icon *ngIf="param.description"-->
<!--              (click)="tooltip.toggle()"-->
<!--              #tooltip="matTooltip"-->
<!--              matTooltip="{{param.description}}"-->
<!--              matTooltipPosition="above"-->
<!--              matTooltipClass="tool-tip"-->
<!--    >help_outline</mat-icon>-->

  </span>
</span>
