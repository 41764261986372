import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {SessionService} from '../_services/session.service';
import {filter, map} from 'rxjs/operators';
import {EditorBlockService, RecEditorBlock} from '../_services/editor-block.service';
import {DataProcess} from '../_libraries/mango-processes/data-process';
import {MatSidenav} from '@angular/material/sidenav';
import {MatLegacyButton as MatButton} from '@angular/material/legacy-button';


/**
 * Komponenta na jednu položku menu.
 *
 * Máme ji, abychom hloupě neopakovali pořád stejné věci (včetně zavření
 * šuplíku s vysunutým menu na mobilu při kliknutí na již aktivní routu).
 */
@Component({
  selector: 'sc-menu-item',
  template: `
    <a mat-list-item *ngIf="session.canView(ident)" [routerLink]="['/'+ident]" routerLinkActive="active" (click)="scnav.closeDrawer()">
      <ng-content></ng-content>
    </a>
  `
})
export class ScNavMenuItemComponent {
  @Input() ident: string;
  constructor(
    public session: SessionService,
    public scnav: ScNavComponent,
  ) {}
}


@Component({
  selector: 'app-sc-nav',
  templateUrl: './sc-nav.component.html',
  styleUrls: ['./sc-nav.component.scss']
})
export class ScNavComponent implements OnInit {
  pageTitle = '';
  handsetLayout = false;
  private _editorBlocks: RecEditorBlock[] = null;
  private _pageEditorBlocks: RecEditorBlock[] = null;
  get pageEditorBlocks(): RecEditorBlock[] {
    return this._pageEditorBlocks;
  }

  _showSubmenuServices = false;
  _showSubmenuBilling = false;
  _showBilling = false;

  public unresolvedProcesses = 0;

  @ViewChild('drawer', {static: false}) public drawer: MatSidenav;
  @ViewChild('drawerButton', {static: false}) drawerButton: MatButton;

  constructor(
    public session: SessionService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private breakpointObserver: BreakpointObserver,
    private editorBlockService: EditorBlockService,
    public processService: DataProcess,
  ) {
    // Když se přihlásím, potřebuji aktualizovat celé menu.
    // Kód pro odznáček s procesy opsán z Kubova v sc-main-toolbar
    this.session.loginChanged().subscribe(next => {
      if (next){
        this.initNavigation();
        this.updateUnresolvedProcesses();
      }
    });

    // Když provedu nějakou změnu procesu, ikonu nevyřízených procesů
    this.processService.getProcessChanged().subscribe(next => {
      if (next){
        this.updateUnresolvedProcesses();
      }
    });
  }

  ngOnInit(): void {
    // Nastavování titulku podle aktivní routy
    /*
	   * Tohle mi přestalo fungovat - Property 'filter' does not exist on type 'Observable<Event>'.
	   * Zkouším předělat na pipeable operator. - neúspěšně
	   */
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }))
      // .map((route) => {
      //   return route;
      // })
      .pipe(filter((route) => route.outlet === 'primary'))
      .subscribe((route) => {
        // Když se naviguje ve stejné routě, tak je potřeba posunout na top. Toto možná budeme muset dělat nějak
        // selektivně.
        window.scrollTo(0, 0);
        route.data.subscribe(data => {
          if (data.title) {
            this.pageTitle = this.routeTitle(null, data.title);
          } else {
            this.pageTitle = this.routeTitle(route.snapshot);
          }
          this.titleService.setTitle(this.pageTitle);
        });
        // Zavření sidebaru, pokud jsme na mobilu:
        this.closeDrawer();
      });

    // Nastavování "mobilního" vzhledu
    this.breakpointObserver.observe([
      '(max-width: 959px)',
    ]).subscribe(result => {
      this.handsetLayout = result.matches ? true : false;
    });

    this.initNavigation();
  }

  public closeDrawer(): void {
    if (this.handsetLayout) {
      if (this.drawer) {
        this.drawer.close();
      }
      // Stupidní fix. pro BUG.
      // Tlačítko vyouvající drawer (side-nav) zůstává focusované, pokud side-nav zavřeme programově. :(
      if (this.drawerButton) {
        this.drawerButton.ripple.trigger.classList.remove('cdk-focused', 'cdk-program-focused');
      }
    }
  }

  private updateUnresolvedProcesses(): void{
    this.processService.getall({hasJob: '1'}).subscribe(next => {
      this.unresolvedProcesses = next.data.length;
    });
  }

  /**
   * Věci, které je potřeba aktualizovat po přihlášení (jsou závislé na přihlášeném uživateli.
   */
  private initNavigation(): void{
    if (this.session.isLoggedIn()) {
      /* Redakční bloky všechny: */
      this.editorBlockService.getall().subscribe(result => {
        this._editorBlocks = result.data;
        /* Redakční bloky do záložek: */
        this._pageEditorBlocks = this._editorBlocks.filter(el => el.placement === 'tabpage');
        // console.log("Všechny redakční bloky", result.data);
      });
    }
  }

  showBilling(): void {
    this._showBilling = !this._showBilling;
    if (this._showBilling) {
      this.router.navigateByUrl('/billing');
    }
  }

  /**
   * Obecná metoda pro zjištění nadpisu stránky podle routy, když není staticky v route.data.
   * @param route Aktivní routa, pro kterou je potřeba zjistit title.
   * @param titleString Pokud není routa, parametr pro zadání titulku explicitně
   * @return string Titulek podle routy.
   */
  protected  routeTitle(route: ActivatedRouteSnapshot, titleString?: string): string {
    let title = '';
    if (route) {
      switch (route.url[0].path) {
        case 'page-editor-block':
          title = this.editorBlockTitle(route.params.id);
          break;
      }
    }
    if (title) {
      title += ' - ';
    }
    const commonTitle = $localize`:@@ScNav.PageTitle:Samoobsluha`;
    title += commonTitle;
    return title;
  }

  /**
   * Zjistí title pro routu na stránky tvořené redakčním blokem. Název se hledá v nalezených redakčních blocích.
   * @param id Id redakčního bloku.
   */
  editorBlockTitle(id: number): string {
    for (let i = 0; (this._editorBlocks !== null) && (i < this._editorBlocks.length); i++) {
      if (this._editorBlocks[i].ttId === id) {
        return this._editorBlocks[i].ttName;
      }
    }
    return null;
  }

  getEditorBlockByPlacement(placement: string): RecEditorBlock {
    return this._editorBlocks ?
      this._editorBlocks.find(el => el.placement === placement) : null;
  }

  public isSubmenuActive(name: string): boolean {
    const parents =
      { '/documents-agreements': 'services'
      , '/user-services': 'services'
      , '/telephony': 'services'
      , '/data': 'services'
      , '/billing': 'billing'
      , '/user-payments': 'billing'
      , '/documents-other': 'billing'
      , '/unicredit': 'billing'
      };
    const active = (parents[this.router.url] === name);
    if (active && name === 'billing' && !this._showSubmenuBilling) {
      this._showSubmenuBilling = true;
    }

    if (active && name === 'services' && !this._showSubmenuServices) {
      this._showSubmenuServices = true;
    }
    return active;
  }
}
